import * as yup from "yup";
import { GetSecurityTokenValidator } from "app/validation/securityTokenValidator";
import { IndueCardStatusFormProps } from "./IndueCardConfirmStatusChange";
import { IndueCardStatus } from "generated/generated-models";

export const Validate = (requiresMfa: boolean) => {
    const schema: yup.ObjectSchema<IndueCardStatusFormProps> = yup.object({
        selectedStatus: yup
            .mixed<IndueCardStatus>()
            .required()
            .label("Status"),

        twoFactorToken: GetSecurityTokenValidator(requiresMfa)
    })
    .defined();

    return schema;
}
