import * as yup from "yup";
import { nameof } from "core/util/nameof";
import { ChangePasswordFormProps } from "./ChangePasswordPage";
import { GetSecurityTokenValidator } from "app/validation/securityTokenValidator";

export const Validate = (requiresMfa: boolean) => {
    const schema: yup.ObjectSchema<ChangePasswordFormProps> = yup.object({
        confirmNewPassword: yup
            .string()
            .required()
            .oneOf(
                [yup.ref(nameof<ChangePasswordFormProps>("newPassword"))],
                "Passwords must match"
            )
            .label("Confirm Password"),

        oldPassword: yup
            .string()
            .required()
            .min(6)
            .label("Old Password"),

        newPassword: yup
            .string()
            .required()
            .min(6)
            .matches(/[A-Z]/, 'At least one upper case letter required')
            .matches(/[a-z]/, 'At least one lower case letter required')
            .matches(/[0-9]/, 'At least one number required')
            .matches(/[!|@|#|$|%|^|&|*|(|)|-|_]/, 'At least one special character required')
            .label("New Password"),

        securityToken: GetSecurityTokenValidator(requiresMfa)

    }).defined();
    return schema;
}
