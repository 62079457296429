type Props = {
    message: string;
    visible?: boolean;
}

/** Use for displaying errors when submitting a form, etc */
const Alert = ({ message, visible = true }: Props) => {
    if (!visible) {
        return null;
    }

    return (
        <div className="alert alert-danger inline-alert">
            {message}
        </div>
    )
}

export default Alert;
