import * as React from "react";
import { IsNullOrWhiteSpace } from 'core/util/stringExtensions';
import { Link, NavLink, useRouteMatch } from "react-router-dom";
import { useIsUserAuthenticatedQuery, useUnreadMessagesCountByPartyQuery } from "generated/generated-models";
import { clientConfigFn } from 'clientConfig';
import TermsAndConditions from "../../other/TermsAndConditions";
import PrivacyPolicy from "../../other/PrivacyPolicy";
import { AppBar, Badge } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import AccountIcon from '@mui/icons-material/AccountBalance';
import CloseIcon from '@mui/icons-material/Close';
import SignoutIcon from '@mui/icons-material/Logout';
import BadgeIcon from '@mui/icons-material/Badge';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import MailIcon from '@mui/icons-material/Mail';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import FeedIcon from '@mui/icons-material/Feed';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import SanitisedLogo from "app/components/SanitisedLogo";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';

const drawerWidth = 260;
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const Navbar = () => {
    const accountMatch = useRouteMatch<{ accountId: string }>("/Account/Index/:accountId/");
    const { data: userSessionData, error: userSessionError, loading: userSessionLoading } = useIsUserAuthenticatedQuery({
        partialRefetch: true,
        notifyOnNetworkStatusChange: true,
        errorPolicy: "all"
    });

    const { data: unreadMessagesCountByParty} = useUnreadMessagesCountByPartyQuery({
        partialRefetch: true,
        notifyOnNetworkStatusChange: true,
        errorPolicy: "all"
    });

    const { hideTransferPage, allowOnLineStatements } = clientConfigFn();
    const loggedIn = userSessionData && !userSessionError && !userSessionLoading;

    const clientConfig = clientConfigFn();

    const [openNav, setOpenNav] = React.useState(false);
    const toggleNav = (event: any) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) { return; }
        setOpenNav(!openNav);
    };
    const handleDrawerCloseNav = () => {
        setOpenNav(false);
    };
    const [openHelp, setOpenHelp] = React.useState(false);
    const handleClickHelp = () => {
      setOpenHelp(!openHelp);
    };
    const [openTerms, setOpenTerms] = React.useState(false);
    const handleClickOpenTerms = () => {
        setOpenTerms(true);
    };
    const handleCloseTerms = () => {
        setOpenTerms(false);
    };
    const [openPrivacy, setOpenPrivacy] = React.useState(false);
    const handleClickOpenPrivacy = () => {
        setOpenPrivacy(true);
    };
    const handleClosePrivacy = () => {
        setOpenPrivacy(false);
    };

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    }));
    const [value, setValue] = React.useState(0);

    return (
        <Box sx={{ display: 'flex' }}>
        <CssBaseline />
            <AppBar position="fixed" className= "appbar">
                <Toolbar>
                {loggedIn &&
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={toggleNav}
                        id="openDrawer"
                    >
                        <MenuIcon />
                    </IconButton>
                }
                {!IsNullOrWhiteSpace(clientConfig.logoImageVariantUri) &&
                    <div className="flex-fill logo-wrapper">
                        <SanitisedLogo 
                            logoUri={clientConfig.logoImageVariantUri ? clientConfig.logoImageVariantUri : clientConfig.logoImageUri}
                            siteTitle={clientConfig.siteTitle}
                        />
                    </div>
                }
                {loggedIn && clientConfigFn().displaySecureMessagePage &&
                    <IconButton
                    id="secure-messaging-button"
                    color="inherit"
                    component={Link}
                    to={`/Security/SecureMessaging`}
                    edge="end"
                    sx={{ mr: 0.7 }}
                        >
                        <Badge badgeContent={unreadMessagesCountByParty?.secureMessagesByParty?.UnreadMessageCount ?? 0} color="error">
                            <MailIcon />
                        </Badge>
                    </IconButton>
                }
                {loggedIn &&
                    <IconButton
                        color="inherit"
                        component={Link}
                        to={`/Security/ProfileSettings`}
                        edge="end"
                        id="openProfileSettings"
                    >
                        <AccountCircle />
                    </IconButton>
                }
                </Toolbar>
            </AppBar>
        <Drawer
            className="nav-drawer"
            variant={"temporary"}
            anchor="left"
            open={openNav}
            onClose={toggleNav}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: drawerWidth,
                },
                zIndex: 'tooltip'
              }}
            >
            <DrawerHeader>
                <IconButton onClick={handleDrawerCloseNav} id="closeDrawerButton">
                    <ChevronLeftIcon />
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List className="nav-drawer-container">
                {!loggedIn && (window.location.pathname !== "/security/login") &&
                    <NavLink to="/" onClick={toggleNav} activeClassName="active" id="homeButton">
                        <ListItem button>
                            <ListItemIcon><HomeIcon /></ListItemIcon>
                            <ListItemText primary='Home' />
                        </ListItem>
                    </NavLink>
                }
                {loggedIn &&
                    <NavLink to="/" onClick={toggleNav} exact activeClassName="active" id="homeButton">
                        <ListItem button>
                            <ListItemIcon><HomeIcon /></ListItemIcon>
                            <ListItemText primary='Home' />
                        </ListItem>
                    </NavLink>
                }
                {loggedIn &&
                    <NavLink to={`/account/accountlist`} onClick={toggleNav} title="Accounts" exact activeClassName="active" id="accountsButton">
                        <ListItem button>
                            <ListItemIcon><AccountIcon /></ListItemIcon>
                            <ListItemText primary='Accounts'/>
                        </ListItem>
                    </NavLink>
                }  
                {accountMatch &&
                  <div className="drawer-subnav">
                    <NavLink to={`${accountMatch.url}`} title={`Account - ${accountMatch.params.accountId}`} onClick={toggleNav} activeClassName="active" id="accountButton">
                        <ListItem button>
                            <ListItemIcon><BadgeIcon /></ListItemIcon>
                            <ListItemText primary='Account' secondary={`${accountMatch.params.accountId}`}/>
                        </ListItem>
                    </NavLink>
                     {/* <Collapse in={openSub} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <>
                                {accountMatch && !hideTransferPage &&
                                    <NavLink to={`${accountMatch.url}/Transfer`} title="Scheduled Transfers" onClick={toggleNav} exact activeClassName="active">
                                        <ListItem button>
                                            <ListItemIcon><CurrencyExchangeIcon /></ListItemIcon>
                                            <ListItemText primary="Scheduled Transfers" />
                                        </ListItem>
                                    </NavLink>
                                }
                                {allowDisbursements &&
                                    <NavLink to={`${accountMatch.url}/disbursement`} title="Request Payment" onClick={toggleNav} exact activeClassName="active">
                                        <ListItem button>
                                            <ListItemIcon><RequestQuoteIcon /></ListItemIcon>
                                            <ListItemText primary="Request Payment" />
                                        </ListItem>
                                    </NavLink>
                                }
                            </>
                            {accountMatch?.params.accountId &&
                                <CardNavLink accountNumber={accountMatch.params.accountId} url={accountMatch.url} />
                            }
                        </List>
                    </Collapse> */}
                  </div>
                }
                {loggedIn && !hideTransferPage &&
                    <NavLink to={"/transfersAndPayments"} onClick={toggleNav} title="Transfers &amp; Payments" exact activeClassName="active" id="transfersPageButton">
                        <ListItem button>
                            <ListItemIcon><CurrencyExchangeIcon /></ListItemIcon>
                            <ListItemText primary={`Transfers & Payments`} />
                        </ListItem>
                    </NavLink>
                }
                {loggedIn && allowOnLineStatements &&
                    <NavLink to={`/statements/StatementsListForParty`} onClick={toggleNav} title="All Statements" exact activeClassName="active" id="statementsPageButton">
                        <ListItem button>
                            <ListItemIcon><FeedIcon /></ListItemIcon>
                            <ListItemText primary='All Statements' />
                        </ListItem>
                    </NavLink>
                }
                {loggedIn &&
                    <NavLink to={"/transaction/TransactionsListForParty"} onClick={toggleNav} title="All Transactions" exact activeClassName="active" id="transactionsPageButton">
                        <ListItem button>
                            <ListItemIcon><CompareArrowsIcon /></ListItemIcon>
                            <ListItemText primary='All Transactions' />
                        </ListItem>
                    </NavLink>
                }
            </List>
            <List className="nav-help">
                <div>
                    <ListItemButton onClick={handleClickHelp} title="Help" id="helpButton">
                        <ListItemIcon><SupportOutlinedIcon /></ListItemIcon>
                        <ListItemText primary='Help'/>
                        {openHelp ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                     <Collapse in={openHelp} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {clientConfig.showTermsAndConditions &&
                            <div className="button-container">
                                <ListItemButton onClick={handleClickOpenTerms} title="Terms and Conditions" id="openTermsAndConditions">
                                    <ListItemIcon><GavelOutlinedIcon /></ListItemIcon>
                                    <ListItemText primary='Terms and Conditions' />
                                </ListItemButton>
                                <Dialog
                                    className="terms-conditions-dialog"
                                    fullScreen
                                    open={openTerms}
                                    onClose={handleCloseTerms}
                                    TransitionComponent={Transition}
                                >
                                    <AppBar className="appbar-legal" sx={{ position: 'fixed' }}>
                                    <Toolbar>
                                        <Typography sx={{ ml: 2, flex: 1 }} component="div">
                                        Terms and Conditions
                                        </Typography>
                                        <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={handleCloseTerms}
                                        aria-label="close"
                                        id="closeTermsAndConditions"
                                        >
                                        <CloseIcon />
                                        </IconButton>
                                    </Toolbar>
                                    </AppBar>
                                    <TermsAndConditions />
                                </Dialog>
                            </div>
                            }
                            {clientConfig.showPrivacyPolicy &&
                            <div className="button-container">
                                <ListItemButton onClick={handleClickOpenPrivacy} title="Privacy Policy" id="openPrivacyPolicy">
                                    <ListItemIcon><PolicyOutlinedIcon /></ListItemIcon>
                                    <ListItemText primary='Privacy Policy' />
                                </ListItemButton>
                                <Dialog
                                    className="privacy-policy-dialog"
                                    fullScreen
                                    open={openPrivacy}
                                    onClose={handleClosePrivacy}
                                    TransitionComponent={Transition}
                                >
                                    <AppBar className="appbar-legal" sx={{ position: 'fixed' }}>
                                    <Toolbar>
                                        <Typography sx={{ ml: 2, flex: 1 }} component="div">
                                            Privacy Policy
                                        </Typography>
                                        <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={handleClosePrivacy}
                                        aria-label="close"
                                        id="closePrivacyPolicy"
                                        >
                                        <CloseIcon />
                                        </IconButton>
                                    </Toolbar>
                                    </AppBar>
                                    <PrivacyPolicy />
                                </Dialog>
                            </div>
                            }
                            {clientConfig.clientVersion === "" || clientConfig.showClientVersion === false ?
                                <></> : 
                                <ListItem title={`Version - ${clientConfig.clientVersion}`}>
                                    <ListItemIcon><InfoOutlinedIcon /></ListItemIcon>
                                    <ListItemText primary='Version' secondary={`${clientConfig.clientVersion}`}/>
                                </ListItem>
                            }
                        </List>
                    </Collapse>
                </div>
                <Divider />
                <Link to={`/Security/Logout`} title="Log Out" id="logOutButton">
                    <ListItem button>
                        <ListItemIcon><SignoutIcon /></ListItemIcon>
                        <ListItemText primary='Log Out' />
                    </ListItem>
                </Link>
            </List>
        </Drawer>
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 'modal' }} elevation={3}>
            <BottomNavigation
                id="footer-quick-nav"
                className="bottom-quick-nav"
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
                {loggedIn &&
                    <BottomNavigationAction id="footer-quick-nav-home" label="Home" icon={<HomeIcon />} component={NavLink} to="/" />
                }
                {loggedIn &&
                    <BottomNavigationAction id="footer-quick-nav-accounts" label="Accounts" icon={<AccountIcon />} component={NavLink} to={`/account/accountlist`} />
                }   
                {loggedIn &&
                    <BottomNavigationAction id="footer-quick-nav-transfers" label="Pay" icon={<CurrencyExchangeIcon />} component={NavLink} to={`/transfersAndPayments`}/>
                }   
            </BottomNavigation>
        </Paper>
    </Box>
    );
};

export {
    Navbar
}
