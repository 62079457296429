import { MutationResult, MutationFunctionOptions, FetchResult, MutationTuple } from "@apollo/client";
import { GraphQLError } from "graphql";
import { useState } from "react";

type ExtendedMutationResult<TData> = MutationResult<TData> & { tokenRequired: boolean }

type extendedMutation<TData, TVariables> = [(options?: MutationFunctionOptions<TData, TVariables>) => Promise<FetchResult<TData>>, ExtendedMutationResult<TData>];

function isTokenRequiredError(error: GraphQLError) {
    if (error.extensions?.code === "AUTH_TOKEN_REQUIRED") {
        return true;
    }

    return false;
}

export function useSecurityTokenMutation<TData, TVariables>(mutationResult: MutationTuple<TData, TVariables>) {
    const [tokenRequired, setTokenRequired] = useState(false);

    const originalResponse = mutationResult[1];

    if (originalResponse.error?.graphQLErrors?.find(error => isTokenRequiredError(error))) {
        if (!tokenRequired) {
            setTokenRequired(true);
        }
    }

    if (originalResponse.error?.graphQLErrors != null) {
        var filteredErrors = originalResponse.error?.graphQLErrors.filter(error => !isTokenRequiredError(error));

        if (filteredErrors.length === 0) {
            originalResponse.error = undefined;
        } else {
            originalResponse.error.graphQLErrors = filteredErrors;
        }
    }

    (mutationResult[1] as ExtendedMutationResult<TData>).tokenRequired = tokenRequired;

    return mutationResult as extendedMutation<TData, TVariables>;
}
