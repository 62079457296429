import * as yup from "yup";
import { InterAccountFormProps } from "./CreateInterAccount";
import { validateSchedule, validateTransferDescription } from "../utilities/validation";
import { isSameDay } from "date-fns";
import { FinanceSummaryQueryDocument, FinanceSummaryQueryQuery } from "generated/generated-models";
import { client } from "core/apollo/client";
import { getCurrencySymbol } from "core/util/currencySymbol"

export const Validate = (accountId?: string) => {
    const schema: yup.ObjectSchema<InterAccountFormProps> = yup.object({        

        transferDescription: validateTransferDescription("Description"),
        transferReference: validateTransferDescription("Reference"),

        startingOn: yup
            .date()
            .required(),

        amount: yup
            .number()
            .required()
            .moreThan(0)
            .label("Amount")
            //eslint-disable-next-line
            .test("transferLimit", "${path} is greater than the available transfer", async function (amount) {
                const startingOn = this.parent.startingOn;

                // Don't check limit in the future as it may change
                if (isSameDay(startingOn, new Date()) === false) {
                    return true;
                }

                const result = await client.query<FinanceSummaryQueryQuery>({
                    query: FinanceSummaryQueryDocument
                });

                var availableTransfer = result.data?.financeSummary?.Instruments
                    ?.flatMap(instrument => instrument?.Accounts)
                    ?.find(account => account?.Id === accountId)
                    ?.Redraw;

                if (amount == null || availableTransfer == null) {
                    return false;
                }

                if (amount > availableTransfer) {
                    return this.createError({ message: `Amount is greater than the available transfer of ${getCurrencySymbol()}${availableTransfer}` });
                }

                return true;
            }),

        ...validateSchedule()
    })
        .defined();

    return schema;
}
