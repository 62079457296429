import * as yup from "yup";
import { GetSecurityTokenValidator } from "app/validation/securityTokenValidator";
import { IndueCardActivationInput } from "./IndueCardConfirmStatusChange";

const config = {
	CardPinFixedLength: 4,
};

export const ValidateActivation = (requiresMfa: boolean) => {
    const schema: yup.ObjectSchema<IndueCardActivationInput> = yup.object({

        NewPin: yup
            .string()
            .required("New PIN is required")
            .matches(new RegExp(`^[0-9]{${config.CardPinFixedLength}}$`), `PIN to be exactly ${config.CardPinFixedLength} digits`),
        
        ConfirmPin: yup
            .string()
            .required("Please confirm your New PIN")
            .oneOf([yup.ref('NewPin')], "PIN's do not match"),
            
            twoFactorToken: GetSecurityTokenValidator(requiresMfa)
        })
    .defined();

    return schema;
}
