import CreateRedraw from "./CreateRedraw";
import ConfirmRedraw from "./ConfirmRedraw";
import RedrawReceipt from "./RedrawReceipt";
import TransferWizard from "../utilities/TransferWizard";
import SelectRedraw from "./SelectRedraw";

const RedrawPage = (props: { accountId: string }) => {
    return (
        <TransferWizard accountId={props.accountId} useSelectionPage={true} authorityType={"redraw"}>
            <TransferWizard.Select>
                <SelectRedraw />
            </TransferWizard.Select>
            <TransferWizard.Create>
                <CreateRedraw />
            </TransferWizard.Create>
            <TransferWizard.Confirm>
                <ConfirmRedraw />
            </TransferWizard.Confirm>
            <TransferWizard.Receipt>
                <RedrawReceipt />
            </TransferWizard.Receipt>
        </TransferWizard>
    )
}

export default RedrawPage;
