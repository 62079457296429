import { ResetPasswordInput } from "generated/generated-models";
import { Formik, Field } from "formik";
import React from "react";
import { CInputFormGroup } from "app/common/forms/CInputFormGroup";
import { nameof } from "core/util/nameof";
import { StringIsEqual } from "core/util/stringExtensions";
import {Validate} from "./ResetPasswordInputValidator";
import { LoadingButton } from '@mui/lab';
import Form from "app/components/Form";
import Alert from '@mui/material/Alert';

interface Props {
    onSubmit: (values: ResetPasswordInput) => void;
    submitting?: boolean;
}

const ResetPasswordForm: React.FC<Props> = ({ onSubmit, submitting}) => {
    return (
        <Formik<ResetPasswordInput>
            initialValues={{ ContactDetails: "", Username: "", ResetMethod: "" }}
            onSubmit={onSubmit}
            validationSchema={Validate()}
        >
            {({ values }) => {
                const registerdDetailsMessage = () => {
                    if (values.ResetMethod === "sms") {
                        return `Please verify your preferred mobile number below.
                        If this does not match the number on record then no password reset will be sent`
                    } else {
                        return `Please verify your preferred email address below.
                        If this does not match the address on record then no password reset will be sent`
                    }
                }

                return (
                    <Form>
                    <div id="reset-password-wrapper">
                        <CInputFormGroup
                            fieldName={nameof<ResetPasswordInput>("Username")}
                            placeholderText="Username"
                        />
                        <div className="form-group">
                            <Alert severity="info">Please choose a method to receive your temporary password.</Alert>
                            <div className="radio form-check">
                                <Field
                                    name={nameof<ResetPasswordInput>("ResetMethod")}
                                    checked={StringIsEqual("sms", values.ResetMethod)}
                                    id={`${nameof<ResetPasswordInput>("ResetMethod")}-sms`}
                                    type="radio"
                                    value="sms"
                                    className="form-check-input"
                                />
                                <label className="form-check-label" htmlFor={`${nameof<ResetPasswordInput>("ResetMethod")}-sms`}>
                                    Send via SMS
                            </label>
                            </div>

                            <div className="radio form-check">
                                <Field
                                    name={nameof<ResetPasswordInput>("ResetMethod")}
                                    checked={StringIsEqual("email", values.ResetMethod)}
                                    id={nameof<ResetPasswordInput>("ResetMethod")}
                                    type="radio"
                                    value="email"
                                    className="form-check-input"
                                />
                                <label className="form-check-label" htmlFor={nameof<ResetPasswordInput>("ResetMethod")}>
                                    Send via E-mail
                            </label>
                            </div>
                        </div>

                        {values.ResetMethod && (<div className="form-group">
                            <Alert severity="info">{registerdDetailsMessage()}</Alert>

                            <CInputFormGroup
                                fieldName={nameof<ResetPasswordInput>("ContactDetails")}
                                label={
                                    !values.ResetMethod
                                        ? "Contact Details"
                                        : StringIsEqual("sms", values.ResetMethod)
                                            ? "Mobile number"
                                            : "Primary email"
                                }
                                placeholderText={
                                    !values.ResetMethod
                                        ? "Contact Details"
                                        : StringIsEqual("sms", values.ResetMethod)
                                            ? "Mobile number"
                                            : "Primary email"
                                }
                            />
                        </div>)}
                        <LoadingButton
                            type="submit" id="submit"
                            sx={{ p: 1.5, mt: 1 }} fullWidth
                            loadingPosition="end"
                            variant="contained" 
                            loading={submitting}
                            >
                            Request Password
                        </LoadingButton>
                    </div>
                    </Form>
                )
            }}
        </Formik>
    );
};

export {
    ResetPasswordForm
}
