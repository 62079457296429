import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CError } from "app/common/CError";
import Title from "app/components/Title";
import { Loading } from "app/lib/components/Loading";
import classNames from "classnames";
import { groupBy } from "core/util/array";
import { BpayBiller, useBpayBillerHistoryQuery } from "generated/generated-models";
import React, { useState } from "react";
import AddBpayBiller from "../ManageBpayBillerHistory/AddBpayBiller";
import { useTransferWizard } from "../utilities/TransferWizard";

type BillerCardProps = { letter: string, billers: BpayBiller[] };

const BillerCard = ({ letter, billers }: BillerCardProps) => {
    const transferWizard = useTransferWizard();
    billers = billers.sort((a, b) => a.BillerName! > b.BillerName! ? 1 : -1)

    return (
        <div className="select-destination">
            <div className="card-header">
                {letter}
            </div>
            <ul className="list-group list-group-flush">
                {billers
                    .map(biller => {
                    const billerNameClass = classNames({ "text-muted": biller.BillerDescription != null })
                    return (
                        <li className="list-group-item" key={biller.Id} onClick={() => transferWizard.confirmBiller(biller)}>
                            <div>{biller.BillerDescription}</div>
                            <div className={billerNameClass}>{biller.BillerName}</div>
                            <div className="text-muted">{biller.Billercode} {biller.CustomerReferenceNumber}</div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

const SelectBpay = () => {
    const { loading, error, data } = useBpayBillerHistoryQuery();
    const [createNewBiller, setCreateNewBiller] = useState(false);
    const transferWizard = useTransferWizard();

    const handleCreateNewBillerClick = () => {
        setCreateNewBiller(true);
    }

    if (loading) {
        return <Loading />
    }

    if (error || data?.userSession?.Party?.BpayBillerHistory == null) {
        return <CError error="Could not load Redraw details" />
    }

    if (createNewBiller) {
        return <AddBpayBiller
            handleClose={() => undefined}
            goBack={() => setCreateNewBiller(false)}
            billerAdded={biller => transferWizard.confirmBiller(biller)}
        />
    }

    const billerHistory = groupBy(data?.userSession?.Party?.BpayBillerHistory,
        (biller) => (biller.BillerDescription ?? biller.BillerName!).toUpperCase()[0] || (biller.BillerName!).toUpperCase()[0],
        (firstKey, secondKey) => firstKey.localeCompare(secondKey))

    return (
        <div>
            <Title title="Select a Biller" />
            <div className="card">
                <div className="select-destination select-new">
                    <div className="card-header">
                        New Biller
                    </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item" onClick={handleCreateNewBillerClick}>
                            <div>Pay new biller</div>
                            <div><FontAwesomeIcon icon={faPlus} size="lg" /></div>
                        </li>
                    </ul>
                </div>
                {billerHistory.map(x =>
                    <BillerCard billers={x.elements} letter={x.key} key={x.key} />
                )}
            </div>
        </div>
    )
}

export default SelectBpay;
