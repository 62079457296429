import React from "react";
import {
    LogoutPayload,
    BankingUserLogoutComponent,
} from "generated/generated-models";
import gql from "graphql-tag";

export const Logout = (props: {
    onLogout?: (data?: LogoutPayload) => void;
}) => {
    const { onLogout } = props;
    return (
        <BankingUserLogoutComponent
            onCompleted={data => {
                if (onLogout) {
                    onLogout(data.logout || undefined);
                }
            }}
            onError={error => {
                if (onLogout) {
                    onLogout();
                }
            }}
        >
            {(logoutMuation, { data, error, called }) => {
                if (!called) {
                    logoutMuation().catch(err => {
                        if (onLogout) {
                            onLogout();
                        }
                    });
                }
                return(
                    <div className="content-wrapper flex-fill">
                        <div className="container sub-wrap">
                            <p className="logout-message">Logged out! Please close your browser.</p>
                        </div>
                    </div>
                )
            }}
        </BankingUserLogoutComponent>
    );
};

export const bankingUserLogoutMutation = gql`
    mutation bankingUserLogout {
        logout {
            Code
            Success
            Message
        }
    }
`;
