import { addTimeZoneOffset } from "core/util/dateExtensions";
import { RecurrenceEndEnum, RequestPayAnyoneTransferInput, TaskFrequencyPeriod } from "generated/generated-models";
import { TransferScheduleInputValues } from "./TransferScheduleInput";

export const transferTypes = {
    DirectDebitTransferTypeId: "4506315D-27A5-480F-AFED-9F91008C46D0",
    RepaymentTransferTypeId: "4506315D-27A5-480F-AFED-9F91008C46D0",
    DirectCreditTransferTypeId: "18D26CAD-F9B5-4870-BB8A-9F91008B6AA4",
    PayAnyoneTransferTypeId: "18D26CAD-F9B5-4870-BB8A-9F91008B6AA4",
    RedrawTransferTypeId: "18D26CAD-F9B5-4870-BB8A-9F91008B6AA4",
    BpayTransferTypeId: "0299344C-B7A3-4E2B-A812-B069A3A29DC6",
    InterAccountTransferTypeId: "7474C57E-BC94-4DCC-8740-9F91008ECBE4"
}

export const createTransferTimes = (recurrence: Partial<TransferScheduleInputValues>) => {
    const getFrequency = () => {
        if (recurrence.recurrenceEnd === RecurrenceEndEnum.Once) {
            return TaskFrequencyPeriod.Once;
        } else {
            return recurrence.frequency || TaskFrequencyPeriod.Once;
        }
    }

    const getNumberOfTimes = () => {
        if (recurrence.recurrenceEnd === RecurrenceEndEnum.Numberofocurrences) {
            return recurrence.numberOfTimesAmount;
        } else {
            return 0;
        }
    }

    const getEndDate = () => {
        if (recurrence.recurrenceEnd === RecurrenceEndEnum.Bydate) {
            return addTimeZoneOffset(recurrence.endingOn!);
        } else {
            return undefined;
        }
    }

    return {
        StartingOn: addTimeZoneOffset(recurrence.startingOn || new Date()),
        Frequency: getFrequency(),
        RecurrenceEnd: recurrence.recurrenceEnd,
        NumberOfTimesAmount: getNumberOfTimes(),
        EndingOn: getEndDate()
    }
}

/** Create a base transfer object */
export const createTransferVariable = (
    accountId: string,
    amount: number,
    withdrawalDescription: string,
    depositDescription: string,
    recurrence: Partial<TransferScheduleInputValues>
) => {
    const times = createTransferTimes(recurrence);

    const input: RequestPayAnyoneTransferInput = {
        TransferType: "00000000-0000-0000-0000-000000000000",
        StartingOn: times.StartingOn,
        Frequency: times.Frequency,
        RecurrenceEnd: times.RecurrenceEnd,
        NumberOfTimesAmount: times.NumberOfTimesAmount,
        FixedAmountOption: true,
        Amount: amount,
        BankAccountId: "00000000-0000-0000-0000-000000000000",
        ToAccountId: "00000000-0000-0000-0000-000000000000",
        WithdrawalDescription: withdrawalDescription,
        DepositDescription: depositDescription,
        AccountId: accountId,
        FromAccountId: "00000000-0000-0000-0000-000000000000",
        EndingOn: times.EndingOn
    }

    return input;
}
