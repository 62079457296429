import BankingCutOff from "app/components/BankingCutoff";
import Form from "app/components/Form";
import Input from "app/components/Input";
import { Loading } from "app/lib/components/Loading";
import { nameof } from "core/util/nameof";
import { Formik, useFormikContext } from "formik";
import {
    useInstrumentAuthorityAccountsQuery,
    TaskFrequencyPeriod,
    RecurrenceEndEnum
} from "generated/generated-models";
import TransferScheduleInput, { TransferScheduleInputValues } from "../utilities/TransferScheduleInput";
import { Validate } from "./CreateInterAccountValidate";
import { useTransferWizard } from "../utilities/TransferWizard";
import { CError } from "app/common/CError";
import FutureDatedWarningText from "app/components/FutureDatedWarningText";
import Button from "app/components/Button";
import Title from "app/components/Title";
import FromAccountCard from "app/components/fundsTransferCards/FromAccountCard";
import FundsTransferCardsContainer from "app/components/fundsTransferCards/FundsTransferCardsContainer";
import PaymentCard from "app/components/PaymentCard/PaymentCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import Alert from "app/components/Alert";

export type InterAccountFormProps = {
    transferReference?: string;
    transferDescription?: string;
    amount?: number;
} & TransferScheduleInputValues

type Props = {
    fromAccountId: string;
    toAccountId?: string;
}

const CreateInterAccount = ({ fromAccountId }: Props) => {
    const { interAccountToAccount, gotoSelect, returnToHome, changeAccount } = useTransferWizard();
    const { values, isSubmitting } = useFormikContext<InterAccountFormProps>();
    const { data, loading, error } = useInstrumentAuthorityAccountsQuery({
        variables: {
            account: fromAccountId,
        },
        fetchPolicy: "network-only"
    });

    if (loading) {
        return <Loading />
    }

    if (error || data?.instrumentByAccount == null) {
        return <CError error="Could not load inter account details" />
    }

    const currentAccount = data?.instrumentByAccount?.Accounts?.filter(x => x?.Id === fromAccountId)[0];

    return (

        <div className="interaccount-create-page">
            <Title
                title="New Inter-Account Transfer"
                subtitle={`From Account ${currentAccount?.Number}`}
            />

            <FundsTransferCardsContainer>
                <FromAccountCard account={currentAccount} title="From" onClick={changeAccount} />
                {interAccountToAccount?.Id === currentAccount?.Id ?
                    <PaymentCard
                    onClick={gotoSelect}
                    title="To"
                    name={<div><FontAwesomeIcon icon={faExclamationTriangle} /> Please select an account</div>}
                    details="Click here to select an account"
                    className="payment-create-summary-account-warning"
                    /> :
                    <FromAccountCard account={interAccountToAccount} title="To" onClick={gotoSelect} />
                }

            </FundsTransferCardsContainer>


            <Form>

                <Input
                    name={nameof<InterAccountFormProps>("transferDescription")}
                    type="text"
                    helpText="This will appear in the account you're withdrawing money from"
                    label="Description"
                    printableOnly = {true}
                />

                <Input
                    name={nameof<InterAccountFormProps>("transferReference")}
                    type="text"
                    helpText="This will appear in the account you're depositing money into"
                    label="Reference"
                />

                <Input
                    name={nameof<InterAccountFormProps>("amount")}
                    type="currency"
                    label="Amount"
                />

                <TransferScheduleInput
                    values={values}
                    config={{
                        minStartingDate: new Date(),
                        minUntilDate: new Date(values.startingOn),
                    }}
                />

                <FutureDatedWarningText date={values.startingOn} />

                <BankingCutOff />
                
                <Alert message="Please select a To account" visible={interAccountToAccount?.Id === currentAccount?.Id} />

                <Button
                    role="secondary"
                    disabled={isSubmitting}
                    onClick={() => returnToHome(currentAccount?.Number!)}
                    id="goBack"
                >
                    Go Back
                </Button>

                <Button
                    isSubmit={true}
                    loading={isSubmitting}
                    disabled={interAccountToAccount?.Id === currentAccount?.Id}
                    id="createTransfer"
                >
                    Create Transfer
                </Button>

            </Form>
        </div>)
}

const CreateInterAccountForm = () => {
    const wizard = useTransferWizard<InterAccountFormProps>();

    const initialValues: InterAccountFormProps = {
        amount: undefined,
        transferDescription: "",
        endingOn: undefined,
        startingOn: new Date(),
        frequency: TaskFrequencyPeriod.Once,
        recurrenceEnd: RecurrenceEndEnum.Never,
        numberOfTimesAmount: undefined,
        transferReference: "",

        ...wizard.formState
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => wizard.createTransfer(values)}
            validationSchema={Validate(wizard.accountId)}
        >
            <CreateInterAccount fromAccountId={wizard.accountId} />
        </Formik>
    )
}

export default CreateInterAccountForm;
