import { Button, Box, Stack, Paper, Avatar, Typography, Divider, Alert } from '@mui/material';
import { SecureMessage, useArchiveSecureMessageMutation, useCreateNewSecureReplyMutation, SecureMessagesByPartyDocument, useIsUserAuthenticatedQuery } from 'generated/generated-models';
import ArchiveIcon from '@mui/icons-material/Archive';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MessageTypeBar from './MessageTypeBar';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedDate } from 'react-intl';
import MailIcon from '@mui/icons-material/MailOutlined';

interface MessageChainViewProps {
    secureMessage: SecureMessage | null
    partyId: string
}

const MessageChainView = (props: MessageChainViewProps) => {    
    const [createNewSecureReply] = useCreateNewSecureReplyMutation();
    const [handleArchiving] = useArchiveSecureMessageMutation();
    const [currentMessage, setCurrentMessage] = useState(props.secureMessage);
    const { data: authenticatedData } = useIsUserAuthenticatedQuery();
    const firstNameInitial = authenticatedData?.userSession?.Party?.FirstName!.charAt(0);
    const lastNameInitial = authenticatedData?.userSession?.Party?.LastName!.charAt(0);

    const bottom = useRef<null | HTMLDivElement>(null)
    const scrollToBottom = () => {
        bottom?.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(()=> {
        scrollToBottom();
        setCurrentMessage(props.secureMessage);
    },[props.secureMessage])

    if (currentMessage == null || currentMessage.ToDoItem.Messages == null) {
        return (
            <Box sx={{ my: 12, textAlign: 'center' }} className="messageNote">
                <MailIcon sx={{ mb: 0.7 }} />
                <p style={{ fontSize: 14 }} >Select an item to read</p>
            </Box>
        );
    }
    async function handleSubmit(messageBody: string) {
        if (messageBody == undefined || messageBody === "") {
            return;
        }
        const result = await createNewSecureReply({
            variables: {
                input: {
                    SecureMessageId: currentMessage?.Id,
                    Body: messageBody
                }
            },
            refetchQueries: [
                { query: SecureMessagesByPartyDocument },
            ]
        })
        if (result.data?.requestNewSecureReply?.GraphSecureMessage != null) {
            const message = result.data.requestNewSecureReply.GraphSecureMessage;
            message.ToDoItem.Messages = message.ToDoItem.Messages.sort((messageA, messageB) => messageA.CreatedOn > messageB.CreatedOn ? 1 : -1);
            setCurrentMessage(message);
        }
    };

    const handleArchivingSubmit = async () => {
        var result = await handleArchiving({
            variables: {
                input: {
                    SecureMessageId: currentMessage?.Id
                },
            },
            refetchQueries: [
                { query: SecureMessagesByPartyDocument },
            ]
        });

        if(result.data?.archiveSecureMessage?.Success !== undefined || result.data?.archiveSecureMessage?.Success === true)
        {
            setCurrentMessage(null);
        }
    }

    return (
        <Stack spacing={3} className="messageView">
            <Box sx={{ml: 4}} className="messageHeader">
                <h3>{currentMessage.Subject}</h3>
                <Stack direction="row" spacing={2} className="messageLinks">
                    {/* Hidden as per CAP-7727*/}
                    {false &&
                        <Button variant="text" startIcon={<AttachFileIcon />}>
                            View all Attachments
                        </Button>
                    }       
                    <Button id="archive-secure-message-button" variant="text" startIcon={<ArchiveIcon />} onClick={handleArchivingSubmit}>
                        {currentMessage.IsArchived ? "Unarchive Message" : "Archive Message"}
                    </Button>
                </Stack>
            </Box>
            <Box sx={{ p:2, pt:1, ml: 4, mt: 0, height: 580, overflow: 'auto'}} id="ChainContainer" className="messageChainContainer">
                {currentMessage.ToDoItem.Messages.map((message, index) => {
                    return (
                        <React.Fragment key={message.Id}>
                            <Stack direction={message.Party === props.partyId ? "row-reverse" : "row"} spacing={1}>
                                <Avatar sx={{ mt:1.7, width: 32, height: 32, fontSize: '0.8rem' }}>{firstNameInitial}{lastNameInitial}</Avatar>
                                <Stack>
                                    <Paper className={message.Party === props.partyId ? "currentUserMessage" : "systemReplyMessage"} elevation={3} sx={{p: 2}} > 
                                        <Typography> {message.Text} </Typography>
                                        <div ref={bottom}></div>
                                    </Paper>
                                    {(currentMessage != null && (index === currentMessage.ToDoItem.Messages.length-1 || 
                                     (message.CreatedBy !== currentMessage.ToDoItem.Messages[index+1].CreatedBy) || 
                                     ((new Date(currentMessage.ToDoItem.Messages[index+1].CreatedOn).getTime() - new Date(message.CreatedOn).getTime())/60000 > 5))) && (
                                        <Typography variant="caption" className='messageDate' sx={{marginTop: "5px", marginLeft:"5px"}}>
                                            <FormattedDate value={message.CreatedOn} day="2-digit" month="short" year="numeric" hour="2-digit" minute="2-digit"/>
                                        </Typography>
                                    )}
                                </Stack>
                            </Stack>
                        </React.Fragment>
                    );
                })}
            </Box>
            {(currentMessage.Subject !== "Capital Upgrade" && !currentMessage.IsArchived) && <MessageTypeBar handleSubmit={handleSubmit}/>}
        </Stack>
    )
}

export default MessageChainView;
