import React, { useEffect, useState } from "react";
import { AccountTransaction, useAccountByIdOrNumberQuery, useIndueCardsandAssociationsByAccountQuery } from "generated/generated-models";
import { EditableAccountDescription } from "./changeAccountDescription/EditableAccountDescription";
import { AccountSummaryHeader } from './AccountSummaryHeader';
import { clientConfigFn } from "clientConfig";
import { Loading } from "app/lib/components/Loading";
import { CError } from "app/common/CError";
import TransactionListView from "app/transaction/TransactionListView";
import MobileTransactionDetail from "app/transaction/TransactionListView/MobileTransactionDetail";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import DisbursementRequestForm from "app/disbursement/DisbursementRequestForm";
import AccountTransfers from "../accountTransfer";
import MoneyFormatted from "app/components/MoneyFormatted";
import MoneySigned from "app/components/MoneySigned";
import Alert from '@mui/material/Alert';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { makeNonEmpty } from "core/util/array";
import { useRouteMatch, Link } from "react-router-dom";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import FeedIcon from '@mui/icons-material/Feed';
import CreditCardIcon from '@mui/icons-material/CreditCard';

const AccountView: React.FC<{ accountId: string }> = ({ accountId }) => {
    const {transactionHistoryLimitDays: historyDays} = clientConfigFn();
    const { data, loading, error } = useAccountByIdOrNumberQuery({
        variables: { idOrNumber: accountId }
    });
    const [detailView, setDetailView] = useState<AccountTransaction>();
    const [tabValue, setTabValue] = React.useState('transactions');
    const [selectedCard, setSelectedCard] = React.useState();
    const { data: dataIndueCards, loading: loadingIndueCards, error: errorIndueCards } = useIndueCardsandAssociationsByAccountQuery({
        variables: { accountId: accountId, accountIdString: accountId },
        fetchPolicy: "network-only",
    });
    const accountMatch = useRouteMatch<{ accountId: string }>("/account/index/:accountId/");    
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
      setTabValue(newValue);
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        if(!loadingIndueCards && dataIndueCards?.indueCardsByAccount?.length !== 0) {
            const indueCards = makeNonEmpty(dataIndueCards?.indueCardsByAccount);
            if(indueCards.length > 0) {
                const firstCard = indueCards[0].Id;
                setSelectedCard(firstCard);
            }
        }

    }, [detailView, loadingIndueCards, dataIndueCards]);

    if (loading === true) {
        return <Loading />
    }
    if (loadingIndueCards || errorIndueCards) {
        return <></>;
    }

    const account = data?.accountByIdOrNumber;
    const cardsEnabled = account?.CardAvailable || (account?.Cards?.length ?? 0) > 0;

    if (error !== undefined || account === undefined) {
        return <section className="sub-wrap"><CError error="Could not load account details" /></section>
    }
    if(detailView != null) {
        return (
            <section className="cap-account-view sub-wrap">
                <MobileTransactionDetail transaction={detailView} goBack={() => setDetailView(undefined)} />
            </section>
        )
    }

    const showErrorsMessage = () => {
        if (clientConfigFn().arrearsMessage === "") {
            return false;
        }
        if (account.ArrearsBalance === undefined) {
            return false;
        }
        if (account.ArrearsBalance > 0) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <section className="account-view sub-wrap">
            <div className="account-header">
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <p className="desc-title">
                            <EditableAccountDescription
                                accountId={account.Id}
                                description={account.Description || account.ProductType || " "}
                            />
                        </p>
                        <p className="acc-num">Acc: {account.Number}</p>
                        <p className="avail-title">Available balance</p><MoneyFormatted amount={account.Redraw || 0.0} className="avail-bal" />
                        <p className="curr-bal">Current balance: <MoneySigned amount={account.CurrentBalance} direction="total" /></p>
                    
                        {showErrorsMessage() &&
                            <Alert severity="error">{clientConfigFn().arrearsMessage}</Alert>
                        }
                    </Grid>
                    <Grid item xs={7} className="quick-actions-container">
                        <div className="quick-actions desktopActions">
                                {accountMatch && !clientConfigFn().hideTransferPage &&
                                    <Button href={`/transfersAndPayments`} variant="contained" sx={{ p: 1.5, mt: 1 }} id="makeTransfersAndPaymentsDesktopButton">
                                        Make a Transfer
                                    </Button>
                                }
                                {accountMatch && clientConfigFn().allowOnLineStatements &&
                                    <Button href={`/account/index/${account.Number}/statements`} variant="contained" sx={{ p: 1.5, mt: 1 }} id="viewStatementsDesktopButton">
                                        View Statements
                                    </Button>
                                }
                                {accountMatch && cardsEnabled && !clientConfigFn().hideCardPage &&
                                    <>{selectedCard !== undefined
                                        ? <Button href={`/account/index/${account.Number}/induecards/${selectedCard}`} variant="contained" sx={{ p: 1.5, mt: 1 }} id="viewSelectedCardDesktopButton">
                                            View Cards
                                        </Button>
                                        : <Button href={`/account/index/${account.Number}/induecards`} variant="contained" sx={{ p: 1.5, mt: 1 }} id="viewCardDesktopButton">
                                            View Cards
                                        </Button>
                                    }</>
                                }
                        </div>
                        <div className="quick-actions mobileActions">
                            {accountMatch && !clientConfigFn().hideTransferPage &&
                                <Button
                                    component={Link}
                                    variant="text"
                                    to={`/transfersAndPayments`}
                                    startIcon={<CurrencyExchangeIcon />}
                                    id="payMobileButton">
                                    Pay
                                </Button>
                            }
                            {accountMatch && clientConfigFn().allowOnLineStatements &&
                                <Button className="btn-view-statement"
                                    component={Link}
                                    variant="text"
                                    to={`/account/index/${account.Number}/statements`}
                                    startIcon={<FeedIcon />}
                                    id="viewStatementsMobileButton">
                                    Statements
                                </Button>
                            }
                            {accountMatch && cardsEnabled && !clientConfigFn().hideCardPage &&
                                <>{selectedCard !== undefined
                                    ? <Button className="btn-view-cards"
                                        component={Link}
                                        variant="text"
                                        to={`/account/index/${account.Number}/induecards/${selectedCard}`}
                                        startIcon={<CreditCardIcon />}
                                        id="viewSelectedCardMobileButton">
                                        Cards
                                        </Button>
                                    : <Button className="btn-view-cards"
                                        component={Link}
                                        variant="text"
                                        to={`/account/index/${account.Number}/induecards`}
                                        startIcon={<CreditCardIcon />}
                                        id="viewCardMobileButton">
                                        Cards
                                        </Button>
                                }</>
                            }
                        </div>
                    </Grid>
                </Grid>
            </div>
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="accountTabs">
                    <TabList onChange={handleTabChange} >
                        <Tab label="Details" value="details" />
                        <Tab label="Transactions" value="transactions" />
                        {clientConfigFn().hideTransferPage === false && <Tab label="Scheduled Transfers" value="scheduledTransfers" />}
                        {clientConfigFn().allowDisbursements && <Tab label="Request Payment" value="requestPayment" />}
                    </TabList>
                </Box>
                <TabPanel className="details-container" value="details" sx={{ px: 2, py: 3 }}>
                    <AccountSummaryHeader account={account} />
                </TabPanel>
                <TabPanel className="transaction-container" value="transactions" sx={{ px: 1, py: 3 }}>
                    {historyDays
                    ? ( <small className="text-muted transactiondays">Displaying transactions for the last {historyDays} days.</small>
                    ) : null }
                    <TransactionListView account={account} setDetailView={setDetailView} />
                </TabPanel>
                {clientConfigFn().hideTransferPage === false &&
                    <TabPanel className="scheduled-transfers-container" value="scheduledTransfers" sx={{ px: 1, py: 3 }}>
                        <AccountTransfers accountIdOrNumber={account.Number!} />
                    </TabPanel>
                }
                {clientConfigFn().allowDisbursements &&
                    <TabPanel className="disbursements-container" value="requestPayment" sx={{ px: 1, py: 3 }}>
                        <DisbursementRequestForm
                            AccountId={account.Id}
                            InstrumentId={account.InstrumentId}
                        />
                    </TabPanel>
                }
            </TabContext>
        </section>
    );
}

export {
    AccountView
}
