import MoneySigned from "./MoneySigned";
import TransactionPageIcon from "app/components/TransactionPageIcon";
import { clientConfigFn } from "clientConfig";

type Props = {
    amount?: number,
    isShowZero?: boolean
    direction: "credit" | "debit"
}

const getColour = (direction: "credit" | "debit") => {
    const config = clientConfigFn();

    switch (direction) {
        case "credit":
            return config.showTransactionsAsLender ? "red" : "green";
        case "debit":
            return config.showTransactionsAsLender ? "green" : "red";
    }
}

const TransactionPageIconMoney = ({ amount = 0, direction, isShowZero }: Props) => {
    const colour = getColour(direction);

    if (amount === 0 && !isShowZero) {
        return null;
    }

    return <span className="transactionpage-moneyicon">
        <TransactionPageIcon iconName={direction} style={{ color: colour }} />
        <MoneySigned amount={amount} direction={direction} isShowZero={isShowZero} />
    </span>
}

export default TransactionPageIconMoney;
