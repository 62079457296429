import { CDate } from "app/common/CapitalDate";
import { AccountTransaction } from "generated/generated-models";

const DescriptionListItem = ({ title, value }: { title: string; value?: React.ReactNode }) => (
    <p className="transaction-description-item">
        <span className="transaction-description-item-term">{title}</span>
        <span className="transaction-description-item-definition">{value}</span>
    </p>
)

const DetailViewerContainer: React.FC = ({ children }) => {

    return (
        <div className="transaction-description">
            <>{children}</>
        </div>
    )
}

const DetailViewDashboard = ({ trans }: { trans: AccountTransaction }) => {
    if (trans.IsPending) {
        return (
            <DetailViewerContainer>
                <DescriptionListItem title="Message ID" value="fc31e3d01949490894b05ef59d1095dc" />
                <DescriptionListItem title="Pending ID" value="1092" />
                <DescriptionListItem title="Date Created" value={<CDate date={trans.CreatedOn}/>} />
                <DescriptionListItem title="Trace Number" value="82938" />
                <DescriptionListItem title="Card Number" value="366000XXXXXX0412" />
                <DescriptionListItem title="Description" value="AUSACC" />
                <DescriptionListItem title="Merchant ID" value="321" />
                <DescriptionListItem title="Merchant" value="TEST MERCHANT" />
                <DescriptionListItem title="Location" value="SYDNEY NSW AU" />
            </DetailViewerContainer>
        )
    } else {
        return (
            <DetailViewerContainer>
                <DescriptionListItem title="Transaction Source" value="Cheque Transaction" />
                <DescriptionListItem title="Description" value="" />
                <DescriptionListItem title="Additional Text" value="INDUE MESSAGE DECODER CHATSWOOD NSW" />
                <DescriptionListItem title="Card Number" value="366000xxxxxx0412" />
                <DescriptionListItem title="Terminal ID" value="654" />
                <DescriptionListItem title="Location" value="INDUE MESSAGE DECODER NSW" />
                <DescriptionListItem title="Merchant Code" value="5541" />
                <DescriptionListItem title="Processing Code" value="Debit" />
                <DescriptionListItem title="Billing Flag" value="POS" />
                <DescriptionListItem title="Cash Component" value="$0.00" />
                <DescriptionListItem title="Device Type" value="POS" />
                <DescriptionListItem title="Transaction Subtype" value="POS with goods sold" />
            </DetailViewerContainer>
        )
    }
}

export default DetailViewDashboard;
