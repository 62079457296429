import ConfirmTransfer from "./ConfirmTransfer";
import TransferReceipt from "./TransferReceipt";
import TransferPayments from "./context";
import TransfersAndPayments from "./TransfersAndPayments";



const TransferAndPaymentsPage = () => {
    return (
        <TransferPayments transferType="" >
            <TransferPayments.Create>
                <TransfersAndPayments />
            </TransferPayments.Create>
            <TransferPayments.Confirm>
                <ConfirmTransfer />
            </TransferPayments.Confirm>
            <TransferPayments.Receipt>
                <TransferReceipt />
            </TransferPayments.Receipt>
        </TransferPayments>
    )
}

export default TransferAndPaymentsPage;