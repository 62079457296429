import { useBankingUserLoginV2Mutation, LoginV3AuthError } from "generated/generated-models";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import RequestMultiFactorToken from "../../components/RequestMultiFactorToken";
import LoginErrorMessage from "./LoginErrorMessage";
import LoginHeader from "./LoginHeader";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import LoginIcon from '@mui/icons-material/Login';
import { LoadingButton } from '@mui/lab';
import { clientConfigFn } from "clientConfig";

type Props = {
    username: string;
    password: string;
    requiresNewPassword: (username: string, password: string, token: string) => void;
    reset: () => void;
    isUsingGoogleAuth: boolean
}

const EnterMfa = ({ username, password, requiresNewPassword, reset, isUsingGoogleAuth }: Props) => {
    const [twoFactorToken, setTwoFactorToken] = useState("");
    const [userLogin, { loading, data, error }] = useBankingUserLoginV2Mutation();

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (twoFactorToken === "") {
            return;
        }

        userLogin({
            variables: {
                input: {
                    Password: password,
                    Username: username,
                    TwoFactorToken: twoFactorToken
                }
            },
            context: { headers: { "X-Interfi-RequestType": "Anonymous" } }
        });
    }

    if (data?.loginV2?.AuthSucceeded) {
        return <Redirect to="/" />
    }

    if (data?.loginV2?.AuthError === LoginV3AuthError.Requirepasswordchange) {
        requiresNewPassword(username, password, twoFactorToken)
    }

    return (
        <div>
            <div id="login-screen">
                <Paper sx={{ p: 10, borderRadius: 3 }}
                variant="elevation"
                elevation={24}
                className="login-screen set-password"
                >
                    <LoginHeader
                        heading={isUsingGoogleAuth ? "Login with 2FA" : "Login with Token"}
                        subHeading={isUsingGoogleAuth ? clientConfigFn().loginAuthenticatorMessage : clientConfigFn().loginSecurityTokenMessage }
                    />
                    <LoginErrorMessage loginError={error} loginResponse={data} isUsingGoogleAuth={isUsingGoogleAuth} />
                    <form autoComplete="off" onSubmit={(e) => handleSubmit(e)} className="prelogin-form">
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <RequestMultiFactorToken
                                getToken={setTwoFactorToken}
                                showRequestNewToken={false}
                                isUsingGoogleAuth={isUsingGoogleAuth}
                            />
                        </Box>
                        <div className="form-group button-row">
                            <Button
                                role="secondary" id="cancel"
                                sx={{ width: 150, p: 1.5, mt: 1, mr: 1 }}
                                variant="outlined"
                                onClick={reset}
                                disabled={loading}>
                                Cancel
                            </Button>
                            <LoadingButton
                                type="submit" id="submit"
                                sx={{ width: 150, p: 0.5, mt: 1 }}
                                endIcon={<LoginIcon />}
                                loading={loading}
                                loadingPosition="end"
                                variant="contained"
                            >
                                Login
                            </LoadingButton>
                        </div>
                    </form>
                </Paper>
            </div>
        </div>
    )
}

export default EnterMfa;
