import React from "react";
import { useTransferPaymentsContext } from "./useTransferPaymentsContext";

const Create: React.FC = (props) => {
    const context = useTransferPaymentsContext();

    if (context.formStage === "Create") {
        return (
            <>
                {props.children}
            </>
        )
    }

    return null;
}

export default Create;
