import { RecurrenceEndEnum, FundsTransfer } from "generated/generated-models";
import React from "react";
import DueOnListItems from "./DueOnListItems";

type DueOnListItemsReceiptProps = {
    fundsTransfer: FundsTransfer;
}

/**Displays when a funds transfer will start and how often it will repeat in a list group based */
const DueOnListItemsReceipt = ({ fundsTransfer }: DueOnListItemsReceiptProps) => {
    const getRecurrence = () => {
        if (fundsTransfer.FurtherNotice === true) {
            return RecurrenceEndEnum.Never;
        }

        if (fundsTransfer.SpecifiedDate === true) {
            return RecurrenceEndEnum.Bydate;
        }

        if (fundsTransfer.NumberOfTimes === true) {
            return RecurrenceEndEnum.Numberofocurrences;
        }

        return RecurrenceEndEnum.Once;
    }

    return (
        <DueOnListItems
            paymentDate={fundsTransfer.StartingOn}
            frequency={fundsTransfer.Frequency || ""}
            endDate={fundsTransfer.EndingOn}
            repeats={fundsTransfer.NumberOfTimesAmount}
            recurrence={getRecurrence()}
        />
    )
}

export default DueOnListItemsReceipt;
