import React, { useEffect } from "react";
import { NavbarMini } from "../layout/Navbar/NavbarMini";
import { Navbar } from "../layout/Navbar/Navbar";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter, Redirect, Route, Switch, useLocation } from "react-router-dom";
import PrivateRoute from "../common/PrivateRoute";
import Login from "../authentication/Login";
import InstrumentPage from "../instrument/InstrumentPage";
import { AccountPageComponent } from "../account/AccountPage";
import { Logout } from "../authentication/Logout";
import AccountList from "../account/AccountList";
import StatementsListForParty from "../statements/StatementsListForParty";
import TransactionsListForParty from "../transaction/TransactionsListForParty";
import ChangePassword from "../authentication/ChangePassword";
import AuthenticatorPage from "../authentication/Authenticator/AuthenticatorPage";
import PartyLimitsPage from "app/partyLimits/PartyLimitsPage";
import ResetPassword from "../authentication/ResetPassword/ResetPassword";
import { useIsUserAuthenticatedQuery, usePartyLimitQuery } from "generated/generated-models";
import ActiveSessions from "../authentication/ActiveSessions/ActiveSessions";
import { clientConfigFn } from "clientConfig";
import AcceptTerms from "../authentication/AcceptTerms";
import { Dashboard } from "../dashboard/Dashboard"; 
import EditPartyLimitsPage from "app/partyLimits/EditPartyLimitsPage";
import TransferAndPaymentsPage from "app/transfersAndPayments/TransferAndPaymentsPage";
import ProfileSettings from "app/authentication/ProfileSettings";
import { SecureMessagingView } from "app/authentication/SecureMessaging/SecureMessagingView";

export const App = () => {
    const { data: userSessionData, error: userSessionError, loading: userSessionLoading } = useIsUserAuthenticatedQuery({
        partialRefetch: true,
        notifyOnNetworkStatusChange: true,
        errorPolicy: "all"
    });
    const loggedIn = userSessionData && !userSessionError && !userSessionLoading;
    
    useEffect(() => {
        document.title = clientConfigFn().siteTitle
    }, [])
    const { pathname } = useLocation();
    const { data } = useIsUserAuthenticatedQuery();
    const { data: limitData, loading: limitLoading, error: limitError } = usePartyLimitQuery();
    const screenSize = window.innerWidth ;
    const mobileScreenSize = screenSize <= 959;

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex' }}>
            <CssBaseline />
                {loggedIn && !mobileScreenSize &&
                    <NavbarMini />
                }
                {loggedIn && mobileScreenSize &&
                    <Navbar />
                }
                <Switch>
                    <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />          
                    <Route
                        path="/Security/login"
                        render={() => (
                            <section className={"login-content-wrapper flex-fill"}>
                                <Login />
                            </section>
                        )}
                    />
                    <Route
                        path="/Security/acceptterms"
                        render={() => (
                            <Box component="main" sx={{ flexGrow: 1, py: 10 }}>
                                <AcceptTerms />
                            </Box>
                        )} 
                    />
                    <Route
                        path="/Security/logout"
                        render={() => (
                            <Box component="main" sx={{ flexGrow: 1, py: 10 }}>
                            <Logout
                                onLogout={() => {
                                    window.location.href = "/Security/login";
                                }}
                            />
                            </Box>
                        )}
                    />
                    <Route path={"/Security/resetpassword"}>
                        <section className="content-wrapper">
                            <div className="container">
                                <ResetPassword />
                            </div>
                        </section>
                    </Route>
                    {!clientConfigFn().hideTransferPage &&
                        <PrivateRoute
                            path="/transfersAndPayments"
                            render={() => (
                                <Box component="main" sx={{ flexGrow: 1, py: 12 }}>
                                    <TransferAndPaymentsPage />
                                </Box>
                            )} 
                        />
                    }
                    <PrivateRoute
                        path="/statements/StatementsListForParty"
                        render={() => (
                            <Box component="main" sx={{ flexGrow: 1, py: 12 }}>
                                <BrowserRouter forceRefresh={true}>
                                    <StatementsListForParty />
                                </BrowserRouter>
                            </Box>
                        )} 
                    />
                    <PrivateRoute
                        path="/account/index/:number/statements"
                        render={() => (
                            <Box component="main" sx={{ flexGrow: 1, py: 12 }}>
                                <StatementsListForParty />
                            </Box>
                        )} 
                    />
                    <PrivateRoute
                        path="/transaction/TransactionsListForParty"
                        render={() => (
                            <Box component="main" sx={{ flexGrow: 1, py: 12 }}>
                                <TransactionsListForParty />
                            </Box>
                        )} 
                    />
                    <PrivateRoute
                        path="/account/accountlist"
                        render={() => (
                            <Box component="main" sx={{ flexGrow: 1, py: 12 }}>
                                <AccountList />
                            </Box>
                        )} 
                    />
                    <PrivateRoute
                        path="/security/changepassword"
                        render={() => (
                            <Box component="main" sx={{ flexGrow: 1, py: 10 }}>
                                <ChangePassword />
                            </Box>
                        )} 
                    />
                    <PrivateRoute
                        path="/security/profilesettings"
                        render={() => (
                            <Box component="main" sx={{ flexGrow: 1, py: 10 }}>
                                <ProfileSettings PreferredCorrespondenceMethod={data?.userSession?.Party?.PreferredCorrespondanceType!} />
                            </Box>
                        )} 
                    />
                    {clientConfigFn().displaySecureMessagePage &&
                        <PrivateRoute
                            path="/security/securemessaging"
                            render={() => (
                                <Box component="main" sx={{ flexGrow: 1, pt: 10 }}>
                                    <SecureMessagingView />
                                </Box>
                            )} 
                        />
                    }
                    {data?.userSession?.MFAEnforced &&
                        <PrivateRoute
                            path="/security/Authenticator"
                            render={() => (
                                <Box component="main" sx={{ flexGrow: 1, py: 10 }}>
                                    <AuthenticatorPage />
                                </Box>
                            )}
                        />
                    }
                    {data?.userSession?.PartyPaymentLimitsEnabled &&
                        <PrivateRoute
                            path="/security/Limits/Edit"
                            render={() => (
                                <Box component="main" sx={{ flexGrow: 1, py: 10 }}>
                                    <EditPartyLimitsPage partyLimits={limitData?.partyLimits} loading={limitLoading} error={limitError} />
                                </Box>
                            )}
                        />
                    }
                    {data?.userSession?.PartyPaymentLimitsEnabled &&
                        <PrivateRoute
                            path="/security/Limits"
                            render={() => (
                                <Box component="main" sx={{ flexGrow: 1, py: 10 }}>
                                    <PartyLimitsPage partyLimits={limitData?.partyLimits} loading={limitLoading} error={limitError} isMFAEnabled={data?.userSession?.UseMultiFactorAuth ?? false} />
                                </Box>
                            )}
                        />
                    }
                    <PrivateRoute 
                        path="/instrument/index/:number"
                        render={({ match }) => (
                            <Box component="main" sx={{ flexGrow: 1, py: 10 }}>
                                <InstrumentPage instrumentNumber={match?.params.number ?? ""} />
                            </Box>
                        )}
                    />
                    <PrivateRoute
                        path="/account/index/:number"
                        render={() => (
                            <Box component="main" sx={{ flexGrow: 1, py: 10 }}>
                                <AccountPageComponent />
                            </Box>
                        )} 
                    /> 
                    <PrivateRoute
                        path="/security/activesessions"
                        render={() => (
                            <Box component="main" sx={{ flexGrow: 1, py: 10 }}>
                                <ActiveSessions userSession={data?.userSession} />
                            </Box>
                        )}
                    /> 

                    {/* Fallback to FinanceSummary */}
                    <PrivateRoute
                        children={
                            <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
                                <Dashboard session={userSessionData?.userSession}/>
                            </Box>
                        }
                    />
                </Switch>
            </Box>
        </React.Fragment>
    );
}

const AppRouter = () => (
    <BrowserRouter>
        <App />
    </BrowserRouter>
)

export default AppRouter;
