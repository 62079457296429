import { clientConfigFn } from "clientConfig";
import { client } from "core/apollo/client";
import { getCurrencySymbol } from "core/util/currencySymbol";
import { isSameDay } from "date-fns";
import { TaskFrequencyPeriod, RecurrenceEndEnum, TransferLimitValidationDocument, TransferLimitValidationQuery, TransferLimitValidationQueryVariables } from "generated/generated-models";
import * as yup from "yup";

export const validateTransferDescription = (label: string) => {
    const config = clientConfigFn();

    let transferDescription = yup
        .string()
        .max(64)
        .label(label)       
        .test(
            'Must have basic latin characters only',
            'You must only enter basic latin characters',
            function(transferDescription) {
                const mystring: string = String(transferDescription);
                var containsNonPrintable : boolean = false;

                for (let step = 0; step < mystring.length; step++) {
                    var ascii = mystring.charCodeAt(step);
                    if(ascii < 32 || ascii > 127)
                    {
                        containsNonPrintable = true;
                    }
                  }
                return (!containsNonPrintable);
            }
          )   

    if (config.fundsTransferDescriptionRequired) {
        transferDescription = transferDescription.required()
    }

    return transferDescription;
}

const getFriendlyName = (limitType: string) => {
    switch(limitType)
    {
        case "bpay":
            return "BPay limit"
        case "payAnyone":
            return "Pay Anyone limit"
        case "redraw":
            return "Redraw limit"
        default:
            return "None"
    }
}

export const validateLimit = (accountId: string, limitType: string) => {
    const friendlyText = getFriendlyName(limitType);

    return {
        amount: yup
            .number()
            .required()
            .moreThan(0)
            .label("Amount")
            .test(limitType, `Amount is greater than the available ${friendlyText}`, async function (amount) {
                const startingOn = this.parent.startingOn;
                const scheduledForToday = isSameDay(new Date(), startingOn);

                if (amount == null) {
                    return false;
                }

                if(limitType === "none")
                {
                    return true;
                }

                const variables: TransferLimitValidationQueryVariables = {
                    accountId: accountId,
                    date: startingOn
                };

                const result = await client.query<TransferLimitValidationQuery>({
                    query: TransferLimitValidationDocument,
                    variables: variables
                });

                if (scheduledForToday) {
                    const currentRedraw = result.data?.accountByIdOrNumber?.Redraw ?? 0;

                    if (currentRedraw < amount) {
                        return this.createError({ message: `Amount is greater than the available ${friendlyText} of ${getCurrencySymbol()}${currentRedraw}` });
                    }
                }
                
                const accountLimit = result.data?.accountLimits
                    ?.find(x => isSameDay(new Date(x?.EffectiveDate || 0), startingOn))
                    ?.Limits

                let limit = 0;

                if (limitType === "redraw") {
                    limit = accountLimit?.RemainingDailyRedrawLimit?.Amount ?? 0;
                } else if (limitType === "payAnyone") {
                    limit = accountLimit?.RemainingDailyPayAnyoneLimit?.Amount ?? 0;
                } else {
                    limit = accountLimit?.RemainingDailyBPayLimit?.Amount ?? 0;
                }

                if (amount == null) {
                    return false;
                }

                if (limit >= amount) {
                    return true;
                } else {
                    return this.createError({ message: `Amount is greater than the available ${friendlyText} of ${getCurrencySymbol()}${limit}` });
                }
            }),
    }
}

export const validateSchedule = () => {
    return {
        frequency: yup
            .mixed<TaskFrequencyPeriod>()
            .required()
            .label("Frequency"),

        recurrenceEnd: yup
            .mixed<RecurrenceEndEnum>()
            .required()
            .label("Recurrence"),

        endingOn: yup
            .date()
            .when(["recurrenceEnd", "frequency", "startingOn"], (recurrenceEnd: RecurrenceEndEnum, frequency: TaskFrequencyPeriod, startingOn: Date, schema: yup.DateSchema) => {
                if (recurrenceEnd === RecurrenceEndEnum.Bydate && frequency !== TaskFrequencyPeriod.Once) {
                    return schema
                        .required()
                        .min(startingOn, "");
                }
                return schema;
            })
            .label("Ending Date"),

        numberOfTimesAmount: yup
            .number()
            .when(["recurrenceEnd", "frequency"], (recurrenceEnd: RecurrenceEndEnum, frequency: TaskFrequencyPeriod, schema: yup.NumberSchema) => {
                if (recurrenceEnd === RecurrenceEndEnum.Numberofocurrences && frequency !== TaskFrequencyPeriod.Once) {
                    return schema
                        .required()
                        .integer()
                        .moreThan(1)
                        .max(99999)
                }
                return schema;
            })
            .label("No. of Payments"),
    }
}
