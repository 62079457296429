import React, { useState } from "react";
import { FinanceSummaryComponent } from "../finance-summary/FinanceSummaryComponent";
import { Account } from "generated/generated-models";


const AccountList = () => {

        const [ selectedAccount, setSelectedAccount ] = useState<Account | undefined>();
        const handleOnSelectedAccount = (account: Account) => {
            setSelectedAccount(account);
        }

        return (
            <div className="accountslist-page">
                <section className="container sub-wrap">
                    <FinanceSummaryComponent account={selectedAccount} handleOnSelectedAccount={handleOnSelectedAccount}/>
                </section>
            </div>
 
        )
    }

export default AccountList;