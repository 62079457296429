import React from "react";

type Props = {
    heading: string;
    subHeading: string;
}

const LoginHeader = ({heading, subHeading }: Props) => {
    return (
        <div className="login-header">
            <h1>{heading}</h1>
            <p>{subHeading}</p>
        </div>
    )
}

export default LoginHeader;
