import React, { Fragment } from "react";
const initialState = {
    childrenVisible: false,
};
type State = Readonly<typeof initialState>;
/**
 * Render children after delay ms
 */
export class DelayedRender extends React.Component<
    {
        delay: number;
    },
    State
> {
    timerId: number = 0;
    readonly state: State = initialState;
    setChildrenVisible() {
        this.setState({ childrenVisible: true });
    }
    componentDidMount() {
        this.timerId = window.setTimeout(
            () => this.setChildrenVisible(),
            this.props.delay
        );
    }
    render() {
        const { childrenVisible } = this.state;
        return <Fragment>{childrenVisible && this.props.children}</Fragment>;
    }
    componentWillUnmount() {
        if (this.timerId) {
            window.clearTimeout(this.timerId);
        }
    }
}
