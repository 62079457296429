import { faArrowAltCircleDown, faArrowAltCircleUp, faCalendar, faComment, faMoneyBillAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { clientConfigFn } from "clientConfig";

type IconType =
    | "debit"
    | "credit"
    | "description"
    | "date"
    | "balance"

type Props = {
    iconName: IconType;
    style?: React.CSSProperties;
}

const getIcon = (icon: IconType) => {
    const config = clientConfigFn();

    switch (icon) {
        case "balance":
            return faMoneyBillAlt;
        case "date":
            return faCalendar;
        case "credit":
            return config.showTransactionsAsLender ? faArrowAltCircleDown : faArrowAltCircleUp;
        case "debit":
            return config.showTransactionsAsLender ? faArrowAltCircleUp : faArrowAltCircleDown;
        case "description":
            return faComment;
        default:
            return null;
    }
}

const TransactionPageIcon = ({ iconName, style }: Props) => {
    const config = clientConfigFn();
    const icon = getIcon(iconName);

    if (!config.showIconsTransactionPage || icon == null) {
        return null;
    }

    const iconStyle = {
        marginRight: '.5rem',
        ...style
    }

    return (
        <FontAwesomeIcon icon={icon} size="lg" style={iconStyle} />
    )
}

export default TransactionPageIcon;
