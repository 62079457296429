import { ErrorMessage } from "formik";
import React from "react";

const FieldErrorMessage = (props: { errorName: string }) => (
    <div className="invalid-feedback form-error-message d-block">
        <ErrorMessage name={props.errorName} />
    </div>
)

export default FieldErrorMessage;
