import React from "react";
import { StatementDocument } from "generated/generated-models";
import { CDate } from "../common/CapitalDate";


interface Props {
    statements: StatementDocument[];
}


const StatementsList = ({ statements }: Props) => (
    <section className="sub-wrap">
        <h2>Statements</h2>
        <table className="table" id="statements">
            <thead>
                <tr>
                    <th>Number</th>
                    <th>Account</th>
                    <th>From</th>
                    <th>To</th>
                </tr>
            </thead>
            <tbody>
                {(statements || []).map(statement => {
                    return (
                        <StatementDocumentRow
                            statement={statement}
                            key={statement.Id || statement.Number}
                        />
                    );
                })}
            </tbody>
        </table>
    </section>
);

const StatementDocumentRow = ({
    statement,
}: {
    statement: StatementDocument;
}) => {
    return (
        <tr>
            <td><a href={generateStatementLink(statement)} target="_blank" rel="noopener noreferrer">{statement.Number}</a></td>
            <td>{statement.AccountNumber}</td>
            <td>
                <CDate date={statement.PeriodFrom} />
            </td>
            <td>
                <CDate date={statement.PeriodTo} />
            </td>
        </tr>
    );
};

const generateStatementLink = (statement: StatementDocument): string => {
    return `/api/statements/${statement.InstrumentId}/documents/${statement.FileId}`;
};

export default StatementsList;
