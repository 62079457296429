import { BankAccount, BpayBiller, Account } from "generated/generated-models";
import React, { ReactNode, useState } from "react"

export type Pages =
    | "AccountSelect"
    | "Select"
    | "Create"
    | "Confirm"
    | "Receipt"

export type TransferWizardContextProps = {
    currentPage: Pages;
    accountId: string;
    formState: any;
    fundsTransferId?: string;
    bpayBiller?: BpayBiller;
    bankAccount?: BankAccount;
    interAccount?: Account;
    updateform: (state: any) => void;
    updatePage: (page: Pages) => void;
    updateFundsTransfer: (id: string) => void;
    updateBpayBiller: (biller: BpayBiller) => void;
    updateInterAccount: (interAccount: Account) => void;
    updateBankAccount: (bankAccount?: BankAccount) => void;
    updateAccountId: (account: string) => void;
}

export const TransferWizardContext = React.createContext<TransferWizardContextProps | undefined>(undefined);

type TransferWizardContextProviderProps = {
    accountId: string,
    useSelectionPage?: boolean,
    children?: ReactNode
}

export const TransferWizardContextProvider: React.FC<TransferWizardContextProviderProps> = (props) => {
    const [formState, updateFormsState] = useState({});

    const initialPage = props.useSelectionPage ? "Select" : "Create";
    const [page, updatePage] = useState<Pages>(initialPage)
    const [fundsTransferId, updateFundsTransferId] = useState<string | undefined>(undefined);
    const [bpayBiller, updateBpayBiller] = useState<BpayBiller | undefined>(undefined);
    const [bankAccount, updateBankAccount] = useState<BankAccount | undefined>(undefined);
    const [interAccount, updateInterAccount] = useState<Account | undefined>(undefined);
    const [accountId, updateAccountId] = useState(props.accountId);

    const context: TransferWizardContextProps = {
        accountId: accountId,
        currentPage: page,
        formState: formState,
        fundsTransferId: fundsTransferId,
        bpayBiller: bpayBiller,
        bankAccount: bankAccount,
        interAccount: interAccount,
        updateform: formState => updateFormsState(() => formState),
        updatePage: pageState => updatePage(() => pageState),
        updateFundsTransfer: updateFundsTransferId,
        updateBpayBiller: biller => updateBpayBiller(() => biller),
        updateBankAccount: bankAccount => updateBankAccount(() => bankAccount),
        updateInterAccount: interAccount => updateInterAccount(() => interAccount),
        updateAccountId: accountId => updateAccountId(() => accountId),
    }

    return (
        <TransferWizardContext.Provider value={context}>
            {props.children}
        </TransferWizardContext.Provider>
    )
}

export const useTransferWizardContext = () => {
    const context = React.useContext(TransferWizardContext)
    if (context == null) {
        throw new Error("This component must be used within a <TransferWizard> component.")
    }
    return context
}
