import { DisbursementStage, File } from "generated/generated-models";
import MoneyFormatted from "../components/MoneyFormatted";
import { makeNonEmpty } from "core/util/array";
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { format } from "date-fns";

export interface Props {
    disbursement: DisbursementStage;
}
const DisbursementRequestSuccessful = ({ disbursement }: Props) => {
    const formatDate = (date: Date) => format(new Date(date), 'd MMM yyyy')

    return (
        <>
            <Typography variant="body1" gutterBottom component="p" sx={{ textAlign:"center" }} className="transfer-amount">
                <MoneyFormatted amount={disbursement.RequestedAmount} />
                <span className="caption">Payment Requested</span>
            </Typography >
            <Typography variant="body1" gutterBottom component="p" sx={{ textAlign:"center" }}>
                Your payment request has been sent for processing with the following details.
            </Typography >
            <h3>Supplier Details</h3>
            <List sx={{ mb: 2 }} className="transfer-details">
                <ListItem disablePadding>
                    <ListItemText primary={<Typography><span className="subtitle">Supplier Name: </span>{disbursement.PayeeName}</Typography>} />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText primary={<Typography><span className="subtitle">BSB: </span>{disbursement.BankIdentifierText}</Typography>} />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText primary={<Typography><span className="subtitle">Account Number: </span>{disbursement.AccountNumber}</Typography>} />
                </ListItem>
            </List>
            <h3>Payment Details</h3>
            <List sx={{ mb: 2 }} className="transfer-details">
                <ListItem disablePadding>
                    <ListItemText primary={<Typography><span className="subtitle">Payment Description: </span>{disbursement.PaymentDescription}</Typography>} />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText primary={<Typography><span className="subtitle">Amount: </span><MoneyFormatted amount={disbursement.RequestedAmount} /></Typography>} />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText primary={<Typography><span className="subtitle">Payment Date: </span>{formatDate(disbursement.PaymentDate)}</Typography>} />
                </ListItem>
                
                {disbursement.Documents![0] !== null &&
                    <ListItem disablePadding>
                        <ListItemText className="supporting-docs"
                            primary={<Typography><span className="subtitle">Supporting Documentation: </span></Typography>} 
                            secondary={<Typography>{makeNonEmpty(disbursement.Documents).map(doc => (
                            <FileItemView fileItem={doc} key={doc.Id} />
                        ))}</Typography>}/>
                    </ListItem>
                }
            </List>
        </>
    );
};

const FileItemView = ({ fileItem }: { fileItem: File }) => {
    return (
        <>{fileItem.FileName}</>
    );
};

export { DisbursementRequestSuccessful };
