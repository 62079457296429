import { TaskFrequencyPeriod, RecurrenceEndEnum } from "generated/generated-models";
import { format } from "date-fns";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

type DueOnListItemsProps = {
    paymentDate?: Date;
    endDate?: Date;
    repeats?: number;
    recurrence: string;
    frequency?: string;
}

/**Displays when a transaction starts and how often it will repeat in a list group */
const DueOnListItems = (props: DueOnListItemsProps) => {
    const recurring = props.frequency?.toLowerCase() !== TaskFrequencyPeriod.Once.toLowerCase();

    // Hack because dates are actually strings
    const formatDate = (date: Date) => format(new Date(date), 'd MMM yyyy')

    const repeatText = () => {
        if (props.frequency?.toLowerCase() === TaskFrequencyPeriod.Weekly.toLowerCase()) {
            return "Repeats Weekly";
        } else if (props.frequency?.toLowerCase() === TaskFrequencyPeriod.Fortnightly.toLowerCase()) {
            return "Repeats Fortnightly";
        } else if (props.frequency?.toLowerCase() === TaskFrequencyPeriod.Monthly.toLowerCase()) {
            return "Repeats Monthly";
        } else {
            return "";
        }
    }

    const untilText = () => {
        if (props.recurrence.toLowerCase() === RecurrenceEndEnum.Bydate.toLowerCase()) {
            return `Ends on ${formatDate(props.endDate!)}`;
        } else if (props.recurrence.toLowerCase() === RecurrenceEndEnum.Numberofocurrences.toLowerCase()) {
            return `Ends after ${props.repeats} payments`;
        } else if (props.recurrence.toLowerCase() === RecurrenceEndEnum.Never.toLowerCase()) {
            return "Continues Forever"
        } else {
            return "";
        }
    }
    
    return (
        <>
            {props.paymentDate != null &&
                <ListItem disablePadding>
                    <ListItemText primary={<Typography><span className="subtitle">Payment Date: </span>{formatDate(props.paymentDate)}</Typography>} />
                </ListItem>
            }
            {recurring &&
                <ListItem disablePadding>
                    <ListItemText primary={<Typography><span className="subtitle">Reoccuring: </span>{repeatText()}</Typography>} />
                </ListItem>
            }
            {recurring &&
                <ListItem disablePadding>
                    <ListItemText primary={<Typography><span className="subtitle"></span>{untilText()}</Typography>} />
                </ListItem>
            }
        </>
    )
}

export default DueOnListItems;
