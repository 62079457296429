import { client } from "core/apollo/client";
import { getCurrencySymbol } from "core/util/currencySymbol";
import { MaxLimitsQuery, MaxLimitsDocument } from "generated/generated-models";
import * as yup from "yup";


type LimitType =
    | "redraw"
    | "payAnyone"
    | "bpay"

    
const getFriendlyName = (limitType: LimitType) => {
    switch(limitType)
    {
        case "bpay":
            return "BPay limit"
        case "payAnyone":
            return "Pay Anyone limit"
        default:
            return "Redraw limit"
    }
}

export const validateLimit = (limitType: LimitType) => {
    var friendlyText = getFriendlyName(limitType);

    return yup
        .number()
        .required()
        .moreThan(0)
        .label(friendlyText)
        .test(limitType, `Amount is greater then the maximum ${friendlyText}`, async function (amount) {

            if (amount == null) {
                return false;
            }

            const result = await client.query<MaxLimitsQuery>({
                query: MaxLimitsDocument,
            });
            
            const accountLimit = result.data?.userSession

            let limit = 0;

            if (limitType === "bpay") {
                limit = accountLimit?.BPayLimitMax ?? 0
            } else if(limitType === "payAnyone") {
                limit = accountLimit?.PayAnyoneLimitMax ?? 0
            } else {
                limit = accountLimit?.RedrawLimitMax ?? 0
            }

            if (amount == null) {
                return false;
            }

            if (limit >= amount || limit === 0) {
                return true;
            } else {
                return this.createError({ message: `Amount is greater than the available ${friendlyText} of ${getCurrencySymbol()}${limit}` });
            }
        })
}