import React from "react";
import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";
import { useAccountFundsTransfersQuery } from "generated/generated-models";
import { makeNonEmpty } from "core/util/array";
import { CError } from "app/common/CError";
import { Loading } from "app/lib/components/Loading";
import PayAnyoneRequest from "./PayAnyoneRequest";
import DeleteTransfer from "./DeleteTransfer";
import Payment from "./Payment";
import Redraw from "./Redraw";
import InterAccountTransfer from "./InterAccountTransferRequest";
import BpayRequest from "./BPayRequest";
import ManageBpayBillerHistory from "./ManageBpayBillerHistory";
import { ManagePayAnyoneAccounts } from "./managePayAnyoneAccounts/ManagePayAnyoneAccounts";
import AccountTransferHome from "./AccountTransferHome";

type Props = {
    accountIdOrNumber: string,
    recentTransfersToShow?: number
}

const AccountTransferPage: React.FC<Props> = ({ accountIdOrNumber, recentTransfersToShow = 10 }) => {
    const { path } = useRouteMatch();
    const { data, loading, error } = useAccountFundsTransfersQuery({
        variables: { accountIdOrNumber: accountIdOrNumber }
    });

    const account = data?.accountByIdOrNumber;
    const basePath = path || "transfer";

    if (loading) {
        return <Loading />;
    }
    if (error || account == null) {
        return <CError error={"Could not load funds transfer details"} />;
    }

    const pendingFundsTransfers = makeNonEmpty(account.PendingAccountFundsTransfers);
    const processedFundsTransfers = makeNonEmpty(account.ProcessedAccountFundsTransfers)
        .slice(0, recentTransfersToShow);

    return (
        <Switch>
            <Route path={`${basePath}/PayAnyone`}>
                <PayAnyoneRequest accountId={account.Id} />
            </Route>
            <Route path={`${basePath}/Redraw`}>
                <Redraw accountId={account.Id} />
            </Route>
            <Route path={`${basePath}/InterAccount`}>
                <InterAccountTransfer accountId={account.Id} />
            </Route>
            <Route path={`${basePath}/bpay`}>
                <BpayRequest accountId={account.Id} />
            </Route>
            <Route path={`${basePath}/Payment`}>
                <Payment accountId={account.Id} />
            </Route>
            <Route path={`${basePath}/bankaccount`}>
                <ManagePayAnyoneAccounts />
            </Route>
            <Route path={`${basePath}/billerhistory`}>
                <ManageBpayBillerHistory />
            </Route>
            <Route path={`${basePath}/Delete/:fundsTransferId`}>
                {transferDetailsProps => {
                    const fundsTransferId = transferDetailsProps?.match?.params.fundsTransferId;
                    const transfer = makeNonEmpty(account.PendingAccountFundsTransfers)
                        .find(x => x.Id === fundsTransferId) || { Id: fundsTransferId };

                    return <DeleteTransfer fundsTransfer={transfer} />;
                }}
            </Route>
            <Route>
                <AccountTransferHome
                    account={account}
                    transferModel={{
                        Redraw: account?.Redraw,
                        BPayAllowed: account?.CurrentUserAccountAuthority?.BPay || false,
                        RedrawAllowed:
                            account?.CurrentUserAccountAuthority?.Redraw || false,
                        PayAnyoneAllowed:
                            account?.CurrentUserAccountAuthority?.PayAnyone || false,
                        InterAccountAllowed:
                            account?.CurrentUserAccountAuthority?.Operate || false,
                        AllowOnlineDisbursements: false,
                        PendingTransfers: pendingFundsTransfers,
                        TransferHistory: processedFundsTransfers,
                    }}
                />
            </Route>
        </Switch>
    );
}

export {
    AccountTransferPage
}
