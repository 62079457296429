import Form from "app/components/Form";
import Input from "app/components/Input";
import RequestMultiFactorTokenFormik from "app/components/RequestMultiFactorTokenFormik";
import Title from "app/components/Title";
import { DelayedRender } from "app/lib/components/DelayedRender";
import { nameof } from "core/util/nameof";
import { Formik } from "formik";
import { ChangePasswordInput, useChangeCurrentUserPasswordMutation, useIsUserAuthenticatedQuery, usePasswordValidatorByApplicationQuery } from "generated/generated-models";
import React from "react";
import { Redirect, useHistory } from "react-router";
import { Validate } from "./changePasswordValidator";
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';

const requiresToken = (statusCode?: string) => statusCode === "499" || statusCode === "498";

export type ChangePasswordFormProps = {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
    securityToken?: string;
}

const ChangePasswordPage = () => {
    const baseSymbols = "!@#$%^&*()";
    const extendedSymbols = "~`-_=+[]{}\\|;:'\",.<>?/";
    const [changePassword, changePasswordResult] = useChangeCurrentUserPasswordMutation();
    const { data: authenticatedData } = useIsUserAuthenticatedQuery();
    const { data: passwordValidator } = usePasswordValidatorByApplicationQuery();

    const firstLogin = authenticatedData?.userSession?.IsFirstLogin || false;
    const hasTempPassword = authenticatedData?.userSession?.HasTempPassword || false;
    const history = useHistory();

    const requiresMfa = () => {
        if (requiresToken(changePasswordResult.data?.changeCurrentUserPassword?.Code)) {
            return true;
        }

        if (authenticatedData?.userSession?.UseMultiFactorAuth) {
            return true;
        }

        return false;
    }

    const getErrorMessage = () => {
        if (changePasswordResult.data?.changeCurrentUserPassword?.Success === true) {
            return undefined;
        }

        if (requiresToken(changePasswordResult.data?.changeCurrentUserPassword?.Code)) {
            return "Security token was invalid";
        }

        const errorMessage = changePasswordResult.data?.changeCurrentUserPassword?.Message;

        if (errorMessage != null && errorMessage !== "") {
            return errorMessage
        }
        return undefined;
    }

    const SuccesfullyChangedPassword = () => {
        if (!changePasswordResult.loading &&
            changePasswordResult.data?.changeCurrentUserPassword?.Success === true
        ) {
            return (
                <Alert severity="success">
                    Your password has been changed successfully.
                    <DelayedRender delay={1500}>
                        <Redirect to="/security/logout" />
                    </DelayedRender>
                </Alert>
            );
        } else {
            return null;
        }
    }

    return (
        <div className="changepassword-page sub-wrap form-wrap">
            <Title title="Change Your Password" />
            {(firstLogin || hasTempPassword) && (
                <Alert severity="error">
                    The password you are using is temporary. You will need to supply a new
                    password before you can continue.
                </Alert>
            )}
            <div className="text-muted">
            Please use the following password rules to ensure you have a valid password:
                <ul>
                <li>Minimum password length: {passwordValidator?.passwordValidatorByApplication?.MinimumPasswordLength}</li>
                {(passwordValidator?.passwordValidatorByApplication?.RequireLowerCaseCharacters) && (
                    <li>At least {passwordValidator?.passwordValidatorByApplication?.MinimumLowerCaseCharacterCount} lower case characters required</li>
                )}
                {(passwordValidator?.passwordValidatorByApplication?.RequireUpperCaseCharacters) && (
                    <li>At least {passwordValidator?.passwordValidatorByApplication?.MinimumUpperCaseCharacterCount} upper case characters required</li>
                )}
                {(passwordValidator?.passwordValidatorByApplication?.ProhibitRepeatingAlphabetCharacters) && (
                    <li>Alphabet characters cannot be repeated, e.g. <b>aa</b>lphabet</li>
                )}
                {(passwordValidator?.passwordValidatorByApplication?.ProhibitSequentialAlphabetCharacters) && (
                    <li>Alphabet characters cannot be sequential, e.g. a<b>bc</b>jtda</li>
                )}
                {(passwordValidator?.passwordValidatorByApplication?.RequireNumberCharacters) && (
                    <li>At least {passwordValidator?.passwordValidatorByApplication?.MinimumNumberCharacterCount} numbers required</li>
                )}
                {(passwordValidator?.passwordValidatorByApplication?.ProhibitRepeatingNumberCharacters) && (
                    <li>Numbers cannot be repeated, e.g. 1<b>22</b>345</li>
                )}
                {(passwordValidator?.passwordValidatorByApplication?.ProhibitSequentialNumberCharacters) && (
                    <li>Numbers cannot be sequential, e.g. 1<b>234</b>1942</li>
                )}
                {(passwordValidator?.passwordValidatorByApplication?.RequireBaseSymbols) && (
                    <li>At least {passwordValidator?.passwordValidatorByApplication?.MinimumBaseSymbolCharacterCount} base symbols required, these include: {baseSymbols}</li>
                )}
                {(passwordValidator?.passwordValidatorByApplication?.RequireExtendedSymbols) && (
                    <li>At least {passwordValidator?.passwordValidatorByApplication?.MinimumExtendedSymbolCharacterCount} extended symbols required, these include: {extendedSymbols}</li>
                )}
                {(passwordValidator?.passwordValidatorByApplication?.ProhibitRepeatingSymbolCharacters) && (
                    <li>Symbols cannot be repeated, e.g. example<b>!!</b></li>
                )}
                {// eslint-disable-next-line
                (passwordValidator?.passwordValidatorByApplication?.MinimumPasswordHistory ?? 0 > 0) && (
                    <li>Cannot reuse any of your last {passwordValidator?.passwordValidatorByApplication?.MinimumPasswordHistory} passwords</li>
                )}
                </ul>
            </div>
            <Formik
                initialValues={{
                    oldPassword: "",
                    newPassword: "",
                    confirmNewPassword: "",
                    securityToken: "",
                }}
                onSubmit={(values) => {
                    const vals: ChangePasswordInput = {
                        CurrentPassword: values.oldPassword,
                        NewPassword: values.newPassword,
                        TwoFactorToken: values.securityToken,
                    };
                    return changePassword({ variables: { input: vals } });
                }}
                validationSchema={Validate(requiresMfa())}
            >
                {({ isSubmitting }) => {
                    return (
                        <Form>
                            <Input
                                name={nameof<ChangePasswordFormProps>("oldPassword")}
                                type="password"
                                label="Old Password"
                            />
                            <Input
                                name={nameof<ChangePasswordFormProps>("newPassword")}
                                type="password"
                                label="New Password"
                            />
                            <Input
                                name={nameof<ChangePasswordFormProps>("confirmNewPassword")}
                                type="password"
                                label="Confirm New Password"
                            />

                            {requiresMfa() &&
                                <RequestMultiFactorTokenFormik
                                    name={nameof<ChangePasswordFormProps>("securityToken")}
                                    requestMfaOnDisplay={true}
                                />
                            }

                            {getErrorMessage() != null &&
                                <Alert severity="error">
                                    {getErrorMessage()}
                                </Alert>
                            }
                            <SuccesfullyChangedPassword />

                            <div className="form-group button-row">
                                <Button
                                    className="btn-cancel" id="cancel"
                                    sx={{ p: 1.5, mt: 1 }}
                                    variant="contained"
                                    onClick={() => history.push(`/Security/ProfileSettings/Security`)}
                                    disabled={isSubmitting} >
                                    Cancel
                                </Button>
                                <LoadingButton
                                    type="submit" id="submit"
                                    sx={{ p: 1.5, mt: 1 }}
                                    loading={isSubmitting}
                                    loadingPosition="end"
                                    variant="contained"
                                >
                                    Change Password
                                </LoadingButton>

                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default ChangePasswordPage;
