import React from "react";
import { Link } from "react-router-dom";
import {
    FundsTransfer,
    TaskFrequencyPeriod,
} from "generated/generated-models";
import { CDate } from "app/common/CapitalDate";
import MoneyFormatted from "app/components/MoneyFormatted";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

interface Props {
    transfer: FundsTransfer;
    canDeleteTransfer: boolean;
}

const TransferUntil: React.FC<{ transfer: FundsTransfer }> = ({ transfer }) => {
    const {
        Frequency,
        FurtherNotice,
        EndingOn,
        NumberOfTimes,
        NumberOfTimesAmount,
        SpecifiedDate,
        RemainingNumberOfTimes,
    } = transfer;

    if (Frequency == null || Frequency.toLocaleUpperCase() === TaskFrequencyPeriod.Once.toUpperCase()) {
        return null;
    }

    if (FurtherNotice) {
        return <span>Until Further notice</span>;
    }

    if (NumberOfTimes) {
        var message = `Remaining ${RemainingNumberOfTimes} of ${NumberOfTimesAmount} `;

        if (NumberOfTimesAmount != null && NumberOfTimesAmount > 1) {
            message += "times";
        } else {
            message += "time";
        }

        return <span>{message}</span>
    }

    if (SpecifiedDate && EndingOn != null) {
        return (
            <span>
                Until <CDate date={EndingOn} />
            </span>
        );
    }
    return null;
};

const TransferRow = ({ transfer, canDeleteTransfer }: Props) => {
    const toAccount = () => {
        if(transfer.Billercode == null || Number(transfer.Billercode) <= 0) {
            return transfer.ToAccountDescription
        } else {
            return `${transfer.BillerName} (${transfer.CustomerReferenceNumber})`;
        }
    }

    const Frequency = () => {
        if(transfer.Frequency != null && transfer.Frequency !== TaskFrequencyPeriod.Once) {
            return <span>{transfer.Frequency}</span>;
        } else {
            return null;
        }
    }

    const AvailableActions = () => {
        if (!canDeleteTransfer) {
            return <td></td>
        }
        return (
            <IconButton component={Link} aria-label="delete" to={(x: { pathname: any; }) => `${x.pathname}/Delete/${transfer.Id}`} id="deleteTransfer"><DeleteIcon/></IconButton>
        );
    }

    return (
        <>
            <tr className={"transfer-row"} key={transfer.Id}>
                <td>
                    <CDate date={transfer.NextScheduledDate} />
                </td>
                <td>
                    {transfer.ReceiptNumber}
                </td>
                <td>
                    <div className="transfer-frequency">
                        <Frequency />
                    </div>
                    <div className="transfer-until">
                        <TransferUntil transfer={transfer} />
                    </div>
                </td>
                <td>
                    <div>{transfer.TransferTypeDescription}</div>
                    {transfer.FromAccountDescription != null && <div>From: {transfer.FromAccountDescription}</div>}
                    <div>
                        To: <span className="col-transfer-to">{toAccount()}</span>
                    </div>
                    {transfer.RegularPayment && (
                        <strong>Regular Payment</strong>
                    )}
                </td>
                <td className="money-cell">
                    <MoneyFormatted amount={transfer.Amount} />
                </td>
                <td className="summary-cell">
                    <Frequency />
                    <TransferUntil transfer={transfer} />
                    <div>{transfer.TransferTypeDescription}</div>
                    <div>From: {transfer.FromAccountDescription}</div>
                    <div>To: <span className="col-tfer-to">{toAccount()}</span></div>

                    {transfer.RegularPayment && (
                        <strong>Regular Payment</strong>
                    )}

                    <MoneyFormatted amount={transfer.Amount} />
                </td>
                <td><AvailableActions /></td>
            </tr>
            <tr className="transfers-mobile">
                <td className="summary-cell transfer-date">
                    <p><CDate date={transfer.NextScheduledDate} /></p>
                </td>
                <td className="summary-cell">
                    <p className="transaction-type">
                        {transfer.TransferTypeDescription} - <Frequency /><AvailableActions />
                    </p>
                    {transfer.RegularPayment && (
                        <p><strong className="text-warning">Regular Payment</strong></p>
                    )}

                    <p><TransferUntil transfer={transfer} /></p>
                    <p><MoneyFormatted amount={transfer.Amount} /></p>
                    <p><span className="subtitle">From:</span> {transfer.FromAccountDescription}</p>
                    <p><span className="subtitle">To:</span> {toAccount()}</p>
                </td>
            </tr>
        </>
    );
};

export default TransferRow;
