import { TransferAccount } from "generated/generated-models";
import { useHistory } from "react-router";
import { useTransferPaymentsContext } from "./useTransferPaymentsContext";


export function useTransferPayments<T>() {
    const context = useTransferPaymentsContext();
    const history = useHistory();

    return {
        formState: context.formState as Partial<T>,
        formStage: context.formStage,
        transferId: context.fundsTransferId,
        fromAccount: context.fromAccount,
        toAccount: context.toAccount,
        transferType: context.transferType,
        isPending: context.isPending,
        createTransfer: (formstate: any) => {
            context.updateform(formstate);
            context.updateStage("Confirm");
        },
        confirmTransfer: (transferId: string) => {
            context.updateFundsTransfer(transferId);
            context.updateStage("Receipt");
        },
        confirmFromAccount: (fromAccount: TransferAccount) => {
            context.updateFromAccount(fromAccount);
        },
        confirmToAccount: (toAccount: TransferAccount) => {
            context.updateToAccount(toAccount);
        },
        updateTransferType: (transferType: string) => {
            context.updateTransferType(transferType);
        },
        updateIsPending: (isPending: boolean) => {
            context.updateIsPending(isPending);
        },
        modifyTransfer: () => {
            context.updateStage("Create");
        },
        returnToHome: () => {
            history.push(`/`);
        }
    };
};
