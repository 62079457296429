export const EmptyString = "";

export function IsNullOrWhiteSpace(value?: string | null) {
    return !value || !value.trim();
}

export function NotEmptyOrWhiteSpace(value?: string | null): value is string {
    return !IsNullOrWhiteSpace(value);
}

/**
 * TODO: check IE < 11 compat with localeCompare
 * @param a
 * @param b
 * @param ignoreCase
 */
export function StringIsEqual(
    a?: string | null,
    b?: string | null,
    ignoreCase: boolean = true
): boolean {
    return (
        a === b ||
        (a != null &&
            b != null &&
            (0 === a.localeCompare(b) ||
                (ignoreCase && a.toLowerCase() === b.toLowerCase())))
    );
}

export function toBool(value?: string, fallback: boolean = false) {
    if (IsNullOrWhiteSpace(value)) {
        return fallback;
    }
    if (typeof value === "string") {
        value = value.trim().toLowerCase();
    }
    switch (value) {
        case "true":
            return true;
        case "1":
            return true;
        case "on":
            return true;
        case "yes":
            return true;
        default:
            return false;
    }
}

export function toOptionalNumber(value?: string) {
    if (IsNullOrWhiteSpace(value)) {
        return undefined;
    }
    return parseInt(value || "", 10);
}
