import React, {useState} from "react";
import { useField } from "formik";
import FieldErrorMessage from "./FieldErrorMessage";
import { getFieldId, getHelpTextId, useAddErrorClass } from "./utilities";
import classNames from "classnames";
import NumericFormat, { NumberFormatProps } from 'react-number-format';
import { getCurrencySymbol } from "core/util/currencySymbol"

type InputType =
    | "text"
    | "wholeNumber"
    | "wholeNumberAuth"
    | "wholeNumberPayment"
    | "currency"
    | "password"
    | "bsb"

type Props = {
    name: string;
    type: InputType;
    className?: string;
    label?: string;
    helpText?: string;
    placeHolder?: string;
    disabled?: boolean;
    printableOnly?: boolean;
}

const Input = (props: Props) => {
    const [field,, helper] = useField(props.name);
    const [value, setValue] = useState();

    let inputClassName = classNames([
        "form-control",
        props.className,
        { "form-control-wholenumber": props.type === "wholeNumber" },
        { "form-control-currency": props.type === "currency" },
        { "form-control-text": props.type === "text" || props.type === "password" || props.type === "bsb" },
    ])

    inputClassName = useAddErrorClass(props.name, inputClassName)
    const helpTextId = props.helpText != null ? getHelpTextId(props.name) : undefined
    const fieldId = getFieldId(props.name);

    const charallow = (e:any) => 
    {
      setValue(e.target.value.replace(/[^ -~]/g,''));
    }

    const handleOnChange = (e:any) => 
    {
        helper.setValue(e.target.value);
        helper.setTouched(e.target);
        field.onChange(e);
        charallow(e);
    }

    const getPattern = () => {
        switch (props.type) {
            case "wholeNumber":
                return "[0-9]*";
            case "wholeNumberAuth":
                return "[0-9]*";
            case "wholeNumberPayment":
                return "[0-9]*";
            case "bsb":
                return `/^\s*\d\d\d-?\d\d\d\s*$/`
            default:
                return undefined;
        }
    }

    const getInputMode = () => {
        switch (props.type) {
            case "wholeNumber":
                return "numeric";
            case "wholeNumberAuth":
                return "numeric";
            case "wholeNumberPayment":
                return "numeric";
            case "bsb":
                return "numeric";
            default:
                return undefined;
        }
    }

    const getType = () => {
        switch(props.type) {
            case "password":
                return "password";
            default:
                return "text"
        }
    }

    const formProps: NumberFormatProps = {
        className: inputClassName,
        id: fieldId,
        name: props.name,
        placeholder: props.placeHolder,
        disabled: props.disabled,
        "aria-describedby": helpTextId,
        pattern: getPattern(),
        inputMode: getInputMode(),
        type: getType()
    }

    return (
        <div className="form-group">
            <label htmlFor={fieldId}>{props.label}</label>
            <div className="input-group">
                {props.type === "currency" &&
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="validationTooltipUsernamePrepend">{getCurrencySymbol()}</span>
                    </div>
                }

                {props.type === "currency" &&
                    <NumericFormat
                        {...formProps}
                        thousandSeparator={true}
                        allowLeadingZeros={false}
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        onValueChange={x => helper.setValue(x.floatValue)}
                        onBlur={() => helper.setTouched(true)}
                        value={field.value}
                    />
                }

                {props.type === "wholeNumber" &&
                    <NumericFormat
                        {...formProps}
                        allowLeadingZeros={true}
                        allowNegative={false}
                        decimalScale={0}
                        onValueChange={x => helper.setValue(x.floatValue)}
                        onBlur={() => helper.setTouched(true)}
                        value={field.value}
                        maxLength={15}
                    />
                }

                {props.type === "wholeNumberAuth" &&
                    <input
                        {...field}
                        {...formProps}
                        onChange={handleOnChange.bind(this)}
                        onBlur={handleOnChange.bind(this)}
                        value={field.value}
                        maxLength={15}
                    />
                }

                {props.type === "wholeNumberPayment" &&
                    <NumericFormat
                        {...formProps}
                        allowLeadingZeros={false}
                        allowNegative={false}
                        decimalScale={0}
                        onValueChange={x => helper.setValue(x.floatValue)}
                        onBlur={() => helper.setTouched(true)}
                        value={field.value}
                        maxLength={5}
                    />
                }

                {(props.type === "text" || props.type === "password" ) && (props.printableOnly !== true) &&
                    <input
                    {...field}
                    {...formProps}
                    />
                }

                {(props.type === "text" || props.type === "password" ) && props.printableOnly === true &&
                    <input
                        {...field}
                        {...formProps}
                        onChange={handleOnChange.bind(this)}
                        onBlur={handleOnChange.bind(this)}
                        value={value}
                    />
                }
                
                {props.type === "bsb" && <NumericFormat 
                    {...field}
                    {...formProps}
                    format={"###-###"}
                    value={field.value}
                    mask={"_"}
                />}
                <FieldErrorMessage errorName={props.name} />

            </div>
            <small id={helpTextId} className="form-text text-muted">{props.helpText}</small>
        </div>
    )
}

export default Input;
