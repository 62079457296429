import gql from "graphql-tag";
// import query from "./financeSummaryQuery.graphql";
// type definitions define the "shape" of your data and specify
// which ways the data can be fetched from the GraphQL server.
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client"
import { FinanceSummaryQueryInlineQuery } from "generated/generated-models";
import { Loading } from "../lib/components/Loading";
import { FinanceBalancesView } from './FinanceBalancesView';

const query = gql`
    query financeSummaryQueryInline {
        financeSummary {
            Id
            TotalPrincipal {
                CurrencyCode
                Amount
            }
            TotalRedraw {
                CurrencyCode
                Amount
            }
            Instruments {
                Id
                Number
                LoanPurpose
                Description
                TotalPrincipal
                TotalRedraw
                Status
                IsUnderHardshipAgreement
                InstrumentType
                Term
                TotalTermMonths
                RemainingTermMonths
                MaturityDate
                PrimaryBorrower

                Accounts {
                    Id
                    Number
                    Description
                    DisplayOrder
                    InstrumentId
                    LegacyNumber
                    ProductType
                    CurrentBalance
                    Redraw
                    InterestType
                    CardAccount
                }
            }
        }
    }
`;

const FinanceSummaryBalances = () => (
    <Query<FinanceSummaryQueryInlineQuery> query={query}>
        {({
            data,
            loading,
            error,
        }: QueryResult<FinanceSummaryQueryInlineQuery>) => {
            if (loading) {
                return <Loading />;
            }
            if (error) {
                return <div>`Error!: ${error.message}`</div>;
            }
            if (!data) {
                return FinanceBalancesView({});
            }
            const financeSummary = data.financeSummary || undefined;

            return FinanceBalancesView({
                financeSummary
            });
        }}
    </Query>
);

export {
    FinanceSummaryBalances
};
