import React from "react";
import Alert from '@mui/material/Alert';

interface Props {
    header?: string;
    error?: string;
}

/** Used for full page errors */
const CError: React.FC<Props> = ({ header, error }) => {
    return (
        <div style={{ width: "100%" }}>
            <Alert severity="error">
                <h2>{header || "Error"}</h2>
                <div>{error}</div>
            </Alert>
        </div>
    );
};

export { CError };
