import React from "react";
import { CEmail } from "../common/CEmail";
import { clientConfigFn } from "clientConfig";

export const SupportContactInformation = ({IncludePlease = false}) => {
    const clientConfig = clientConfigFn();
    const phoneNumber = clientConfig && clientConfig.supportPhoneNumber;
    const emailAddress = clientConfig && clientConfig.supportEmailAddress;

    if (phoneNumber !== "" && emailAddress !== "") {
        return (
            <span>
                {IncludePlease ? "Please contact" : "Contact"} support on {phoneNumber}, or alternatively email <CEmail email={emailAddress} />.
            </span>
        );
    }
    if (phoneNumber !== "") {
        return <span>{IncludePlease ? "Please contact" : "Contact"} support on {phoneNumber}</span>;
    }
    if (emailAddress !== "") {
        return (
            <span>
                {IncludePlease ? "Please contact" : "Contact"} support <CEmail email={emailAddress} />
            </span>
        );
    }
    return <span>{IncludePlease ? "Please contact" : "Contact"} support.</span>;
};
