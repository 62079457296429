import { useState } from "react";
import Title from "app/components/Title";
import { Form, Formik } from "formik";
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import { usePartyChangeElectronicCommsStatusMutation } from "generated/generated-models";
import { useSecurityTokenMutation } from "core/apollo/SecurityTokenApollo";
import Alert from '@mui/material/Alert';
import RequestMultiFactorTokenFormik from "app/components/RequestMultiFactorTokenFormik";
import { Validate } from "./ProfileElectronicCommunicationsInputValidator";

export type ElectronicCommsStatusInput = {
    ProfileId?: string;
    SMSNotificationStatus?: boolean;
    EmailNotificationStatus?: boolean;
    StatementNotificationStatus?: boolean;
    TwoFactorToken?: string;
}

type ElectronicCommsStatusMFAProps =
    | "WithoutMFA"
    | "WithMFA"

const ProfileElectronicCommunications = ({ ProfileId, SMSNotificationStatus, EmailNotificationStatus, StatementNotificationStatus }: ElectronicCommsStatusInput) => {
    const [electronicCommsStatus, setElectronicCommsStatus] = useState<boolean | undefined>(undefined);
    const [changeElectronicCommsStatus, { data: changeElectronicCommsStatusData, error: changeElectronicCommsStatusError }] = useSecurityTokenMutation(usePartyChangeElectronicCommsStatusMutation());
    const [electronicCommsMFA, setElectronicCommsMFA] = useState<ElectronicCommsStatusMFAProps>("WithoutMFA");

    const handleRequiresMFA = () => {
        setElectronicCommsMFA("WithMFA")
    };

    const handleElectronicCommsSubmit = async (values: ElectronicCommsStatusInput) => {
        const result = await changeElectronicCommsStatus({
            variables: {
                input:
                {
                   SMSNotificationStatus: !values.SMSNotificationStatus!,
                   EmailNotificationStatus: !values.SMSNotificationStatus!,
                   StatementNotificationStatus: !values.SMSNotificationStatus!,
                   TwoFactorToken: values.TwoFactorToken
                }
            }
        });

        if (result.data?.changeElectronicCommsStatus?.Code === "499") {
            handleRequiresMFA();
        }
        if (result.data?.changeElectronicCommsStatus?.Success) {
            setElectronicCommsStatus(true);

            setTimeout(
                () => {
                    setElectronicCommsStatus(true);
                    window.location.reload();
                },
                3000
            );
        }
    }
    
    const initialValues: ElectronicCommsStatusInput = {
        ProfileId: ProfileId,
        SMSNotificationStatus: SMSNotificationStatus,
        EmailNotificationStatus: SMSNotificationStatus,
        StatementNotificationStatus: SMSNotificationStatus,
    }

    let electronicStatus = false
    if (SMSNotificationStatus) {
        electronicStatus = true
    }
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleElectronicCommsSubmit(values)}
            validationSchema={Validate(electronicCommsMFA === "WithMFA")}
        >
            {({ isSubmitting }) => {
                return (
                    <>
                        <Title title="Electronic Communications"/>
                        {electronicStatus === false &&
                            <p className="intro-text">You are about to opt in to receive electronic communications on this profile. Please confirm you are happy to proceed.</p>
                        }
                        {electronicStatus === true &&
                        <p className="intro-text">You are about to opt out from receiving electronic communications on this profile. Please confirm you are happy to proceed.</p>
                        }
                        <Form>
                            {!electronicCommsStatus &&
                                <RequestMultiFactorTokenFormik
                                    name={"TwoFactorToken"}
                                    showRequestNewToken={true}
                                    requestMfaOnDisplay={true}
                                    visible={electronicCommsMFA === "WithMFA"}
                                />
                            }
                            {(changeElectronicCommsStatusError || changeElectronicCommsStatusData?.changeElectronicCommsStatus?.Success === false) &&
                                <Alert severity="error">
                                    {changeElectronicCommsStatusData?.changeElectronicCommsStatus?.Message}
                                </Alert>
                            }
                            {electronicCommsStatus
                                ? <Alert severity="success">You have successfully updated your electronic communication settings.</Alert>
                                : <div className="button-row">
                                    <Button
                                        className="btn-cancel" id="cancel"
                                        sx={{ p: 1.5, mt: 1 }}
                                        variant="contained"
                                        onClick={() => window.location.reload()}
                                        disabled={isSubmitting}>
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit" id="submit"
                                        sx={{ p: 1.5, mt: 1 }}
                                        loading={isSubmitting}
                                        loadingPosition="end"
                                        variant="contained"
                                    >
                                        Confirm
                                    </LoadingButton>
                                </div>
                            }
                        </Form>
                    </>
                )
            }}
        </Formik>
    )
}

export default ProfileElectronicCommunications;
