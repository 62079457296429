import { Children } from "react";

const FundsTransferCardsContainer: React.FC = (props) => {
    if (Children.count(props.children) === 0) {
        return null;
    }

    return (
        <ul className="card payment-create-summary list-group list-group-flush">
            {Children.map(props.children, child =>
                <li className="list-group-item">
                    {child}
                </li>
            )}
        </ul>
    );
}

export default FundsTransferCardsContainer;
