import * as yup from "yup";
import { SetAuthCodeProps } from "./AuthenticatorPage";

export const ValidateCode = () => {
    const schema: yup.ObjectSchema<SetAuthCodeProps> = yup.object({

        authenticatorToken: yup
            .string()
            .label("Authenticator Code")
            .required()
            .min(6)
            .max(6)
            .matches(/^[0-9]+$/, "Authenticator code should only contain numbers"),
    })
        .defined();

    return schema;
}
