import { CError } from "app/common/CError";
import Title from "app/components/Title";
import { Loading } from "app/lib/components/Loading";
import { BankAccount, useAccountFundsTransfersQuery } from "generated/generated-models";
import { useTransferWizard } from "../utilities/TransferWizard";
import { makeNonEmpty } from "core/util/array";
import React from "react";
import Button from "app/components/Button";
import { useHistory } from "react-router";
import PaymentCard from "app/components/PaymentCard/PaymentCard";
import FundsTransferCardsContainer from "app/components/fundsTransferCards/FundsTransferCardsContainer";
import Alert from '@mui/material/Alert';

const RedrawCard = ({ bankAccount }: { bankAccount: BankAccount }) => {
    const transferWizard = useTransferWizard();

    return (
        <PaymentCard
            onClick={() => transferWizard.confirmRedrawAccount(bankAccount)}
            title="To"
            name={bankAccount?.AccountName}
            details={
                <div>
                    <div>{bankAccount?.BankIdentifierText} {bankAccount?.AccountNumber}</div>
                    <div>{bankAccount?.BankName}</div>
                </div>
            }
        />
    )
}

const SelectRedraw = () => {
    const { accountId } = useTransferWizard();
    const history = useHistory();

    const { data, loading, error } = useAccountFundsTransfersQuery({
        variables: { accountIdOrNumber: accountId }
    });

    if (loading) {
        return <Loading />
    }

    if (error || data?.accountByIdOrNumber?.BankAccounts == null) {
        return <CError error="Could not load Redraw details" />
    }

    const account = data.accountByIdOrNumber;

    const redrawAccounts = makeNonEmpty(account.BankAccounts);

    return (
        <div>
            <Title title="Select Redraw Bank Account" />
            <div>
                {account.BankAccounts?.length === 0 &&
                    <div>
                         <Alert severity="warning">No available Bank Accounts associated with this account</Alert>
                    </div>}
                <FundsTransferCardsContainer>
                    {redrawAccounts.map(x =>
                        <RedrawCard bankAccount={x} key={x.Id} />
                    )}
                </FundsTransferCardsContainer>
            </div>
            <br />
            <Button
                role="secondary"
                onClick={() => history.goBack()}
                id="goBackButton"
            >
                Go Back
            </Button>
        </div>
    )
}

export default SelectRedraw;
