import BankingCutOff from "app/components/BankingCutoff";
import { CError } from "app/common/CError";
import Form from "app/components/Form";
import Input from "app/components/Input";
import { Loading } from "app/lib/components/Loading";
import { nameof } from "core/util/nameof";
import { Formik, useFormikContext } from "formik";
import {
    RecurrenceEndEnum,
    TaskFrequencyPeriod,
    useAccountFundsTransfersQuery
} from "generated/generated-models";
import TransferScheduleInput, { TransferScheduleInputValues } from "../utilities/TransferScheduleInput";
import { Validate } from "./CreateBpayValidate";
import { useTransferWizard } from "../utilities/TransferWizard";
import Title from "app/components/Title";
import FutureDatedWarningText from "app/components/FutureDatedWarningText";
import FromAccountCard from "app/components/fundsTransferCards/FromAccountCard";
import FundsTransferCardsContainer from "app/components/fundsTransferCards/FundsTransferCardsContainer";
import PaymentCard from "app/components/PaymentCard/PaymentCard";

export type BpayFormProps = {
    transferDescription?: string;
    amount?: number;
} & TransferScheduleInputValues

type Props = {
    accountId: string;
}

const Payment = (props: Props) => {
    const { bpayBiller, gotoSelect, changeAccount, returnToHome } = useTransferWizard();
    const { data, loading, error } = useAccountFundsTransfersQuery({
        variables: { accountIdOrNumber: props.accountId }
    });
    const { values, isSubmitting } = useFormikContext<BpayFormProps>();

    if (loading) {
        return <Loading />
    }

    if (error || data?.accountByIdOrNumber == null) {
        return <CError error="Could not load BPAY details" />
    }

    return (
        <div className="bpay-create-page">
            <Title
                title="Pay a BPAY Bill"
            />

            <FundsTransferCardsContainer>
                <FromAccountCard account={data.accountByIdOrNumber} title="From" onClick={changeAccount} />
                <PaymentCard
                    title="To"
                    name={bpayBiller?.BillerDescription || bpayBiller?.BillerName}
                    details={`BILLER ${bpayBiller?.Billercode} REF ${bpayBiller?.CustomerReferenceNumber}`}
                    onClick={gotoSelect}
                />
            </FundsTransferCardsContainer>

            <Form>
                <Input
                    name={nameof<BpayFormProps>("transferDescription")}
                    type="text"
                    helpText="This will appear in your account"
                    label="Description"
                    printableOnly = {true}
                />

                <Input
                    name={nameof<BpayFormProps>("amount")}
                    type="currency"
                    label="Amount"
                />

                <TransferScheduleInput
                    values={values}
                    config={{
                        minStartingDate: new Date(),
                        minUntilDate: new Date(values.startingOn),
                    }}
                />

                <FutureDatedWarningText date={values.startingOn} />

                <BankingCutOff />

                <input
                    type="submit"
                    id="cancel"
                    value="Go Back"
                    className="btn btn-secondary"
                    onClick={() => returnToHome(data.accountByIdOrNumber?.Number!)}
                    disabled={isSubmitting}
                />
                <input
                    type="submit"
                    id="submit"
                    value="Pay Bill"
                    className="btn btn-primary btn-submit"
                    disabled={isSubmitting}
                />
            </Form>
        </div>
    )
}

const PaymentForm = () => {
    const wizard = useTransferWizard<BpayFormProps>();

    const initialValues: BpayFormProps = {
        amount: undefined,
        transferDescription: "",
        endingOn: undefined,
        startingOn: new Date(),
        frequency: TaskFrequencyPeriod.Once,
        recurrenceEnd: RecurrenceEndEnum.Never,
        numberOfTimesAmount: undefined,

        ...wizard.formState
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => wizard.createTransfer(values)}
            validationSchema={Validate(wizard.accountId)}
        >
            <Payment accountId={wizard.accountId} />
        </Formik>
    )
}

export default PaymentForm;
