import Input from "app/components/Input";
import React, { useState } from "react";
import Form from "app/components/Form";
import {
    BankAccount,
    CurrentUserPartyBankAccountsDocument,
    useCreatePayAnyoneBankAccountMutation,
    TransferAccountsByPartyDocument
} from "generated/generated-models";
import { nameof } from "core/util/nameof";
import { Formik } from "formik";
import { Validate } from "./AddPayAnyoneValidate";

import RequestMultiFactorTokenFormik from "app/components/RequestMultiFactorTokenFormik";
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';

export type AddPayAnyoneFormProps = {
    accountName: string;
    accountNumber: string;
    bsb: string;
    twoFactorToken?: string;
}

type AddPayAnyoneProps = {
    handleClose: () => void;
    goBack?: () => void;
    payAnyoneAdded?: (bankAccount: BankAccount) => void;
}

type AddPayAnyonePage = 
    | "WithoutMFA"
    | "WithMFA"

const AddPayAnyone = ({
    handleClose,
    goBack = () => { },
    payAnyoneAdded = (_) => { }
}: AddPayAnyoneProps) => {
    const [createPayAnyone, { data, error }] = useCreatePayAnyoneBankAccountMutation();
    const initialValues: AddPayAnyoneFormProps = {
        accountName: "",
        accountNumber: "",
        bsb: "",
    };
    const [addPayAnyonePage, setAddPayAnyonePage] = useState<AddPayAnyonePage>("WithoutMFA");

    const handleRequiresMFA = () => {
        setAddPayAnyonePage("WithMFA")
    };

    const handleCancelClick = () => {
        goBack();
        handleClose();
    }

    const handleSubmit = async (values: AddPayAnyoneFormProps) => {
        const result = await createPayAnyone(
            {
                variables: {
                    input: {
                        AccountName: values.accountName,
                        AccountNumber: values.accountNumber,
                        Bsb: values.bsb,
                        TwoFactorToken: values.twoFactorToken
                    }
                },
                refetchQueries: [{ query: CurrentUserPartyBankAccountsDocument }, { query: TransferAccountsByPartyDocument}]
            }
        );
        if (result.data?.createPayAnyoneBankAccount?.NewPartyBankAccount?.BankAccount != null) {
            payAnyoneAdded(result.data?.createPayAnyoneBankAccount?.NewPartyBankAccount?.BankAccount);
            handleClose();
        }

        if(result.data?.createPayAnyoneBankAccount?.Code === "499") {
            handleRequiresMFA();
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={values => handleSubmit(values)}
            validationSchema={() => Validate(addPayAnyonePage === "WithMFA")}
        >
            {({ isSubmitting, values }) => {
                return (
                    <div>
                        <h2>Add Pay Anyone Account</h2>
                        <Form>
                            <Input
                                name={nameof<AddPayAnyoneFormProps>("accountName")}
                                type="text"
                                label="Account Name"
                            />

                            <Input
                                name={nameof<AddPayAnyoneFormProps>("bsb")}
                                type="text"
                                label="BSB"
                            />

                            <Input
                                name={nameof<AddPayAnyoneFormProps>("accountNumber")}
                                type="text"
                                label="Account Number"
                            />

                            {addPayAnyonePage === "WithMFA" &&
                                <RequestMultiFactorTokenFormik
                                    name={nameof<AddPayAnyoneFormProps>("twoFactorToken")}
                                    showRequestNewToken={true}
                                    requestMfaOnDisplay={true}
                                />
                            }

                            {data?.createPayAnyoneBankAccount?.Message != null &&
                                <Alert severity="error">
                                    {data?.createPayAnyoneBankAccount.Message}
                                </Alert>
                            }
                            {error != null &&
                                <Alert severity="error">
                                    Error submitting create new pay anyone bank account request. Please try again
                                </Alert>
                            }

                            <div className="button-row">
                                <Button
                                    className="btn-cancel" id="cancelButton"
                                    sx={{ p: 1.5, mt: 1 }}
                                    variant="contained"
                                    onClick={handleCancelClick}
                                    disabled={isSubmitting} >
                                    Cancel
                                </Button>
                                <LoadingButton
                                    type="submit" id="submit"
                                    sx={{ p: 1.5, mt: 1 }}
                                    loading={isSubmitting}
                                    loadingPosition="end"
                                    variant="contained"
                                >
                                    Create Account
                                </LoadingButton>
                            </div>
                        </Form>
                    </div>
                )
            }}
        </Formik>
    )
}

export default AddPayAnyone;
