import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CError } from "app/common/CError";
import Title from "app/components/Title";
import { Loading } from "app/lib/components/Loading";
import { groupBy } from "core/util/array";
import { BankAccount, useCurrentUserPartyBankAccountsQuery } from "generated/generated-models";
import React, { useState } from "react";
import { useHistory } from "react-router";
import AddPayAnyone from "../managePayAnyoneAccounts/AddPayAnyone";
import { useTransferWizard } from "../utilities/TransferWizard";
import Button from '@mui/material/Button';

type PayAnyoneCardProps = { letter: string, bankAccounts: BankAccount[] };

const PayAnyoneCard = ({ letter, bankAccounts }: PayAnyoneCardProps) => {
    const transferWizard = useTransferWizard();
    bankAccounts = bankAccounts.sort((a, b) => a.AccountName! > b.AccountName! ? 1 : -1)

    return (
        <div className="select-destination">
            <div className="card-header">
                {letter}
            </div>
            <ul className="list-group list-group-flush">  
                {bankAccounts
                    .map(bankAccount => {
                    return (
                        <li className="list-group-item" key={bankAccount.Id} onClick={() => transferWizard.confirmPayAnyone(bankAccount)}>
                            <div>{bankAccount.AccountName}</div>
                            <div className="text-muted">{bankAccount.BankIdentifierText} {bankAccount.AccountNumber}</div>
                            <div className="text-muted">{bankAccount.BankName}</div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

const SelectPayAnyone = () => {
    const { loading, error, data } = useCurrentUserPartyBankAccountsQuery();
    const history = useHistory();
    const [createNewAccount, setCreateNewAccount] = useState(false);
    const transferWizard = useTransferWizard();

    const handleCreateNewAccountClick = () => {
        setCreateNewAccount(true);
    }

    if (loading) {
        return <Loading />
    }

    if (error || data?.userSession?.Party?.PartyBankAccounts == null) {
        return <CError error="Could not load Pay Anyone details" />
    }

    if (createNewAccount) {
        return <AddPayAnyone
            handleClose={() => undefined}
            goBack={() => setCreateNewAccount(false)}
            payAnyoneAdded={payAnyone => transferWizard.confirmPayAnyone(payAnyone)}
        />
    }

    const bankAccounts = data?.userSession?.Party?.PartyBankAccounts
        .filter(partyBankAccount => partyBankAccount?.PayAnyone === true && partyBankAccount.Deleted === false)
        .map(partyBankAccount => partyBankAccount?.BankAccount)

    const payAnyoneAccounts = groupBy(bankAccounts,
        (bankAccount) => (bankAccount.AccountName || "").toUpperCase()[0],
        (firstKey, secondKey) => firstKey.localeCompare(secondKey))

    return (
        <div>
            <Title title="Select Pay Anyone" />
            <div className="card">
                <div className="select-destination select-new">
                    <div className="card-header">
                        New Bank Account
                    </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item" onClick={handleCreateNewAccountClick}>
                            <div>New Pay Anyone</div>
                            <div><FontAwesomeIcon icon={faPlus} size="lg" /></div>
                        </li>
                    </ul>
                </div>
                {payAnyoneAccounts.map(x =>
                    <PayAnyoneCard bankAccounts={x.elements} letter={x.key} key={x.key} />
                )}
            </div>
            <br />
            <div className="button-row">
                <Button
                    className="btn-cancel" id="cancel"
                    sx={{ p: 1.5, mt: 1 }}
                    variant="contained"
                    onClick={() => history.goBack()} >
                    Cancel
                </Button>
            </div>
        </div>
    )
}

export default SelectPayAnyone;
