import { Maybe } from "graphql/jsutils/Maybe";

export function notEmpty<TValue>(
    value: TValue | null | undefined
): value is TValue {
    return value !== null && value !== undefined;
}

export function makeNonEmpty<TValue>(value: Maybe<Maybe<TValue>[]>) {
    return (value || []).filter(notEmpty);
}

type groupByResult<ElementType, KeyType> = {
    key: KeyType;
    elements: ElementType[];
}

export function groupBy<ElementType, KeyType>(
    transactions: (ElementType | null | undefined)[],
    getKey: (element: ElementType) => KeyType,
    keyComparisonCheck: (firstKey: KeyType, secondKey: KeyType) => number) {

    const group = transactions
        .reduce((acc, element) => {
            if (element == null) {
                return acc;
            }

            const key = getKey(element);

            if (acc.length === 0) {
                acc.push({
                    key: key,
                    elements: [element]
                })
                return acc;
            }

            for (let i = 0; i < acc.length; i++) {
                if (keyComparisonCheck(acc[i].key, key) === 0) {
                    acc[i].elements.push(element);
                    return acc;
                }

                if (keyComparisonCheck(key, acc[i].key) < 0) {
                    acc.splice(i, 0, {
                        key: key,
                        elements: [element]
                    })
                    return acc;
                }
            }

            acc.push({
                key: key,
                elements: [element]
            });
            return acc;
        }, [] as groupByResult<ElementType, KeyType>[]);

    return group;
}
