import { DisbursementRequestInput } from "generated/generated-models";
import * as yup from "yup";

export const yupDisbursementRequestInput = yup
    .object<DisbursementRequestInput>({
        AccountId: yup.string().required(),
        InstrumentId: yup.string().required(),
        AccountNumber: yup
            .string()
            .label("Supplier Account Number")
            .required()
            .min(4)
            .matches(
                /^\s*\d+\s*$/,
                "Supplier account number should only have digits 0-9"
            ),
        BankIdentifierText: yup
            .string()
            .label("Supplier BSB")
            .required()
            .min(6)
            .matches(/^\s*\d\d\d-?\d\d\d\s*$/, "Format should be xxx-xxx"),
        File: yup.mixed(),
        PayeeName: yup
            .string()
            .label("Supplier Name")
            .required(),
        PaymentDate: yup.date().required(),
        PaymentDescription: yup
            .string()
            .label("Payment Description")
            .required(),
        RequestedAmount: yup
            .number()
            .label("Amount")
            .required()
            .positive()
            .moreThan(0),
    })
    .required();
