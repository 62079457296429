import * as yup from "yup";

export const DateRequiredValidator = (dateRequired: boolean) => {

    if (dateRequired) {
        return yup
            .date()
            .required("Effective To date is required for temporary limits")
    }
    else {
        return yup
            .date()
            .notRequired();
    }
}
