import { useState, useEffect, useRef, useCallback } from "react";
import { findDOMNode } from "react-dom";
import { useIndueCardCreateCardMutation } from "generated/generated-models";
import $ from 'jquery';
import { Guid } from "guid-typescript";
import { useHistory } from "react-router";
import Button from '@mui/material/Button';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Title from "app/components/Title";
import { Formik } from "formik";
import Form from "app/components/Form";
import RequestMultiFactorTokenFormik from "app/components/RequestMultiFactorTokenFormik";
import { nameof } from "core/util/nameof";
import { Validate } from "./IndueCardConfirmCreateCardValidate";
import Alert from '@mui/material/Alert';

export type IndueCardCreateCardProps = {
    accountId?: Guid | string;
    twoFactorToken?: string;
}
type MFAStatus =
    | "WithoutMFA"
    | "WithMFA"

const IndueCardConfirmCreateCardPage = ({ accountId }: IndueCardCreateCardProps) => {
    const [acceptedTerms, setAcceptedTerms] = useState<boolean>();
    const [readTerms, setReadTerms] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [currentMFAStatus, setCurrentMFAStatus] = useState<MFAStatus>("WithoutMFA");
    const [createCard, { data, error }] = useIndueCardCreateCardMutation();
    const [numPages, setNumPages] = useState<number>();
    const pdfDocument = useCallback<(x: HTMLDivElement) => void>(element => {
        if (element == null) {
            return;
        }

        element.onscroll = (e: Event) => {
            // Get how close the user is to scrolling to the bottom of the page
            const difference = element.scrollHeight - element.scrollTop - element.clientHeight;
            // Use an epsilon cause floating point numbers are sad
            const bottom = Math.abs(difference) < 10;

            if (bottom) {
                setReadTerms(true);
            }
        }
    }, []);

    const url = "/client/misc/cardTermsAndConditions.pdf";

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    }

    const initialValues: IndueCardCreateCardProps = {
        accountId: accountId,
    }

    const handleRequiresMFA = () => {
        setCurrentMFAStatus("WithMFA")
    };

    // Handle Bootstrap tooltips.
    // Replace this logic if we ever install reactstrap/react bootstrap
    const toolTipRef = useRef(null);

    useEffect(() => {
        const toolTip = findDOMNode(toolTipRef.current);
        if (toolTip !== null) {
            $((toolTip)).tooltip()
        }
    }, [toolTipRef])

    useEffect(() => {
        const toolTip = findDOMNode(toolTipRef.current);
        if (toolTip !== null && readTerms === true) {
            $(toolTip).tooltip("disable");
        }
    }, [readTerms, toolTipRef])


    const handleUserAcceptsTerms = async (values: IndueCardCreateCardProps) => {
        try {
            setSubmitting(true);

            const result = await createCard(
                {
                    variables: {
                        input: {
                            AccountId: accountId,
                            TwoFactorToken: values.twoFactorToken,
                        }
                    },
                }
            );

            if (result.data?.createCard?.Code === "499") {
                handleRequiresMFA();
            }

            if (result.data?.createCard?.Success === true) {
                setAcceptedTerms(true);
            }
        } catch (ex) {
            console.error("Could not order card.");
        }
        finally {
            setSubmitting(false);
        }
    }

    const history = useHistory();

    if (acceptedTerms === false) {
        history.goBack();

    }

    if (acceptedTerms === true) {
        history.goBack();
    }

    return (
        <div className="container sub-wrap card-terms-page">
            <Title title="Terms and Conditions" />

            <div className="card-terms-container">
                <div className="card-terms-document-container">
                    <Document
                        file={url}
                        onLoadSuccess={onDocumentLoadSuccess}
                        className="card-terms-document"
                        inputRef={pdfDocument}
                        loading="Loading Terms and Conditions"
                    >
                        {
                            Array.from(
                                new Array(numPages),
                                (el, index) => (
                                    <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        scale={1.5}
                                        className="card-terms-page"
                                        loading=""
                                    />
                                ),
                            )}
                    </Document>
                </div>
            </div>

            <Formik
                initialValues={initialValues}
                onSubmit={values => handleUserAcceptsTerms(values)}
                validationSchema={() => Validate(currentMFAStatus === "WithMFA")}
            >
                {({ isSubmitting}) => {
                    return (
                        <div className="firsttimelogin-tc-button-container">
                            <Form>

                                <div className="sub-wrap form-wrap">
                                    {data?.createCard?.Message != null &&
                                        <Alert severity="error">
                                            {data?.createCard.Message}
                                        </Alert>
                                    }

                                    {currentMFAStatus === "WithMFA" &&
                                        <div>
                                            <RequestMultiFactorTokenFormik
                                                name={nameof<IndueCardCreateCardProps>("twoFactorToken")}
                                                showRequestNewToken={true}
                                                requestMfaOnDisplay={true}
                                            />
                                        </div>
                                    }
                                    {error != null &&
                                        <Alert severity="error" style={{ marginTop: "0.5rem" }}>
                                            Could not order a card at this time. Please try again later or contact customer service.
                                        </Alert>
                                    }
                                </div>

                                <Button
                                    variant="contained"
                                    role="secondary"
                                    disabled={isSubmitting}
                                    id="cancel"
                                    className="btn-cancel"
                                    onClick={() => setAcceptedTerms(false)}
                                >
                                    Cancel
                                </Button>
                                <span
                                    className="d-inline-block float-right"
                                    title="Please read to the end of the Terms and Conditions"
                                    ref={toolTipRef}
                                >
                                    <Button
                                        variant="contained"
                                        style={{ pointerEvents: readTerms ? "auto" : "none" }}
                                        type="submit"
                                        id="submit"
                                        disabled={!readTerms || submitting}
                                    >
                                        Accept Terms
                                    </Button>
                                </span>
                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </div>
    )
}

export default IndueCardConfirmCreateCardPage;
