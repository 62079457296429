import React from "react";
import ReactDatepicker from "react-datepicker";
import { useFormikContext, useField } from "formik";
import { getFieldId, useAddErrorClass } from "./utilities";
import FieldErrorMessage from "./FieldErrorMessage";
import classNames from "classnames";

type Props = {
    name: string;
    className?: string;
    label?: string;
    disabled?: boolean;
    maxDate?: Date;
    minDate: Date;
}

const DatePicker = (props: Props) => {
    const { setFieldValue, setFieldTouched } = useFormikContext();
    const [field] = useField(props.name);
    const fieldId = getFieldId(props.name);

    let inputClassName = classNames("form-control");
    inputClassName = useAddErrorClass(props.name, inputClassName);

    return (
        <div className="form-group ">
            <label htmlFor={fieldId}>{props.label}</label>
            <div className="input-group">
                <ReactDatepicker
                    selected={(field.value && new Date(field.value)) || null}
                    onChange={val => {
                        setFieldValue(field.name, val);
                    }}
                    id={fieldId}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    className={inputClassName}
                    dateFormat="d MMM y"
                    disabled={props.disabled}
                    onChangeRaw={_e => {
                        setFieldTouched(field.name, true, true);
                    }}
                />
            </div>
            <FieldErrorMessage errorName={props.name} />
        </div>
    )
}

export default DatePicker;
