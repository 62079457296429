export const sortByProp = <T>(
    array: T[],
    propName: keyof T,
    order: "ASC" | "DESC" = "ASC"
): T[] => {
    const dir = order === "ASC" ? 1 : -1;
    return array.sort((a, b) => {
        if (a[propName] < b[propName]) {
            return -1 * dir;
        }
        if (a[propName] > b[propName]) {
            return 1 * dir;
        }
        return 0;
    });
};
