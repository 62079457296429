import React, { useState, useEffect, useRef } from "react";
import { findDOMNode } from "react-dom";
import TermsAndConditions from "../other/TermsAndConditions";
import { Redirect } from "react-router";
import { useAcceptTermsMutation } from "generated/generated-models";
import $ from 'jquery';
import Button from '@mui/material/Button';


const AcceptTerms = () => {
    const [acceptedTerms, setAcceptedTerms] = useState<boolean | undefined>(undefined);
    const [readTerms, setReadTerms] = useState(false);
    const [acceptTerms] = useAcceptTermsMutation();

    // Handle Bootstrap tooltips.
    // Replace this logic if we ever install reactstrap/react bootstrap
    const toolTipRef = useRef(null);

    useEffect(() => {
        const toolTip = findDOMNode(toolTipRef.current);
        if (toolTip !== null) {
            $((toolTip)).tooltip()
        }
    }, [toolTipRef])

    useEffect(() => {
        const toolTip = findDOMNode(toolTipRef.current);
        if (toolTip !== null && readTerms === true) {
            $(toolTip).tooltip("disable");
        }
    }, [readTerms, toolTipRef])

    const handleUserAcceptsTerms = async () => {
        const result = await acceptTerms()

        if (result.data?.acceptTerms?.Success === true) {
            setAcceptedTerms(true);
        }
    }

    const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        // Get how close the user is to scrolling to the bottom of the page
        const difference = e.currentTarget.scrollHeight - e.currentTarget.scrollTop - e.currentTarget.clientHeight;
        // Use an epsilon cause floating point numbers are sad
        const bottom =  Math.abs(difference) < 10;

        if (bottom) {
            setReadTerms(true);
        }
    }

    if (acceptedTerms === false) {
        return <Redirect to="/security/logout" />
    }

    if (acceptedTerms === true) {
        return <Redirect to="/" />
    }

    return (
        <section className="content-wrapper flex-fill">
            <div className="container sub-wrap firsttimelogin-page">
                <div className="firsttimelogin-tc-container" onScroll={handleScroll}>
                    <div className="firsttimelogin-tc-inner">
                        <TermsAndConditions />
                    </div>
                </div>

                <div className="firsttimelogin-tc-button-container">
                    <Button variant="outlined"  
                    sx={{ p: 1.5, mt: 1 }}
                    onClick={() => setAcceptedTerms(false)}
                    id="cancel">Cancel</Button>

                    <span
                        className="d-inline-block float-right"
                        title="Please read to the end of the Terms and Conditions"
                        ref={toolTipRef}
                    >
                        <Button
                            variant="contained"
                            sx={{ p: 1.5, mt: 1 }}
                            style={{ pointerEvents: readTerms ? "auto" : "none" }}
                            type="submit"
                            disabled={!readTerms}
                            onClick={handleUserAcceptsTerms}
                            id="acceptTermsAndConditionsButton"
                        >
                            Accept Terms
                        </Button>
                    </span>
                </div>
            </div>
        </section>
    )
}

export default AcceptTerms;
