import * as React from 'react';
import BpayText from "app/components/BpayText"
import BankingCutOff from "app/components/BankingCutoff";
import ManageBpayBillerHistory from "../accountTransfer/ManageBpayBillerHistory/ManageBpayBillerHistory";
import { ManagePayAnyoneAccounts } from "../accountTransfer/managePayAnyoneAccounts/ManagePayAnyoneAccounts";
import { RecurrenceEndEnum, TaskFrequencyPeriod, TransferAccount, useTransferAccountsByPartyQuery, usePartyLimitQuery, useIsUserAuthenticatedQuery } from "generated/generated-models";
import { CError } from "app/common/CError";
import { Loading } from "app/lib/components/Loading";
import MoneyFormatted from "../components/MoneyFormatted";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import DomainIcon from '@mui/icons-material/Domain';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BPayLandIcon from "app/layout/bpay-icon-land.png";
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import TransferScheduleInput, { TransferScheduleInputValues } from 'app/accountTransfer/utilities/TransferScheduleInput';
import { useTransferPayments } from './context/useTransferPayments';
import { Formik, useFormikContext } from 'formik';
import Form from "app/components/Form";
import { Validate } from './TransferAndPaymentsValidate';
import { nameof } from 'core/util/nameof';
import FutureDatedWarningText from 'app/components/FutureDatedWarningText';
import Input from 'app/components/Input';
import SanitisedImage from 'app/components/SanitisedImage';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from "react-router-dom";
import { clientConfigFn } from "clientConfig";
import { FormControlLabel, Switch, Tooltip } from '@mui/material';

export type TransfersAndPaymentsProps = {
  transferDescription?: string;
  transferReference?: string;
  amount?: number;
  useNPP?: boolean;
} & TransferScheduleInputValues

const GetTransferType = ( fromAccount: TransferAccount|undefined, toAccount: TransferAccount|undefined) => {
  if(toAccount?.IsBPay) {
      return "bpay"
  }
  else if(!fromAccount?.IsBankAccount && toAccount?.IsBankAccount && toAccount.IsExternal) {
      return "payAnyone"
  }
  else if(!fromAccount?.IsBankAccount && toAccount?.IsBankAccount && !toAccount.IsExternal) {
      return "redraw"
  }
  else {
      return "none"
  }
}

const TransfersAndPayments = () => {
  const [value, setValue] = React.useState('TransfersAndPayments');
  const [childValue, setChildValue] = React.useState('PayAnyone');
  const [selectedFromAccount, setSelectedFromAccount] = React.useState<string | null>();
  const [selectedToAccount, setSelectedToAccount] = React.useState<string | null>();
  const [selectedToAccountIsBPay, setSelectedToAccountIsBPay] = React.useState<string | null>();
  const [selectedToAccountIsExternal, setSelectedToAccountIsExternal] = React.useState<string | null>();
  const [selectedFromAccountIsNominated, setSelectedFromAccountIsNominated] = React.useState<boolean | null>();
  const [selectedLinkedFromAccount, setSelectedLinkedFromAccount] = React.useState<string | undefined>();
  const [canUseNPP, setCanUseNPP] = React.useState<string | undefined>();
  const [funderAllowsNPP, setFunderAllowsNPP] = React.useState<string | undefined>();
  const [clearanceDate, setClearanceDate] = React.useState<Date | undefined>();
  const [fromRedrawBalance, setFromRedrawBalance] = React.useState();
  const [toRedrawBalance, setToRedrawBalance] = React.useState();
  const { data: dataUserSession } = useIsUserAuthenticatedQuery();
  const { data, loading, error } = useTransferAccountsByPartyQuery();
  const { fromAccount, confirmFromAccount, confirmToAccount, returnToHome } = useTransferPayments();
  const { values, isSubmitting, setFieldValue } = useFormikContext<TransfersAndPaymentsProps>();
  const { data: limitData, loading: limitLoading, error: limitError } = usePartyLimitQuery();
  const newLimitsEnabled = dataUserSession?.userSession && dataUserSession?.userSession?.PartyPaymentLimitsEnabled;

  if (loading || limitLoading) {
    return <Loading />
  }
  if (error) {
      return <section className="sub-wrap"><CError error="Could not load account details" /></section>
  } 
  if (limitError != null || limitData == null) {
      return <CError error="Could not load party limits" />
  }
  
  const filterInterAccounts = data?.transferAccountsByParty?.filter(fromAccount => 
    fromAccount?.AllowRead && fromAccount?.IsBPay === false &&  fromAccount?.IsBankAccount === false &&  fromAccount?.IsExternal === false
    );
  const filteredNominatedAccounts = data?.transferAccountsByParty?.filter(filteredAccount => 
    filteredAccount?.IsBankAccount && filteredAccount?.IsDirectDebit &&
    filteredAccount?.IsDirectCredit && filteredAccount?.IsExternal === false
    );

  const listFromAccounts = [filterInterAccounts, filteredNominatedAccounts];
  const mergedFromAccounts = listFromAccounts.flatMap((fromAccount) => {
    return fromAccount
  });
  const fromAccounts = mergedFromAccounts!.sort((a, b) => a?.AccountName!.toUpperCase()! > b?.AccountName!.toUpperCase()! ? 1 : -1).map((fromAccount: any) => {
    const accountCategory = fromAccount!.AccountCategory!.toUpperCase();
    return {
      AccountCategory: /[0-9]/.test(accountCategory) ? '0-9' : accountCategory,
      ...fromAccount,
    };
  });

  const filteredInterLinkedAccounts = data?.transferAccountsByParty?.filter(filteredAccount => 
    filteredAccount?.InstrumentId === selectedLinkedFromAccount && 
    filteredAccount?.AccountNumber !== selectedFromAccount
    );
  const filteredExternalAccounts = data?.transferAccountsByParty?.filter(filteredAccount => 
    filteredAccount?.IsExternal
    );
  const filteredBPayAccounts = data?.transferAccountsByParty?.filter(filteredAccount => 
    filteredAccount?.IsExternal && filteredAccount?.IsBPay
    );
  const filteredDisableBPayAccounts = data?.transferAccountsByParty?.filter(filteredAccount => 
    filteredAccount?.IsBankAccount && filteredAccount?.IsExternal && filteredAccount?.IsBPay === false
    );
  const filteredNominatedToAccounts = data?.transferAccountsByParty?.filter(filteredAccount => 
    filteredAccount?.IsBankAccount && filteredAccount?.IsDirectDebit &&
    filteredAccount?.IsDirectCredit && filteredAccount?.IsExternal === false &&
    (filteredAccount?.CapitalAccountNumber! || []).includes(fromAccount?.AccountNumber!)
    );

  const filteredPaymentFromNominated = data?.transferAccountsByParty?.filter(filteredAccount => 
    (fromAccount?.CapitalAccountNumber! || []).includes(filteredAccount?.AccountNumber!));

  const filterAccounts = (fromAccount: TransferAccount|undefined) => {
    // ALLOW INTER ACCOUNT TRANSFERS
    if(fromAccount?.AllowRead && fromAccount?.AllowOperate && fromAccount?.AllowRedraw === false && fromAccount?.AllowPayAnyone === false && fromAccount?.AllowBPay === false) {
      return [filteredInterLinkedAccounts]
    }
    // ALLOW INTER ACCOUNT & REDRAW TRANSFERS
    else if(fromAccount?.AllowRead && fromAccount?.AllowOperate && fromAccount?.AllowPayAnyone === false && fromAccount?.AllowBPay === false) {
      return [filteredInterLinkedAccounts, filteredNominatedToAccounts]
    }
    // ALLOW INTER ACCOUNT, REDRAW & PAY-ANYONE TRANSFERS (REDRAW IS REQUIRED FOR PAY-ANYONE)
    else if(fromAccount?.AllowRead && fromAccount?.AllowOperate && fromAccount?.AllowRedraw && fromAccount?.AllowBPay === false) {
      return [filteredInterLinkedAccounts, filteredNominatedToAccounts, filteredDisableBPayAccounts]
    }
    // ALLOW INTER ACCOUNT & BPAY TRANSFERS
    else if(fromAccount?.AllowRead && fromAccount?.AllowOperate && fromAccount?.AllowBPay && fromAccount?.AllowRedraw === false && fromAccount?.AllowPayAnyone === false) {
      return [filteredInterLinkedAccounts, filteredBPayAccounts]
    }
    // ALLOW PAYMENTS FROM NOMINATED ACCOUNT TO INTER ACCOUNT
    else if(fromAccount?.AllowRead === false && fromAccount?.AllowOperate === false && fromAccount?.IsBankAccount && fromAccount?.IsDirectDebit && fromAccount?.IsExternal === false) {
      return [filteredPaymentFromNominated]
    }
    // ALLOW ALL TRANSACTIONS
    else {
      return [filteredInterLinkedAccounts, filteredExternalAccounts, filteredNominatedToAccounts]
    }
  }
 
  const filteredAccounts = filterAccounts(fromAccount);
  const filteredToAccounts = filteredAccounts.flatMap((toAccount) => {
    return toAccount
  });

  const listToAccounts = filteredToAccounts.slice().sort((a, b) => a?.AccountName! > b?.AccountName! ? 1 : -1)
  const toAccounts = listToAccounts!.sort((a, b) => a?.AccountName!.toUpperCase()! > b?.AccountName!.toUpperCase()! ? 1 : -1).map((toAccount: any) => {
    const accountCategory = toAccount!.AccountCategory!.toUpperCase();
    return {
      AccountCategory: /[0-9]/.test(accountCategory) ? '0-9' : accountCategory,
      ...toAccount,
    };
  });

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
      setSelectedFromAccount(null);
      setSelectedToAccount(undefined);
      setSelectedLinkedFromAccount(undefined);
      setFromRedrawBalance(undefined);
      setToRedrawBalance(undefined);
  };
  const handleChildTabChange = (_event: React.SyntheticEvent, newChildValue: string) => {
      setChildValue(newChildValue);
  };
  const handleFromAccountChange = (_event: any, value: any) => {
    if(value !== null ?? undefined) {
      const isNominated = value.IsBankAccount && value.IsDirectDebit && value.IsExternal === false;
      setSelectedLinkedFromAccount(value.InstrumentId);
      setSelectedFromAccount(value.AccountNumber);
      setFromRedrawBalance(value.RedrawBalance);
      confirmFromAccount(value);
      setSelectedToAccount(undefined);
      setToRedrawBalance(undefined);
      setFunderAllowsNPP(value.FunderAllowsNPP);
      setSelectedFromAccountIsNominated(isNominated);
    }
    else {
      setSelectedFromAccount(undefined);
      setSelectedToAccount(undefined);
      setSelectedLinkedFromAccount(undefined);
      setFromRedrawBalance(undefined);
      setToRedrawBalance(undefined);
      setFunderAllowsNPP(undefined);
      confirmFromAccount(value);
    }
  };
  const handleToAccountChange = (_event: any, value: any) => {
    if(value !== null) {
      const canNPP = funderAllowsNPP ? value.AccountIsValidForNPP : false;
      confirmToAccount(value);
      setSelectedToAccount(value);
      setSelectedToAccountIsBPay(value.IsBPay);
      setSelectedToAccountIsExternal(value.IsExternal);
      setCanUseNPP(canNPP);
      setFieldValue('useNPP', canNPP ? clientConfigFn().useRealtimePaymentDefaultedOn : false);
      setClearanceDate(new Date(value.MinimumDirectDebitDate));
      setFieldValue('startingOn', selectedFromAccountIsNominated ? new Date(value.MinimumDirectDebitDate) : new Date());
      if(value.RedrawBalance !== null) {
        setToRedrawBalance(value.RedrawBalance);
      }
    }
    else {
      setToRedrawBalance(undefined);
      setSelectedToAccount(undefined);
      confirmToAccount(value);
    }
  };

  return (
      <section className="transfers-and-payments">
        <div className="list-header">
          <h2>Transfers &amp; Payments</h2>
        </div>        
        <Box sx={{ width: '100%' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange}>
                <Tab label="Transfers &amp; Payments" value="TransfersAndPayments" />
                <Tab label="Payees" value="Payees" />
              </TabList>
            </Box>
            <TabPanel value="TransfersAndPayments" sx={{ px: 0, py: 3 }}>
              <Box sx={{ mb: 6 }}>
                <FormControl fullWidth sx={{ my: 1, minWidth: 120 }} className="dd-transfer-accounts">
                  <span className="redraw-balance">{fromRedrawBalance !== undefined ? <>Available: <b><MoneyFormatted amount={fromRedrawBalance} /></b></> : ''}</span>
                  <Autocomplete
                    fullWidth
                    options = {fromAccounts!.sort((a, b) => -b.AccountCategory.localeCompare(a.AccountCategory))}
                    groupBy={(option) => option.AccountCategory}
                    getOptionLabel={(option) => option.AccountName}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <span className="account-icon">
                          { 
                            <AttachMoneyIcon />
                          }
                        </span>
                        <span className="account-title">
                          <span className="title">{option.AccountName}</span><br />
                          <span className="details">{option.BSB ? option.BSB + " | " : "" }   {option.AccountNumber}</span>
                        </span>
                        <span className="account-bsb">{option.BSB}</span>
                        <span className="account-num">{option.AccountNumber}</span>
                        <span className="account-balance">
                          {
                            <>Available: <b><MoneyFormatted amount={option.RedrawBalance} /></b></>
                          }                        
                        </span>
                      </Box>
                    )}
                    renderInput={(params) => <TextField {...params} label="From" />}
                    onChange={handleFromAccountChange}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ my: 1, minWidth: 120 }} className="dd-transfer-accounts">
                  {selectedFromAccount !== null && selectedFromAccount !== undefined
                    ? <><span className="redraw-balance">{toRedrawBalance !== undefined ? <>Available: <b><MoneyFormatted amount={toRedrawBalance} /></b></> : ''}</span>
                      <Autocomplete
                        fullWidth
                        options = {toAccounts!.sort((a, b) => -b.AccountCategory.localeCompare(a.AccountCategory))}
                        groupBy={(option) => option.AccountCategory}
                        getOptionLabel={(option) => option.AccountName}
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            <span className="account-icon">
                              { 
                                option.IsBPay ? <SanitisedImage width={"73px"} src={BPayLandIcon} alt="BPay" /> :
                                (option.IsBankAccount ? <DomainIcon /> : <AttachMoneyIcon />)
                              }
                            </span>
                            <span className="account-title">
                              <span className="title">{option.AccountName} {option.IsBPay ? <span className="bpay-flag">BPAY</span> : "" }</span><br />
                              <span className="details">
                                {
                                  option.IsBPay ? option.BillerCode + " | " :
                                  (option.IsBankAccount ? option.BSB + " | " : "")
                                }   {option.AccountNumber}</span>
                            </span>
                            <span className="account-bsb">{option.BSB}</span>
                            <span className="account-num">{option.AccountNumber}</span>
                            
                            <span className="account-balance">
                              {
                                option.IsBPay || option.IsBankAccount || option.IsExternal ? ""
                                : <>Available: <b><MoneyFormatted amount={option.RedrawBalance} /></b></>
                              }   
                            </span>                     
                          </Box>
                        )}
                        renderInput={(params) => <TextField {...params} label="To" />}
                        onChange={handleToAccountChange}
                      />
                      </>
                    : <Autocomplete
                      disabled
                      fullWidth
                      options = {toAccounts!.sort((a, b) => -b.AccountCategory.localeCompare(a.AccountCategory))}
                      renderInput={(params) => <TextField {...params} label="To" />}
                    />
                    }
                </FormControl>
              </Box>
              
              {selectedToAccount &&
              <Box className="payment-details">
                <Form>
                    <Typography variant="h6" gutterBottom component="h6" sx={{ mt: 5, mb: 2 }}>
                        Payment Details
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                          <Input
                            name={nameof<TransfersAndPaymentsProps>("amount")}
                            type="currency"
                            label="Amount"
                          />
                      </Grid>
                      <Grid item xs={6}>
                        {newLimitsEnabled &&
                          <Grid container spacing={2} className="daily-limits-info">
                            <Grid item xs={6}>
                              <h4>Daily Limit</h4>
                              {selectedToAccountIsBPay &&
                                <p className="daily-limit-amount"><MoneyFormatted amount={limitData?.partyLimits?.DailyBPayLimit?.Amount || 0}/></p>
                              }
                              {selectedToAccountIsExternal && !selectedToAccountIsBPay &&
                                <p className="daily-limit-amount"><MoneyFormatted amount={limitData?.partyLimits?.DailyPayAnyoneLimit?.Amount || 0}/></p>
                              }
                              {!selectedToAccountIsExternal && !selectedToAccountIsBPay &&
                                <p className="daily-limit-amount"><MoneyFormatted amount={limitData?.partyLimits?.DailyRedrawLimit?.Amount || 0}/></p>
                              }
                            </Grid>
                            <Grid item xs={6}>
                                <h4 className="remaining-limit-title">Remaining Daily Limit</h4>
                                <p className="daily-limit-amount">
                                  {selectedToAccountIsBPay && 
                                    <MoneyFormatted amount={limitData?.partyLimits?.RemainingDailyBPayLimit?.Amount || 0}/>
                                  }
                                  {selectedToAccountIsExternal && !selectedToAccountIsBPay && 
                                    <MoneyFormatted amount={limitData?.partyLimits?.RemainingDailyPayAnyoneLimit?.Amount || 0}/>
                                  }
                                  {!selectedToAccountIsExternal && !selectedToAccountIsBPay &&
                                    <MoneyFormatted amount={limitData?.partyLimits?.RemainingDailyRedrawLimit?.Amount || 0}/>
                                  }
                                  <IconButton className="edit-limits" aria-label="edit" title='Edit Daily Limits' component={Link} size="small" to={() => (`/Security/Limits`)} id="editLimitsButton">
                                    <EditIcon />
                                  </IconButton>
                                </p>
                            </Grid>
                          </Grid>
                        }
                      </Grid>
                      <Grid item xs={6}>
                          <Input
                            name={nameof<TransfersAndPaymentsProps>("transferDescription")}
                            type="text"
                            helpText="This will appear in the account you're withdrawing money from"
                            label="Description"
                            printableOnly = {true}
                          />
                      </Grid>
                      <Grid item xs={6}>
                        {!selectedToAccountIsBPay &&
                          <Input
                              name={nameof<TransfersAndPaymentsProps>("transferReference")}
                              type="text"
                              helpText="This will appear in the account you're depositing money into"
                              label="Reference"
                          />
                        }
                      </Grid>
                      {canUseNPP &&
                      <Grid item xs={12}>
                          <Tooltip title={clientConfigFn().realtimePaymentTooltipText}>
                            <FormControlLabel 
                              control={<Switch color="primary" />} 
                              label="Realtime Payment?"
                              checked={canUseNPP ? values.useNPP : false}
                              onChange={e => {setFieldValue('useNPP', (e.target as HTMLInputElement).checked)}} />
                            </Tooltip>
                      </Grid>
                      }
                      {!values.useNPP &&
                      <TransferScheduleInput
                          values={values}
                          config={{
                              minStartingDate: new Date(),
                              minUntilDate: new Date(values.startingOn),
                              isDirectDebit: selectedFromAccountIsNominated ?? false,
                              clearanceDate: clearanceDate
                          }}
                      />
                      }
                      {!values.useNPP &&
                      <Grid item xs={12}>
                        <FutureDatedWarningText date={values.startingOn} />
                        <BankingCutOff />
                      </Grid>
                    }
                  </Grid>
                  <div className="form-group button-row">
                    <Button
                        sx={{ width: 150, p: 1.5, mt: 1 }} id="cancel"
                        variant="contained"
                        className="btn-cancel"
                        onClick={() => returnToHome()}>
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit" id="submit"
                        sx={{ p: 1.5, mt: 1 }}
                        loadingPosition="end"
                        variant="contained"
                        disabled={isSubmitting}
                    >
                        Create Transfer
                    </LoadingButton>
                  </div>
                </Form>
              </Box>
              }
            </TabPanel>
            <TabPanel value="Payees" sx={{ px: 0, py: 3 }}>
                <TabContext value={childValue}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChildTabChange} className="nested-tab">
                      <Tab label="Pay Anyone" value="PayAnyone" />
                      {clientConfigFn().showManageBPAYBillers &&
                        <Tab label={<BpayText />} value="BPAY" />
                      }
                    </TabList>
                  </Box>
                  <TabPanel value="PayAnyone" sx={{ px: 0, py: 3 }}>
                    <ManagePayAnyoneAccounts />
                  </TabPanel>
                  {clientConfigFn().showManageBPAYBillers &&
                    <TabPanel value="BPAY" sx={{ px: 0, py: 3 }}>
                      <ManageBpayBillerHistory />
                    </TabPanel>
                  }
              </TabContext>
            </TabPanel>
          </TabContext>
        </Box>
      </section>   
  );
}

const TransfersAndPaymentsForm = () => {
  const transfer = useTransferPayments<TransfersAndPaymentsProps>();

  const initialValues: TransfersAndPaymentsProps = {
      amount: undefined,
      transferDescription: "",
      transferReference: "",
      endingOn: undefined,
      startingOn: new Date(),
      frequency: TaskFrequencyPeriod.Once,
      recurrenceEnd: RecurrenceEndEnum.Never,
      numberOfTimesAmount: undefined,
      useNPP: false,

      ...transfer.formState
  }

  return (
      <Formik
          initialValues={initialValues}
          onSubmit={(values) => transfer.createTransfer(values)}
          validationSchema={Validate(transfer.fromAccount?.AccountId, GetTransferType(transfer.fromAccount, transfer.toAccount))}
      >
        <TransfersAndPayments />
      </Formik>
  )
}

export default TransfersAndPaymentsForm;