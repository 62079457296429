import React from 'react';
import DisplayHtmlContent from './DisplayHtmlContent';

const PrivacyPolicy = () =>
    <DisplayHtmlContent
        errorMessage="Could not load Privacy Policy"
        url="/client/misc/privacyPolicy.html"
    />


export default PrivacyPolicy;
