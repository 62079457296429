import React, { useState } from "react";
import { IsNullOrWhiteSpace } from 'core/util/stringExtensions';
import { FinanceSummaryComponent } from "../finance-summary/FinanceSummaryComponent";
import { FinanceSummaryBalances } from "../finance-summary/FinanceSummaryBalances";
import { Account, AccountTransaction, IsUserAuthenticatedQuery } from "generated/generated-models";
import TransactionListView from "../transaction/TransactionListView";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { clientConfigFn } from "clientConfig";

const Dashboard: React.FC<{ session: IsUserAuthenticatedQuery["userSession"] }> = 
    ({ session }) => {
        let name = (session && session.Party && session.Party.SearchName);
        if (IsNullOrWhiteSpace(name)) {
            name = '';
        }

        const [, setDetailView] = useState<AccountTransaction>();
        const [selectedAccount, setSelectedAccount] = useState<Account | undefined>();
        const handleOnSelectedAccount = (account: Account) => {
            setSelectedAccount(account);
        }
 
        return (
            <div className="dashboard-page">
                <section className="dashboard-banner">
                    <section className="container sub-wrap">
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={12}>
                                <Grid item xs={5}>
                                    <div className="user-details">
                                        <h2>Welcome back,</h2>
                                        <h3>{name}</h3>
                                    </div>
                                </Grid>
                                <Grid item xs={4} className="user-balances-container">
                                    <div className="user-balances">
                                        <FinanceSummaryBalances />
                                    </div>
                                </Grid>
                                <Grid item xs={3} className="quick-actions-container">
                                    <div className="quick-actions">
                                        {!clientConfigFn().hideTransferPage &&
                                            <Button href={`/transfersAndPayments`} variant="contained" sx={{ p: 1.5, mt: 1 }} id="makeTransferButton">
                                                Make a Transfer
                                            </Button>
                                        }
                                        {clientConfigFn().allowOnLineStatements &&
                                            <Button href={`/statements/StatementsListForParty`} variant="contained" sx={{ p: 1.5, mt: 1 }} id="listStatementsButton">
                                                View Statements
                                            </Button>
                                        }                                   
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </section>
                </section>

                <section className="container sub-wrap">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={8}>
                            <Grid item xs={6} className="list-accounts">
                                <FinanceSummaryComponent account={selectedAccount} handleOnSelectedAccount={handleOnSelectedAccount}/>
                            </Grid>
                            <Grid item xs={6} className="list-transactions">
                                <TransactionListView account={selectedAccount} setDetailView={setDetailView} />
                            </Grid>
                        </Grid>
                    </Box>
                </section>
            </div>
        )
    }

export { 
    Dashboard 
}