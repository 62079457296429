import React from "react";
import { ResetPasswordForm } from "./ResetPasswordForm";
import { useRequestPasswordResetMutation, ResetPasswordInput } from "generated/generated-models";
import Alert from '@mui/material/Alert';

const ResetPassword = () => {
    const [passwordReset, { data, loading, error }] =
        useRequestPasswordResetMutation({ onError: () => { }, context: { headers: { "X-Interfi-RequestType": "Anonymous" } } });

    const handlePasswordReset = (input: ResetPasswordInput) => {
        passwordReset({
            variables: {
                input
            }
        });
    }

    const ResetResults = () => {
        if (loading) {
            return (
                <Alert severity="warning">
                    Attempting to reset password
                </Alert>
            );
        }

        if (error !== undefined) {
            return (
                <Alert severity="error">
                    Password reset request failed.
                </Alert>
            );
        }
        if (data?.requestPasswordReset?.Success) {
            return (
                <Alert severity="success">
                    Check your email or sms for details of your new password
                </Alert>
            );
        }
        if (data?.requestPasswordReset?.Success === false) {
            return (
                <Alert severity="error">
                    Password reset request failed.
                </Alert>
            );
        }
        return null;
    };

    return (
        <div id="reset-password-form" className="reset-password-container">
            <h2>Reset your Password</h2>

            <ResetResults />

            {(data?.requestPasswordReset?.Success !== true) && (
                <ResetPasswordForm
                    onSubmit={handlePasswordReset}
                    submitting={loading}
                />
            )}
        </div>
    );
};

export default ResetPassword;
