import React from "react";
import {
    ChangeAccountDescriptionInput,
    ChangeAccountDescriptionComponent,
} from "generated/generated-models";
import { Formik, Form } from "formik";
import { Guid } from "guid-typescript";
import { CInputFormGroup } from "app/common/forms/CInputFormGroup";
import { nameof } from "core/util/nameof";
import { Loading } from "app/lib/components/Loading";
import { yupChangeAccountDescriptionInput } from "./yupChangeAccountDescriptionInput";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

interface Props {
    accountId: Guid | string;
    previousDescription: string;
    onCancel: () => void;
    onUpdated: () => void;
}

const ChangeAccountDescription: React.FC<Props> = ({
    accountId,
    previousDescription,
    onCancel,
    onUpdated,
}) => {
    return (
        <div className="cap-change-account-description">
            <ChangeAccountDescriptionComponent>
                {(mutateFn, { called, data, error, loading }) => (
                    <Formik<ChangeAccountDescriptionInput>
                        initialValues={{
                            AccountId: accountId.toString(),
                            Name: previousDescription,
                        }}
                        validationSchema={yupChangeAccountDescriptionInput}
                        onSubmit={values => {
                            mutateFn({
                                variables: {
                                    input: {
                                        AccountId: values.AccountId,
                                        Name: values.Name,
                                        clientMutationId: Guid.create().toString(),
                                    },
                                },
                            }).then(() => {
                                onUpdated();
                            });
                        }}
                    >
                        {({ values }) => {
                            const isDisabled = () => {
                                if(called && loading) {
                                    return true;
                                }
                                else {
                                    return data?.changeAccountDescription?.Success ?? false
                                }
                            }

                            return (
                                <Form>
                                    <Grid container spacing={2} >
                                        <Grid item xs={6}>
                                            <CInputFormGroup
                                                fieldName={nameof<typeof values>("Name")}
                                                label="Account Name:"
                                                inputClassName="form-control account-name-edit"
                                                fieldWrapper={fieldNode => {
                                                    return (
                                                        <div className="form-group button-row">
                                                            {fieldNode}
                                                            <Button
                                                                type="submit" id="submit"
                                                                sx={{ p: 1.5, mr: 1 }}
                                                                variant="contained">
                                                                Save
                                                            </Button>
                                                            <Button
                                                                className="btn-cancel"
                                                                sx={{ p: 1.5 }}
                                                                variant="contained"
                                                                onClick={onCancel}
                                                                disabled={isDisabled()} 
                                                                id="cancel">
                                                                Cancel
                                                            </Button>
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    {called && loading && <Loading />}
                                    
                                </Form>
                            );
                        }}
                    </Formik>
                )}
            </ChangeAccountDescriptionComponent>{" "}
        </div>
    );
};

export { ChangeAccountDescription as ChangeAccountDescriptionComponent };
