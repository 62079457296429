import React from "react";

type Props = {
    title: string;
    subtitle?: string;
}

const Title = (props: Props) => {
    let subtitle = null;

    if(props.subtitle != null) {
        subtitle = <>
                <br />
                <small className="text-muted">{props.subtitle}</small>
            </>
    }

    return (
        <h2>
            {props.title}
            {subtitle}
        </h2>
    )
}

export default Title;
