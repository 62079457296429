import React, { useState } from "react";
import IndueCardListTable from "./IndueCardListTable";
import { IndueCardsandAssociationsByAccountQuery, useIndueCardDetailsQuery, useIndueCardsandAssociationsByAccountQuery } from "generated/generated-models";
import { makeNonEmpty } from "core/util/array";
import { Loading } from "app/lib/components/Loading";
import { CError } from "app/common/CError";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import IndueCardDetailsPage from "./IndueCardDetailsPage";

type Props = {
    accountId: string;
    accountNumber: string;
    cardAvailable: boolean
}

const requestCardError = (queryResult?: IndueCardsandAssociationsByAccountQuery) => {
    const cards = makeNonEmpty(queryResult?.indueCardsByAccount);
    const association = queryResult?.partyAssociationByAccount;
    const authority = queryResult?.accountByIdOrNumber?.CurrentUserAccountAuthority;

    if (!authority?.Operate) {
        return "You do not have permission to order a card";
    }
    if (association?.IsSecondaryBorrower && !association.PrimaryBorrowerHasCard && cards?.length === 0) {
        return "You cannot order a card until the Primary Borrower has a non-closed card for the account";
    }
    if (association?.IsNonBorrower && cards?.length === 0) {
        return "Only borrowers can order a card for the account";
    }
    return undefined;
}

const RequestCard = ({ queryResult, cardAvailable }: { queryResult?: IndueCardsandAssociationsByAccountQuery, cardAvailable: boolean }) => {
    const cards = makeNonEmpty(queryResult?.indueCardsByAccount);

    if (!cardAvailable || cards.length !== 0) {
        return null;
    }

    const errorMessage = requestCardError(queryResult);
    if (errorMessage != null) {
        return (
            <>
                <Alert severity="warning">{errorMessage}</Alert>
                <Button variant="contained" disabled={true} id="requestCardButton">Request Card</Button>
            </>
        )
    }

    return (
        <Button
            component={Link}
            to={(x: { pathname: any; }) => `${x.pathname}/RequestCard`}
            variant="contained"
            id="requestCardButton">
            Request Card
        </Button>
    );
}

const IndueCardListPage: React.FC<Props> = ({ accountId, accountNumber, cardAvailable }) => {
    const { data, loading, error } = useIndueCardsandAssociationsByAccountQuery({
        variables: { accountId: accountId, accountIdString: accountId },
        fetchPolicy: "network-only",
    });
    const [selectedCard, setSelectedCard] = useState();
    const indueCards = makeNonEmpty(data?.indueCardsByAccount);

    const { data: dataCardDetails } = useIndueCardDetailsQuery({
        variables: { input: { CardId: selectedCard } },
        fetchPolicy: "network-only",
    });
    const card = dataCardDetails?.indueCardDetails;

    React.useEffect(()=> {
        if(!loading && data) {
            const indueCards = makeNonEmpty(data?.indueCardsByAccount);
            if(indueCards.length > 0) {
                const firstCard = indueCards[0].Id;
                setSelectedCard(firstCard);
            }
        }
      },[loading, data])

    if (loading) {
        return <Loading />;
    }
    if (error) {
        return <CError error="Could not retrieve card details" />
    }

    return (
        <div className="main-content sub-wrap cardPage">
            <div className="title-row-with-button">
                <div className="list-header">
                    <h2>Cards</h2>
                    <span className="list-subhead">For Account: {accountNumber}</span>
                </div>
                <RequestCard queryResult={data} cardAvailable={cardAvailable} />
            </div>
            <div className="cardsContainer">
                <IndueCardListTable
                    accountNumber={accountNumber}
                    cards={makeNonEmpty(indueCards)}
                    handleOnSelectedCard={selectedCard}
                />
                {selectedCard !== undefined &&
                    <IndueCardDetailsPage cardId={selectedCard} indueCardProps={card}/>
                }
            </div>
        </div>
    );
}

export default IndueCardListPage;
