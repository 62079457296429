import React, { Fragment, useState } from "react";
import { PartyBankAccount, CurrentUserPartyBankAccountsDocument, useCurrentUserPartyBankAccountsQuery, useDeletePartyBankAccountMutation} from "generated/generated-models";
import { Loading } from "app/lib/components/Loading";
import { CError } from "app/common/CError";
import { makeNonEmpty } from "core/util/array";
import { useScreenSize } from "app/lib/useScreenSize";
import AddPayAnyone from "./AddPayAnyone";
import Box from '@mui/material/Box';
import { DataGrid, GridLinkOperator, GridRowsProp, GridColDef, GridOverlay } from '@mui/x-data-grid';
import type { Theme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface RowProps {
    pba: PartyBankAccount;
    deletePartyBankAccount: (pba: PartyBankAccount) => void;
}
function CustomNoRowsOverlay() {
    return (
      <GridOverlay>
        <Box sx={{ m: 3, textAlign:"center" }}>You have no saved pay anyone bank accounts.</Box>
      </GridOverlay>
    );
  }

const BankAccountRow = ({ pba, deletePartyBankAccount }: RowProps) => {
    return (
        <IconButton aria-label="delete" id="deletePartyBankAccount"><DeleteIcon onClick={() => deletePartyBankAccount(pba)}/></IconButton>
    );
};

interface ViewProps {
    bankAccounts: PartyBankAccount[];
    deletePartyBankAccount: (pba: PartyBankAccount) => void;
}
const ManagePayAnyoneAccountsView: React.FC<ViewProps> = ({
    bankAccounts,
    deletePartyBankAccount,
}) => {   
    const [open, setOpen] = React.useState(false);
    const screenSize = useScreenSize();
    const fullScreen = useMediaQuery("(max-width: 768px)");
    bankAccounts.sort((a, b) => a.BankAccount?.AccountName!.toUpperCase()! > b.BankAccount?.AccountName!.toUpperCase()! ? 1 :-1)
    const rows: GridRowsProp = bankAccounts.map((item: any) => item);
    const columns: GridColDef[] = [
        { field: 'AccountName', headerName: 'Name', minWidth: 300, flex: 3, renderCell: (params) => (params.row.BankAccount.AccountName)},
        { field: 'BankIdentifierText', headerName: 'BSB', minWidth: 150, flex: 2, renderCell: (params) => (params.row.BankAccount.BankIdentifierText)},
        { field: 'AccountNumber', headerName: 'Account No.', minWidth: 150, flex: 2, renderCell: (params) => (params.row.BankAccount.AccountNumber)},
        { field: ' ', headerName: '', minWidth: 20, flex: 1, align:"center", renderCell: (params) => (BankAccountRow({pba: params.row, deletePartyBankAccount: deletePartyBankAccount || (() => {return;}),}))},
    ];
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));
    const handleAddPayAnyoneClick = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }

    return (
        <div className="biller-container" style={{ height: 650, width: '100%' }}>
            <Box className="tab-header" sx={{ mb: 2 }}>
                <Typography variant="h6" component="h6" sx={{ mx: 1, my: 0 }}>
                    Pay Anyone Accounts
                </Typography>
                <Button startIcon={<GroupAddIcon />} onClick={handleAddPayAnyoneClick} id="addPayAnyoneButton">Add Pay Anyone</Button>
            </Box>
            {screenSize === "desktop" 
            ?   <DataGrid
                className='payees-grid'
                columns={columns}
                rows={rows}
                getRowId={(r) => r.Id}
                rowsPerPageOptions={[10, 25, 50]}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'AccountName', sort: 'asc' }],
                    },
                    pagination: {
                        pageSize: 10,
                        },
                    }
                }
                components={{
                    NoRowsOverlay: CustomNoRowsOverlay
                }}
                disableColumnSelector
                componentsProps={{
                    filterPanel: {
                    linkOperators: [GridLinkOperator.And],
                    columnsSort: 'asc',
                    filterFormProps: {
                        linkOperatorInputProps: {
                        variant: 'outlined',
                        size: 'small',
                        },
                        columnInputProps: {
                        variant: 'outlined',
                        size: 'small',
                        sx: { mt: 'auto' },
                        },
                        operatorInputProps: {
                        variant: 'outlined',
                        size: 'small',
                        sx: { mt: 'auto' },
                        },
                        deleteIconProps: {
                        sx: {
                            '& .MuiSvgIcon-root': { color: '#d32f2f' },
                        },
                        },
                    },
                    sx: {
                        '& .MuiDataGrid-filterForm': { p: 2 },
                        '& .MuiDataGrid-filterForm:nth-child(even)': {
                        backgroundColor: (theme: Theme) =>
                            theme.palette.mode === 'dark' ? '#444' : '#f5f5f5',
                        },
                        '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 2 },
                        '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                        '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                        '& .MuiDataGrid-filterFormValueInput': { width: 200 },
                    },
                    },
                }}
                />
            : <Stack spacing={2}>
                {bankAccounts.map(account => {
                    return (
                        <Item sx={{pb: 1.5 }}>
                            <List className="payees-list-mobile">
                                <ListItem disablePadding className="delete-item">
                                    <ListItemText primary={<Typography><IconButton aria-label="delete" id="deletePartyBankAccountButton"><ClearIcon onClick={() => deletePartyBankAccount(account)}/></IconButton></Typography>} />
                                </ListItem>
                                <ListItem disablePadding sx={{ px: 3 }}>
                                    <ListItemText primary={<Typography><span className="subtitle">{account.BankAccount?.AccountName}</span></Typography>} />
                                </ListItem>
                                <ListItem disablePadding sx={{ px: 3 }}>
                                    <ListItemText primary={<Typography><span className="subtitle">BSB: </span> {account.BankAccount?.BankIdentifierText}</Typography>} />
                                </ListItem>
                                <ListItem disablePadding sx={{ px: 3 }}>
                                    <ListItemText primary={<Typography><span className="subtitle">Account No: </span> {account.BankAccount?.AccountNumber}</Typography>} />
                                </ListItem>
                            </List>
                        </Item>
                    )
                })}
              </Stack>
            }

            <Dialog
                fullScreen={fullScreen}
                className="add-payee"
                open={open}
                onClose={handleClose}
                fullWidth
            >
                <DialogContent sx={{ m: 4 }}>
                    <DialogContentText>
                        <AddPayAnyone handleClose={handleClose}/>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
};

const ManagePayAnyoneAccounts = () => {
    const [partyBankAccountToBeDeleted, setPartyBankAccountToBeDeleted] = useState<PartyBankAccount | undefined>(undefined)
    const [deletePartyBankAccountMutation, { loading: deletePbaLoading, error: deletePbaError }] = useDeletePartyBankAccountMutation()
    const fullScreen = useMediaQuery("(max-width: 768px)");

    const deletePartyBankAccount = () => {
        deletePartyBankAccountMutation({
            variables: {
                input: { PartyBankAccountId: partyBankAccountToBeDeleted?.Id },
            },
            refetchQueries: [
                { query: CurrentUserPartyBankAccountsDocument },
            ],
        }).then(() => setPartyBankAccountToBeDeleted(undefined));
    }

    const { data, loading, error } = useCurrentUserPartyBankAccountsQuery()

    if (loading) {
        return <Loading />
    }
    if (error) {
        return <CError error="Could not load bank accounts." />
    }

    if (partyBankAccountToBeDeleted) {
        return (
            <Dialog
                fullScreen={fullScreen}
                className="delete-payment-account"
                open={true}
            >
                <DialogTitle>
                    <DeleteIcon />
                    <Typography variant="h5" gutterBottom component="h5" sx={{ mx: 2, my: 2 }}>
                        Delete Account?
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ m: 4, mt: 0 }}>
                    <DialogContentText>
                        <Typography variant="body1" gutterBottom component="p" sx={{ textAlign:"center" }}>
                            Are you sure you would like to delete this Pay Anyone account?
                        </Typography >
                        <List sx={{ mb: 2 }}>
                            <ListItem disablePadding>
                                <ListItemText primary={<Typography><span className="subtitle">{partyBankAccountToBeDeleted.BankAccount?.AccountName}</span></Typography>} />
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemText primary={<Typography><span className="subtitle">BSB: </span> {partyBankAccountToBeDeleted.BankAccount?.BankIdentifierText}</Typography>} />
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemText primary={<Typography><span className="subtitle">Account No: </span> {partyBankAccountToBeDeleted.BankAccount?.AccountNumber}</Typography>} />
                            </ListItem>
                        </List>

                        {partyBankAccountToBeDeleted.BankAccount?.HasPendingTransfer === true &&
                            <Alert severity="warning">
                                There are Upcoming Transfers against these details. They will continue to be processed as usual unless cancelled manually.
                            </Alert>
                        }

                        <div className="button-row">
                            <Button
                                className="btn-cancel" id="cancel"
                                sx={{ p: 1.5, mt: 1 }}
                                variant="contained"
                                onClick={() => setPartyBankAccountToBeDeleted(undefined)}>
                                Cancel
                            </Button>
                            <LoadingButton
                                type="submit" id="submit"
                                sx={{ p: 1.5, mt: 1 }}
                                onClick={deletePartyBankAccount}
                                loading={deletePbaLoading}
                                loadingPosition="end"
                                variant="contained"
                            >
                                Confirm
                            </LoadingButton>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Fragment>
            {deletePbaError && (
                <CError error="Could not remove bank accounts." />
            )}
            <ManagePayAnyoneAccountsView
                bankAccounts={makeNonEmpty(
                    data?.userSession?.Party?.PartyBankAccounts
                ).filter(x => !x.Deleted && x.PayAnyone)}
                deletePartyBankAccount={setPartyBankAccountToBeDeleted}
            />
        </Fragment>
    );
}

export { ManagePayAnyoneAccounts };