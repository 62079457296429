import * as yup from "yup";
import { AddPayAnyoneFormProps } from "./AddPayAnyone";
import { GetSecurityTokenValidator } from "app/validation/securityTokenValidator";

export const Validate = (requiresMfa: boolean) => {
    const schema: yup.ObjectSchema<AddPayAnyoneFormProps> = yup.object({
        accountName: yup
            .string()
            .label("Account Name")
            .max(1024)
            .required(),

        accountNumber: yup
            .string()
            .label("Account Number")
            .min(6)
            .max(9)
            .matches(/^[0-9]+$/, "Account Number should only contain numbers")
            .required(),

        bsb: yup
            .string()
            .label("BSB")
            .required()
            .matches(/^[0-9,-]+$/, "BSB should only contain numbers")
            .test("lengthCheck", "BSB must be 6 digits", bsb => {
                if (bsb?.length === 6) {
                    return true
                } else if (bsb?.length === 7 && bsb.indexOf("-") !== -1) {
                    return true;
                }
                return false;
            }),

        twoFactorToken: GetSecurityTokenValidator(requiresMfa),
    })
        .defined();

    return schema;
}
