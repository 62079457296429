import { useState } from "react";
import { usePagedTransactions } from "../../PaginationUtilities";
import TransactionRowDashboard from "./TransactionRowDashboard";
import { Account } from "generated/generated-models";
import { Loading } from "app/lib/components/Loading";
import { CApolloError } from "app/common/CApolloError";

const RecentActivityList = ({ account}: { account?: Account }) => {
    const [detailTransaction, setDetailTransaction] = useState<string>();
    const { transactions, loading, error } = usePagedTransactions(account);

    if (transactions !== undefined) {
        if (loading) {
            return <Loading />
        }
    } else {
        <div className="no-data-container"><p>There are no transactions to display.</p></div>
    }
    if (error ) {
        return <CApolloError />
    }

    return (
        <>
        <section className="transactions-list">
            <div className="list-header">
                <h2>Recent Activity</h2>
                <span className="list-subhead">For Account: { account?.Number }</span>
            </div>
            {transactions !== undefined && transactions.length > 0 ?
                <div className="accord-container">
                    {transactions?.map(trans =>
                        <TransactionRowDashboard trans={trans} detailView={detailTransaction} setDetailView={setDetailTransaction} key={trans?.Id} />
                    )}
                </div>
                : <div className="no-data-container"><p>There are no transactions to display.</p></div>
            }
        </section>
        </>
    );
};

export default RecentActivityList;
