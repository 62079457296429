import React from "react";
import { CError } from "app/common/CError";
import { Loading } from "app/lib/components/Loading";
import { useAccountFundsTransfersQuery } from "generated/generated-models";
import DueOnListItemsReceipt from "../utilities/list/DueOnListItemsReceipt";
import { useTransferWizard } from "../utilities/TransferWizard";
import MoneyFormatted from "app/components/MoneyFormatted";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import useMediaQuery from '@mui/material/useMediaQuery';

const PaymentReceipt = () => {
    const { accountId, paymentAccount, returnToHome, transferId } = useTransferWizard();
    const { data, loading, error } = useAccountFundsTransfersQuery({
        variables: { accountIdOrNumber: accountId },
        fetchPolicy: "network-only"
    });
    const fullScreen = useMediaQuery("(max-width: 768px)");
    const [, setOpen] = React.useState(false);

    const handleClose = () => {
      setOpen(false);
    };

    if (loading === true) {
        return <Loading />
    }

    if (error != null || data?.accountByIdOrNumber?.ProcessedAccountFundsTransfers == null) {
        return <CError error="Could not load payment details. Please try again." />
    }

    const fundsTransfer = data.accountByIdOrNumber.ProcessedAccountFundsTransfers
        .concat(data.accountByIdOrNumber.PendingAccountFundsTransfers)
        .find(x => x?.Id === transferId);

    if (fundsTransfer == null) {
        return <CError error="Could not load payment details. Please try again." />
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={true}
            className="success-transfer-payment"
            onClose={handleClose}
        >
            <DialogTitle>
                <ReceiptLongIcon />
            </DialogTitle>
            <DialogContent sx={{ m: 4, mt: 0 }}>
                <DialogContentText>
                <Typography variant="body1" gutterBottom component="p" sx={{ textAlign:"center" }} className="transfer-amount">
                    <MoneyFormatted amount={fundsTransfer?.Amount || 0} />
                    <span className="caption">Paid successfully to {data.accountByIdOrNumber.ProductType}</span>
                    <span className="sub-caption">Receipt No: {fundsTransfer.ReceiptNumber}</span>
                </Typography >
                <List sx={{ mb: 2 }} className="transfer-details">
                    <ListItem disablePadding>
                        <ListItemText primary={<Typography><span className="subtitle">From: </span>{paymentAccount?.AccountName} <br /> {paymentAccount?.BankIdentifierText} | {paymentAccount?.AccountNumber} ({paymentAccount?.BankName})</Typography>} />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary={<Typography><span className="subtitle">To: </span>{data.accountByIdOrNumber.ProductType} - {data.accountByIdOrNumber.Number || ""}</Typography>} />
                    </ListItem>
                    <DueOnListItemsReceipt fundsTransfer={fundsTransfer} />
                    <ListItem disablePadding>
                        <ListItemText primary={<Typography><span className="subtitle">Description: </span> {fundsTransfer.DepositDescription}</Typography>} />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary={<Typography><span className="subtitle">Reference: </span> {fundsTransfer.WithdrawalDescription}</Typography>} />
                    </ListItem>
                </List>
                <div className="button-row">
                    <Button
                        fullWidth
                        type="submit" id="submit"
                        sx={{ p: 1.5, mt: 1 }}
                        onClick={() => returnToHome(data.accountByIdOrNumber?.Number!)}
                        variant="contained"
                    >
                        Done
                    </Button>
                </div>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default PaymentReceipt;
