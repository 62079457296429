import { CError } from "app/common/CError";
import Title from "app/components/Title";
import { Loading } from "app/lib/components/Loading";
import { BankAccount, useAccountFundsTransfersQuery} from "generated/generated-models";
import { useTransferWizard } from "../utilities/TransferWizard";
import { makeNonEmpty } from "core/util/array";
import Button from "app/components/Button";
import { useHistory } from "react-router";
import PaymentCard from "app/components/PaymentCard/PaymentCard";
import FundsTransferCardsContainer from "app/components/fundsTransferCards/FundsTransferCardsContainer";
import Alert from '@mui/material/Alert';

type PaymentAccountCardProps = { bankAccount: BankAccount };

const PaymentAccountCard = ({ bankAccount }: PaymentAccountCardProps) => {
    const transferWizard = useTransferWizard();

    return (
        <PaymentCard
            onClick={() => transferWizard.confirmPaymentAccount(bankAccount)}
            title="From"
            name={bankAccount?.AccountName}
            details={
                <div>
                    <div>{bankAccount?.BankIdentifierText} {bankAccount?.AccountNumber}</div>
                    <div>{bankAccount?.BankName}</div>
                </div>
            }
        />
    )
}

const SelectPayment = () => {
    const { accountId } = useTransferWizard();
    const { data, loading, error } = useAccountFundsTransfersQuery({
        variables: { accountIdOrNumber: accountId }
    });
    const history = useHistory();

    if (loading) {
        return <Loading />
    }

    if (error || data?.accountByIdOrNumber?.BankAccounts == null) {
        return <CError error="Could not load Payment bank account details" />
    }

    const account = data.accountByIdOrNumber;

    const paymentAccounts = makeNonEmpty(account.BankAccounts);

    return (
        <div>
            <Title title="Select Payment From Account" />
            <div className="select-destination">
                {account.BankAccounts?.length === 0 &&
                <div>
                    <Alert severity="warning">No available Bank Accounts associated with this account</Alert>
                </div>}
                <FundsTransferCardsContainer>
                    {paymentAccounts.map(x =>
                        <PaymentAccountCard bankAccount={x} key={x.Id} />
                    )}
                </FundsTransferCardsContainer>
            </div>
            <br />
            <Button
                    role="secondary"
                    onClick={() => history.goBack()}
                    id="goBackButton"
                >
                    Go Back
            </Button>
        </div>
    )
}

export default SelectPayment;