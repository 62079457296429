import { Formik, Form } from "formik";
import RequestMultiFactorTokenFormik from "app/components/RequestMultiFactorTokenFormik";
import { nameof } from "core/util/nameof";
import Input from "app/components/Input";
import { useIndueCardDetailsQuery, useIndueCardChangePinMutation } from "generated/generated-models";
import React from "react";
import { Validate } from "./IndueCardResetPinValidate";
import Title from "app/components/Title";
import { Loading } from "app/lib/components/Loading";
import { CError } from "app/common/CError";
import { Guid } from "guid-typescript";
import { BrowserRouter, Redirect, useLocation } from "react-router-dom";
import { DelayedRender } from "app/lib/components/DelayedRender";
import { useSecurityTokenMutation } from "core/apollo/SecurityTokenApollo";
import { getMaskedCardNumber } from "./utilities/cardNumber";
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';

export type IndueCardResetInput = {
    CardId: Guid | string;
    NewPin?: string;
    ConfirmPin?: string;
    TwoFactorToken?: string;
}

type IndueCardResetPinProps = {
    handleClose: () => void;
    goBack?: () => void;
    cardId: Guid | string;
}

const IndueCardResetPinPage = ({ handleClose, goBack = () => { }, cardId }: IndueCardResetPinProps) => {
    const [changePin, { data: changePinData, error: changePinError, tokenRequired }] = useSecurityTokenMutation(useIndueCardChangePinMutation());

    const { data, loading, error } = useIndueCardDetailsQuery({
        variables: { input: { CardId: cardId } },
        fetchPolicy: "network-only",
    });

    const handleSubmit = async (values: IndueCardResetInput) => {
        const pinString = values?.NewPin?.toString() ?? "";

        await changePin(
            {
                variables: {
                    input: {
                        CardId: cardId,
                        Pin: pinString,
                        TwoFactorToken: values.TwoFactorToken
                    }
                },
            }
        );
    }

    const { pathname } = useLocation();

    const SuccesfullyChangedPin = () => {
        if (changePinData?.changePin?.Result === true) {
            return (
                <Alert severity="success">
                    PIN successfully changed!
                    <DelayedRender delay={1500}>
                        <BrowserRouter forceRefresh={true}>
                            <Redirect to={`${pathname}`}/>
                        </BrowserRouter>
                    </DelayedRender>
                </Alert>
            )
        } else {
            return null;
        }
    }

    const handleCancelClick = () => {
        goBack();
        handleClose();
    }

    const card = data?.indueCardDetails;
    const initialValues: IndueCardResetInput = {
        CardId: cardId,
        NewPin: undefined,
        ConfirmPin: undefined
    }

    if (loading) {
        return <Loading />;
    }

    if (error || card == null) {
        return <CError error={`Could not retrieve card details. ${error?.message}`} />;
    }
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={() => Validate(tokenRequired)}
        >
            {({ isSubmitting }) =>
                <>
                    <Title title="Change PIN" />
                    <p>Choose a new 4 digit PIN. You should use this PIN the next time you are using card {getMaskedCardNumber(card)}.</p>
                    <Form>
                        <Input
                            name={nameof<IndueCardResetInput>("NewPin")}
                            type="wholeNumberAuth"
                            label="New PIN"
                        />

                        <Input
                            name={nameof<IndueCardResetInput>("ConfirmPin")}
                            type="wholeNumberAuth"
                            label="Confirm PIN"
                        />

                        <RequestMultiFactorTokenFormik
                            name={"TwoFactorToken"}
                            showRequestNewToken={true}
                            requestMfaOnDisplay={true}
                            visible={tokenRequired}
                        />
                        {changePinError &&
                            <Alert severity="error">Could not change PIN. {changePinError?.message}</Alert>
                        }

                        <SuccesfullyChangedPin />

                        <div className="button-row">
                            <Button
                                className="btn-cancel" id="cancel"
                                sx={{ p: 1.5, mt: 1 }}
                                variant="contained"
                                onClick={handleCancelClick}
                                disabled={isSubmitting}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                type="submit" id="submit"
                                sx={{ p: 1.5, mt: 1 }}
                                loading={isSubmitting}
                                loadingPosition="end"
                                variant="contained"
                            >
                                Change PIN
                            </LoadingButton>
                        </div>
                    </Form>
                </>
            }
        </Formik>
    )
}

export { IndueCardResetPinPage };
