/** Base config at build time, not overriden by window.__INTERFI__ */
export interface BaseConfig {
	/** BaseUri, used to access the api. Fixed at build time, window config wont override. */
	baseuri: string;

	/** Build version. Fixed at build time, window config wont override  */
	clientVersion: string;
}

/** Client configuration options  */
export interface ClientConfig {
	/** Title used for the site */
	siteTitle: string;

	/** Brand text in the footer */
	footerText: string;

	/** Contact email address */
	supportEmailAddress: string;

	/** Contact phone number */
	supportPhoneNumber: string;

	/** Text value for the bank cut off eg 2pm */
	bankingCutOffWarning: string;

	logoImageUri: string;

	/** Brand Logo suitable for dark backgrounds (Transparent background), use absolute offset **/
	logoImageLightUri: string;

	/** Brand Logo suitable for light backgrounds (Transparent background), use absolute offset **/
	logoImageDarkUri: string;

	/** Logo Variant, eg '/images/logo-withoutTagline.png' */
	logoImageVariantUri: string;

	/** Secondary Logo, eg '/images/secondary-logo.png' */
	logoSecondaryImageUri: string;

	/** Large image on login screen. at least 1000x1000, will scale and cover. eg '/images/hero.jpg' */
	heroImageUri: string;

	bankCardActiveUri: string;
	bankCardInActiveUri: string;

	/** Login Screen layout, 2 options using the hero image and a default fallback using the 'brandColourPrimary' variable */
	loginScreenLayout: string;

	/** Show the instrument title on the instrument page */
	showInstrumentTitle: boolean;

	/** Show Account Title on the account page */
	showAccountTitle: boolean;

	/** Show an arrears message on the account page if account in arrears */
	arrearsMessage: string;

	/** Custom page header for the 'Forgot your Username?' popup */
	forgotUsernamePageTitle: string;

	/** Custom body copy for the 'Forgot your Username?' popup */
	forgotUsernamePageCopy: string;

	/** Custom page header for the 'Forgot your Password?' popup */
	forgotPasswordPageTitle: string;

	/** Custom body copy for the 'Forgot your Password?' popup */
	forgotPasswordPageCopy: string;

	/** title to use for My Finances, defaults to My Finances */
	myFinancesTitle: string;

	/** Label to use for matures metric, defaults to Matures */
	maturesMetricLabel?: string;

	/** Account screen available column header, defaults to Available */
	accountAvailableLabel: string;

	/** Account screen - minimum instalment label */
	minimumInstalmentLabel: string;

	/** Redirect to the instrument page if the user only has a single instrument */
	redirectToSingleInstrument: boolean;

	/** Hide the Nav link to the transfers page */
	hideTransferPage: boolean;

	/** Text to use for Transfer menu item */
	transferMenuText: string;

	/** Hide the Last login from the menu */
	hideLastLoginIp: boolean;

	/** Number of days of transactions to show */
	transactionHistoryLimitDays?: number;

	/** How many transactions to show per page */
	transactionPageSize?: number;

	/** How many accounts to display on dashboard */
	numberOfAccountsToDisplayOnDashboard?: number;

	/** Welcome message to display on login screen */
	loginWelcomeMessage: string;

	/** Reset Password button text displayed on login screen */
	loginResetPasswordText: string;

	/** Allow passwords to be reset online, otherwise show forgotten password */
	allowOnlinePasswordReset: boolean;

	/** Show online statements */
	allowOnLineStatements: boolean;

	/** Show available redraw columns and tile */
	showAvailableRedraw: boolean;

	/** Show terms and conditions in footer */
	showTermsAndConditions: boolean;

	/** Show Privacy Policy in footer */
	showPrivacyPolicy: boolean;

	/** Display version number in footer */
	showClientVersion: boolean;

	/** If true makes the funds transfer description and reference required fields */
	fundsTransferDescriptionRequired: boolean;

	/** If true show debit/credit transactions from a Lender's perspective, if false, show from a Borrowers perspective */
	showTransactionsAsLender: boolean;

	/** If true, override login style to have a full coloured background, login box centered, and logo in the top-left corner */
	useSimpleLayout: boolean;

	/** Specifies the local currency symbol to be used with Fortis */
	currencyFormat: string;

	/** Specifies the locale for the currency symbol to be used with Fortis this can affect whether the symbol is prefixed by a letter, ie A$ vs $ */
	currencyLocale: string;

	//** Hide the Nav link to the Cards page */
	hideCardPage: boolean;

	// ** Text value for the future dated funds transfer warning */
	futureDatedTransferWarning: string;

	//** If true, will show the logged on user's name after 'myFinancesTitle', ie. allows welcome message to be set */
	showUserInWelcomeMessage: boolean;

	showIconsTransactionPage: boolean;

    BETA_showDetailView: boolean;

	//** If true, this will display the Primary Borrowers Name on the list of accounts rather than the Instrument Description or Loan Purpose */
	showPrimaryBorrowerName: boolean;
	
	//** If true, this will display an accounts current rate in the account summary header */
	showAccountCurrentRate: boolean;

	//** If true, this will display over limit amount in the account summary header */
	canViewArrearsAmount: boolean;

	transferCardsSubText: string;

	//** The productLabel to be used in the authenticator QR uri */
	_productLabel: string;

	//** The issuer to be used in the authenticator QR uri */
	_issuer: string; 

	//** Login screen - Custom text that will be displayed on the login screen when using Google Auth for 2FA.*/
	loginAuthenticatorMessage: string; 

	//** Manage 2FA - Custom text that will be displayed on the Manage Two-Factor Authentication (2FA) page when Authenticator app has been setup.*/
	manageAuthenticatorMessage: string; 

	//* Login screen - Custom text that will be displayed on the login screen when using Security Token (via SMS/Email) for 2FA.*/
	loginSecurityTokenMessage: string; 

	//* Setup 2FA - Custom text that will be displayed on the Setup Two-Factor Authentication (2FA) page when using Security Token (via SMS/Email) for 2FA.*/
	manageSecurityTokenMessage: string;

	//** Account Disbursements - Show disbursement form for users to request a payment to be made on their behalf from a selected account */
	allowDisbursements: boolean

	//** Disbursements - Custom text shown above file uploads for disbursements */
	disbursementFileText: string;

	//** Profile Settings - If true, display email and phone number in Personal Information section. */
	showProfilePersonalInformation: boolean;

	//** Profile Settings - If true, display both residential and mailing addresses. */
	showProfileAddresses: boolean;

	//** Profile Settings - If true, display the Preferred method of correspondence set against the party. */
	showProfilePreferredMethodOfCorrespondence:  boolean;

	//** Secure Messaging - If disabled, secure message page will be hidden. */
	displaySecureMessagePage:  boolean;

	//** Transfers & Payments - If true, provide users with the ability to manage BPAY billers, (i.e. add and delete billers) */
	showManageBPAYBillers: boolean;

	//** Transfers & Payments - If true, the 'Realtime Payment?' option will be defaulted to 'ON' for applicable transfers */
	useRealtimePaymentDefaultedOn: boolean;

	//** Transfers & Payments - The tooltip text that will be displayed when user hovers over the Realtime Payment switch */
	realtimePaymentTooltipText: string;
}
const clientConfigFn = (): ClientConfig & BaseConfig => {
	const windowConfig = window && (window as any).__INTERFI__ as Partial<ClientConfig>;
	return {
		baseuri: process.env.REACT_APP_FORTIS_BASEURI || "///",
		clientVersion: process.env.REACT_APP_CAPITAL_CLIENT_VERSION || "",

		// SITE BRANDING & LAYOUT
		siteTitle: "",
		footerText: "",
		useSimpleLayout: false,
		currencyFormat: "AUD",
		currencyLocale: "EN-AU",
		logoImageUri: "", //OBSOLETE
		logoSecondaryImageUri: "", //OBSOLETE
		logoImageLightUri: "",
        logoImageDarkUri: "",
        logoImageVariantUri: "",
		heroImageUri: "",
		bankCardActiveUri: "",
		bankCardInActiveUri: "",
		loginScreenLayout: "",

		// HELP & SUPPORT
		showClientVersion: true,
		showTermsAndConditions: false,
		showPrivacyPolicy: false,
		supportEmailAddress: "",
		supportPhoneNumber: "",
		
		// LOGIN SCREEN
		loginWelcomeMessage: "Welcome to Fortis. Our online solution to support your business goals.",
		forgotUsernamePageTitle: "",
		forgotUsernamePageCopy: "",
		forgotPasswordPageTitle: "",
        forgotPasswordPageCopy: "",

		// LOGIN SCREEN - RESET PASSWORD
		allowOnlinePasswordReset: false,
		loginResetPasswordText: "Reset Password",

 		// NAVIGATION
		transferMenuText: "Transfer", //OBSOLETE

		// USER PROFILE
		hideLastLoginIp: false,

		// AUTHENTICATOR / 2FA
		_productLabel: "",
        _issuer: "",
		loginAuthenticatorMessage: "Confirm your login by entering the authorisation code provided by your preferred one-time password application.",
		manageAuthenticatorMessage: "To manage your authentication settings, please enter the authorisation code provided by your preferred one-time password application.",
		loginSecurityTokenMessage: "Confirm your login by entering the security token that you will receive shortly.",
		manageSecurityTokenMessage: "To begin setting up an authenticator app, enter the security token that you will receive shortly.",

		// MY FINANCES / ACCOUNTS
		myFinancesTitle: "My Finances",
		showUserInWelcomeMessage: false,
		showInstrumentTitle: true,
		maturesMetricLabel: "Matures",
		showAccountTitle: true,
		minimumInstalmentLabel: "Min monthly instalment",
		showAvailableRedraw: true,
		arrearsMessage: "",
		accountAvailableLabel: "Available",
		showPrimaryBorrowerName: false,
		showAccountCurrentRate: false,
		numberOfAccountsToDisplayOnDashboard: 5,
		canViewArrearsAmount: false,
		redirectToSingleInstrument: false,
	
		// TRANSFERS
		hideTransferPage: false,
		bankingCutOffWarning: "",
		fundsTransferDescriptionRequired: false,
		futureDatedTransferWarning: "Future-dated transfers require cleared funds in your account on the day before the scheduled transfer date or your transfer will be unsuccessful.",
		transferCardsSubText: "",
		allowDisbursements: false,
		disbursementFileText: "",
		showManageBPAYBillers: true,
		useRealtimePaymentDefaultedOn: false,
		realtimePaymentTooltipText: "Choosing Realtime payment will allow for a single payment to be processed immediately",
		
		// STATEMENTS
		allowOnLineStatements: false,
		
		// CARDS
		hideCardPage: true,
		
		// TRANSACTIONS
		transactionHistoryLimitDays: 90,
		transactionPageSize: 20,
		showIconsTransactionPage: false,
        BETA_showDetailView: false,
		showTransactionsAsLender: true,

		// PROFILE SETTINGS
		showProfilePersonalInformation: true,
		showProfileAddresses: true,
		showProfilePreferredMethodOfCorrespondence:  true,

		// SECURE MESSAGING
		displaySecureMessagePage: true,

		...windowConfig, //overrides from live site
	};
};

export {
	clientConfigFn
}
