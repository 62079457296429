import { CError } from "app/common/CError";
import FromAccountCard from "app/components/fundsTransferCards/FromAccountCard";
import FundsTransferCardsContainer from "app/components/fundsTransferCards/FundsTransferCardsContainer";
import Title from "app/components/Title";
import { Loading } from "app/lib/components/Loading";
import { makeNonEmpty } from "core/util/array";
import { Account, useInstrumentByAccountIdQuery } from "generated/generated-models";
import { useTransferWizard } from "../utilities/TransferWizard";
import { AuthorityType } from "./TransferWizard/TransferWizardContainer";

const AccountCard = ({ account }:  { account: Account }) => {
    const { updateAccount } = useTransferWizard();

    return (
        <FromAccountCard account={account} onClick={() => updateAccount(account.Id)} />
    )
}

const SelectAccount = ({ accountNumber, authorityType }: { accountNumber: string, authorityType: AuthorityType }) => {
    const { loading, error, data } = useInstrumentByAccountIdQuery({ variables: { account: accountNumber } });

    const accounts = makeNonEmpty(data?.instrumentByAccount?.Accounts)
        .filter(account => {
            switch(authorityType) {
                case "bpay":
                    return account.CurrentUserAccountAuthority?.BPay ?? false;
                case "operate":
                    return account.CurrentUserAccountAuthority?.Operate ?? false;
                case "payAnyone":
                    return account.CurrentUserAccountAuthority?.PayAnyone ?? false;
                case "redraw":
                    return account.CurrentUserAccountAuthority?.Redraw ?? false;
                default:
                    return false;
            }
        });

    if (loading) {
        return <Loading />
    }

    if (error || accounts == null) {
        return <section className="sub-wrap"><CError error="Could not load account details" /></section>
    }

    return (
        <div className="select-destination">
            <Title title="Select an Account" />
            <FundsTransferCardsContainer>
                {accounts.map(x =>
                    <AccountCard account={x} key={x.Id} />
                )}
            </FundsTransferCardsContainer>
        </div>
    )
}

export default SelectAccount;
