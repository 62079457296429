import React from "react";
import Select from "./Select";
import Confirm from "./Confirm";
import Create from "./Create";
import Receipt from "./Receipt";
import TransferWizardContainer, { Props as TransferWizardProps } from "./TransferWizardContainer";
export { useTransferWizard } from "./useTransferWizard";

type TransferWizardComposition = {
    Select: React.FC;
    Create: React.FC;
    Confirm: React.FC;
    Receipt: React.FC;
} & React.FC<TransferWizardProps>;

const TransferWizard: TransferWizardComposition = props =>
    <TransferWizardContainer {...props} />;
TransferWizard.Select = Select;
TransferWizard.Confirm = Confirm;
TransferWizard.Create = Create;
TransferWizard.Receipt = Receipt;

export default TransferWizard;
