import { ErrorLink } from "@apollo/client/link/error";
import { log } from "../log";
import { equal as uriEqual } from "uri-js";
import {
    LoginUri,
    SecurityUri,
    client,
} from "./client";
import { ServerError, ServerParseError } from "@apollo/client";

const authFailed = (error: Error | ServerError | ServerParseError) => {
    if (("statusCode" in error) && (error.statusCode === 401 || error.statusCode === 403)) {
        return true;
    }

    return false;
}

const onLoginPage = () => {
    if (uriEqual(window.location.href, LoginUri, { tolerant: true })) {
        return true;
    }
    if (window.location.pathname.includes(SecurityUri)) {
        return true;
    }

    return false;
}

export const errorHandler: ErrorLink.ErrorHandler = ({
    graphQLErrors,
    networkError
}) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
            log.error(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
        );
    }
    if (networkError) {
        log.error(`[Network error]: ${networkError.message}`);

        if (authFailed(networkError) && !onLoginPage()) {

            log.warn("Logging out and redirect to login");
            client
                .clearStore()
                .then(() => (window.location.href = LoginUri))
                .catch(() => (window.location.href = LoginUri));
        }
    }
};
