import React from "react";
import { RecurrenceEndEnum, TaskFrequencyPeriod } from "generated/generated-models";
import { nameof } from "core/util/nameof";
import Datepicker from "app/components/DatePicker";
import Input from "app/components/Input";
import ButtonGroup from "app/components/ButtonGroup";
import Grid from '@mui/material/Grid';

export type TransferScheduleInputValues = {
    startingOn: Date;
    frequency: TaskFrequencyPeriod;
    recurrenceEnd: RecurrenceEndEnum;
    endingOn?: Date;
    numberOfTimesAmount?: number;
}

const setMaxYear  = new Date().getFullYear();
const setMaxMonth = new Date().getMonth();
const setMaxDay   = new Date().getDate();
const setMaxDate  = new Date(setMaxYear + 500, setMaxMonth, setMaxDay);

type Config = {
    minStartingDate: Date;
    minUntilDate: Date;
    maxDate?: Date;
    isDirectDebit?: boolean;
    clearanceDate?: Date;
}

type Props = {
    values: TransferScheduleInputValues
    config: Config
}

const TransferUntilInput = ({ values, config }: Props) => {
    const mergedConfig = {
        ...defaultConfig,
        ...config,
    };
    const { minStartingDate, isDirectDebit, clearanceDate } = mergedConfig;
    const showUntilInputBlock = values.frequency !== TaskFrequencyPeriod.Once;
    const numberOfTimesInputEnabled = values.recurrenceEnd === RecurrenceEndEnum.Numberofocurrences;
    const untilDateInputEnabled = values.recurrenceEnd === RecurrenceEndEnum.Bydate;

    if (!showUntilInputBlock) {
        return null
    }

    return (
        <>
            <Grid item xs={6}>
                <ButtonGroup
                    label="Until"
                    name={nameof<typeof values>("recurrenceEnd")}
                >
                    <ButtonGroup.Button value={RecurrenceEndEnum.Never}>Forever</ButtonGroup.Button>
                    <ButtonGroup.Button value={RecurrenceEndEnum.Bydate}>Date</ButtonGroup.Button>
                    <ButtonGroup.Button value={RecurrenceEndEnum.Numberofocurrences}>No. of Payments</ButtonGroup.Button>
                </ButtonGroup>
            </Grid>
            {untilDateInputEnabled &&
                <Grid item xs={6}>
                    <Datepicker
                        name={nameof<typeof values>("endingOn")}
                        label="End Date"
                        minDate={isDirectDebit ? clearanceDate : minStartingDate}
                        maxDate={setMaxDate}
                    />
                </Grid>
            }
            {numberOfTimesInputEnabled &&
                <Grid item xs={6}>
                    <Input
                        label="No. of Payments"
                        name={nameof<typeof values>("numberOfTimesAmount")}
                        type="wholeNumberPayment"
                    />
                </Grid>
            }
        </>
    );
};

const defaultConfig = {
    minStartingDate: new Date(),
    minUntilDate: new Date(),
    maxDate: undefined,
    isDirectDebit: false,
    clearanceDate: new Date(),
};

const TransferScheduleInput = ({ values, config }: Props) => {
    const mergedConfig = {
        ...defaultConfig,
        ...config,
    };
    const { minStartingDate, maxDate, isDirectDebit, clearanceDate } = mergedConfig;
    return (
        <>
            <Grid item xs={6}>
                <ButtonGroup
                    name={nameof<typeof values>("frequency")}
                    label="Frequency"
                >
                    <ButtonGroup.Button value={TaskFrequencyPeriod.Once}>Once</ButtonGroup.Button>
                    <ButtonGroup.Button value={TaskFrequencyPeriod.Weekly}>Weekly</ButtonGroup.Button>
                    <ButtonGroup.Button value={TaskFrequencyPeriod.Fortnightly}>Fortnightly</ButtonGroup.Button>
                    <ButtonGroup.Button value={TaskFrequencyPeriod.Monthly}>Monthly</ButtonGroup.Button>
                </ButtonGroup>
            </Grid>
            
            <Grid item xs={6}>
                <Datepicker
                    label="Starting On"
                    name={nameof<typeof values>("startingOn")}
                    minDate={isDirectDebit ? clearanceDate : minStartingDate}
                    maxDate={maxDate}                   
                />
            </Grid>
            <TransferUntilInput config={mergedConfig} values={values} />

        </>
    );
};

export default TransferScheduleInput;
