import DOMPurify from "dompurify";
import { Link } from "react-router-dom";

type SanitisedLogoProps = {
    logoUri: String,
    siteTitle: String
}

const SanitisedLogo = (props: SanitisedLogoProps) => {
    return (
        <Link to="" className="logo-link"
            dangerouslySetInnerHTML={{ __html: 
                DOMPurify.sanitize(`
                    <img
                        class="logo"
                        src=${props.logoUri}
                        alt=${props.siteTitle + " Logo"}
                    />
                `)
            }} 
        />
    );
}

export default SanitisedLogo;