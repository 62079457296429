import React from "react";
import { clientConfigFn } from "clientConfig";
import Alert from '@mui/material/Alert';

const BankingCutoff = () => {
    return (
        <Alert severity="warning">
            {clientConfigFn().bankingCutOffWarning}
        </Alert>
    )
}

export default BankingCutoff;
