import * as yup from "yup";
import { PayAnyoneFormProps } from "./CreatePayAnyone";
import { validateLimit, validateSchedule, validateTransferDescription } from "../utilities/validation";

export const Validate = (accountId: string) => {
    const schema: yup.ObjectSchema<PayAnyoneFormProps> = yup.object({
        transferDescription: validateTransferDescription("Description"),
        transferReference: validateTransferDescription("Reference"),

        startingOn: yup
            .date()
            .required(),

        ...validateLimit(accountId, "payAnyone"),
        ...validateSchedule()
    })
    .defined();

    return schema;
}
