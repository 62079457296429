import * as yup from "yup";
import { nameof } from "core/util/nameof";
import { EnterNewPasswordFormProps } from "./EnterNewPassword";

export const Validate = () =>{
    const schema: yup.ObjectSchema<EnterNewPasswordFormProps> = yup.object({
        confirmPassword: yup
            .string()
            .required()
            .oneOf(
                [yup.ref(nameof<EnterNewPasswordFormProps>("password"))],
                "Passwords must match"
            )
            .label("Confirm Password"),

            password: yup
            .string()
            .required()
            .min(6)
            .matches(/[A-Z]/, 'At least one upper case letter required')
            .matches(/[a-z]/, 'At least one lower case letter required')
            .matches(/[0-9]/, 'At least one number required')
            .matches(/[!|@|#|$|%|^|&|*|(|)|-|_]/, 'At least one special character required')
            .label("New Password")

    }).defined();
    return schema
}