import * as yup from "yup";
import { AddBpayFormProps } from "./AddBpayBiller";
import { GetSecurityTokenValidator } from "app/validation/securityTokenValidator";

export const Validate = (requiresMfa: boolean) => {
    const schema: yup.ObjectSchema<AddBpayFormProps> = yup.object({
        billerCode: yup
            .number()
            .label("Biller Code")
            .required()
            .test("length", "Biller Code must be at least 4 digits", val => {
                if (val == null) {
                    return true;
                } else if (val.toString().length >= 4) {
                    return true;
                }
                return false;
            }),

        customerReferenceNumber: yup
            .string()
            .label("Customer Reference Number")
            .required()
            .max(20)
            .matches(/^[0-9]+$/, "Customer Reference Number should only contain numbers"),

        billerDescription: yup
            .string()
            .label("Biller Nickname")
            .required()
            .test(
                'Must have basic latin characters only',
                'You must only enter basic latin characters',
                function(transferDescription) {
                    const mystring: string = String(transferDescription);
                    var containsNonPrintable : boolean = false;
    
                    for (let step = 0; step < mystring.length; step++) {
                        var ascii = mystring.charCodeAt(step);
                        if(ascii < 32 || ascii > 127)
                        {
                            containsNonPrintable = true;
                        }
                      }
                    return (!containsNonPrintable);
                }
              )            
            .max(20),

        twoFactorToken: GetSecurityTokenValidator(requiresMfa)
    })
        .defined();

    return schema;
}
