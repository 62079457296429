import { validateLimit, validateSchedule, validateTransferDescription } from "app/accountTransfer/utilities/validation";
import * as yup from "yup";
import { TransfersAndPaymentsProps } from "./TransfersAndPayments";

export const Validate = (accountId: string, transferType: string) => {
    if(transferType !== 'bpay') {
        const schema: yup.ObjectSchema<TransfersAndPaymentsProps> = yup.object({
            transferDescription: validateTransferDescription("Description"),
            transferReference: validateTransferDescription("Reference"),

            startingOn: yup
                .date()
                .required(),

            ...validateLimit(accountId, transferType),
            ...validateSchedule()
        })
        .defined();

        return schema;
    }
    else { 
        const schema: yup.ObjectSchema<TransfersAndPaymentsProps> = yup.object({
            transferDescription: validateTransferDescription("Description"),

            startingOn: yup
                .date()
                .required(),

            ...validateLimit(accountId, transferType),
            ...validateSchedule()
        })
        .defined();

        return schema;
    }
}
