import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { Form, Formik } from "formik";
import { CError } from "app/common/CError";
import { Loading } from "app/lib/components/Loading";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LockIcon from '@mui/icons-material/Lock';
import Typography from '@mui/material/Typography';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import SecurityIcon from '@mui/icons-material/Security';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CampaignIcon from '@mui/icons-material/Campaign';
import { usePartyProfileDetailsQuery, PreferredCorrespondanceType, usePartyElectronicCommsStatusQuery, usePartyChangePersonalDetailsMutation } from 'generated/generated-models';
import { CDate } from 'app/common/CapitalDate';
import { clientConfigFn } from 'clientConfig';
import { FormattedDate } from 'react-intl';
import Switch from '@mui/material/Switch';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ProfileMarketingCommunications from './ProfileMarketingCommunications';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import ProfileElectronicCommunications from './ProfileElectronicCommunications';
import { useSecurityTokenMutation } from 'core/apollo/SecurityTokenApollo';
import { Validate } from './ProfilePersonalDetailsInputValidator';
import RequestMultiFactorTokenFormik from 'app/components/RequestMultiFactorTokenFormik';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';

export type PersonalDetailsInput = {
    ProfileId?: string;
    PreferredCorrespondenceMethod?: string;
    TwoFactorToken?: string;
}

type PersonalDetailsMFAProps =
    | "WithoutMFA"
    | "WithMFA"

const ProfileSettings = ({ ProfileId, PreferredCorrespondenceMethod }: PersonalDetailsInput) => {
    const { data: partyProfileDetails, loading: partyProfileDetailsLoading, error: partyProfileDetailsError } = usePartyProfileDetailsQuery();
    const { data: partyElectronicCommsData } = usePartyElectronicCommsStatusQuery();
    const partyDetails = partyProfileDetails?.userSession;
    const [value, setValue] = React.useState('Profile');
    const [preferredCorrespondence, setPreferredCorrespondence] = React.useState(PreferredCorrespondenceMethod);
    const [openMarketing, setOpenMarketing] = React.useState(false);
    const [openElectronic, setOpenElectronic] = React.useState(false);
    const [personalDetailsStatus, setPersonalDetailsStatus] = useState<boolean | undefined>(undefined);
    const [changePersonalDetails, { data: changePersonalDetailsData, error: changePersonalDetailsError }] = useSecurityTokenMutation(usePartyChangePersonalDetailsMutation());
    const [personalDetailsMFA, setPersonalDetailsMFA] = useState<PersonalDetailsMFAProps>("WithoutMFA");
    const fullScreen = useMediaQuery("(max-width: 768px)");
    const history = useHistory();

    const handleRequiresMFA = () => {
        setPersonalDetailsMFA("WithMFA")
    };

    const handlePersonalDetailsSubmit = async (values: PersonalDetailsInput) => {
        const result = await changePersonalDetails({
            variables: {
                input:
                {
                    PreferredCorrespondenceMethod: preferredCorrespondence,
                    TwoFactorToken: values.TwoFactorToken
                }
            }
        });

        if (result.data?.partyPersonalDetails?.Code === "499") {
            handleRequiresMFA();
        }
        if (result.data?.partyPersonalDetails?.Success) {
            setPersonalDetailsStatus(true)

            setTimeout(
                () => {
                    window.location.reload();
                },
                3000
            );
        }
    }

    const mailingAddress = partyDetails?.Party?.Addresses?.filter(a => a?.IsDefaultMailingAddress && !a?.IsDeleted && !a?.IsPrevious).flatMap(ma => ma?.Address);    
    const residentialAddress = partyDetails?.Party?.Addresses?.filter(a => a?.IsDefaultResidentialAddress && !a?.IsDeleted && !a?.IsPrevious).flatMap(ra => ra?.Address);
    const preferredEmail = partyDetails?.Party?.Contacts?.filter(pe => pe?.IsPreferredEmail && !pe?.IsDeleted).flatMap(em => em?.Details);
    const preferredPhone = partyDetails?.Party?.Contacts?.filter(pp => pp?.IsPreferredPhone && !pp?.IsDeleted).flatMap(ph => ph?.Details);
    const preferredSms = partyDetails?.Party?.Contacts?.filter(ps => ps?.IsPreferredSms && !ps?.IsDeleted).flatMap(ps => ps?.Details);
    const isMFAEnabled = partyProfileDetails?.userSession?.UseMultiFactorAuth;
    const newLimitsEnabled = partyProfileDetails?.userSession?.PartyPaymentLimitsEnabled;

    let electronicStatus = false;
    const partyElectComms = partyElectronicCommsData?.electronicComms;

    if (partyElectComms?.SMSNotificationStatus && partyElectComms?.EmailNotificationStatus && partyElectComms?.StatementNotificationStatus) {
        electronicStatus = true
    }

    const correspondenceList = Object.values(PreferredCorrespondanceType).map(value => ({       
        name: value
    }));
    const disableCorrespondenceMailEmailPhoneSms = correspondenceList.filter(obj => {
        return obj.name !== 'MAIL' && obj.name !== 'EMAIL' && obj.name !== 'PHONE' && obj.name !== 'SMS'
    })
    const disableCorrespondenceMailEmailPhone = correspondenceList.filter(obj => {
        return obj.name !== 'MAIL'&& obj.name !== 'EMAIL' && obj.name !== 'PHONE'
    })
    const disableCorrespondenceMailEmailSms = correspondenceList.filter(obj => {
        return obj.name !== 'MAIL'&& obj.name !== 'EMAIL' && obj.name !== 'SMS'
    })
    const disableCorrespondenceMailPhoneSms = correspondenceList.filter(obj => {
        return obj.name !== 'MAIL'&& obj.name !== 'PHONE' && obj.name !== 'SMS'
    })
    const disableCorrespondenceEmailPhoneSms = correspondenceList.filter(obj => {
        return obj.name !== 'EMAIL' && obj.name !== 'PHONE' && obj.name !== 'SMS'
    })
    const disableCorrespondenceEmailMail = correspondenceList.filter(obj => {
        return obj.name !== 'EMAIL' && obj.name !== 'MAIL'
    })
    const disableCorrespondenceEmailSms = correspondenceList.filter(obj => {
        return obj.name !== 'EMAIL' && obj.name !== 'SMS'
    })
    const disableCorrespondencePhoneSms = correspondenceList.filter(obj => {
        return obj.name !== 'PHONE' && obj.name !== 'SMS'
    })
    const disableCorrespondencePhoneEmail = correspondenceList.filter(obj => {
        return obj.name !== 'PHONE' && obj.name !== 'EMAIL'
    })
    const disableCorrespondencePhoneMail = correspondenceList.filter(obj => {
        return obj.name !== 'PHONE' && obj.name !== 'MAIL'
    })
    const disableCorrespondenceMailSms = correspondenceList.filter(obj => {
        return obj.name !== 'MAIL' && obj.name !== 'SMS'
    })
    const disableCorrespondenceMail = correspondenceList.filter(obj => {
        return obj.name !== 'MAIL'
    })
    const disableCorrespondencePhone = correspondenceList.filter(obj => {
        return obj.name !== 'PHONE'
    })
    const disableCorrespondenceEmail = correspondenceList.filter(obj => {
        return obj.name !== 'EMAIL'
    })
    const disableCorrespondenceSms = correspondenceList.filter(obj => {
        return obj.name !== 'SMS'
    })

    const filterCorrespondence = (mailingAddress: any, preferredPhone: any, preferredEmail: any, preferredSms: any) => {
        if (mailingAddress?.length === 0 && preferredEmail?.length === 0 && preferredPhone?.length === 0 && preferredSms?.length === 0) {
            return disableCorrespondenceMailEmailPhoneSms
        }
        else if (preferredEmail?.length === 0 && preferredPhone?.length === 0 && preferredSms?.length === 0) {
            return disableCorrespondenceEmailPhoneSms
        }
        else if (mailingAddress?.length === 0 && preferredEmail?.length === 0 && preferredPhone?.length === 0) {
            return disableCorrespondenceMailEmailPhone
        }
        else if (mailingAddress?.length === 0 && preferredPhone?.length === 0 && preferredSms?.length === 0) {
            return disableCorrespondenceMailPhoneSms
        }
        else if (mailingAddress?.length === 0 && preferredEmail?.length === 0 && preferredSms?.length === 0) {
            return disableCorrespondenceMailEmailSms
        }
        else if (preferredEmail?.length === 0 && preferredSms?.length === 0) {
            return disableCorrespondenceEmailSms
        }
        else if (preferredEmail?.length === 0 && mailingAddress?.length === 0) {
            return disableCorrespondenceEmailMail
        }
        else if (mailingAddress?.length === 0 && preferredSms?.length === 0) {
            return disableCorrespondenceMailSms
        }
        else if (preferredPhone?.length === 0 && preferredSms?.length === 0) {
            return disableCorrespondencePhoneSms
        }
        else if (preferredPhone?.length === 0 && preferredEmail?.length === 0) {
            return disableCorrespondencePhoneEmail
        }
        else if (preferredPhone?.length === 0 && mailingAddress?.length === 0) {
            return disableCorrespondencePhoneMail
        }
        else if (mailingAddress?.length === 0) {
            return disableCorrespondenceMail
        }
        else if (preferredPhone?.length === 0) {
            return disableCorrespondencePhone
        }
        else if (preferredSms?.length === 0) {
            return disableCorrespondenceSms
        }
        else if (preferredEmail?.length === 0) {
            return disableCorrespondenceEmail
        }
        else {
            return correspondenceList
        }
    }
    const filterCorrespondenceList = filterCorrespondence(mailingAddress, preferredPhone, preferredEmail, preferredSms);
    const filteredCorrespondenceList = filterCorrespondenceList.flatMap((filteredCorrespondence: any) => {
      return filteredCorrespondence
    });

    const handleChangeCorrespondence = (event: SelectChangeEvent) => {
        const correspondenceSel = event.target.value;
        setPreferredCorrespondence(correspondenceSel);
    }
    const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        history.push({pathname: `/Security/ProfileSettings/${newValue}`});
    };
    const handleClickMarketing = () => {
        setOpenMarketing(true);
    };
    const handleCloseMarketing = () => {
        setOpenMarketing(false);
    };
    const handleClickElectronic = () => {
        setOpenElectronic(true);
    };
    const handleCloseElectronic = () => {
        setOpenElectronic(false);
    };

    useEffect(() => {
        switch (window.location.pathname) {
            case "/Security/ProfileSettings/Profile":
               setValue('Profile')
              break;
            case "/Security/ProfileSettings/Account":
               setValue('Account')
              break;
            case "/Security/ProfileSettings/Security":
               setValue('Security')
              break;
         
            default:
               setValue('Profile')
              break;
           }
    }, []);


    if (partyProfileDetailsLoading) {
        return <Loading />;
    }
    if (partyProfileDetailsError) {
        return <CError error={"Could not load funds transfer details"} />;
    }   

    const initialValues: PersonalDetailsInput = {
        ProfileId: ProfileId,
        PreferredCorrespondenceMethod: PreferredCorrespondenceMethod,
    }

    return (
        <section className="sub-wrap profile-settings-container">
            <div className="list-header">
                <h2>Profile Settings</h2>
            </div> 
            <Box sx={{ width: '100%' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange}>
                            <Tab label="Profile" value="Profile"  />
                            <Tab label="Account" value="Account" />
                            <Tab label="Security" value="Security" />
                        </TabList>
                    </Box>
                    <TabPanel value="Profile" sx={{ px: 0, py: 3 }} className="tabContent">
                        <List className="profile-login-details" sx={{ width: '100%'}}>
                            <ListItem sx={{ px: 1, py: 0.2}}>
                                <ListItemIcon>
                                    <AdminPanelSettingsOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={<Typography><span className='primaryText'>Last Login: </span><FormattedDate value={partyDetails?.LastLoginAttempt!} day="2-digit" month="long" year="numeric"/></Typography>}/>
                            </ListItem>
                            {!clientConfigFn().hideLastLoginIp && (
                                <ListItem sx={{ px: 1, py: 0.2}}>
                                    <ListItemIcon>
                                        <LocationOnOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography><span className='primaryText'>Last Login Location: </span> {partyDetails?.LastLoginFrom} </Typography>} />
                                </ListItem>
                            )}
                        </List>

                        <h4>Personal Information</h4>
                        <section className='profile-personal-info'>
                            <Grid item xs={12} sx={{ my:3 }}>
                                {partyDetails?.Party?.EntityType === 'Individual' &&
                                    <Typography><span className='primaryText'>Name: </span> {partyDetails?.Party?.FirstName} {partyDetails?.Party?.LastName}</Typography>
                                }
                                {partyDetails?.Party?.EntityType === 'Organisation' &&
                                    <Typography><span className='primaryText'>Organisation Name: </span> {partyDetails?.Party?.OrganisationName}</Typography>
                                }
                            </Grid>
                        </section>

                        {isMFAEnabled
                            ? <>
                            <Formik initialValues={initialValues}
                                onSubmit={(values) => handlePersonalDetailsSubmit(values)}
                                validationSchema={Validate(personalDetailsMFA === "WithMFA")}
                            >
                                {({ isSubmitting }) => {
                                    return (
                                        <>
                                        <Form>
                                        <section className='profile-personal-info'>
                                            {clientConfigFn().showProfilePersonalInformation &&
                                                <Grid container spacing={2} sx={{mt:0, mb:4}}>
                                                    <Grid item xs={6}>
                                                        {preferredEmail!.length > 0
                                                        ? <>                   
                                                            <TextField
                                                                fullWidth
                                                                sx={{mt:0, mb:0.5}}
                                                                id="outlined-email"
                                                                label="Email"
                                                                type="text"
                                                                defaultValue={preferredEmail}
                                                                disabled
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />                                  
                                                            </>
                                                        : <Alert severity="warning" sx={{mt:0, mb:0}}>There is no preferred email address set on your profile.</Alert>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        {preferredPhone!.length > 0
                                                        ? <>
                                                            <FormControl fullWidth variant="outlined">
                                                                <InputLabel htmlFor="phone">Phone  <Tooltip title="For security reasons you are unable to update your phone number. If this is required, please contact support."><InfoIcon /></Tooltip></InputLabel>
                                                                <OutlinedInput
                                                                    id="phone"
                                                                    type="text"
                                                                    name="phone"
                                                                    label="Phone &nbsp;&nbsp;&nbsp;&nbsp;"
                                                                    defaultValue={preferredPhone}
                                                                    readOnly={true}
                                                                    disabled
                                                                />
                                                            </FormControl>
                                                            </>
                                                        : <Alert severity="warning" sx={{mt:0, mb:0}}>There is no preferred phone number set on your profile.</Alert>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            }
                                            {clientConfigFn().showProfileAddresses &&
                                                <>
                                                <h4>Residential Address</h4>
                                                {residentialAddress!.length > 0
                                                    ? <Grid container spacing={2} sx={{mt:0, mb:4}}>
                                                        {residentialAddress?.map(resAddress => {
                                                            return (
                                                            <>
                                                                {resAddress?.AddressDetailType === 'PRINTFORMAT' &&
                                                                    <>
                                                                    <Grid item xs={6}>
                                                                        <TextField
                                                                            fullWidth
                                                                            sx={{mt:0, mb:0.5}}
                                                                            id="outlined-addressLineOne"
                                                                            label="Address Line 1"
                                                                            type="text"
                                                                            defaultValue={resAddress?.AddressLine1 }
                                                                            disabled
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <TextField
                                                                            fullWidth
                                                                            sx={{mt:0, mb:0.5}}
                                                                            id="outlined-addressLineTwo"
                                                                            label="Address Line 2"
                                                                            type="text"
                                                                            defaultValue={resAddress?.AddressLine2 }
                                                                            disabled
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <TextField
                                                                            fullWidth
                                                                            sx={{mt:0, mb:0.5}}
                                                                            id="outlined-addressLineThree"
                                                                            label="Address Line 3"
                                                                            type="text"
                                                                            defaultValue={resAddress?.AddressLine3 }
                                                                            disabled
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <TextField
                                                                            fullWidth
                                                                            sx={{mt:0, mb:0.5}}
                                                                            id="outlined-addressLineFour"
                                                                            label="Address Line 4"
                                                                            type="text"
                                                                            defaultValue={resAddress?.AddressLine4 }
                                                                            disabled
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    </>
                                                                }
                                                                {resAddress?.AddressDetailType === 'STREET' &&
                                                                    <>
                                                                    {resAddress?.PropertyName &&
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                sx={{mt:0, mb:0.5}}
                                                                                id="outlined-propertyName"
                                                                                label="Property Name"
                                                                                type="text"
                                                                                defaultValue={resAddress?.PropertyName }
                                                                                disabled
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {resAddress?.Building &&
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="outlined-building"
                                                                                label="Building"
                                                                                type="text"
                                                                                defaultValue={resAddress?.Building }
                                                                                disabled
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {resAddress?.FloorName &&
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="outlined-floorName"
                                                                                label="Floor Name"
                                                                                type="text"
                                                                                defaultValue={resAddress?.FloorName }
                                                                                disabled
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {resAddress?.FloorNumber &&
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="outlined-floorNumber"
                                                                                label="Floor Number"
                                                                                type="text"
                                                                                defaultValue={resAddress?.FloorNumber }
                                                                                disabled
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {resAddress?.UnitNumber &&
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="outlined-unitNumber"
                                                                                label="Unit Number"
                                                                                type="text"
                                                                                defaultValue={resAddress?.UnitNumber }
                                                                                disabled
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {resAddress?.LotNumber &&
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="outlined-lotNumber"
                                                                                label="Lot Number"
                                                                                type="text"
                                                                                defaultValue={resAddress?.LotNumber }
                                                                                disabled
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {resAddress?.StreetNumberFrom &&
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="outlined-streetNumberFrom"
                                                                                label="Street Number From"
                                                                                type="text"
                                                                                defaultValue={resAddress?.StreetNumberFrom }
                                                                                disabled
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {resAddress?.StreetNumberTo &&
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="outlined-streetNumberTo"
                                                                                label="Street Number To"
                                                                                type="text"
                                                                                defaultValue={resAddress?.StreetNumberTo }
                                                                                disabled
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {resAddress?.StreetName &&
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="outlined-streetName"
                                                                                label="Street Name"
                                                                                type="text"
                                                                                defaultValue={resAddress?.StreetName }
                                                                                disabled
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {resAddress?.StreetType &&
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="outlined-streetType"
                                                                                label="Street Type"
                                                                                type="text"
                                                                                defaultValue={resAddress?.StreetType }
                                                                                disabled
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    </>
                                                                }
                                                                
                                                                <Grid item xs={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        sx={{mt:0, mb:0.5}}
                                                                        id="outlined-suburb"
                                                                        label="Suburb / Town"
                                                                        type="text"
                                                                        defaultValue={resAddress?.SuburbTown }
                                                                        disabled
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        sx={{mt:0, mb:0.5}}
                                                                        id="outlined-country"
                                                                        label="Country"
                                                                        type="text"
                                                                        defaultValue={resAddress?.Country?.Name }
                                                                        disabled
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                </Grid>                                                   
                                                                <Grid item xs={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        sx={{mt:0, mb:0.5}}
                                                                        id="outlined-state"
                                                                        label="State"
                                                                        type="text"
                                                                        defaultValue={resAddress?.Region?.Name }
                                                                        disabled
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        sx={{mt:0, mb:0.5}}
                                                                        id="outlined-postcode"
                                                                        label="Postcode"
                                                                        type="text"
                                                                        defaultValue={resAddress?.PostalCode }
                                                                        disabled
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </>
                                                            )
                                                        })}
                                                    </Grid>
                                                    : <Alert severity="warning" sx={{mt:0.2, mb:4}}>There is no default residential address set on your profile.</Alert>
                                                }

                                                <h4>Mailing Address</h4>
                                                {mailingAddress!.length > 0
                                                    ? <Grid container spacing={2} sx={{mt:0, mb:4}}>
                                                        {mailingAddress?.map(mailAddress => {
                                                            return (
                                                            <>
                                                                {mailAddress?.AddressDetailType === 'PRINTFORMAT' &&
                                                                    <>
                                                                    <Grid item xs={6}>
                                                                        <TextField
                                                                            fullWidth
                                                                            sx={{mt:0, mb:0.5}}
                                                                            id="outlined-addressLineOne"
                                                                            label="Address Line 1"
                                                                            type="text"
                                                                            defaultValue={mailAddress?.AddressLine1 }
                                                                            disabled
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <TextField
                                                                            fullWidth
                                                                            sx={{mt:0, mb:0.5}}
                                                                            id="outlined-addressLineTwo"
                                                                            label="Address Line 2"
                                                                            type="text"
                                                                            defaultValue={mailAddress?.AddressLine2 }
                                                                            disabled
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <TextField
                                                                            fullWidth
                                                                            sx={{mt:0, mb:0.5}}
                                                                            id="outlined-addressLineThree"
                                                                            label="Address Line 3"
                                                                            type="text"
                                                                            defaultValue={mailAddress?.AddressLine3 }
                                                                            disabled
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <TextField
                                                                            fullWidth
                                                                            sx={{mt:0, mb:0.5}}
                                                                            id="outlined-addressLineFour"
                                                                            label="Address Line 4"
                                                                            type="text"
                                                                            defaultValue={mailAddress?.AddressLine4 }
                                                                            disabled
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    </>
                                                                }
                                                                {mailAddress?.AddressDetailType === 'STREET' &&
                                                                    <>
                                                                    {mailAddress?.PropertyName &&
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                sx={{mt:0, mb:0.5}}
                                                                                id="outlined-propertyName"
                                                                                label="Property Name"
                                                                                type="text"
                                                                                defaultValue={mailAddress?.PropertyName }
                                                                                disabled
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {mailAddress?.Building &&
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="outlined-building"
                                                                                label="Building"
                                                                                type="text"
                                                                                defaultValue={mailAddress?.Building }
                                                                                disabled
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {mailAddress?.FloorName &&
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="outlined-floorName"
                                                                                label="Floor Name"
                                                                                type="text"
                                                                                defaultValue={mailAddress?.FloorName }
                                                                                disabled
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {mailAddress?.FloorNumber &&
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="outlined-floorNumber"
                                                                                label="Floor Number"
                                                                                type="text"
                                                                                defaultValue={mailAddress?.FloorNumber }
                                                                                disabled
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {mailAddress?.UnitNumber &&
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="outlined-unitNumber"
                                                                                label="Unit Number"
                                                                                type="text"
                                                                                defaultValue={mailAddress?.UnitNumber }
                                                                                disabled
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {mailAddress?.LotNumber &&
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="outlined-lotNumber"
                                                                                label="Lot Number"
                                                                                type="text"
                                                                                defaultValue={mailAddress?.LotNumber }
                                                                                disabled
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {mailAddress?.StreetNumberFrom &&
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="outlined-streetNumberFrom"
                                                                                label="Street Number From"
                                                                                type="text"
                                                                                defaultValue={mailAddress?.StreetNumberFrom }
                                                                                disabled
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {mailAddress?.StreetNumberTo &&
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="outlined-streetNumberTo"
                                                                                label="Street Number To"
                                                                                type="text"
                                                                                defaultValue={mailAddress?.StreetNumberTo }
                                                                                disabled
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {mailAddress?.StreetName &&
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="outlined-streetName"
                                                                                label="Street Name"
                                                                                type="text"
                                                                                defaultValue={mailAddress?.StreetName }
                                                                                disabled
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {mailAddress?.StreetType &&
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="outlined-streetType"
                                                                                label="Street Type"
                                                                                type="text"
                                                                                defaultValue={mailAddress?.StreetType }
                                                                                disabled
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    </>
                                                                }
                                                                <Grid item xs={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        sx={{mt:0, mb:0.5}}
                                                                        id="outlined-suburb"
                                                                        label="Suburb / Town"
                                                                        type="text"
                                                                        defaultValue={mailAddress?.SuburbTown }
                                                                        disabled
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        sx={{mt:0, mb:0.5}}
                                                                        id="outlined-country"
                                                                        label="Country"
                                                                        type="text"
                                                                        defaultValue={mailAddress?.Country?.Name }
                                                                        disabled
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                </Grid>                                                   
                                                                <Grid item xs={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        sx={{mt:0, mb:0.5}}
                                                                        id="outlined-state"
                                                                        label="State"
                                                                        type="text"
                                                                        defaultValue={mailAddress?.Region?.Name }
                                                                        disabled
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        sx={{mt:0, mb:0.5}}
                                                                        id="outlined-postcode"
                                                                        label="Postcode"
                                                                        type="text"
                                                                        defaultValue={mailAddress?.PostalCode }
                                                                        disabled
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </>
                                                            )
                                                        })}
                                                    </Grid>
                                                    : <Alert severity="warning" sx={{mt:0.2, mb:4}}>There is no default mailing address set on your profile.</Alert>
                                                }
                                                </>
                                            }

                                            {clientConfigFn().showProfilePreferredMethodOfCorrespondence &&
                                                <>
                                                <h4>Preferred method of correspondence</h4>
                                                <Grid container spacing={2} sx={{mt:1, mb:2}} className="correspondence-select">
                                                    <Grid item xs={12}>
                                                    {filteredCorrespondenceList.length > 0
                                                        ?  <FormControl fullWidth variant="outlined">
                                                                <InputLabel htmlFor="preferredCorrespondence">Correspondence Method</InputLabel>
                                                                <Select
                                                                    className='correspondenceSelect'
                                                                    label={'Correspondence Method'}
                                                                    value={preferredCorrespondence}
                                                                    onChange={handleChangeCorrespondence}
                                                                >
                                                                    {filteredCorrespondenceList.map((correspondence, key) => (
                                                                        <MenuItem className={`select-${correspondence.name}`} key={key} value={correspondence.name}>
                                                                            {correspondence.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                <FormHelperText>This is your preferred method of direct correspondence.</FormHelperText>
                                                            </FormControl>
                                                        : <Alert severity="warning" sx={{mt:0.2, mb:4}}>You cannot update your preferred method of correspondence as there are no default contact details set on your profile</Alert>
                                                        }
                                                    </Grid>
                                                </Grid>
                                                <RequestMultiFactorTokenFormik
                                                    name={"TwoFactorToken"}
                                                    showRequestNewToken={true}
                                                    requestMfaOnDisplay={true}
                                                    visible={personalDetailsMFA === "WithMFA"}
                                                />
                                                {(changePersonalDetailsError || changePersonalDetailsData?.partyPersonalDetails?.Success === false) &&
                                                    <Alert severity="error">
                                                        {changePersonalDetailsData?.partyPersonalDetails?.Message}
                                                    </Alert>
                                                }
                                                {personalDetailsStatus
                                                    ? <Alert severity="success">You have successfully updated your personal profile settings.</Alert>
                                                    : <div className="button-row">
                                                        <Button
                                                            className="btn-cancel" id="cancel"
                                                            sx={{ p: 1.5, mt: 1 }}
                                                            variant="contained"
                                                            onClick={() => window.location.reload()}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <LoadingButton
                                                            type="submit" id="submit"
                                                            sx={{ p: 1.5, mt: 1 }}
                                                            loading={isSubmitting}
                                                            loadingPosition="end"
                                                            variant="contained"
                                                        >
                                                            Save
                                                        </LoadingButton>
                                                    </div>
                                                }
                                                </>
                                            }
                                        </section>
                                        </Form>
                                        </>
                                    )
                                }}
                            </Formik>
                            </>
                            : <Alert severity="warning">Two-Factor Authentication (2FA) must be enabled on your profile to view further details, please contact support to proceed.</Alert>
                        }
                    </TabPanel>
                    <TabPanel value="Account" sx={{ px: 0, py: 3 }} className="tabContent">
                        {newLimitsEnabled &&
                            <>
                            <h4>Payment Limits</h4>
                            <List className="containerListOptions" sx={{ width: '100%'}}>
                                <ListItem disablePadding component={NavLink} to={`/Security/Limits`} id="manageTransferLimitsButton">
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <MonetizationOnOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={<Typography className='primaryText'>Payment Limits</Typography>} secondary="Manage daily transfer limits" />
                                        <KeyboardArrowRightIcon />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            </>
                        }
                        {isMFAEnabled &&
                            <>
                            <h4>Marketing Communications</h4>
                            <List className="containerListOptions" sx={{ width: '100%'}}>
                                <ListItem disablePadding onClick={handleClickMarketing} id="toggleMarketingCommunicationsButton">
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <CampaignIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={<Typography className='primaryText'>Receive marketing communications</Typography>} secondary={`Enable to opt in and receive marketing communications.`} />
                                        <Switch disabled checked={partyDetails?.Party?.AcceptsAdvertising } />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            </>
                        }      
                        {isMFAEnabled && 
                            <>
                            <h4>Electronic Communications</h4>
                            <p className='info'>For security reasons there are some communications that cannot be sent electronically, these will continue to be sent via post or SMS.</p>
                            <List className="containerListOptions" sx={{ width: '100%'}}>
                                <ListItem disablePadding onClick={handleClickElectronic} id="toggleCommunicationsViaElectronics">
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <MailOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={<Typography className='primaryText'>Receive electronic communications</Typography>} secondary={`Enable to receive all communications electronically.`} />
                                        <Switch disabled checked={electronicStatus} />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            </>
                        }
                    </TabPanel>
                    <TabPanel value="Security" sx={{ px: 0, py: 3 }} className="tabContent">
                        <h4>Security</h4>
                        <List className="containerListOptions" sx={{ width: '100%'}}>
                            <ListItem disablePadding component={NavLink} to={`/Security/ChangePassword`} id="changePasswordButton">
                                <ListItemButton>
                                    <ListItemIcon>
                                        <LockIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography className='primaryText'>Change Password</Typography>} secondary={<Typography className='secondaryText'>Last Changed <CDate date={partyDetails?.LastPasswordChange} /></Typography>}/>
                                    <KeyboardArrowRightIcon />
                                </ListItemButton>
                            </ListItem>
                            {isMFAEnabled && 
                                <ListItem disablePadding component={NavLink} to={`/Security/Authenticator`} id="toggleTwoFactorAuthentication">
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <SecurityIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={<Typography className='primaryText'>Two-Factor Authentication (2FA) via Mobile App</Typography>} secondary={`Enable to add an extra layer of security to your account.`} />
                                        <Switch disabled checked={partyDetails?.UseMultiFactorAuth} />
                                    </ListItemButton>
                                </ListItem>
                            }
                        </List>
                    </TabPanel>
                </TabContext>
            </Box>

            <Dialog 
                open={openMarketing} 
                onClose={handleCloseMarketing}
                fullScreen={fullScreen}
                fullWidth
            >
                <DialogContent sx={{ m: 4 }}>
                    <DialogContentText>
                        <ProfileMarketingCommunications ProfileId={partyDetails?.Party?.Id} MarketingStatus={partyDetails?.Party?.AcceptsAdvertising}/>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            <Dialog 
                open={openElectronic} 
                onClose={handleCloseElectronic}
                fullScreen={fullScreen}
                fullWidth
            >
                <DialogContent sx={{ m: 4 }}>
                    <DialogContentText>
                        <ProfileElectronicCommunications ProfileId={partyDetails?.Party?.Id} SMSNotificationStatus={partyElectComms?.SMSNotificationStatus} />
                        {/* <ProfileElectronicCommunications ProfileId={partyDetails?.Party?.Id} SMSNotificationStatus={partyElectComms?.SMSNotificationStatus} EmailNotificationStatus={partyElectComms?.EmailNotificationStatus} StatementNotificationStatus={partyElectComms?.StatementNotificationStatus} /> */}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </section>
    )
};

export default ProfileSettings;