import React from "react";
import { PlainAccountDescription } from "./PlainAccountDescription";
import { Guid } from "guid-typescript";
import { ChangeAccountDescriptionComponent } from "./ChangeAccountDescription";
import log from "core/log";
import { clientConfigFn } from "clientConfig";

interface Props {
    accountId: Guid | string;
    description: string;
    isEditing?: boolean;
}
interface State {
    isEditing: boolean;
}

class EditableAccountDescription extends React.Component<Props, State> {
    readonly state: State = {
        isEditing: this.props.isEditing || false,
    };

    onStartEdit = () => {
        log.info("Start editing account description");
        this.setState({ isEditing: true });
    };
    onFinishEdit = () => {
        log.info("Stop editing account description");
        this.setState({ isEditing: false });
    };

    render() {
        const { isEditing } = this.state;
        const { description, accountId } = this.props;
        const { showAccountTitle } = clientConfigFn();

        if (!showAccountTitle) {
            return null;
        }

        if (!isEditing) {
            return (
                <PlainAccountDescription
                    description={description}
                    onStartEdit={this.onStartEdit}
                />
            );
        }

        return (
            <ChangeAccountDescriptionComponent
                accountId={accountId}
                previousDescription={description}
                onCancel={this.onFinishEdit}
                onUpdated={this.onFinishEdit}
            />
        );
    }
}

export { EditableAccountDescription };
