import React from 'react';
import { useIsUserAuthenticatedQuery } from "../../generated/generated-models";
import { Loading } from "../lib/components/Loading"
import { Redirect, Route, RouteProps } from 'react-router';
import { hasMultipleActiveSessions } from 'app/authentication/ActiveSessions/ActiveSessions';
import { clientConfigFn } from 'clientConfig';

/** Redirects to login/password/etc when attempting to access a private page */
const PrivateRoute: React.FC<RouteProps> = (props) => {
    const { data, loading } = useIsUserAuthenticatedQuery();

    if (loading) {
        return <Loading />
    }

    const isOnPath = (path: string, location: Location) => {
        return location?.pathname?.indexOf(path) >= 0;
    };

    const isAuthenticated = data?.userSession?.CurrentSession?.Id !== undefined

    const shouldRedirectToChangePassword = () => {
        return isAuthenticated
            && !shouldRedirectToAcceptTerms()
            && data?.userSession?.HasTempPassword === true
    }

    const shouldRedirectToAcceptTerms = () => {
        return isAuthenticated
            && !data?.userSession?.LastTermsAcceptance
            && clientConfigFn().showTermsAndConditions
    }

    /** Returns undefined if user can access page returns path to redirect page */
    const getPathname = () => {
        if (!isAuthenticated) {
            return "/security/login";
        }

        if (shouldRedirectToAcceptTerms()) {
            if (isOnPath("firsttimelogin", window.location)) {
                return undefined;
            }
            return "/security/acceptterms";
        }

        if (shouldRedirectToChangePassword()) {
            if (isOnPath("changepassword", window.location)) {
                return undefined;
            }
            return "/security/changepassword"
        }

        if (hasMultipleActiveSessions(data?.userSession)) {
            if (isOnPath("activesessions", window.location)) {
                return undefined;
            }
            return "/security/activesessions"
        }

        return undefined;
    };

    const pathName = getPathname();

    if (pathName === undefined) {
        return (
            <section className="content-wrapper flex-fill">
                <Route {...props} />
            </section>
        )
    } else {
        return <Route
            render={({ location }) => {
                return <Redirect
                    to={{
                        pathname: pathName,
                        state: { from: location }
                    }}
                />
            }} />
    }
}


export default PrivateRoute;
