import { useFormikContext } from "formik";

/**
 * Add an error class if appropriate
 * @param fieldName Name of the formik field
 * @param className Current class names
 */
export const useAddErrorClass = (fieldName: string, className: string) => {
    const { errors, touched } = useFormikContext<any>();

    if (errors[fieldName] && touched[fieldName]) {
        return `${className} is-invalid`
    }

    return className;
}

export const getHelpTextId = (fieldName: string) => {
    return `${fieldName}-helpblock`
}

/**
 * Generate a field id based on the name/id
 * @param fieldName Name of the field
 * @param id Optionally pass in Id to override name
 */
export const getFieldId = (fieldName: string, id?: string) => {
    if (id != null) {
        return id;
    }
    return `${fieldName}-input`;
}
