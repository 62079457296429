import { FundsTransfer } from "generated/generated-models";
import TransferRow from "./TransferRow";
import React from "react";

type RowsProps = {
    pendingTransfers: FundsTransfer[];
    canDeleteTransfer: boolean;
}

const PendingTransferRows = ({ pendingTransfers, canDeleteTransfer }: RowsProps) => {   
    if (pendingTransfers.length === 0) {
        return (
            <tr className="no-transfers-row">
                <td colSpan={6}>
                    <p className="text-center text-muted">No pending transfers</p>
                </td>
            </tr>
        );
    }
    return (
        <>{
            pendingTransfers.map(transfer => (
                <TransferRow
                    transfer={transfer}
                    canDeleteTransfer={canDeleteTransfer}
                    key={transfer.Id}
                />))
        }</>
    );
};

type Props = {
    pendingTransfers: FundsTransfer[];
    interAccountAllowed: boolean;
}

const PendingTransfers: React.FC<Props> = (props) => {
    return (
        <div className="pendingtransfers-container">
            <h3>Upcoming Transfers</h3>
            <p className="intro">
                Transfers that are scheduled to come into or go out of your account.
            </p>
            <div className="table-responsive">
                <table className="table pending-transfers">
                    <thead>
                        <tr>
                            <th className="date-header">Next Due</th>
                            <th className="receipt-header">Receipt #</th>
                            <th className="frequency-header">Frequency</th>
                            <th className="type-header">Type</th>
                            <th className="amount-header">Amount</th>
                            <th className="action-header"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <PendingTransferRows
                            canDeleteTransfer={props.interAccountAllowed}
                            pendingTransfers={props.pendingTransfers}
                        />
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PendingTransfers;
