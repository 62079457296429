import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider } from "@apollo/client";
import { IntlProvider } from "react-intl";

import "react-app-polyfill/ie11"; // tslint:disable-line:no-submodule-imports
import "react-app-polyfill/stable"; // tslint:disable-line:no-submodule-imports

import App from "./app/app/App";
import { client } from "./core/apollo/client";
import "assets/styles/main.scss"
import "react-datepicker/dist/react-datepicker.css";
require("bootstrap");

if (process.env.NODE_ENV !== "production") {
    localStorage.setItem("debug", "capital:*");
}

const ApolloApp = () => (
    <IntlProvider locale="en-AU">
        <ApolloProvider client={client}>
            <App />
        </ApolloProvider>
    </IntlProvider>
);

ReactDOM.render(<ApolloApp />, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
