import React, { useState } from "react";
import LoginWrapper from "app/authentication/Login/LoginWrapper";
import EnterUsernameAndPasswordV2 from "./EnterUsernamePassword";
import EnterMfa from "app/authentication/Login/EnterMfa";
import EnterNewPassword from "./EnterNewPassword";

type LoginPage =
    | "UsernamePassword"
    | "SecurityToken"
    | "NewPassword"

type LoginDetails = {
    username: string;
    password: string;
    token: string;
}

const Login = () => {    
    const [loginPage, setLoginPage] = useState<LoginPage>("UsernamePassword")
    const [loginDetails, setLoginDetails] = useState<LoginDetails>({
        password: "",
        username: "",
        token: ""
    });

    const [isUsingGoogleAuth, setUsingGoogleAuth] = useState(false);

    const reset = () =>{
        setLoginPage("UsernamePassword")

        setLoginDetails({
            password: "",
            username: "",
            token: ""
        })
    }

    const handleRequiresMFa = (username: string, password: string) => {
        setLoginDetails({
            username: username,
            password: password,
            token: ""
        })
        setLoginPage("SecurityToken")
    }

    const requiresNewPassword = (username: string, password: string, token: string) => {
        setLoginDetails({
            username: username,
            password: password,
            token: token
        })
        setLoginPage("NewPassword")
    }

    return (
        <LoginWrapper>
            {loginPage === "SecurityToken" &&
                <EnterMfa
                    username={loginDetails.username}
                    password={loginDetails.password}
                    requiresNewPassword={requiresNewPassword} 
                    reset={reset}
                    isUsingGoogleAuth={isUsingGoogleAuth}
                />
            }
            {loginPage === "UsernamePassword" &&
                <EnterUsernameAndPasswordV2
                    requiresMfa={handleRequiresMFa}
                    requiresNewPassword={requiresNewPassword}
                    setUsingGoogleAuth={setUsingGoogleAuth}
                    isUsingGoogleAuth={isUsingGoogleAuth}
                />
            }
            {loginPage === "NewPassword" && 
                <EnterNewPassword
                    username={loginDetails.username}
                    password={loginDetails.password}
                    twoFactorToken={loginDetails.token}
                    reset={reset}
                />
            }
        </LoginWrapper>
    )
}

export default Login;
