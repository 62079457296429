import * as yup from "yup";
import { ElectronicCommsStatusInput } from "./ProfileElectronicCommunications";
import { GetSecurityTokenValidator } from "app/validation/securityTokenValidator";

export const Validate = (requiresMfa: boolean) => {   
    const schema: yup.ObjectSchema<ElectronicCommsStatusInput> = yup.object({
        TwoFactorToken: GetSecurityTokenValidator(requiresMfa)
    }).defined();
    return schema;
}
