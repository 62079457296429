import React, { useEffect, useState } from "react";
import {
    useIsUserAuthenticatedQuery,
    useRequestNewMultiFactorSecurityTokenMutation
} from "generated/generated-models";
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

type Props = {
    /** Called whenever the user entered token changes */
    getToken?: (token: string) => void;
    /** Display error message */
    errorMessage?: string;
    /** Whether the request new token button is displayed */
    showRequestNewToken?: boolean;
    /** Whether a token is automatically requested when this component mounts */
    requestMfaOnDisplay?: boolean;
    /** Whether the component should be displayed */
    visible?: boolean;
    isUsingGoogleAuth: boolean
}

const RequestMultiFactorTokenComponent = ({
    showRequestNewToken = true,
    errorMessage,
    getToken,
    requestMfaOnDisplay = false,
    visible = true,
    isUsingGoogleAuth = false
}: Props) => {
    const initialSeconds = 60;
    const [requestToken] = useRequestNewMultiFactorSecurityTokenMutation();
    const [seconds, setSeconds] = useState(0);
    const [alreadyRequestedMfa, setAlreadyRequestedMfa] = useState(false);
    const { data: userSessionData, error: userSessionError, loading: userSessionLoading } = useIsUserAuthenticatedQuery({
        partialRefetch: true,
        notifyOnNetworkStatusChange: true,
        errorPolicy: "all"
    });
    const loggedIn = userSessionData && !userSessionError && !userSessionLoading;
    const AuthLabel = isUsingGoogleAuth ? "Authorisation Code" : "Security Token";

    useEffect(() => {
        if (requestMfaOnDisplay && visible && !alreadyRequestedMfa) {
            setAlreadyRequestedMfa(true);
            setSeconds(initialSeconds);
            requestToken({ variables: { input: {} } });
        }
    }, [requestMfaOnDisplay, visible, alreadyRequestedMfa, requestToken])

    useEffect(() => {
        let interval: NodeJS.Timeout | undefined = undefined;
        if (seconds > 0) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds - 1);
            }, 1000);
        } else if (seconds === 0) {
            clearInterval(interval);
        }
        return () => {
            if (interval != null) {
                clearInterval(interval);
            }
        }
    }, [seconds]);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        if (getToken != null) {
            getToken(e.target.value)
        }
    }

    const RequestTokenButton = () => {
        if (isUsingGoogleAuth)
        {
            return null;
        }

        const handleClick = () => {
            requestToken({ variables: { input: {} } })
            setSeconds(60);
        }
        if (seconds <= 0) {
            return (
                <div className="request-token">
                    <Button variant="text" onClick={handleClick} id="getNewTokenButton">
                        Get New Token
                    </Button>
                </div>
            )
        } else {
            return (
                <div className="request-token">
                    <p className="text-muted">You may request a new token in: {seconds.toString().padStart(2, "0")} secs.</p>
                </div>
            )
        }
    }

    if (!visible) {
        return null;
    }

    return (
        <>
        {loggedIn ?
            <div className="request-token-container">
                <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="Token">{AuthLabel}</InputLabel>
                    <OutlinedInput
                        id="Token"
                        type="text"
                        name="Token"
                        onChange={handleOnChange}
                        label={AuthLabel}
                    />
                </FormControl>
                <div className="invalid-feedback form-error-message d-block">
                    {errorMessage}
                </div>
                {showRequestNewToken && <RequestTokenButton />}
            </div >
            :
            <div className="request-token-container">
                <FormControl fullWidth variant="filled">
                    <InputLabel htmlFor="Token">{AuthLabel}</InputLabel>
                    <FilledInput
                        id="Token"
                        type="text"
                        name="Token"
                        onChange={handleOnChange}
                    />
                </FormControl>
                <div className="invalid-feedback form-error-message d-block">
                    {errorMessage}
                </div>
                {showRequestNewToken && <RequestTokenButton />}
            </div >
        }
        </>
    )
};

export default RequestMultiFactorTokenComponent;
