import { CApolloError } from "app/common/CApolloError";
import { Loading } from "app/lib/components/Loading";
import { useInstrumentByIdOrNumberQuery } from "generated/generated-models";
import AccountsSummary from "./AccountsSummary";
import InstrumentHeader from "./InstrumentHeader";

const InstrumentPage = ({ instrumentNumber }: { instrumentNumber: string }) => {
    const { data, loading, error } = useInstrumentByIdOrNumberQuery({ variables: { idOrNumber: instrumentNumber } });

    const instrument = data?.instrumentByIdOrNumber;

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <CApolloError error={error} />
    }

    return (
        <section className="sub-wrap">
            <InstrumentHeader
                instrument={instrument}
            />

            {/* Empty function call to 'AccountsSummary will be addressed later - DO WE NEED THIS PAGE */}
            <AccountsSummary handleOnSelectedAccount={() => {}} instrument={instrument} />
        </section>
    );
}

export default InstrumentPage;
