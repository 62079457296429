import SanitisedBackgroundImage from "app/components/SanitisedBackgroundImage";
import SanitisedLogo from "app/components/SanitisedLogo";
import { clientConfigFn } from "clientConfig";
import React from "react";

const LoginWrapper: React.FC = ({ children }) => {
    const config = clientConfigFn();
    
    if (config.loginScreenLayout === "backgroundHero" && config.heroImageUri !== "") {
        return (
            <div className="login-page login-screen-backgroundhero" style={{backgroundImage: "url("+ config.heroImageUri +")"}}>
                <header className="header">
                    <div className="login-screen-header-one">
                        <SanitisedLogo 
                            logoUri={config && config.logoImageLightUri}
                            siteTitle={config.siteTitle}
                        />
                    </div>
                </header>
                <div className="flex-fill login-wrapper">
                    <div className="login-wrapper-inner">
                        {children}
                    </div>
                </div>
            </div >
        )
    } 
    if (config.loginScreenLayout === "splitHero" && config.heroImageUri !== "") {
        return (
            <div className="login-page login-screen-splitHero">
                <div className="column login-wrapper">
                <header className="header">
                    <div className="login-screen-header-two">  
                        <SanitisedLogo 
                            logoUri={config && config.logoImageDarkUri}
                            siteTitle={config.siteTitle}
                        />
                    </div>
                </header>
                    <div className="login-wrapper-inner">
                        {children}
                    </div>
                </div>
                <div className="column login-hero-wrapper">
                    
                    <SanitisedBackgroundImage src={config.heroImageUri} className="login-hero-img" />
                </div>
            </div >
        )
    } else {
        return (
            <div className="login-page login-screen-default">
                <div className="background-fill"></div>
                <header className="header">
                    <div className="login-screen-header-three">
                        <SanitisedLogo 
                            logoUri={config && config.logoImageLightUri}
                            siteTitle={config.siteTitle}
                        />
                    </div>
                </header>
                <div className="flex-fill login-wrapper">
                    <div className="login-wrapper-inner">
                        {children}
                    </div>
                </div>
            </div >
        )
    }
}

export default LoginWrapper;
