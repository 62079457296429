import { CTime } from "app/common/CapitalDate";
import MoneyFormatted from "app/components/MoneyFormatted";
import { FormattedDate } from "react-intl";

type Props = {
    rowHeading: string;
    dailyLimit?: string;
    rowAmount?: number;
    rowDate?: Date;
    subHeading?: string;
    subAmount?: number;
}

const DisplayTable = (props: Props) => {

    return (
        <>
            <div className="display-limits">
                <h4>{props.rowHeading}</h4>
                <div className="limits">
                    <p className="limit">
                        <span className="title">{props.dailyLimit}</span>
                        {props.rowAmount !== undefined &&
                            <MoneyFormatted amount={props.rowAmount} />
                        }
                    </p>
                    {props.subHeading &&
                        <p className="limit">
                            <span>{props.subHeading} </span>
                            {props.rowAmount !== undefined &&
                                <MoneyFormatted amount={props.subAmount} />
                            }   
                        </p>
                    }
                </div>
            </div>
            {props.rowDate &&
                <>
                    <FormattedDate value={props.rowDate} year="numeric" month="short" day="numeric"/> <CTime date={props.rowDate} />
                </>
            }

        </>
    )

}

export default DisplayTable