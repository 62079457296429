/**
 * provides autocomplete for object property names to avoid magic strings
 * @example nameof<typeof values>("UseNewBankAccount") = "UseNewBankAccount"
 */

export function nameof<T>(key: keyof T): keyof T {
    return key;
}

/**
 * provides autocomplete for object property names to avoid magic strings
 * @example const fac = nameofFactory<Account>();  fac("Id") = "Id"
 */
export const nameofFactory = <T>() => (name: keyof T) => name;
