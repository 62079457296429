import { updateNetworkStatus } from "./resolvers/updateNetworkStatus";
import { Resolvers as ApolloResolvers, DataProxy } from "@apollo/client";
import {
    Query,
    Mutation,
    InterestConversionNotification,
    Maybe,
} from "generated/generated-models";
import { dismissNotificationMutation } from "./mutations/dismissNotification";
import log from "../log";

// import { Query } from "generated/generated-models";
export const ResultCode = {
    success: "200",
    badInput: "400",
    exception: "500",
};

export type LocalStateResolver<
    Result = any,
    Args = any,
    Context = { cache: DataProxy; [key: string]: any },
    RootValue = any,
    Info = any
> = (rootValue: RootValue, args: Args, context: Context, info?: Info) => Result;

interface RootResolver {
    Query: { [key in keyof Query]?: LocalStateResolver<Query[key]> } | Resolvers;
    Mutation:
        | { [key in keyof Mutation]?: LocalStateResolver<Mutation[key]> }
        | Resolvers;
}

interface Resolvers {
    [key: string]: Resolvers | LocalStateResolver;
}

export const resolvers: RootResolver & Resolvers & ApolloResolvers = {
    Query: {},
    Mutation: {
        updateNetworkStatus,
        dismissNotification: dismissNotificationMutation,
    },
    InterestConversionNotification: {
        Dismissed: (
            root: Maybe<InterestConversionNotification>,
            _args: any,
            _context: any
        ) => {
            log.info(`Dismissed: `, {
                root,
                _args,
                dismissed: root && root.Dismissed,
            });
            return root && root.Dismissed !== undefined && root.Dismissed;
        },
    },
};
