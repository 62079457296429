import * as yup from "yup";
import { GetSecurityTokenValidator } from "app/validation/securityTokenValidator";
import { IndueCardResetInput } from "./IndueCardResetPinPage";

const config = {
	CardPinFixedLength: 4,
};

export const Validate = (requiresMfa: boolean) => {

    const schema: yup.ObjectSchema<IndueCardResetInput> = yup.object({
        CardId: yup.string().required(),
        
        NewPin: yup
            .string()
            .required("New Pin is required")
            .matches(new RegExp(`^[0-9]{${config.CardPinFixedLength}}$`), `Pin to be exactly ${config.CardPinFixedLength} digits`),
        
        ConfirmPin: yup
            .string()
            .required("Please confirm your New Pin")
            .oneOf([yup.ref('NewPin')], "Pins do not match"),
        
        TwoFactorToken: GetSecurityTokenValidator(requiresMfa)
    })
    .defined();

    return schema;
}
