import React from "react";
import { CError } from "app/common/CError";
import { Loading } from "app/lib/components/Loading";
import { useAccountFundsTransfersQuery } from "generated/generated-models";
import DueOnListItemsReceipt from "app/accountTransfer/utilities/list/DueOnListItemsReceipt";
import { useTransferPayments } from "./context/useTransferPayments";
import { TransfersAndPaymentsProps } from "./TransfersAndPayments";
import MoneyFormatted from "app/components/MoneyFormatted";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import useMediaQuery from '@mui/material/useMediaQuery';
import DueOnListItems from "app/accountTransfer/utilities/list/DueOnListItems";
import { Alert } from "@mui/material";

const TransferReceipt = () => {
    const { transferId, transferType, fromAccount, toAccount, isPending, formState, returnToHome } = useTransferPayments<TransfersAndPaymentsProps>();
    const { data, loading, error } = useAccountFundsTransfersQuery({
        variables: { accountIdOrNumber: transferType === "Payment" ? toAccount?.AccountId : fromAccount?.AccountId },
        fetchPolicy: "network-only"
    });
    const fullScreen = useMediaQuery("(max-width: 768px)");
    const [, setOpen] = React.useState(false);

    const handleClose = () => {
      setOpen(false);
    };

    if (loading === true) {
        return <Loading />
    }

    if ((!formState.useNPP && transferType !== "InterAccount") && (error != null || data?.accountByIdOrNumber?.ProcessedAccountFundsTransfers == null)) {
        return <CError error="Could not load payment details" />
    }

    const fundsTransfer = data?.accountByIdOrNumber?.ProcessedAccountFundsTransfers?.concat(data.accountByIdOrNumber.PendingAccountFundsTransfers)
        .find(x => x?.Id === transferId);

    if ((!formState.useNPP && transferType !== "InterAccount") && fundsTransfer == null) {
        return <CError error="Could not load payment details" />
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={true}
            className="success-transfer-payment"
            onClose={handleClose}
        >
            <DialogTitle>
                <ReceiptLongIcon />
            </DialogTitle>
            <DialogContent sx={{ m: 4, mt: 0 }}>
                <DialogContentText>
                <Typography variant="body1" gutterBottom component="p" sx={{ textAlign:"center" }} className="transfer-amount">
                    <MoneyFormatted amount={fundsTransfer?.Amount ?? formState.amount} />
                    <span className="caption">Paid successfully to {toAccount?.AccountName}</span>
                    {(!formState.useNPP && transferType !== "InterAccount") &&
                    <span className="sub-caption">Receipt No: {fundsTransfer?.ReceiptNumber}</span>
                    }
                </Typography >
                <List sx={{ mb: 2 }} className="transfer-details">
                    <ListItem disablePadding>
                        <ListItemText primary={<Typography><span className="subtitle">From: </span>{fromAccount?.AccountName} - {fromAccount?.AccountNumber} </Typography>} />
                    </ListItem>
                    <ListItem disablePadding>
                        {toAccount?.BillerCode || toAccount?.BSB 
                            ? <ListItemText primary={<Typography><span className="subtitle">To: </span>{toAccount?.AccountName} <br /> {toAccount?.BillerCode ?? toAccount?.BSB} | {toAccount?.AccountNumber ?? ""}</Typography>} />
                            : <ListItemText primary={<Typography><span className="subtitle">To: </span>{toAccount?.AccountName} - {toAccount?.AccountNumber ?? ""}</Typography>} />
                        }
                    </ListItem>
                    {(!formState.useNPP && transferType !== "InterAccount") &&
                    <DueOnListItemsReceipt fundsTransfer={fundsTransfer!} />
                    }
                    {(!formState.useNPP && transferType !== "InterAccount") &&
                        <DueOnListItems
                            recurrence={formState.recurrenceEnd || ""}
                            paymentDate={formState.startingOn}
                            frequency={formState.frequency || ""}
                            endDate={formState.endingOn}
                            repeats={formState.numberOfTimesAmount}
                        />
                    }
                    <ListItem disablePadding>
                        <ListItemText primary={<Typography><span className="subtitle">Description: </span> {fundsTransfer?.DepositDescription ?? formState.transferDescription}</Typography>} />
                    </ListItem>
                    {fundsTransfer?.WithdrawalDescription &&
                        <ListItem disablePadding>
                            <ListItemText primary={<Typography><span className="subtitle">Reference: </span> {fundsTransfer.WithdrawalDescription}</Typography>} />
                        </ListItem>
                    }
                </List>

                {(formState.useNPP && isPending) &&
                    <Alert severity="warning">
                        Payment request has been received but not yet processed. You will see the payment as pending until fully processed.
                    </Alert>
                }
                <div className="button-row">
                    <Button
                        fullWidth
                        type="submit" id="submit"
                        sx={{ p: 1.5, mt: 1 }}
                        onClick={() => returnToHome()}
                        variant="contained"
                    >
                        Done
                    </Button>
                </div>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default TransferReceipt;
