import BankingCutOff from "app/components/BankingCutoff";
import { CError } from "app/common/CError";
import Form from "app/components/Form";
import Input from "app/components/Input";
import { Loading } from "app/lib/components/Loading";
import { nameof } from "core/util/nameof";
import { Formik, useFormikContext } from "formik";
import {
    RecurrenceEndEnum,
    TaskFrequencyPeriod,
    useAccountFundsTransfersQuery
} from "generated/generated-models";
import TransferScheduleInput, { TransferScheduleInputValues } from "../utilities/TransferScheduleInput";
import { Validate } from "./CreatePayAnyoneValidate";
import { useTransferWizard } from "../utilities/TransferWizard";
import Title from "app/components/Title";
import FutureDatedWarningText from "app/components/FutureDatedWarningText";
import FundsTransferCardsContainer from "app/components/fundsTransferCards/FundsTransferCardsContainer";
import FromAccountCard from "app/components/fundsTransferCards/FromAccountCard";
import PaymentCard from "app/components/PaymentCard/PaymentCard";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';

export type PayAnyoneFormProps = {
    transferReference?: string;
    transferDescription?: string;
    amount?: number;
} & TransferScheduleInputValues

type Props = {
    accountId: string;
}

const CreatePayAnyone = (props: Props) => {
    const { changeAccount, payAnyoneBankAccount, returnToHome , gotoSelect } = useTransferWizard();
    const { data, loading, error } = useAccountFundsTransfersQuery({
        variables: { accountIdOrNumber: props.accountId }
    });
    const { values, isSubmitting } = useFormikContext<PayAnyoneFormProps>();

    if (loading) {
        return <Loading />
    }
    if (error || data?.accountByIdOrNumber == null) {
        return <CError error="Could not load pay anyone details" />
    }

    return (
        <div className="payanyone-create-page">
            <Title
                title="Pay Anyone"
            />
            <FundsTransferCardsContainer>
                <FromAccountCard account={data.accountByIdOrNumber} title="From" onClick={changeAccount} />
                {payAnyoneBankAccount == null ?
                    <PaymentCard
                    onClick={gotoSelect}
                    title="To"
                    name={<div><FontAwesomeIcon icon={faExclamationTriangle} /> Please select a pay anyone account</div>}
                    details="Click here to select a pay anyone account"
                    className="payment-create-summary-account-warning"
                /> :
                    <PaymentCard
                    title="To"
                    name={payAnyoneBankAccount?.AccountName}
                    details={`${payAnyoneBankAccount?.BankIdentifierText} ${payAnyoneBankAccount?.AccountNumber} (${payAnyoneBankAccount?.BankName})`}
                    onClick={gotoSelect}
                />
                }
            </FundsTransferCardsContainer>
            <Form>
            <Box sx={{ width: '100%' }}>
                <Box className="payment-details">
                    <Typography variant="h6" gutterBottom component="h6" sx={{ mt: 5, mb: 2 }}>
                        Payment Details
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Input
                                name={nameof<PayAnyoneFormProps>("amount")}
                                type="currency"
                                label="Amount"
                            />
                        </Grid>
                        <Grid item xs={6}><label>Daily Limits will appear here where relevant</label></Grid>
                        <Grid item xs={6}>
                            <Input
                                name={nameof<PayAnyoneFormProps>("transferDescription")}
                                type="text"
                                helpText="This will appear in the account you're redrawing money from"
                                label="Description"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                name={nameof<PayAnyoneFormProps>("transferReference")}
                                type="text"
                                helpText="This will appear in the account you're depositing money into"
                                label="Reference"
                            />
                        </Grid>
                        <TransferScheduleInput
                            values={values}
                            config={{
                                minStartingDate: new Date(),
                                minUntilDate: new Date(values.startingOn),
                            }}
                        />
                        <Grid item xs={12}>
                            <FutureDatedWarningText date={values.startingOn} />
                            <BankingCutOff />

                            {payAnyoneBankAccount == null &&
                                <Alert severity="error">Please select a pay anyone account</Alert>
                            }
                        </Grid>
                    </Grid>
                    <div className="form-group button-row">
                        <Button
                            sx={{ width: 150, p: 1.5, mt: 1 }}
                            disabled={isSubmitting}
                            onClick={() => returnToHome(data.accountByIdOrNumber?.Number!)}
                            variant="contained"
                            className="btn-cancel"
                            id="cancel">
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit" id="submit"
                            disabled={payAnyoneBankAccount == null}
                            sx={{ p: 1.5, mt: 1 }}
                            loadingPosition="end"
                            variant="contained"
                        >
                            Pay Anyone
                        </LoadingButton>
                    </div>
                </Box>
            </Box>
            </Form>
        </div>
    )
}

const CreatePayAnyoneForm = () => {
    const wizard = useTransferWizard<PayAnyoneFormProps>();
    const initialValues: PayAnyoneFormProps = {
        amount: undefined,
        transferDescription: "",
        endingOn: undefined,
        startingOn: new Date(),
        frequency: TaskFrequencyPeriod.Once,
        recurrenceEnd: RecurrenceEndEnum.Never,
        numberOfTimesAmount: undefined,
        transferReference: "",

        ...wizard.formState
    }
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => wizard.createTransfer(values)}
            validationSchema={Validate(wizard.accountId)}
        >
            <CreatePayAnyone accountId={wizard.accountId} />
        </Formik>
    )
}

export default CreatePayAnyoneForm;
