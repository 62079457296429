import * as yup from "yup";
import { ChangeAccountDescriptionInput } from "generated/generated-models";

export const yupChangeAccountDescriptionInput = yup.object<
    ChangeAccountDescriptionInput
>({
    AccountId: yup.string().required(),
    Name: yup
        .string()
        .required()
        .min(1),
});
