import gql from "graphql-tag";
export const ClientSchema = gql`
	interface MutationResponse {
		Code: String!
		Success: Boolean!
		Message: String
	}

	type CurrentTheme {
		theme: String!
	}

	type LocalConfig {
		allowOnlinePasswordReset: Boolean!
		currentTheme: CurrentTheme
	}

	type InterestConversionNotification {
		Dismissed: Boolean
	}

	type Query {
		localConfig: LocalConfig!
	}

	type DismissNotificationResult implements MutationResponse {
		Code: String!
		Success: Boolean!
		Message: String
		Data: InterestConversionNotification
	}

	type Mutation {
		dismissNotification(NotificationId: ID!): DismissNotificationResult
	}
`;
export default ClientSchema;
