import * as yup from "yup";
import { IndueCardCreateCardProps } from "./IndueCardConfirmCreateCard";
import { GetSecurityTokenValidator } from "app/validation/securityTokenValidator";

export const Validate = (requiresMfa: boolean) => {
    const schema: yup.ObjectSchema<IndueCardCreateCardProps> = yup.object({
        twoFactorToken: GetSecurityTokenValidator(requiresMfa),
    })
        .defined();

    return schema;
}
