import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MoneySigned from "app/components/MoneySigned";
import classNames from "classnames";
import { AccountTransaction } from "generated/generated-models"

const MobileTransaction = ({transaction}: {transaction: AccountTransaction}) => {
    const pendingClassName = classNames([{ "money-pending": transaction.IsPending }]);

    return (
        <div style={{ textAlign: "end" }}>
        {transaction.IsPending &&
            <FontAwesomeIcon icon={faClock} size="sm" className="money-pending-icon" />
        }
        {!!transaction.Decrease &&
            <MoneySigned amount={transaction.Decrease} direction="credit" className={pendingClassName} addColour={true} />
        }
        {!!transaction.Increase &&
            <MoneySigned amount={transaction.Increase} direction="debit" className={pendingClassName} />
        }
    </div>
    )
}

export default MobileTransaction;
