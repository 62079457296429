import React from "react";
import { match as Match } from "react-router-dom";
import IndueCardListTable from "./IndueCardListTable";
import { useIndueCardDetailsQuery, useIndueCardsandAssociationsByAccountQuery } from "generated/generated-models";
import { Loading } from "app/lib/components/Loading";
import { CError } from "app/common/CError";
import { makeNonEmpty } from "core/util/array";
import IndueCardDetailsPage from "./IndueCardDetailsPage";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

type Props = {
    accountId: string;
    accountNumber: string;
    cardId: string;
    match?: Match<{ number: string, cardid?: string}>;
}

const IndueCardPage: React.FC<Props> = ({ accountId, accountNumber, cardId }) => {
    const { data, loading, error } = useIndueCardDetailsQuery({
        variables: { input: { CardId: cardId } },
        fetchPolicy: "network-only",
    });
    const card = data?.indueCardDetails;
    const { data: dataIndueCards, loading: loadingIndueCards, error: errorIndueCards } = useIndueCardsandAssociationsByAccountQuery({
        variables: { accountId: accountId, accountIdString: accountId},
        fetchPolicy: "network-only",
    }); 
    const indueCards = makeNonEmpty(dataIndueCards?.indueCardsByAccount);

    if (loading || loadingIndueCards) {
        return <Loading />;
    }
    if (error || errorIndueCards || card == null) {
        return <CError error="Could not retrieve card details" />;
    }
console.log(cardId)
    return (
        <div className="main-content sub-wrap cardPage">
            <div className="cards-header">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <div className="list-header">
                            <h2>Cards</h2>
                            <span className="list-subhead">For Account: {accountNumber}</span>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="quick-actions">
                            <Button href={`/account/index/${accountNumber}`} variant="contained" sx={{ p: 1.5, mt: 1 }} id="viewAccountDetailsButton">
                                View Account Details
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="cardsContainer">
                <IndueCardListTable 
                    accountNumber={accountNumber}
                    cards={makeNonEmpty(indueCards)}
                    handleOnSelectedCard={cardId}
                />
                <IndueCardDetailsPage cardId={cardId}/>
            </div>
        </div>
    );
}

export default IndueCardPage;
