import React from "react";
import FieldErrorMessage from "../FieldErrorMessage";

type ButtonGroupContextProps = {
    name: string;
}

export const ButtonGroupContext = React.createContext<ButtonGroupContextProps | undefined>(undefined);

export type Props = {
    /** Field name */
    name: string;
    /** Label for the button group */
    label?: string;
}

const ButtonGroup: React.FC<Props> = (props) => {
    const context = {
        name: props.name
    }

    return (
        <div className="form-group">
            <label className="button-group-label">{props.label}</label>
            <div className="btn-group btn-group-toggle">
                <ButtonGroupContext.Provider value={context}>
                    {props.children}
                </ButtonGroupContext.Provider>
            </div>
            <FieldErrorMessage errorName={props.name} />
        </div>
    )
}

export default ButtonGroup;
