import { BankAccount, BpayBiller, Account, useAccountFundsTransfersQuery } from "generated/generated-models";
import { useHistory } from "react-router";
import { useTransferWizardContext } from "./useTransferWizardContext";
import { makeNonEmpty } from "core/util/array";


export function useTransferWizard<T>() {
    const context = useTransferWizardContext();
    const history = useHistory();

    const fundsTransferQuery = useAccountFundsTransfersQuery({
        variables: { accountIdOrNumber: context.accountId }
    });

    return {
        formState: context.formState as Partial<T>,
        transferId: context.fundsTransferId,
        bpayBiller: context.bpayBiller,
        payAnyoneBankAccount: context.bankAccount,
        interAccountToAccount: context.interAccount,
        accountId: context.accountId,
        redrawAccount: context.bankAccount,
        paymentAccount: context.bankAccount,
        createTransfer: (formstate: any) => {
            context.updateform(formstate);
            context.updatePage("Confirm");
        },
        confirmTransfer: (transferId: string) => {
            context.updateFundsTransfer(transferId);
            context.updatePage("Receipt");
        },
        modifyTransfer: () => {
            context.updatePage("Create");
        },
        gotoSelect: () => {
            context.updatePage("Select");
        },
        changeAccount: () => {
            context.updatePage("AccountSelect");
        },
        confirmBiller: (biller: BpayBiller) => {
            context.updateBpayBiller(biller);
            context.updatePage("Create");
        },
        confirmPayAnyone: (account: BankAccount) => {
            context.updateBankAccount(account);
            context.updatePage("Create");
        },
        confirmInterAccount: (account: Account) => {
            context.updateInterAccount(account);
            context.updatePage("Create");
        },
        updateAccount: (accountId: string) => {
            context.updateAccountId(accountId);
            context.updatePage("Create");

            fundsTransferQuery.refetch().then(({data}) => {
                const bankAccounts = makeNonEmpty(data.accountByIdOrNumber?.BankAccounts);

                if (bankAccounts.findIndex(x => x.Id === accountId) === -1) {
                    context.updateBankAccount(undefined);
                }
            });
        },
        returnToHome: (accountNumber: string) => {
            history.push(`/Account/Index/${accountNumber}/Transfer`);
        },
        confirmPaymentAccount: (account: BankAccount) => {
            context.updateBankAccount(account);
            context.updatePage("Create");
        },
        confirmRedrawAccount: (account: BankAccount) => {
            context.updateBankAccount(account);
            context.updatePage("Create");
        }
    };
};
