import { Formik } from "formik";
import Form from "app/components/Form";
import { DisbursementRequestInput, DisbursementRequestComponent } from "generated/generated-models";
import log from "core/log";
import { Guid } from "guid-typescript";
import { nameof } from "core/util/nameof";
import Datepicker from "app/components/DatePicker";
import { yupDisbursementRequestInput } from "./yupDisbursementRequestInput";
import { CApolloError } from "../common/CApolloError";
import { DisbursementRequestSuccessful } from "./DisbursementRequestSuccessful";
import { addTimeZoneOffset } from 'core/util/dateExtensions';
import Input from "app/components/Input";
import { Box, Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FileThumb } from "./FileThumb";
import { ChangeEvent, useState } from "react";
import { clientConfigFn } from "clientConfig";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';

interface DisbursementRequestFormValues extends DisbursementRequestInput {}

interface Props {
    InstrumentId?: Guid | string;
    AccountId: Guid | string;
}

const DisbursementRequestForm = (props: Props) => {
    const [fileData, setFileData] = useState("");
    const [fileName, setFileName] = useState("");
    const [file, setFile] = useState<File>();
    const fullScreen = useMediaQuery("(max-width: 768px)");
    const [, setOpen] = useState(false);

    const handleClose = () => {
      setOpen(false);
    };

    const getBase64 = async (file: File) => {
        return new Promise<string>(resolve => {
          let reader = new FileReader();
          reader.readAsDataURL(file);
    
          reader.onload = () => {
            let encoded = reader.result?.toString().replace(/^data:(.*,)?/, '');
            if(encoded && (encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }
            resolve(encoded!);
          };
        });
      };

      const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
        if(e.target && e.target.files && e.target.files[0])
        {
            const file = e.target.files[0];
            setFile(file);
            setFileName(file.name);
            const base64 = await getBase64(file);
            setFileData(base64);
        }

      }

    return (
        <section className="disbursement-request">
            <DisbursementRequestComponent>
                {(mutateFn, { called, data, error, loading }) => (
                    <Formik<DisbursementRequestFormValues>
                        initialValues={{
                            File: "",
                            FileName: "",
                            InstrumentId: `${props.InstrumentId}`,
                            AccountId: props.AccountId.toString(),
                            AccountNumber: "",
                            BankIdentifierText: "",
                            PayeeName: "",
                            PaymentDate: addTimeZoneOffset(new Date()),
                            PaymentDescription: "",
                            RequestedAmount: 0,
                            SupplierBankAccountName: "",
                        }}
                        onSubmit={values => {
                            if (values) {
                                mutateFn({
                                    variables: {
                                        input: {
                                            ...(values as DisbursementRequestInput),
                                            File: fileData,
                                            FileName: fileName
                                        },
                                    },
                                    update: (cache, result) => {
                                        log.info("Disbursement Request", result);
                                    },
                                });
                            }
                        }}
                        validationSchema={yupDisbursementRequestInput}
                        render={formik => {
                            if (called && !loading && error) {
                                return <CApolloError error={error} />;
                            }
                            if (called && !loading && !error) {
                                return (
                                    <Dialog
                                        fullScreen={fullScreen}
                                        open={true}
                                        className="success-requested-disbursement"
                                        onClose={handleClose}
                                    >
                                        <DialogTitle>
                                            <ReceiptLongIcon />
                                        </DialogTitle>
                                        <DialogContent sx={{ m: 4, mt: 0 }}>
                                            <DialogContentText>
                                            {data &&
                                            data.disbursementRequest &&
                                            data.disbursementRequest.Data && (
                                                <DisbursementRequestSuccessful
                                                    disbursement={data.disbursementRequest.Data}
                                                />
                                            )}
                                            <div className="button-row">
                                                <Button
                                                    fullWidth
                                                    type="submit" id="submit"
                                                    sx={{ p: 1.5, mt: 1 }}
                                                    onClick={() => window.location.reload()}
                                                    variant="contained"
                                                >
                                                    Done
                                                </Button>
                                            </div>
                                            </DialogContentText>
                                        </DialogContent>
                                    </Dialog>
                                );
                            }

                            return (
                                <Box className="disbursement-details">
                                    <h3>Request New Disbursement</h3>
                                    <Form>
                                        <Grid container spacing={2} sx={{mt:2}}>
                                            <Grid item xs={6}>
                                                <Input
                                                    name={nameof<DisbursementRequestFormValues>("PayeeName")}
                                                    type="text"
                                                    label="Supplier Name"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Input
                                                    name={nameof<DisbursementRequestFormValues>("BankIdentifierText")}
                                                    type="bsb"
                                                    label="Supplier BSB"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Input
                                                    name={nameof<DisbursementRequestFormValues>("AccountNumber")}
                                                    type="text"
                                                    label="Supplier Account Number"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Input
                                                    name={nameof<DisbursementRequestFormValues>("PaymentDescription")}
                                                    type="text"
                                                    label="Payment Description"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Input
                                                    name={nameof<DisbursementRequestFormValues>("RequestedAmount")}
                                                    type="currency"
                                                    label="Amount"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Datepicker
                                                    name={nameof<DisbursementRequestFormValues>("PaymentDate")}
                                                    label="Payment Date"
                                                    minDate={new Date()}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div className="form-group">
                                                    <label htmlFor="File">Supporting Documentation</label>
                                                    {clientConfigFn().disbursementFileText &&
                                                        <p className="help-block">
                                                            {clientConfigFn().disbursementFileText}
                                                        </p>
                                                    }
                                                    <div className="upload-container">
                                                        <div className="upload-wrap">
                                                            <input
                                                                id="File"
                                                                name="File"
                                                                type="file"
                                                                onChange={event => {
                                                                    handleUpload(event);
                                                                }}
                                                                className="form-control file-upload-input"
                                                            />
                                                            <div className="drag-text">
                                                                <h3>Drag &amp; drop</h3>
                                                                <p>or browse your files</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="helper-text">
                                                        For example, an invoice, estimate of fees or a receipt.
                                                    </p>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FileThumb file={file} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className="form-group button-row">
                                                    <Button
                                                        sx={{ width: 150, p: 1.5, mt: 1 }}
                                                        variant="contained"
                                                        className="btn-cancel"
                                                        onClick={() => window.location.reload()}
                                                        id="cancel">
                                                        Cancel
                                                    </Button>
                                                    <LoadingButton
                                                        type="submit" id="submit"
                                                        sx={{ p: 1.5, mt: 1 }}
                                                        loadingPosition="end"
                                                        variant="contained"
                                                        disabled={
                                                            (called && loading) ||
                                                            (data &&
                                                                data.disbursementRequest &&
                                                                data.disbursementRequest.Success) || undefined
                                                        }>
                                                        Request Disbursement
                                                    </LoadingButton>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                </Box>
                            );
                        }}
                    />
                )}
            </DisbursementRequestComponent>
        </section>
    );
}

export default DisbursementRequestForm;
