import React from "react";
import Confirm from "./Confirm";
import Create from "./Create";
import Receipt from "./Receipt";
import TransferAndPaymentsContainer, { Props as TransferAndPaymentsProps } from "./TransfersAndPaymentsContainer";


type TransferPaymentsComposition = {
    Create: React.FC;
    Confirm: React.FC;
    Receipt: React.FC;
} & React.FC<TransferAndPaymentsProps>;

const TransferPayments: TransferPaymentsComposition = props =>
    <TransferAndPaymentsContainer {...props} />;
    TransferPayments.Confirm = Confirm;
    TransferPayments.Create = Create;
    TransferPayments.Receipt = Receipt;

export default TransferPayments;
