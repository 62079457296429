import { IdGetter, defaultDataIdFromObject } from "@apollo/client/cache";
import { IsNullOrWhiteSpace } from "../util/stringExtensions";
export const dataIdFromObject: IdGetter = (result: any) => {
    let id = defaultDataIdFromObject(result);
    if (!IsNullOrWhiteSpace(id)) {
        return id;
    }
    if (result.Id !== undefined) {
        if (`${result.Id}`.startsWith(result.__typename)) {
            return result.Id; // already has typename
        }
        id = `${result.__typename}:${result.Id}`;
        return id;
    }
    return id;
};
