import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import { SelectChangeEvent } from "@mui/material";
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { SecureMessage, ToDoItemType, useCreateNewSecureMessageMutation, useFinanceSummaryQueryQuery, useToDoItemTypesQuery } from 'generated/generated-models';
import React, { ChangeEvent, useEffect, useState } from 'react';
import AddAttachment from "./AddAttachment";
import { CError } from 'app/common/CError';
import { Loading } from 'app/lib/components/Loading';
import { makeNonEmpty } from 'core/util/array';
import { Form, Formik, ErrorMessage } from "formik";
import { Validate } from "./CreateNewMessageValidate";
import { nameof } from "core/util/nameof";
import FieldErrorMessage from 'app/components/FieldErrorMessage';

interface CreateNewMessageProps {
    handleCloseNewMessage: () => void
    handleCreatedNewMessage: (secureMessage: SecureMessage) => void
}

export type CreateNewMessageInput = {
    SelectedToDoItem: ToDoItemType | undefined;
    SelectedAccount: string | undefined;
    Message: string;
}

const CreateNewMessage = (props: CreateNewMessageProps) => {
    const [toDoItemTypesArray, setToDoItemTypesArray] = useState<ToDoItemType[]>([]);
    const { data: financeSummary, loading: loadingFinanceSummary, error: financeSummaryErrors } = useFinanceSummaryQueryQuery();
    const accountNumbers = financeSummary?.financeSummary?.Instruments?.flatMap(instrument => instrument?.Accounts).flatMap(account => account?.Number);

    const { data: toDoItemTypes, loading: loadingToDoItemTypes, error: errorToDoItemTypes } = useToDoItemTypesQuery({
        variables: { input: { IsSecureMessage: true } },
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (!loadingToDoItemTypes && toDoItemTypes) {
            setToDoItemTypesArray(makeNonEmpty(toDoItemTypes.toDoItemTypes).sort((toDoItemTypeA, toDoItemTypeB) => ((!toDoItemTypeA.SecureMessageType || !toDoItemTypeB.SecureMessageType || toDoItemTypeA.SecureMessageType.DisplayOrder > toDoItemTypeB.SecureMessageType.DisplayOrder) ? 1 : -1)));
        }
    }, [toDoItemTypes, loadingToDoItemTypes])

    function handleCancelClick() {
        props.handleCloseNewMessage();
    }

    const [createNewSecureMessage] = useCreateNewSecureMessageMutation();

    async function handleSubmit(values: CreateNewMessageInput) {
        const result = await createNewSecureMessage({
            variables: {
                input: {
                    AccountNumber: values.SelectedToDoItem?.SecureMessageType?.IsAccount ? values.SelectedAccount : undefined,
                    Message: values.Message,
                    ToDoItemTypeId: values.SelectedToDoItem?.Id,
                }
            }
        })

        if (result.data?.requestNewSecureMessage?.GraphSecureMessage != null) {
            props.handleCreatedNewMessage(result.data?.requestNewSecureMessage?.GraphSecureMessage);
            props.handleCloseNewMessage();
        }
    };

    if (loadingToDoItemTypes) {
        return <Loading />;
    }
    if (errorToDoItemTypes) {
        return <CError error="Could not retrieve subjects" />
    }

    const initialValues: CreateNewMessageInput = {
        SelectedToDoItem: undefined,
        SelectedAccount: "",
        Message: ""
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={values => handleSubmit(values)}
            validationSchema={Validate}
        >
            {({ isSubmitting, values, handleChange, errors, touched }) => {
                return (
                    <Form>
                        <h2>New Message</h2>
                        <Grid container spacing={2} sx={{ mt: 0, mb: 4 }} className="createNewMessage">
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined" error={!!(errors.SelectedToDoItem && touched.SelectedToDoItem)}>
                                    <InputLabel>Subject</InputLabel>
                                    <Select
                                        fullWidth
                                        label="Subject"
                                        onChange={handleChange}
                                        name={nameof<CreateNewMessageInput>("SelectedToDoItem")}
                                    >
                                        {toDoItemTypesArray.map((toDoItemType) => {
                                            return (
                                                // @ts-ignore  -- allows toDoItemType to be passed through as a value
                                                <MenuItem key={toDoItemType.Id} value={toDoItemType}>{toDoItemType.Description}</MenuItem>
                                            );
                                        })}
                                    </Select>
                                    <FieldErrorMessage errorName={nameof<CreateNewMessageInput>("SelectedToDoItem")} />
                                </FormControl>
                            </Grid>
                            {values.SelectedToDoItem?.SecureMessageType?.IsAccount && (<Grid item xs={12}>
                                {loadingFinanceSummary && <Loading />}
                                {financeSummaryErrors && <CError error="Could not retrieve accounts" />}
                                {!loadingFinanceSummary && !financeSummaryErrors && (
                                    <FormControl fullWidth variant="outlined" error={!!(errors.SelectedAccount && touched.SelectedAccount)}>
                                        <InputLabel>Account</InputLabel>
                                        <Select
                                            fullWidth
                                            label="Account"
                                            onChange={handleChange}
                                            name={nameof<CreateNewMessageInput>("SelectedAccount")}
                                            value={values.SelectedAccount}
                                        >
                                            {accountNumbers?.map((accountNumber) => {
                                                return (
                                                    <MenuItem key={accountNumber} value={accountNumber}>{accountNumber}</MenuItem>
                                                );
                                            })}
                                        </Select>
                                        <FieldErrorMessage errorName={nameof<CreateNewMessageInput>("SelectedAccount")} />
                                    </FormControl>
                                )}
                            </Grid>)}
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined" error={!!(errors.Message && touched.Message)}>
                                    <InputLabel htmlFor="message" error={true}>Message</InputLabel>
                                    <OutlinedInput
                                        id="message"
                                        type="text"
                                        label="Message"
                                        multiline
                                        minRows={5}
                                        onChange={handleChange}
                                        name={nameof<CreateNewMessageInput>("Message")}
                                    />
                                    <FieldErrorMessage errorName={nameof<CreateNewMessageInput>("Message")} />
                                </FormControl>
                            </Grid>
                            {/* Hidden as per CAP-7727*/}
                            {false &&
                                <AddAttachment />
                            }
                        </Grid>
                        <Grid item xs={6}>
                            {/* <FileThumb file={file} /> */}
                        </Grid>
                        <div className="button-row">
                            <Button
                                className="btn-cancel" id="cancel"
                                sx={{ p: 1.5, mt: 1 }}
                                variant="contained"
                                onClick={handleCancelClick}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                className='btn-sendMessage'
                                type="submit" id="submit"
                                loading={isSubmitting}
                                sx={{ p: 1.5, mt: 1 }}
                                loadingPosition="end"
                                variant="contained"
                                startIcon={<SendIcon />}
                            >
                                Send Message
                            </LoadingButton>
                        </div>
                    </Form>
                )
            }
            }
        </Formik>
    )
}

export default CreateNewMessage;
