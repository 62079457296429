import { ApolloError } from '@apollo/client';
import { clientConfigFn } from 'clientConfig';
import { makeNonEmpty } from 'core/util/array';
import { addDays } from 'date-fns';
import { Account, AccountTransaction, useAccountByIdOrNumberQuery } from 'generated/generated-models';
import { useState } from 'react';

const filterTransactions = (transactions: (AccountTransaction | undefined)[] | undefined, transactionHistoryLimitDays: number) => {
    const filteredTrans = makeNonEmpty(transactions)
        .filter(tran => (tran.IsPending && !tran.WasReversed) || // pending transactions will be reversed once no longer pending
            (tran.AppearsOnStatement && !tran.WasBackedOut));

    const cutOffDate = transactionHistoryLimitDays > 0 ? addDays(new Date(), -transactionHistoryLimitDays) : new Date(1990, 1, 1);

    // Dirty hack because our types are wrong
    return filteredTrans.filter(tran => new Date(tran.EffectiveDate) >= cutOffDate);
}

type Transactions = {
    filteredTransactions: AccountTransaction[];
    pagedTransactions: AccountTransaction[];
}

const pageTransactions = (transactions: (AccountTransaction | undefined)[] | undefined, page: number, transactionPageSize: number, transactionHistoryLimitDays: number): Transactions => {
    const filteredTransactions = filterTransactions(transactions, transactionHistoryLimitDays);

    if (transactionPageSize < 1) {
        return {
            filteredTransactions: makeNonEmpty(filteredTransactions),
            pagedTransactions: makeNonEmpty(filteredTransactions)
        };
    }

    const startIndex = (page - 1) * transactionPageSize;
    const endIndex = startIndex + transactionPageSize;

    return {
        filteredTransactions: filteredTransactions,
        pagedTransactions: filteredTransactions.slice(startIndex, endIndex)
    };
}

type PageTransactions = {
    currentPage: number;
    totalPages: number;
    nextPage: () => void;
    previousPage: () => void;
    gotoPage: (page: number) => void;
    error?: ApolloError;
    loading: boolean;
    transactions?: AccountTransaction[];
}

export const usePagedTransactions: (account?: Account, disablePaging?: boolean) => PageTransactions = (account, disablePaging = false) => {
    const { transactionHistoryLimitDays = 0 } = clientConfigFn();

    const { data, error } = useAccountByIdOrNumberQuery({
        variables: { idOrNumber: account?.Id },
        fetchPolicy: "network-only",
        skip: account === undefined
    });
    const [currentPage, setCurrentPage] = useState(1);
    let { transactionPageSize = 0 } = clientConfigFn();

    if (disablePaging) {
        transactionPageSize = 0;
    }

    const transactions = pageTransactions(data?.accountByIdOrNumber?.Transactions, currentPage, transactionPageSize, transactionHistoryLimitDays);
    const totalPages = transactionPageSize > 0 ? Math.ceil((transactions?.filteredTransactions?.length ?? 0) / transactionPageSize) : 1;

    const constrainPage = (pageNumber: number) => {
        return Math.min(Math.max(pageNumber, 1), totalPages);
    }

    const returnValue = {
        currentPage: currentPage,
        totalPages: totalPages,
        nextPage: () => setCurrentPage(x => constrainPage(x + 1)),
        previousPage: () => setCurrentPage(x => constrainPage(x - 1)),
        gotoPage: (page: number) => setCurrentPage(constrainPage(page)),
        loading: false
    }

    if (error) {
        return {
            ...returnValue,
            error: error,
        }
    }

    if (data == null) {
        return {
            ...returnValue,
            loading: true,
        }
    }

    return {
        ...returnValue,
        transactions: transactions.pagedTransactions
    };
}
