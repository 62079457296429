import { ResetPasswordInput } from "generated/generated-models";
import * as yup from "yup";
import { nameof } from "core/util/nameof";


export const Validate = () =>{
    const schema: yup.ObjectSchema<ResetPasswordInput> = yup.object({
        Username: yup
            .string()
            .required(),
        ResetMethod: yup.string().required(),
        ContactDetails: yup
            .string()
            .required('Can not be blank')
            .when(nameof<ResetPasswordInput>("ResetMethod"), {
                is: "email",
                then: yup.string().required('Email can not be blank').email('Email is not valid'),
            })
            .when(nameof<ResetPasswordInput>("ResetMethod"), {
                is: "sms",
                then: yup.string().required('Mobile number can not be blank').min(4, 'Mobile number must be at least 4 digits'),
            }),

    }).defined();
    return schema
}
