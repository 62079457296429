import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import LoginHeader from "./LoginHeader";
import { clientConfigFn } from "clientConfig";
import { useBankingUserLoginV2Mutation, LoginV3AuthError } from "generated/generated-models";
import LoginErrorMessage from "./LoginErrorMessage";
import ForgottenUsername from "../ForgottenUsername";
import ForgottenPassword from "../ForgottenPassword";
import ResetPassword from "../ResetPassword/ResetPassword";
import TermsAndConditions from "../../other/TermsAndConditions";
import PrivacyPolicy from "../../other/PrivacyPolicy";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoginIcon from '@mui/icons-material/Login';
import { LoadingButton } from '@mui/lab';
import Typography from '@mui/material/Typography';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import useMediaQuery from '@mui/material/useMediaQuery';

type Props = {
    requiresMfa: (username: string, password: string) => void;
    requiresNewPassword: (username: string, password: string, token: string) => void;
    setUsingGoogleAuth: (isUsingGoogleAuth: boolean) => void;
    isUsingGoogleAuth: boolean;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const EnterUsernamePassword = ({ requiresMfa, requiresNewPassword, setUsingGoogleAuth }: Props) => {
    const clientConfig = clientConfigFn();
    const [username, setUsername] = useState("");
    const [userLogin, { loading, data, error }] = useBankingUserLoginV2Mutation();
    const fullScreen = useMediaQuery("(max-width: 768px)");

    const [password, setPassword] = React.useState({
        password: '',
        showPassword: false,
    });
    const handleChange = (prop: string) => (event: any) => {
        setPassword({ ...password, [prop]: event.target.value });
    };
    const handleClickShowPassword = () => {
        setPassword({
            ...password,
            showPassword: !password.showPassword,
        });
    };
    const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
    };
    const [, setLoading] = React.useState(false);
    function handleClick() {
        setLoading(true);
    }

    const [openUsername, setOpenUsername] = React.useState(false);
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
    const handleOpenUsername = (scrollType: DialogProps['scroll']) => () => {
        setOpenUsername(true);
        setScroll(scrollType);
    };
    const handleCloseUsername = () => {
        setOpenUsername(false);
    };
    const [openPassword, setOpenPassword] = React.useState(false);
    const handleOpenPassword = (scrollType: DialogProps['scroll']) => () => {
        setOpenPassword(true);
        setScroll(scrollType);
    };
    const handleClosePassword = () => {
        setOpenPassword(false);
    };
    const [openResetPassword, setOpenResetPassword] = React.useState(false);
    const handleOpenResetPassword = (scrollType: DialogProps['scroll']) => () => {
        setOpenResetPassword(true);
        setScroll(scrollType);
    };
    const handleCloseResetPassword = () => {
        setOpenResetPassword(false);
    };

    const [openTerms, setOpenTerms] = React.useState(false);
    const handleClickOpenTerms = () => {
        setOpenTerms(true);
    };
    const handleCloseTerms = () => {
        setOpenTerms(false);
    };
    const [openPrivacy, setOpenPrivacy] = React.useState(false);
    const handleClickOpenPrivacy = () => {
        setOpenPrivacy(true);
    };
    const handleClosePrivacy = () => {
        setOpenPrivacy(false);
    };
    const [openSupport, setOpenSupport] = React.useState(false);
    const handleClickOpenSupport = () => {
        setOpenSupport(true);
    };
    const handleCloseSupport = () => {
        setOpenSupport(false);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (username === "" || password.password === "") {
            return;
        }

        userLogin({
            variables: {
                input: {
                    Password: password.password,
                    Username: username,
                }
            },
            context: { headers: { "X-Interfi-RequestType": "Anonymous" }, },
        })
            .then(response => {
                if (response?.data?.loginV2 === undefined) {
                    return;
                }

                if (response.data.loginV2.AuthError === LoginV3AuthError.Mfatokeninvalid 
                    || response.data.loginV2.AuthError === LoginV3AuthError.Mfarequired) {
                    setUsingGoogleAuth(response.data.loginV2.IsUsingGoogleAuth);
                    requiresMfa(username, password.password);
                }

                if(response.data.loginV2.AuthError === LoginV3AuthError.Requirepasswordchange){
                    requiresNewPassword(username, password.password, "")
                }
            });
    }

    if (data?.loginV2?.AuthSucceeded) {
        return <Redirect to="/" />
    }
      
    return (
        <div>
            <div id="login-screen">
                <Paper sx={{ p: 10, borderRadius: 3 }}
                variant="elevation"
                elevation={24}
                className="login-screen"
                >
                <LoginHeader heading="LOGIN" subHeading={clientConfig.loginWelcomeMessage} />
                <LoginErrorMessage loginError={error} loginResponse={data} />
                <form autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <FormControl fullWidth variant="filled">
                            <InputLabel htmlFor="filled-adornment-username">Username</InputLabel>
                            <FilledInput
                                id="filled-adornment-username"
                                type="text"
                                value={username}
                                onChange={e => setUsername(e.target.value)}
                                endAdornment={
                                <InputAdornment position="end">
                                    <Button className="input-link" variant="text" onClick={handleOpenUsername('paper')} id="forgotUsernameButton">Forgot?</Button>
                                </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl fullWidth variant="filled">
                            <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
                            <FilledInput
                                id="filled-adornment-password"
                                type={password.showPassword ? 'text' : 'password'}
                                value={password.password}
                                onChange={handleChange('password')}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    className="icn-password-visibility"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    id="togglePasswordVisibility"
                                    >
                                    {password.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                    {clientConfig.allowOnlinePasswordReset
                                      ? <Button className="input-link" variant="text" onClick={handleOpenResetPassword('paper')} id="resetPasswordPassword">Reset?</Button>
                                      : <Button className="input-link" variant="text" onClick={handleOpenPassword('paper')} id="forgotPasswordPassword">Forgot?</Button>
                                    }
                                </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Box>
                    <LoadingButton
                        type="submit" id="submit"
                        sx={{ mt: 1 }} fullWidth
                        onClick={handleClick}
                        endIcon={<LoginIcon />}
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                    >
                        Login
                    </LoadingButton>
                </form>
                </Paper>

                <Dialog
                    className="forgot-details-dialog"
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={openUsername}
                    onClose={handleCloseUsername}
                    scroll={scroll}
                    fullScreen={fullScreen}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }}>
                        <IconButton
                        id="closeUsernameHandleButton"
                        color="inherit"
                        onClick={handleCloseUsername}
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            right: 14,
                            top: 12,
                        }}
                        >
                        <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                    <ForgottenUsername />
                    </DialogContent>
                </Dialog>

                <Dialog
                    className="forgot-details-dialog"
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={openPassword}
                    onClose={handleClosePassword}
                    scroll={scroll}
                    fullScreen={fullScreen}
                    >
                    <DialogTitle sx={{ m: 0, p: 2 }}>
                        <IconButton
                        color="inherit"
                        id="closePasswordHandleButton"
                        onClick={handleClosePassword}
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            right: 14,
                            top: 12,
                        }}
                        >
                        <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                    <ForgottenPassword />
                    </DialogContent>
                </Dialog>

                <Dialog
                    className="forgot-details-dialog"
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={openResetPassword}
                    onClose={handleCloseResetPassword}
                    scroll={scroll}
                    fullScreen={fullScreen}
                    >
                    <DialogTitle sx={{ m: 0, p: 2 }}>
                        <IconButton
                        color="inherit"
                        id="closePasswordResetHandleButton"
                        onClick={handleCloseResetPassword}
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            right: 14,
                            top: 12,
                          }}
                        >
                        <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <ResetPassword />
                    </DialogContent>
                </Dialog>

                <div className="legal-details">
                    {
                        clientConfig.showTermsAndConditions &&
                        <div className="button-container">
                            <Button variant="text" onClick={handleClickOpenTerms} id="openTermsAndConditionsButton">
                                Terms and Conditions
                            </Button>
                            <span>|</span>
                            <Dialog
                                className="terms-conditions-dialog"
                                fullScreen
                                open={openTerms}
                                onClose={handleCloseTerms}
                                TransitionComponent={Transition}
                            >
                                <AppBar className="appbar-legal" sx={{ position: 'fixed' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} component="div">
                                    Terms and Conditions
                                    </Typography>
                                    <IconButton
                                    edge="end"
                                    id="closeTermsAndConditionsButton"
                                    color="inherit"
                                    onClick={handleCloseTerms}
                                    aria-label="close"
                                    >
                                    <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                                </AppBar>
                                <TermsAndConditions />
                            </Dialog>
                        </div>
                    }
                    {
                        clientConfig.showPrivacyPolicy &&
                        <div className="button-container">
                            <Button variant="text" onClick={handleClickOpenPrivacy} id="openPrivacyPolicy">
                                Privacy Policy
                            </Button>
                            { clientConfig.supportEmailAddress &&<span>|</span> }
                            <Dialog
                                className="privacy-policy-dialog"
                                fullScreen
                                open={openPrivacy}
                                onClose={handleClosePrivacy}
                                TransitionComponent={Transition}
                            >
                                <AppBar className="appbar-legal" sx={{ position: 'fixed' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} component="div">
                                        Privacy Policy
                                    </Typography>
                                    <IconButton
                                    edge="end"
                                    color="inherit"
                                    onClick={handleClosePrivacy}
                                    aria-label="close"
                                    id="closePrivacyPolicy"
                                    >
                                    <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                                </AppBar>
                                <PrivacyPolicy />
                            </Dialog>
                        </div>
                    }
                    {
                        clientConfig.supportEmailAddress &&
                        <div className="button-container">
                            <Button variant="text" onClick={handleClickOpenSupport} id="openSupportButton">
                                Support
                            </Button>
                            <Dialog
                                className="support-dialog"
                                open={openSupport}
                                onClose={handleCloseSupport}
                                fullWidth={true}
                                maxWidth={'sm'}
                                scroll={scroll}
                                fullScreen={fullScreen}
                            >
                                <DialogTitle sx={{ m: 0, p: 2 }}>
                                    <IconButton
                                    color="inherit"
                                    id="closeSupportButton"
                                    onClick={handleCloseSupport}
                                    aria-label="close"
                                    sx={{
                                        position: 'absolute',
                                        right: 14,
                                        top: 12,
                                    }}
                                    >
                                    <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <div className="dialog-details-container">
                                        <h2>Need support?</h2>
                                        <p>If you need assistance, you can contact our support team anytime by
                                        {clientConfig.supportEmailAddress && !clientConfig.supportPhoneNumber && <> emailing <a href={'mailto:' + clientConfig.supportEmailAddress}> {clientConfig.supportEmailAddress}</a>.</>}
                                        {clientConfig.supportPhoneNumber && !clientConfig.supportEmailAddress && <> calling <br /> {clientConfig.supportPhoneNumber}.</>}
                                        {clientConfig.supportEmailAddress && clientConfig.supportPhoneNumber && <> emailing <a href={'mailto:' + clientConfig.supportEmailAddress}> {clientConfig.supportEmailAddress}</a> or calling <br /> {clientConfig.supportPhoneNumber}.</>}</p>

                                    </div>
                                </DialogContent>
                            </Dialog>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default EnterUsernamePassword;
