import SelectAccount from "../SelectAccount";
import { AuthorityType } from "./TransferWizardContainer";
import { useTransferWizardContext } from "./useTransferWizardContext";

const AccountSelect = ({authorityType}: {authorityType: AuthorityType}) => {
    const context = useTransferWizardContext();

    if (context.currentPage === "AccountSelect") {
        return (
            <SelectAccount accountNumber={context.accountId} authorityType={authorityType} />
        )
    }

    return null;
}

export default AccountSelect;
