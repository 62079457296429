import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { AccountTransaction, useAccountByIdOrNumberQuery, useFinanceSummaryQueryQuery } from "generated/generated-models";
import TransactionListView from "app/transaction/TransactionListView";
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { CError } from "app/common/CError";
import { Loading } from "app/lib/components/Loading";

const TransactionsListForParty = () => {
  const { data: dataAccounts, loading: loadingAccounts, error: errorAccounts  } = useFinanceSummaryQueryQuery();
  const accountsByPartyId = dataAccounts?.financeSummary?.Instruments?.flatMap(instrument => instrument?.Accounts);
  const [listAccounts, setListAccounts] = React.useState<Array<string>>([]);
  const [, setDetailView] = React.useState<AccountTransaction>();  
  const [selectedOptions, setSelectedOptions] = React.useState();

  const { data: dataAccountById } = useAccountByIdOrNumberQuery({
    variables: { idOrNumber: selectedOptions! }
  })
  const accountById = dataAccountById?.accountByIdOrNumber;

  React.useEffect(()=> {
    if(!loadingAccounts && dataAccounts) {
      const accountNumber = accountsByPartyId?.map(account => account?.Number);
      const accountNumberObject = accountNumber!.map((item: any) => item);
      const firstAccount = (accountNumberObject[0]);

      setSelectedOptions(firstAccount);
      setListAccounts(accountNumberObject)
    }
  },[loadingAccounts, dataAccounts])
  
  if (loadingAccounts) {
    return <Loading />;
  }
  if (errorAccounts) {
      return <section className="sub-wrap"><CError error={"Could not retrieve account statements."} /></section>
  }

  const handleAccountChange = (_event: any, value: any) => {
    setSelectedOptions(value)
  }
  
  return (
    <section className="sub-wrap transactions-list">
      <div className="list-header">
        <h2>Transactions</h2>
        <span className="list-subhead">For Account: {selectedOptions}</span>
      </div>
      
      <Box className="filterContainer">
        <div style={{ width: '100%' }}>
          <FormControl sx={{ width: 220, mr: 3 }} size="small" className='filterControl'>
            <Autocomplete
              id="filtered-account-outlined"
              size="small"
              options={listAccounts}
              disableClearable
              onChange={handleAccountChange}
              getOptionLabel={(option) => option}
              defaultValue={selectedOptions}

              PaperComponent={({ children }) => (
                <Paper style={{ fontSize:'0.9rem' }}>{children}</Paper>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Account" />
              )}
            />
          </FormControl>
        </div>
      </Box>
      <TransactionListView account={accountById} setDetailView={setDetailView} />

    </section>
  )
}

export default TransactionsListForParty;