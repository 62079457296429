import React from "react";
import { useTransferWizardContext } from "./useTransferWizardContext";

const Confirm: React.FC = (props) => {
    const context = useTransferWizardContext();

    if (context.currentPage === "Confirm") {
        return (
            <>
                {props.children}
            </>
        )
    }

    return null;
}

export default Confirm;
