import { IndueCard, IndueCardStatus, useAccountByIdOrNumberQuery } from "generated/generated-models";
import React from "react";
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router";
import { Card } from "@mui/material";
import { clientConfigFn } from "clientConfig";
import MoneySigned from "app/components/MoneySigned";
import { FormattedDate } from "react-intl";
import SanitisedImage from "app/components/SanitisedImage";

const statusToText = (status: IndueCardStatus) => {
    switch (status) {
        case IndueCardStatus.Active:
            return "Active";
        case IndueCardStatus.Activeoff:
            return "On Hold";
        case IndueCardStatus.Cardcreated:
            return "Created";
        case IndueCardStatus.Closed:
            return "Closed";
        case IndueCardStatus.Lost:
            return "Lost";
        case IndueCardStatus.Ordered:
            return "Ordered";
        case IndueCardStatus.Other:
            return "Other";
        case IndueCardStatus.Restricted:
            return "Restricted";
        case IndueCardStatus.Stolen:
            return "Stolen";
        case IndueCardStatus.Damaged:
            return "Damaged";
    }
    return true
}

const CardTableRow = ({ card, account }: { card: IndueCard, account: string }) => {
    const history = useHistory();
    const clientConfig = clientConfigFn();

    const { data } = useAccountByIdOrNumberQuery({
        variables: { idOrNumber: account }
    });

    const handleClick = () => {
        history.push(`/account/index/${account}/induecards/${card.Id}`);
    };

    const lastFourDigits = card.CardNumber?.substring(card.CardNumber?.length - 4) ?? "";
    const maskedCard = "•••• •••• •••• " + lastFourDigits;
    const { cardid }: { cardid: string } = useParams();

    let cardClassName = "cardElement";
    let cardStyles = {
        paperContainer: {
            backgroundImage: `url(${clientConfig.bankCardInActiveUri ? clientConfig.bankCardInActiveUri : ""})`
        }
    };

    if (card.Id === cardid) {
        cardClassName = "cardElementSelected";
        cardStyles = {
            paperContainer: {
                backgroundImage: `url(${clientConfig.bankCardActiveUri ? clientConfig.bankCardActiveUri : ""})`
            }
        };
    }

    return (
        <Card className={`cardItem ${cardClassName}`} onClick={handleClick} key={card.Id} style={cardStyles.paperContainer}>
            <div className="cardHeader">
                <SanitisedImage src={clientConfig.logoImageVariantUri} height={'22px'} />
                <p className={`cardStatus status-${card.Status}`}>{statusToText(card.Status)}</p>
            </div>
            <p className="cardBalance">
                <span className="bal-title">Balance</span>
                <MoneySigned amount={data?.accountByIdOrNumber?.CurrentBalance} direction="total" />
            </p>
            <div className="cardDetails">
                <p>{maskedCard}</p>
                <p><FormattedDate value={card.ExpiryDate!} year="2-digit" month="2-digit" /></p>
            </div>
        </Card>
    );
};

const CardTableBody = (account: string, cards?: IndueCard[]) => {
    if (!cards || cards.length === 0) {
        return (
            <p>There are no cards for this account</p>
        );
    }
    return cards.map(card => <CardTableRow card={card} account={account} key={card.Id}/>);
};

type IndueCardListTableProps = {
    selectable?: boolean
    accountNumber: string,
    cards?: IndueCard[],
    handleOnSelectedCard?: string,
};

const IndueCardListTable = ({accountNumber, cards}: IndueCardListTableProps) => {
    return (
        <div className="cardListContainer">
            {CardTableBody(accountNumber, cards)}
        </div>
    )
}

export default IndueCardListTable;
