import { useScreenSize } from "app/lib/useScreenSize";
import { Account, AccountTransaction } from "generated/generated-models";
import DesktopTransactionList from "./DesktopTransactionList";
import MobileTransactionList from "./MobileTransactionList";
import RecentActivityList from "./DesktopTransactionList/RecentActivityList";

export type TransactionListProps = {
    account?: Account;
    setDetailView: (transaction: AccountTransaction) => void;
}

const TransactionListView = ({ account, setDetailView }: TransactionListProps) => {
    const screenSize = useScreenSize();
    const isRoot = window.location.pathname === '/';

    if (screenSize === "desktop" && isRoot) {
        return <RecentActivityList account={account} />
    } 
    else if (screenSize === "desktop") {
        return <DesktopTransactionList account={account} />    
    } 
    else { 
        return <MobileTransactionList account={account} setDetailView={setDetailView} />
    }
}

export default TransactionListView;
