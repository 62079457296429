import AddIcon from '@mui/icons-material/Add';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { TablePagination, Badge, Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CError } from 'app/common/CError';
import { Loading } from 'app/lib/components/Loading';
import { makeNonEmpty } from 'core/util/array';
import { SecureMessage, useSecureMessagesByPartyQuery, useReadSecureMessageMutation, SecureMessagesByPartyDocument, usePartyProfileDetailsQuery, UnreadMessagesCountByPartyDocument } from 'generated/generated-models';
import React, { ChangeEvent, createContext, useCallback, useEffect, useState } from 'react';
import CreateNewMessage from './CreateNewMessage';
import MessageChainView from './MessageChainView';
import MessageOverviewCard from './MessageOverviewCard';
import MessageSearchBar from './MessageSearchBar';
import MailIcon from '@mui/icons-material/MailOutlined';

const DEFAULT_ROWS_PER_PAGE = 10

enum SecureMessageSortingType {
    Date = "Date",
    AZ = "AZ",
    ZA = "ZA"
}

enum SecureMessageStatus {
    All = "All",
    Unread = "Unread",
    Archive = "Archive"
}

interface SecureMessageContextType {
    SelectedMessage: SecureMessage | null,
    Filter: {
        Keyword: string,
        MessageStatus: SecureMessageStatus
    }
    Sorting: SecureMessageSortingType,
}

const SecureMessageContext = createContext<SecureMessageContextType | null>(null);

const SecureMessagingView = () => {
    const [openNewMessage, setOpenNewMessage] = useState(false);
    const fullScreen = useMediaQuery("(max-width: 768px)");
    const [messagePool, setMessagePool] = useState<SecureMessage[]>([]);
    const [filteredMessages, setFilteredMessages] = useState<SecureMessage[]>(messagePool);
    const { data: partyProfileDetails } = usePartyProfileDetailsQuery();
    const partyId = partyProfileDetails?.userSession?.Party?.Id;
    const [handleReading] = useReadSecureMessageMutation();
    const [secureMessageContext, setSecureMessageContext] = useState<SecureMessageContextType>({
        SelectedMessage: null,
        Filter: {
            Keyword: "",
            MessageStatus: SecureMessageStatus.All
        },
        Sorting: SecureMessageSortingType.Date,
    });
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
    const [page, setPage] = useState(0);
    const [heldMessageIds, setHeldMessageIds] = useState<string[]>([]);

    const { data: messagesByParty, loading: loadingMessagesByParty, error: errorMessagesByParty } = useSecureMessagesByPartyQuery({
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (!loadingMessagesByParty && messagesByParty) {
            setMessagePool(makeNonEmpty(messagesByParty.secureMessagesByParty?.SecureMessages));
        }
    }, [messagesByParty, loadingMessagesByParty])

    useEffect(() => {
        if (messagePool) {
            setFilteredMessages(filterMessages(messagePool));
        }
    }, [secureMessageContext.Filter])

    useEffect(() => {
        if (messagePool) {
            const sortedMessages = sortMessages(messagePool)
            setMessagePool(sortedMessages);
            setFilteredMessages(filterMessages(sortedMessages));
        }
    }, [messagePool, secureMessageContext.Sorting])

    function handleChangePage(_event: React.MouseEvent<HTMLButtonElement | MouseEvent> | null, pageNumber: number) {
        setPage(pageNumber);
    }

    function handleChangeRowsPerPage(_event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setRowsPerPage(Number(_event.target.value));
    }

    const filterMessages = (unfilteredMessages: SecureMessage[]) => {
        return unfilteredMessages.reduce((messages: SecureMessage[], message: SecureMessage) => {
            if ((secureMessageContext?.Filter.MessageStatus === SecureMessageStatus.All && message.IsArchived) ||
                (secureMessageContext?.Filter.MessageStatus === SecureMessageStatus.Unread && (message.IsRead && !heldMessageIds.includes(message.Id))) ||
                (secureMessageContext?.Filter.MessageStatus === SecureMessageStatus.Archive && !message.IsArchived) ||
                (secureMessageContext?.Filter.Keyword && secureMessageContext?.Filter.Keyword.length >= 3 && !message.ToDoItem.Messages?.some(element => element?.Text.toLowerCase().includes(secureMessageContext?.Filter.Keyword.toLowerCase())))) {
                return messages
            }
            messages.push(message);
            return messages;
        }, []);
    }

    const sortMessages = (unsortedMessages: SecureMessage[]) => {
        switch (secureMessageContext.Sorting) {
            case SecureMessageSortingType.Date:
                return unsortedMessages.sort((messageA, messageB) => (messageA.ToDoItem.Messages[messageA.ToDoItem.Messages.length - 1]?.CreatedOn < messageB.ToDoItem.Messages[messageB.ToDoItem.Messages.length - 1]?.CreatedOn) ? 1 : -1);

            case SecureMessageSortingType.AZ:
                return unsortedMessages.sort((messageA, messageB) => (messageA.ToDoItem.Text! > messageB.ToDoItem.Text!) ? 1 : -1);

            case SecureMessageSortingType.ZA:
                return unsortedMessages.sort((messageA, messageB) => (messageA.ToDoItem.Text! < messageB.ToDoItem.Text!) ? 1 : -1);

            default:
                return unsortedMessages;
        }
    }

    function handleTabChange(_event: React.SyntheticEvent, newValue: SecureMessageStatus) {
        setHeldMessageIds([]);
        setSecureMessageContext({ ...secureMessageContext, Filter: { ...secureMessageContext.Filter, MessageStatus: newValue } });
    }

    function handleSortByChange(event: SelectChangeEvent) {
        setSecureMessageContext({ ...secureMessageContext, Sorting: (SecureMessageSortingType as any)[event.target.value] });
    }

    const handleNewMessageClick = useCallback(() => {
        setOpenNewMessage(true);
    }, []);

    const handleCloseNewMessage = useCallback(() => {
        setOpenNewMessage(false);
    }, []);

    function handleCreatedNewMessage(secureMessage: SecureMessage) {
        setHeldMessageIds([]);
        setSecureMessageContext({ ...secureMessageContext, Filter: { ...secureMessageContext.Filter, MessageStatus: SecureMessageStatus.All }, SelectedMessage: secureMessage.Id === secureMessageContext.SelectedMessage?.Id ? null : secureMessage })
        setMessagePool([...messagePool, secureMessage]);
    }

    function handleSearchChange(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setSecureMessageContext({ ...secureMessageContext, Filter: { ...secureMessageContext.Filter, Keyword: _event.currentTarget.value.trim() } });
    }

    function handleSelectMessage(secureMessage: SecureMessage) {
        setSecureMessageContext({ ...secureMessageContext, SelectedMessage: secureMessage.Id === secureMessageContext.SelectedMessage?.Id ? null : secureMessage })
        if (secureMessage.IsRead !== true) {
            setHeldMessageIds([...heldMessageIds, secureMessage.Id])
            handleReadingSubmit(secureMessage);
        }
    }

    const handleReadingSubmit = async (secureMessage: SecureMessage) => {
        await handleReading({
            variables: {
                input: {
                    SecureMessageId: secureMessage?.Id
                },
            },
            refetchQueries: [
                { query: SecureMessagesByPartyDocument }, { query: UnreadMessagesCountByPartyDocument }
            ]
        });
    }

    if (loadingMessagesByParty) {
        return <Loading />;
    }
    if (errorMessagesByParty) {
        return <CError error="Could not retrieve messages" />
    }

    return (
        <>
            <section className="sub-wrap secure-messaging-container">
                <div className="list-header">
                    <h2>Messages</h2>
                </div>
                <Box sx={{ width: '100%' }}>
                    <Grid container spacing={2} sx={{ mt: 0, mb: 0 }}>
                        <Grid item xs={3.5} className="messagesList" sx={{mr:4}}>
                            <TabContext value={secureMessageContext.Filter.MessageStatus}>
                                <Box sx={{ mb: 3 }}>
                                    <TabList onChange={handleTabChange}>
                                        {Object.keys(SecureMessageStatus).map((secureMessageStatus) => {
                                            return (
                                                <Tab key={secureMessageStatus} label={secureMessageStatus === 'Unread' 
                                                    ? <Badge badgeContent={messagesByParty?.secureMessagesByParty?.UnreadMessageCount ?? 0} color="error">{secureMessageStatus}</Badge>
                                                    : secureMessageStatus} value={(SecureMessageStatus as any)[secureMessageStatus]} />                                   
                                            );
                                        })}
                                    </TabList>
                                </Box>
                                <Button className='newMessageBtn' id="new-secure-message-button" variant="outlined" fullWidth startIcon={<AddIcon />} onClick={handleNewMessageClick}>New Message</Button>
                                {filteredMessages == null || filteredMessages.length === 0 &&
                                    <Alert severity='info' sx={{ mt: 2 }}>We didn't find anything to show here.</Alert>
                                }
                                {filteredMessages != null && filteredMessages.length !== 0 &&
                                    <SecureMessageContext.Provider value={secureMessageContext}>
                                        <List sx={{ px: 0.8, py: 0.5, mt:1, height: 670, overflow: "auto" }}>
                                            {(
                                                rowsPerPage > 0
                                                    ? filteredMessages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : filteredMessages
                                            ).map(message => {
                                                return (
                                                    <React.Fragment key={message.Id}>
                                                        <MessageOverviewCard message={message} handleSelectMessage={handleSelectMessage} />
                                                        <Divider />
                                                    </React.Fragment>
                                                );
                                            })}
                                        </List>
                                    </SecureMessageContext.Provider>
                                }
                            </TabContext>
                        </Grid>
                        <Grid item xs={7.5} className="messageViewContainer">
                        {messagesByParty?.secureMessagesByParty?.SecureMessages != undefined && messagesByParty?.secureMessagesByParty?.SecureMessages?.length > 0 &&
                            <Box sx={{ml: 4}} className="messageFilters">
                                <MessageSearchBar handleSearchChange={handleSearchChange} />
                                <FormControl sx={{ minWidth: 120 }} size="small">
                                    <InputLabel>Sort By</InputLabel>
                                    <Select
                                        value={(SecureMessageSortingType as any)[secureMessageContext.Sorting]}
                                        label="Sort by"
                                        onChange={handleSortByChange}
                                        >
                                        {Object.keys(SecureMessageSortingType).map((secureMessageSortingType) => {                                         
                                            return (
                                                <MenuItem key={secureMessageSortingType} value={secureMessageSortingType}>{(SecureMessageSortingType as any)[secureMessageSortingType]}</MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>

                            </Box>
                        }
                        {filteredMessages != null && filteredMessages.length !== 0 && secureMessageContext.SelectedMessage == null &&
                            <Box sx={{ my: 12, textAlign: 'center' }} className="messageNote">
                                <MailIcon sx={{ mb: 0.7 }} />
                                <p style={{ fontSize: 14 }} >Select an item to read</p>
                            </Box>
                        }
                        {secureMessageContext.SelectedMessage != null &&
                            <MessageChainView secureMessage={secureMessageContext.SelectedMessage} partyId={partyId} />
                        }
                        {messagesByParty?.secureMessagesByParty?.SecureMessages != undefined && messagesByParty?.secureMessagesByParty?.SecureMessages?.length > 0 &&
                            <Box sx={{ml: 4}} className="messageFilters">
                                <TablePagination
                                    component="div"
                                    count={filteredMessages.length}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    sx={{
                                        overflowX:'scroll',
                                        '&::-webkit-scrollbar':{
                                            width:0,
                                        },
                                        margin: "0px",
                                        padding: "0px",

                                    }}
                                    />
                            </Box>
                        }
                        </Grid>
                    </Grid>
                </Box>
            </section>

            <Dialog
                fullScreen={fullScreen}
                className="createNewMessage"
                open={openNewMessage}
                onClose={handleCloseNewMessage}
                fullWidth
            >
                <DialogContent sx={{ m: 4 }}>
                    <DialogContentText>
                        <CreateNewMessage handleCloseNewMessage={handleCloseNewMessage} handleCreatedNewMessage={handleCreatedNewMessage} />
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    )
};

export { SecureMessagingView, SecureMessageContext, SecureMessageStatus };

