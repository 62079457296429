import * as yup from "yup";
import { BpayFormProps } from "./CreateBpay";
import { validateLimit, validateSchedule, validateTransferDescription } from "../utilities/validation";

export const Validate = (accountId: string) => {
    const schema: yup.ObjectSchema<BpayFormProps> = yup.object({
        transferDescription: validateTransferDescription("Description"),

        startingOn: yup
            .date()
            .required(),

        ...validateLimit(accountId, "bpay"),
        ...validateSchedule()
    })
        .defined();

    return schema;
}
