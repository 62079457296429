import React, { ChangeEvent } from "react";

type Props = {
    time: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}


const TimePicker = (props: Props) => {
    return (
        <div className="form-group">
            <label>Effective To Time:</label>
            <div className="input-group">
                <input
                type="time"
                step="1"
                value={props.time}
                onChange={props.onChange}
                className={"form-control"}
                />
            </div>
        </div>
    )
}

export default TimePicker;