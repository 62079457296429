import React, { Fragment } from "react";
import { IsNullOrWhiteSpace } from "core/util/stringExtensions";

interface Props {
    file?: Partial<File>;
}

const validFileExtensions = ["jpg", "jpeg", "gif", "svg", "png"];

const isImageExt = (file?: Partial<File>) => {
    if (file === undefined) {
        return false;
    }
    if (IsNullOrWhiteSpace(file.name)) {
        return false;
    }

    return validFileExtensions.some(
        ext => file && file.name && file.name.endsWith(ext)
    );
};

export class FileThumb extends React.Component<Props> {
    state = {
        loading: false,
        thumb: undefined,
    };
    componentWillReceiveProps(nextProps: Props) {
        if (!nextProps.file) {
            this.setState({ loading: false, thumb: undefined });
            return;
        }
        this.setState({ loading: true }, () => {
            const reader = new FileReader();
            reader.onloadend = () => {
                this.setState({ loading: false, thumb: reader.result });
            };

            if (nextProps.file) {
                reader.readAsDataURL(nextProps.file as File);
            } else {
                this.setState({ loading: false, thumb: undefined });
            }
        });
    }
    render() {
        const { file } = this.props;
        const { loading, thumb } = this.state;
        if (!file) {
            return null;
        }
        if (loading) {
            return <p>loading...</p>;
        }
        const fileName = file.name;
        const fileExtension = fileName!.split('.').pop();
        return (
            <Fragment>
                <section className="file-upload-details">
                    {isImageExt(file)
                        ? <img
                            src={thumb}
                            alt={file.name}
                            className="img-thumb"
                            height={60}
                            width={60}
                        />
                        : <div className="file-filetype">{fileExtension}</div>
                    }
                    
                    <div className="file-details">
                        <p>{file.name} <span>{Math.round(file.size || 0 / 1000)} KB</span></p>
                    </div>
                </section>
            </Fragment>
        );
    }
}
