import {
    Redirect,
    useHistory,
    useParams
} from "react-router-dom";
import {
    AccountFundsTransfersDocument,
    FundsTransfer,
    useStopPendingFundsTransferMutation,
    useAccountFundsTransfersQuery
} from "generated/generated-models";
import MoneyFormatted from "../../components/MoneyFormatted";
import React from "react";
import { CLoanWord } from "../../lang/CLoanWord";
import { Loading } from "app/lib/components/Loading";
import { CError } from "app/common/CError";
import { format } from "date-fns";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Alert from '@mui/material/Alert';
import useMediaQuery from '@mui/material/useMediaQuery';

type Props = { 
    fundsTransfer: FundsTransfer;
 }

const DeleteTransfer = ({ fundsTransfer }: Props) => {
    const [stopTransferMutation, { error, data, loading }] = useStopPendingFundsTransferMutation();
    const { data: transferData, loading: transferLoading, error: transferError } = useAccountFundsTransfersQuery({
        variables: { accountIdOrNumber: fundsTransfer.AccountId }
    });
    const history = useHistory();
    const fullScreen = useMediaQuery("(max-width: 768px)");
    const { number }: { number: string } = useParams();
    const [, setOpen] = React.useState(false);
    
    const handleClose = () => {
        setOpen(false);
    };

    if (data?.stopPendingFundsTransfer?.Success === true) {
        return <Redirect to={`/account/index/${number}`} push={false} />;
    }
    if (error || data?.stopPendingFundsTransfer?.Success === false) {
        return <section className="sub-wrap"><CError error="Failed to stop transfer" /></section>
    }
    if (transferLoading === true) {
        return <Loading />
    }
    if (transferError != null) {
        return <section className="sub-wrap"><CError error="Could not load account details. Please try again." /></section>
    }

    const stopTransfer = () => {
        stopTransferMutation({
            variables: { input: { FundsTransferId: fundsTransfer.Id } },
            refetchQueries: [
                {
                    query: AccountFundsTransfersDocument,
                    variables: {accountIdOrNumber: fundsTransfer.AccountId}
                }
            ]
        });
    }
console.log(number)
    return (
        <Dialog
            fullScreen={fullScreen}
            open={true}
            className="cancel-transfer-payment"
            onClose={handleClose}
        >
            <DialogTitle>
                <CurrencyExchangeIcon />
            </DialogTitle>
            <DialogContent sx={{ m: 4, mt: 0 }}>
                <DialogContentText>
                <Typography variant="body1" gutterBottom component="p" sx={{ textAlign:"center" }} className="transfer-amount">
                    <MoneyFormatted amount={fundsTransfer?.Amount || 0} />
                    <span className="caption">Cancel transfer to {fundsTransfer?.BillerName || fundsTransfer.ToAccountDescription}</span>
                    <span className="sub-caption">Receipt No: {fundsTransfer.ReceiptNumber}</span>
                </Typography >

                <List sx={{ mb: 2 }} className="transfer-details">
                    <ListItem disablePadding>
                        <ListItemText primary={<Typography><span className="subtitle">From: </span>{transferData?.accountByIdOrNumber?.ProductType} - ({fundsTransfer.FromAccountDescription }) </Typography>} />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary={<Typography><span className="subtitle">To: </span>{fundsTransfer?.BillerName || fundsTransfer.ToAccountDescription}</Typography>} />
                    </ListItem>
                    {fundsTransfer.StartingOn != null &&
                        <ListItem disablePadding>
                            <ListItemText primary={<Typography><span className="subtitle">Payment Date: </span> {format(new Date(fundsTransfer.StartingOn), 'd MMM yyyy')}</Typography>} />
                        </ListItem>
                    }
                    <ListItem disablePadding>
                        <ListItemText primary={<Typography><span className="subtitle">Description: </span> {fundsTransfer.DepositDescription}</Typography>} />
                    </ListItem>
                    {fundsTransfer.CreatedOn &&
                        <ListItem disablePadding>
                            <ListItemText primary={<Typography><span className="subtitle">Created On: </span> {format(new Date(fundsTransfer.CreatedOn), 'd MMM yyyy')}</Typography>} />
                        </ListItem>
                    }
                </List>

                { fundsTransfer.RegularPayment &&
                    <Alert severity="warning">
                        This transfer is part or all of your regular payment. If you
                        remove this, you must ensure you make other arrangments to pay
                        your {<CLoanWord />} in order to meet your minimum required
                        repayment. Failure to do so may cause your account to go into
                        default.
                    </Alert>
                }

                <div className="button-row">
                        <Button
                            className="btn-cancel" id="cancel"
                            sx={{ p: 1.5, mt: 1 }}
                            variant="contained"
                            onClick={() => history.push(`/account/index/${number}`)}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit" id="submit"
                            sx={{ p: 1.5, mt: 1 }}
                            onClick={() => stopTransfer()}
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                        >
                            Confirm
                        </LoadingButton>
                    </div>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

export default DeleteTransfer;
