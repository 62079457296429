import React from "react";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

export const PlainAccountDescription = ({
    description,
    onStartEdit,
}: {
    description: string;
    onStartEdit: () => void;
}) => {
    return (
        <>
            <span title="Edit Account Name" onClick={onStartEdit}>
                {description}
            </span>
            <IconButton className="edit-desc-title" aria-label="edit" title='Edit Account Name' onClick={onStartEdit} id="edit">
                <EditIcon />
            </IconButton>
        </>
    );
};
