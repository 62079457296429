import classNames from "classnames";

type Props = {
    title?: string;
    name?: React.ReactNode;
    details?: React.ReactNode;
    onClick?: () => void;
    className?: string;
}

const PaymentCard = (props: Props) => {
    const classes = classNames(["card-body", props.className, {"selectable-card": props.onClick != null}])
    return (
        <div className={classes} onClick={props.onClick}>
            <h5 className="card-title">{props.title}</h5>
            <div>
                <div>{props.name}</div>
                <div className="text-muted">{props.details}</div>
            </div>
        </div>
    );
}

export default PaymentCard;
