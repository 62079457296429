import React from "react";
import { IsShariaComponent } from "generated/generated-models";
export const CLoanWord = () => {
    return (
        <IsShariaComponent>
            {({ data }) =>
                <span>
                    {shariaRentText(
                        (data && data.userSession && data.userSession.IsSharia) || false
                    )}
                </span>
            }
        </IsShariaComponent>
    );
};
const shariaRentText = (isSharia: boolean) => {
    return (isSharia && "Rental") || "Loan";
};
