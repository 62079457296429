
import { useBankingUserLoginV2Mutation } from "generated/generated-models";
import { Redirect } from "react-router-dom";
import LoginHeader from "./LoginHeader";
import Input from "app/components/Input";
import { nameof } from "core/util/nameof";
import Form from "app/components/Form";
import { Formik } from "formik";
import { Validate } from "./EnterNewPasswordValidator";
import LoginErrorMessage from "./LoginErrorMessage";
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import LoginIcon from '@mui/icons-material/Login';
import { LoadingButton } from '@mui/lab';

export type EnterNewPasswordFormProps = {
    password: string;
    confirmPassword: string;
}

type Props = {
    username: string;
    password: string;
    twoFactorToken: string;
    reset: () => void;
}

const initialFormValues: EnterNewPasswordFormProps = { password: "", confirmPassword: "" }

const EnterNewPassword = ({ username, password, twoFactorToken, reset }: Props) => {
    const [userLogin, { loading, data, error }] = useBankingUserLoginV2Mutation();

    const handleSubmit = async (values: EnterNewPasswordFormProps) => {
        await userLogin({
            variables: {
                input: {
                    Password: password,
                    Username: username,
                    TwoFactorToken: twoFactorToken,
                    NewPassword: values.password
                }
            },
            context: { headers: { "X-Interfi-RequestType": "Anonymous" }, },
        });
    }
    if (data?.loginV2?.AuthSucceeded) {
        return <Redirect to="/" />
    }

    return (
        <div>
            <div id="login-screen">
                <Paper sx={{ p: 10, borderRadius: 3 }}
                variant="elevation"
                elevation={24}
                className="login-screen set-password"
                >
                <LoginHeader
                    heading="Set New Password"
                    subHeading="Please set a new password for your account. "
                />
                <LoginErrorMessage isUsingGoogleAuth={data?.loginV2?.IsUsingGoogleAuth} loginError={error} loginResponse={data} />

                <Formik
                    initialValues={{
                        ...initialFormValues
                    }}
                    onSubmit={(values) => {
                        handleSubmit(values)
                    }}
                    validationSchema={Validate()}
                >
                    {() => {
                        return (
                            <Form className="prelogin-form">
                                <Input
                                    name={nameof<EnterNewPasswordFormProps>("password")}
                                    type="password"
                                    label="New Password"
                                />
                                <Input
                                    name={nameof<EnterNewPasswordFormProps>("confirmPassword")}
                                    type="password"
                                    label="Confirm New Password"
                                />

                                <div className="form-group button-row">

                                    <Button
                                        role="secondary" id="cancel"
                                        sx={{ width: 150, p: 1.5, mt: 1 }}
                                        variant="outlined"
                                        onClick={reset}
                                        disabled={loading}>
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit" id="submit"
                                        sx={{ width: 150, p: 1.5, mt: 1 }}
                                        endIcon={<LoginIcon />}
                                        loading={loading}
                                        loadingPosition="end"
                                        variant="contained"
                                    >
                                        Login
                                    </LoadingButton>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
                </Paper>
            </div>
        </div>
    )
}

export default EnterNewPassword;
