import MoneyFormatted from "../components/MoneyFormatted";
import MoneySigned from "../components/MoneySigned";
import { FinanceSummary, Instrument } from "generated/generated-models";
import React from "react";

interface FinanceSummaryViewProps {
    financeSummary?: FinanceSummary;
    onLineClick?: (instrument: Instrument) => void;
    myFinancesTitle?: string;
    showUserInWelcomeMessage?: boolean;
}

const FinanceBalancesView: (
    props: FinanceSummaryViewProps
) => JSX.Element = ({ financeSummary }) => {

    return (
        <div>
            <span className="bal-title">Total Current Balance</span>
            <h2><MoneySigned amount={financeSummary?.TotalPrincipal?.Amount} direction="total" /></h2>
            <span className="bal-title">Total Available Balance</span>
            <h3><MoneyFormatted amount={financeSummary?.TotalRedraw?.Amount} /></h3>
        </div>
    );
};

export {
    FinanceBalancesView
}
