import * as yup from "yup";

export const GetSecurityTokenValidator = (requiresMfa: boolean) => {

    if (requiresMfa) {
        return yup
            .string()
            .label("Security Token / Auth Code")
            .required()
            .length(6);
    }
    else {
        return yup
            .string()
            .label("Security Token / Auth Code")
            .notRequired();
    }
}
