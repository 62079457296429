import Form from "app/components/Form";
import Input from "app/components/Input";
import { nameof } from "core/util/nameof";
import { Formik } from "formik";
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import { clientConfigFn } from "clientConfig";
import RequestMultiFactorToken from "../../components/RequestMultiFactorToken";
import React, { useState } from "react";
import {
    useGenerateAuthSecretMutation, useEnableProfileAuthenticatorMutation, useIsUserAuthenticatedQuery,
    useDisableProfileAuthenticatorMutation
} from "generated/generated-models";
import { ValidateCode } from "./AuthenitacorAuthCodeValidation";
import QRCode from "qrcode.react";
import { useHistory } from "react-router";
import Title from "app/components/Title";
import { Redirect } from "react-router-dom";
import Alert from '@mui/material/Alert';

type Stage =
    | "EnterMFA"
    | "AddAuth"
    | "RemoveAuth"

type AttemptState =
    | "None"
    | "Failed"
    | "Succeeded"

export type SetAuthCodeProps = {
    authenticatorToken?: string;
}


const AuthenticatorPage = () => {
    const history = useHistory();
    const [twoFactorToken, setTwoFactorToken] = useState("");
    const [generateAuthSecret] = useGenerateAuthSecretMutation();
    const [attemptState, setAttemptState] = useState<AttemptState>("None");
    const [authSecret, setAuthSecret] = useState("");
    const [enableAuth, { error: enableAuthError }] = useEnableProfileAuthenticatorMutation();
    const [currentStage, setCurrentStage] = useState<Stage>("EnterMFA");
    const { data: authenticatedData } = useIsUserAuthenticatedQuery();
    const [displayRemoveWarning, setDisplayRemoveWarning] = useState(false);
    const [disableAuth, { error: disableAuthError }] = useDisableProfileAuthenticatorMutation();
    const [goHome, setGoHome] = useState(false);

    const authCodeProps: SetAuthCodeProps = {
        authenticatorToken: ""
    }

    const QRUri = (productLabel: string, issuer: string, serect: string) => {
        var uri: string = "otpauth://totp/" + encodeURIComponent(productLabel) + "?secret=" + serect + "&issuer=" + encodeURIComponent(issuer);
        return uri;
    };

    const isUsingGoogleAuth = authenticatedData?.userSession?.IsUsingGoogleAuth || false;
    const mfaEnabled = authenticatedData?.userSession?.UseMultiFactorAuth || false;

    const enableRemoveWarning = () => {
        setDisplayRemoveWarning(true);
    }

    const disableRemoveWarning = () => {
        setDisplayRemoveWarning(false);
    }

    const handleMFASubmit = async (e: React.FormEvent) => {
        e.preventDefault();


        var result = await generateAuthSecret({
            variables: {
                input: {
                    TwoFactorToken: twoFactorToken
                }
            },

        });

        if (result.data?.generateAuthSecret?.Success === undefined || result.data?.generateAuthSecret?.Success === false) {
            setAttemptState("Failed");
        }
        else {
            setAttemptState("Succeeded");
            setTimeout(
                () => {
                    setAttemptState("None");

                    if (isUsingGoogleAuth === true) {
                        setCurrentStage("RemoveAuth");
                    }
                    else {
                        setCurrentStage("AddAuth");
                    }
                },
                1500);

            setAuthSecret(result.data?.generateAuthSecret?.AuthSecret ? result.data?.generateAuthSecret?.AuthSecret : "");
        }
    }

    const handleRemoveSubmit = async () => {
        setDisplayRemoveWarning(false);

        var result = await disableAuth({
            variables: {
                input: {
                }
            },
        });

        if (result.data?.disableProfileAuthenticator?.Success === undefined || result.data?.disableProfileAuthenticator?.Success === false) {
            setAttemptState("Failed");
        }
        else {
            setAttemptState("Succeeded");
            setTimeout(
                () => {
                    setAttemptState("None");
                    setGoHome(true);
                },
                1500);
        }
    }

    const handleAuthCodeSubmit = async (values: SetAuthCodeProps) => {
        var result = await enableAuth({
            variables: {
                input: {
                    AuthenticatorToken: values.authenticatorToken?.toString()
                }
            },
        });

        if (result.data?.enableProfileAuthenticator?.Success === undefined || result.data?.enableProfileAuthenticator?.Success === false) {
            setAttemptState("Failed");
        }
        else {
            setAttemptState("Succeeded");
            setTimeout(
                () => {
                    setAttemptState("None");
                    setCurrentStage("RemoveAuth");
                },
                1500);
        }
    }

    if (goHome === true) {
        return <Redirect to="/Security/ProfileSettings/Security" />
    }

    return (
        <div className="sub-wrap form-wrap">
            {currentStage === "EnterMFA" &&
                <section>
                    <Title
                        title={isUsingGoogleAuth ? "Manage Two-Factor Authentication" : "Setup Two-Factor Authentication"}
                    />
                    <div>
                        <p className="text-muted intro-text">{isUsingGoogleAuth ? clientConfigFn().manageAuthenticatorMessage : clientConfigFn().manageSecurityTokenMessage }</p>
                    </div>
                    {!mfaEnabled &&
                        <Alert severity="error">Multifactor authentication not enabled for your profile, please contact support to proceed.</Alert>
                    }
                    <form autoComplete="off" onSubmit={(e) => handleMFASubmit(e)}>
                    {mfaEnabled &&
                        <RequestMultiFactorToken
                            getToken={setTwoFactorToken}
                            showRequestNewToken={true}
                            isUsingGoogleAuth={isUsingGoogleAuth}
                        />}
                    
                        {attemptState === "Failed" &&
                            <Alert severity="error">
                                Invalid token, please try again.
                            </Alert>
                        }

                        {attemptState === "Succeeded" &&
                            <Alert severity="success">
                                Token successful
                            </Alert>
                        }

                        <div className="form-group button-row">
                            <Button 
                                variant="contained"
                                sx={{ p: 1.5, mt: 1 }}
                                className="btn-cancel"
                                onClick={() => history.push('/Security/ProfileSettings/Security')}
                                id="cancel"
                            >
                                Cancel
                            </Button>
                            <Button
                                sx={{ p: 1.5, mt: 1 }}
                                variant="contained"
                                type="submit"
                                disabled={!mfaEnabled}
                                id="submit"
                            >     {isUsingGoogleAuth === false ? 'Setup' : 'Manage'} Authenticator
                            </Button>
                        </div>
                    </form>
                </section>

            }

            {currentStage === "AddAuth" &&
                <Formik
                    initialValues={authCodeProps}
                    onSubmit={(values) => handleAuthCodeSubmit(values)}
                    validationSchema={() => ValidateCode()}
                >
                    {({ isSubmitting }) => {
                        return (
                            <div>
                                <Title title="Setup Two-Factor Authentication" />
                                <p className="text-muted">Using your preferred authentication app scan the following QR code or manually enter the secret below to setup your authenticator.</p>
                                <p className="text-muted">Once your authenticator is installed please enter the current code below confirming your installation.</p>
                                <p className="text-muted">If you cancel before confirming your installation the authenticator will not be applied to your account and the below QR code and secret will no longer be valid.</p>

                                <Form>
                                    <QRCode includeMargin value={QRUri(clientConfigFn()._productLabel, clientConfigFn()._issuer, authSecret)} />
                                    <p className="text-muted btm-nomargin">Secret: {authSecret}</p>

                                    <Input
                                        name={nameof<SetAuthCodeProps>("authenticatorToken")}
                                        type="wholeNumberAuth"
                                        placeHolder="Please enter your code"
                                    />

                                    {enableAuthError != null &&
                                        <Alert severity="error">
                                            Error verifying
                                        </Alert>
                                    }
                                    {attemptState === "Failed" &&
                                        <Alert severity="error">
                                            Invalid code, please try again.
                                        </Alert>
                                    }
                                    {attemptState === "Succeeded" &&
                                        <Alert severity="success">
                                            Authentication successfully added
                                        </Alert>
                                    }
                                        <div className="form-group button-row">
                                            <Button 
                                                variant="contained" 
                                                className="btn-cancel"
                                                onClick={() => history.goBack()}
                                                id="cancel"
                                            >
                                                Cancel
                                            </Button>
                                            <LoadingButton
                                                type="submit" 
                                                loading={isSubmitting}
                                                loadingPosition="end"
                                                variant="contained"
                                                id="submit"
                                            >
                                                Verify
                                            </LoadingButton>
                                        </div>
                                </Form>
                            </div>
                        )
                    }}
                </Formik>
            }

            {currentStage === "RemoveAuth" &&
                <section className="container authentication">
                    <div className="section-intro">
                        <h2>Manage Two-Factor Authentication</h2>
                        <p>Two-Factor Authentication (2FA) adds an extra layer of security to your account by requiring more than just a password to login.</p>
                        <p>If you choose to remove 2FA, you will lose a layer of security and will only receive login tokens via SMS/Email.</p>
                    </div>
                    <table className="table table-selectable">
                        <tbody>
                            <tr className="table-no-select">
                                <td className="row-title">2FA Authenticator</td>
                                <td align='right'>
                                    <Button variant="text" onClick={enableRemoveWarning} id="removeButton">Remove</Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {displayRemoveWarning === false &&
                        <div className="form-group button-row">
                                <Button 
                                    variant="contained" id="cancel"
                                    sx={{ p: 1.5, mt: 1 }}
                                    className="btn-cancel"
                                    onClick={() => history.push('/Security/ProfileSettings/Security')}
                                >
                                    Cancel
                                </Button>
                            </div>
                    }
                    {displayRemoveWarning === true &&
                        <div>
                            <Alert severity="warning">
                                You are about to remove Two-Factor Authentication. Once removed you will only receive login tokens via SMS/Email.
                            </Alert>
                            <div className="form-group button-row">
                                <Button 
                                    variant="contained" id="cancel"
                                    className="btn-cancel"
                                    onClick={disableRemoveWarning}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained" id="submit"
                                    type="submit" onClick={handleRemoveSubmit}
                                >
                                    Confirm
                                </Button>
                            </div>
                        </div>
                    }

                    {disableAuthError != null &&
                        <Alert severity="error">
                            Error removing authentication
                        </Alert>
                    }
                    {attemptState === "Succeeded" &&
                        <Alert severity="success">
                            Authentication successfully removed
                        </Alert>
                    }
                    {attemptState === "Failed" &&
                        <Alert severity="error">
                            Failed to Remove authenticator
                        </Alert>
                    }
                </section>
            }
        </div>
    )
}

export default AuthenticatorPage;
