import React from "react";
import { CDate } from "../common/CapitalDate";
import MoneyFormatted from "../components/MoneyFormatted";
import { Account } from "generated/generated-models";
import { clientConfigFn } from "clientConfig";
import NumberPercentage from "app/components/NumberPercentage";

const AccountSummaryHeader: React.FC<{ account: Account; }> = ({ account }) => {
    let offsetProduct;

    if (account.OffsetAccount) {
        offsetProduct = true;
    }
    else {
        offsetProduct = false;
    }

    return (<div className="account-metric-head">
        {offsetProduct === false && 
            <div className="account-metrics metric-group">
                {clientConfigFn().showAccountCurrentRate &&
                    <p><span className="title">Current Rate: </span><NumberPercentage number={account.Rate ? account.Rate : 0} /></p>
                }
                <p><span className="title">{clientConfigFn().minimumInstalmentLabel}: </span> <MoneyFormatted amount={account.MonthlyInstalment} /></p>
                <p><span className="title">Next instalment due: </span> <CDate date={account.NextInstalmentDue} /></p>
                {clientConfigFn().canViewArrearsAmount && 
                    <p><span className="title">Arrears Amount: </span><MoneyFormatted amount={account.ArrearsBalance} /></p>
                }
            </div>
        }

        {offsetProduct === true && 
            <p>There are no additional details to display for this account.</p>
        }
    </div>);
}

export {
    AccountSummaryHeader
}
