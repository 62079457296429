import { DataProxy } from "@apollo/client";
import { log } from "core/log";
import { LocalStateResolver } from "../resolvers";

interface NetworkStatus {
    isConnected: boolean;
}
export const updateNetworkStatus: LocalStateResolver<
    NetworkStatus,
    NetworkStatus
> = (_: any, args, { cache }: { cache: DataProxy }) => {
    if (undefined === args) {
        return { isConnected: false };
    }

    const { isConnected } = args;
    log.info(`updateNetworkStatus ${isConnected ? "True" : "False"}`);
    //cache.writeData({ data: { isConnected } });
    return { isConnected };
};
