import React from "react";

export const Loading = () => {
    return (
        <div className="loading-container flex-fill">
            <div className="loading-inner-container">
            <div role="progressbar" aria-valuetext="Loading page" className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        </div>
    );
};
