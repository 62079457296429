import * as yup from "yup";
import { PersonalDetailsInput } from "./ProfileSettings";
import { GetSecurityTokenValidator } from "app/validation/securityTokenValidator";

export const Validate = (requiresMfa: boolean) => {   
    const schema: yup.ObjectSchema<PersonalDetailsInput> = yup.object({
        TwoFactorToken: GetSecurityTokenValidator(requiresMfa)
    }).defined();
    return schema;
}
