import { CDate } from "../common/CapitalDate";
import { InstrumentByIdOrNumberQuery } from "generated/generated-models";
import MoneySigned from "../components/MoneySigned";
import MoneyFormatted from "../components/MoneyFormatted";
import { CApolloError } from "app/common/CApolloError";
import { clientConfigFn } from "clientConfig";

const GreaterThanZero = ({ value }: { value: number }) => {
    let displayValue = value < 0 ? 0 : value;

    return <span className="metric-value">
        {displayValue}
    </span>
}

const InstrumentHeader = ({ instrument }: { instrument: InstrumentByIdOrNumberQuery["instrumentByIdOrNumber"] }) => {
    const clientConfig = clientConfigFn();

    if (instrument == null) {
        return <CApolloError />;
    }
    return (
        <div className="account-metric-head">
            {clientConfig.showInstrumentTitle &&
                <h3 className="loan-purpose-title">
                    {instrument.LoanPurpose}
                </h3>
            }
            <div className="metric-group-container">
                <p className="instrument-totalprincipal">
                    <span className="metric-label">Current balance: </span>
                    <span className="metric-value">
                        <MoneySigned amount={instrument.TotalPrincipal} direction="total" />
                    </span>
                </p>
                <p className="instrument-number">
                    <span className="metric-label">Loan Number: </span>
                    <span className="metric-value">{instrument.Number}</span>
                </p>
                <p className="instrument-maturitydate">
                    <span className="metric-label">{clientConfig.maturesMetricLabel}: </span>
                    <span className="metric-value">
                        <CDate date={instrument.MaturityDate} />
                    </span>
                </p>
                {clientConfigFn().showAvailableRedraw &&
                    <p className="instrument-totalredraw">
                        <span className="metric-label">Available balance: </span>
                        <span className="metric-value">
                            <MoneyFormatted amount={instrument.TotalRedraw || 0.0} />
                        </span>
                    </p>
                }
                <p className="instrument-totaltermmonths">
                    <span className="metric-label">Term (months): </span>
                    <span className="metric-value">
                        {instrument.TotalTermMonths}
                    </span>
                </p>
                <p className="instrument-remainingtermmonths">
                    <span className="metric-label">Term remaining (months): </span>
                    <GreaterThanZero value={instrument.RemainingTermMonths} />
                </p>
            </div>
        </div>
    )
}

export default InstrumentHeader;
