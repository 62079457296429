import {
    DismissNotificationResult,
    DismissNotificationMutationVariables,
    InterestConversionNotification,
    Maybe,
} from "generated/generated-models";
import log from "core/log";
import { dataIdFromObject } from "core/apollo/dataIdFromObject";
import gql from "graphql-tag";
import { LocalStateResolver, ResultCode } from "../resolvers";
import { IsNullOrWhiteSpace } from "core/util/stringExtensions";
import {
    SelectionSetNode,
    FragmentDefinitionNode,
    FieldNode,
    DocumentNode,
} from "graphql";

export const dismissNotificationMutation: LocalStateResolver<
    DismissNotificationResult,
    DismissNotificationMutationVariables
> = (root, args, _context, info) => {
    //
    if (args === undefined || IsNullOrWhiteSpace(args.notificationId)) {
        const message = "Missing NotificationId";
        log.warn("Can't hide notification: " + message);
        // return { Code: ResultCode.badInput, Success: false, Message: message };
    }
    log.info(`Hiding notification`, { args, root });
    const { cache } = _context;
    const id = `${dataIdFromObject({
        id: args.notificationId,
        __typename: "InterestConversionNotification",
    })}`;

    const fragment = gql`
        fragment writeHiddenNotification on InterestConversionNotification {
            __typename
            Id
            Dismissed @client
            ConversionType
        }
    `;
    mergeFieldSelections(info, fragment);

    const cnotif = cache.readFragment<Partial<InterestConversionNotification>>({
        fragment: fragment,
        id,
    });
    const data: Partial<InterestConversionNotification> = {
        ...cnotif,
        Dismissed: true,
    };
    cache.writeFragment({ fragment: fragment, id, data });

    const result = {
        __typename: "DismissNotificationResult",
        Code: ResultCode.success,
        Success: true,
        Message: "Dismissed",
        Data: (data && data.ConversionType ? data : undefined) as Maybe<
            InterestConversionNotification
        >,
    } as DismissNotificationResult;
    return result;
};

function mergeFieldSelections(
    info: { field: FieldNode },
    fragment: DocumentNode
) {
    if (info && info.field && info.field.selectionSet) {
        const infoSelections = info.field.selectionSet as SelectionSetNode;
        const notificationSelections = infoSelections.selections.find(
            x => x.kind === "Field" && x.name.value === "Data"
        ) as FieldNode;
        const t =
            fragment &&
            fragment.definitions &&
            (fragment.definitions[0] as FragmentDefinitionNode);
        const fragSelections = t && t.selectionSet && t.selectionSet;

        if (
            notificationSelections &&
            notificationSelections.selectionSet &&
            fragSelections.selections
        ) {
            log.trace("joining selections");
            fragSelections.selections = notificationSelections.selectionSet.selections.concat(
                fragSelections.selections
            );
        }
    }
}
