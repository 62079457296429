import MoneyFormatted from "../components/MoneyFormatted";
import MoneySigned from "../components/MoneySigned";
import {
    Account,
    FinanceSummary,
    Instrument,
    useIsUserAuthenticatedQuery
} from "generated/generated-models";
import { clientConfigFn } from "clientConfig";
import { IsNullOrWhiteSpace } from "core/util/stringExtensions";
import AccountsSummary from "../instrument/AccountsSummary";
import InstrumentHeader from "../instrument/InstrumentHeader";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

const { numberOfAccountsToDisplayOnDashboard } = clientConfigFn();

const FinanceLine: (
    instrument: Instrument,
    handleOnSelectedAccount: (account: Account) => void,
    onClick?: (instrument: Instrument) => void,
) => JSX.Element = (instrument, handleOnSelectedAccount) => {

    if (instrument == null) {
        return <></>;
    }

    const lineClicked = (e: React.SyntheticEvent<{}>) => {
        e.preventDefault();
    };

    return (
        <>
        <Accordion disableGutters className="dash-accordion accordion-parent"
        data-href={"/Instrument/Index/" + instrument.Number}
            key={instrument.Number || instrument.Id || ""}
            onClick={lineClicked}
        >
             <AccordionSummary className="accordion-summary-parent"
                expandIcon={<AddIcon />}
             >
                <span className="instrument-details">
                    {clientConfigFn().showPrimaryBorrowerName
                    ? <b>{instrument.PrimaryBorrower} </b>
                    : <b>{instrument.Description || instrument.LoanPurpose} </b>
                    }
                    - {instrument.Number}
                </span>
            </AccordionSummary>
            <AccordionDetails>
                <InstrumentHeader instrument={instrument} />
            </AccordionDetails>
        </Accordion>
        <AccountsSummary instrument={instrument} handleOnSelectedAccount={handleOnSelectedAccount} />
        </>
    );
};

const WelcomeMessage = ({showUser, title} : {showUser?: boolean, title?: string}) => {
    const { data } = useIsUserAuthenticatedQuery();
    if(showUser){
        if(!IsNullOrWhiteSpace(data?.userSession?.Party?.FirstName)){
            return <h2>{title} {data?.userSession?.Party?.FirstName}</h2>
        }
        else{
            return <h2>{title} {data?.userSession?.Party?.OrganisationName}</h2>
        }
    }
    return <h2>{title}</h2>
}

interface FinanceSummaryViewProps {
    handleOnSelectedAccount: (account: Account) => void;
    financeSummary?: FinanceSummary;
    onLineClick?: (instrument: Instrument) => void;
    myFinancesTitle?: string;
    showUserInWelcomeMessage?: boolean;
}

const FinanceSummaryView: (
    props: FinanceSummaryViewProps
) => JSX.Element = ({ handleOnSelectedAccount, financeSummary, onLineClick, myFinancesTitle, showUserInWelcomeMessage }) => {
    
    const isRoot = window.location.pathname === '/';
    
    return (
        <div>
        <section className="accounts-list">
            <div className="list-header">
                <WelcomeMessage showUser={showUserInWelcomeMessage} title={myFinancesTitle} />
            </div>
            {financeSummary!.Instruments!.length > 0 ?
                <section>
                    {isRoot ?
                        <div className="account-container">
                            {financeSummary &&
                                financeSummary.Instruments &&
                                financeSummary.Instruments.filter(x => x != null).slice(0, numberOfAccountsToDisplayOnDashboard).map(inst => {
                                    if (inst) {
                                        return FinanceLine(inst, handleOnSelectedAccount, onLineClick);
                                    }
                                    return <></>;
                                })
                            }
                        </div>
                        :<div className="accord-container">
                            {financeSummary &&
                                financeSummary.Instruments &&
                                financeSummary.Instruments.filter(x => x != null).map(inst => {
                                    if (inst) {
                                        return FinanceLine(inst, handleOnSelectedAccount, onLineClick);
                                    }
                                    return <></>;
                                })
                            }
                        </div>
                    }
                    <div className="button-row button-row-showall">
                        <Button href={`/account/accountlist`} variant="contained" sx={{ p: 1.5, mt: 1 }} className="btn-showall" id="showAllAccountsButton">
                            Show All
                        </Button>
                    </div>
                </section>
              : <div className="no-data-container"><p>There are no financial accounts linked to your profile.</p></div>
            }

            <section className="total-balance">
                <div>
                    <p className="main-balance">Total Current Balance</p>
                    <p className="main-balance"><MoneySigned amount={financeSummary?.TotalPrincipal?.Amount} direction="total" /></p>
                </div>

                {clientConfigFn().showAvailableRedraw ?
                    <div>
                        <p className="avail-balance">Total Available Balance</p>
                        <p><MoneyFormatted amount={financeSummary?.TotalRedraw?.Amount} /></p>
                    </div>
                : null}
            </section>
        </section>
        </div>
    );
};

export {
    FinanceSummaryView
}


