import React from "react";
import { FormattedDate, FormattedTime } from "react-intl";

const CDate: React.FC<{
    date?: Date | string | null;
    format?: string;
}> = ({ date, format }) => {
    if (date) {
        return <FormattedDate value={date} format={format} />;
    }
    return <span>N/A</span>;
};

const CTime: React.FC<{ date?: Date | string | null }> = ({ date }) => {
    if (date) {
        if(!isNaN(new Date(date).getTime())){
            return <FormattedTime value={date} />;
        }
        return <span>{date}</span>
    }
    return <span />;
};

export{
    CDate,
    CTime
}
