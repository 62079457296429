import { FundsTransfer } from "generated/generated-models";
import TransferRow from "./TransferRow";
import React from "react";
const RecentTransfers = ({ recentTransfers }: { recentTransfers: FundsTransfer[] }) => {
    return (
        <div className="recenttransfers-container">
            <h3>Recent Transfers</h3>
            <p className="intro">
                The ten most recent transfers to come into or go out of your
                account.
            </p>
            <div className="table-responsive">
                <table className="table recent-transfers">
                    <thead>
                        <tr>
                            <th className="date-header">Processed</th>
                            <th className="receipt-header">Receipt #</th>
                            <th className="frequency-header">Frequency</th>
                            <th className="type-header">Type</th>
                            <th className="amount-header">Amount</th>
                            <th className="action-header"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {(recentTransfers.length === 0) && (
                            <tr className="no-transfers-row">
                                <td colSpan={6}>
                                    <p className="text-center text-muted">No transfer history</p>
                                </td>
                            </tr>
                        )}
                        {recentTransfers.map(transfer => (
                                <TransferRow
                                    transfer={transfer}
                                    canDeleteTransfer={false}
                                    key={transfer.Id}
                                />
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RecentTransfers;
