import CreatePayment from "./CreatePayment";
import ConfirmPayment from "./ConfirmPayment";
import PaymentReciept from "./PaymentReceipt";
import TransferWizard from "../utilities/TransferWizard";
import SelectPayment from "./SelectPayment";

const PaymentPage = (props: { accountId: string }) => {
    return (
        <TransferWizard accountId={props.accountId} useSelectionPage={true} authorityType={"operate"}>
            <TransferWizard.Create>
                <CreatePayment />
            </TransferWizard.Create>
            <TransferWizard.Confirm>
                <ConfirmPayment />
            </TransferWizard.Confirm>
            <TransferWizard.Receipt>
                <PaymentReciept />
            </TransferWizard.Receipt>
            <TransferWizard.Select>
                <SelectPayment />
            </TransferWizard.Select>
        </TransferWizard>
    )
}

export default PaymentPage;
