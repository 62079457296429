import React, { ReactNode } from "react";
import { Field, useFormikContext } from "formik";
import { CErrorMessage } from "app/common/forms/ErrorMessage";

type Props = {
    groupClassName?: string;
    inputClassName?: string;
    inputType?: string;
    fieldName: string;
    label?: string;
    inputId?: string;
    placeholderText?: string;
    showLabel?: boolean;
    formNote?: string;
    disabled?: boolean;
    pattern?: string;
    inputmode?: string;
    fieldWrapper?: (feildNode: React.ReactNode) => ReactNode;
}

export const CInputFormGroup: React.FC<Props> = ({
    fieldName,
    groupClassName = "form-group",
    inputClassName = "form-control",
    inputType,
    label,
    inputId,
    placeholderText,
    showLabel = true,
    fieldWrapper = (fieldNode: React.ReactNode) => fieldNode,
    formNote,
    disabled = false,
    pattern,
    inputmode,
    children
}) => {
    const { errors, touched } = useFormikContext<any>();

    if (errors[fieldName] && touched[fieldName]) {
        inputClassName = `${inputClassName} is-invalid`
    }

    return (
        <div className={groupClassName}>
            {showLabel && <label htmlFor={fieldName}>{label || fieldName}</label>}
            {fieldWrapper(
                <>
                    <Field
                        type={inputType}
                        className={inputClassName}
                        id={inputId || fieldName}
                        name={fieldName}
                        placeholder={placeholderText}
                        disabled={disabled}
                        pattern={pattern}
                        inputmode={inputmode}
                    />
                    <CErrorMessage name={fieldName} />
                    <small className="form-text text-muted formnote">{formNote}</small>
                </>
            )}
        </div>
    );
}

export default CInputFormGroup;
