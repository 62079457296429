import React from "react";
import PendingTransfers from "./PendingTransfers";
import RecentTransfers from "./RecentTransfers";
import {
    Account,
    FundsTransfer,
} from "generated/generated-models";

type FundsTransferProps = {
    transferModel: {
        InterAccountAllowed: boolean;
        RedrawAllowed: boolean;
        PayAnyoneAllowed: boolean;
        AllowOnlineDisbursements: boolean;
        BPayAllowed: boolean;
        Redraw?: number;
        PendingTransfers?: FundsTransfer[];
        TransferHistory?: FundsTransfer[];
    };
    account: Account;
}

const AccountTransferHome = (props: FundsTransferProps) => {
    const { transferModel } = props;

    return (
        <div className="scheduled-transfers">
            <PendingTransfers
                interAccountAllowed={props.transferModel.InterAccountAllowed}
                pendingTransfers={props.transferModel.PendingTransfers || []}
            />
            <RecentTransfers recentTransfers={transferModel.TransferHistory || []} />
        </div>
    );
};

export default AccountTransferHome;
