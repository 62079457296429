import React, { useState } from "react";
import { BpayBillerHistoryDocument, useBpayBillerHistoryQuery, useDeleteBpayPartyBillerMutation} from "generated/generated-models";
import { StringIsEqual } from "core/util/stringExtensions";
import { Loading } from "app/lib/components/Loading";
import { CError } from "app/common/CError";
import { useScreenSize } from "app/lib/useScreenSize";
import Box from '@mui/material/Box';
import AddBpayBiller from "./AddBpayBiller";
import { DataGrid, GridLinkOperator, GridRowsProp, GridColDef, GridOverlay } from '@mui/x-data-grid';
import type { Theme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import Typography from '@mui/material/Typography';
import BpayText from "app/components/BpayText"
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type BpayBiller = {
    id: string,
    code: string,
    description: string,
    name: string,
    customerReferenceNumber: string,
    HasPendingTransfer: boolean
}
interface Props {
    billerHistory: BpayBiller[];
    deleteBiller: (biller: BpayBiller) => void;
}

function CustomNoRowsOverlay() {
    return (
      <GridOverlay>
        <Box sx={{ m: 3, textAlign:"center" }}>You have no saved <BpayText /> billers.</Box>
      </GridOverlay>
    );
  }

const billerDisplayName = (biller: BpayBiller) => {
    return `${biller.description === "" ? biller.name : biller.description} (${StringIsEqual(biller.description, biller.name) ? "" : biller.name.trim()})`;
};

const BillerHistoryRow = (props: { biller: BpayBiller, deleteBiller: (biller: BpayBiller) => void }) => {
    return (
        <IconButton aria-label="delete" id="deleteButton"><DeleteIcon onClick={() => props.deleteBiller(props.biller)}/></IconButton>
    );
};

interface Props {
    billerHistory: BpayBiller[];
    deleteBiller: (biller: BpayBiller) => void;
}
const ManageBpayBillerHistoryView: React.FC<Props> = ({
    billerHistory,
    deleteBiller,
}) => {
    const [open, setOpen] = React.useState(false);
    const screenSize = useScreenSize();
    const fullScreen = useMediaQuery("(max-width: 768px)");
    const sortedBillers = billerHistory.sort((a, b) => a.description.toUpperCase() > b.description.toUpperCase() ? 1 : -1).map((item: any) => item);
    const rows: GridRowsProp = sortedBillers;
    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Biller Name', minWidth: 300, flex: 3, renderCell: (params) => (billerDisplayName(params.row)) },
        { field: 'code', headerName: 'Biller Code', minWidth: 150, flex: 2 },
        { field: 'customerReferenceNumber', headerName: 'Customer Ref', minWidth: 150, flex: 2 },
        { field: ' ', headerName: '', minWidth: 20, flex: 1, align:"center", renderCell: (params) => (<BillerHistoryRow key={params.row.code} biller={params.row} deleteBiller={deleteBiller} />)},
    ];
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));
    const handleAddBillerClick = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }
    return (
        <div className="biller-container" style={{ height: 650, width: '100%' }}>
            <Box className="tab-header" sx={{ mb: 2 }}>
                <Typography variant="h6" component="h6" sx={{ mx: 1, my: 0 }}>
                    <BpayText /> Billers
                </Typography>
                <Button startIcon={<GroupAddIcon />} onClick={handleAddBillerClick} id="addBillerButton">Add Biller</Button>
            </Box>
            {screenSize === "desktop" 
            ?  <DataGrid
                className='payees-grid'
                columns={columns}
                rows={rows}
                getRowId={(r) => r.id}
                rowsPerPageOptions={[10, 25, 50]}
                initialState={{
                    pagination: {
                        pageSize: 10,
                        },
                    }
                }
                components={{
                    NoRowsOverlay: CustomNoRowsOverlay
                }}
                disableColumnSelector
                componentsProps={{
                    filterPanel: {
                    linkOperators: [GridLinkOperator.And],
                    columnsSort: 'asc',
                    filterFormProps: {
                        linkOperatorInputProps: {
                        variant: 'outlined',
                        size: 'small',
                        },
                        columnInputProps: {
                        variant: 'outlined',
                        size: 'small',
                        sx: { mt: 'auto' },
                        },
                        operatorInputProps: {
                        variant: 'outlined',
                        size: 'small',
                        sx: { mt: 'auto' },
                        },
                        deleteIconProps: {
                        sx: {
                            '& .MuiSvgIcon-root': { color: '#d32f2f' },
                        },
                        },
                    },
                    sx: {
                        '& .MuiDataGrid-filterForm': { p: 2 },
                        '& .MuiDataGrid-filterForm:nth-child(even)': {
                        backgroundColor: (theme: Theme) =>
                            theme.palette.mode === 'dark' ? '#444' : '#f5f5f5',
                        },
                        '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 2 },
                        '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                        '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                        '& .MuiDataGrid-filterFormValueInput': { width: 200 },
                    },
                    },
                }}
                />
            : <Stack spacing={2}>
                {sortedBillers.map(biller => {
                    return (
                        <Item sx={{ pb: 1.5 }}>
                            <List className="payees-list-mobile">
                                <ListItem disablePadding className="delete-item">
                                    <ListItemText primary={<Typography><IconButton aria-label="delete" id="deletePayeeButton"><ClearIcon onClick={() => deleteBiller(biller)}/></IconButton></Typography>} />
                                </ListItem>
                                <ListItem disablePadding sx={{ px: 3 }}>
                                    <ListItemText primary={<Typography><span className="subtitle">{biller.description}</span></Typography>} />
                                </ListItem>
                                <ListItem disablePadding sx={{ px: 3 }}>
                                    <ListItemText primary={<Typography>{biller.name}</Typography>} />
                                </ListItem>
                                <ListItem disablePadding sx={{ px: 3 }}>
                                    <ListItemText primary={<Typography><span className="subtitle">Biller Code: </span> {biller.code}</Typography>} />
                                </ListItem>
                                <ListItem disablePadding sx={{ px: 3 }}>
                                    <ListItemText primary={<Typography><span className="subtitle">Customer Ref: </span> {biller.customerReferenceNumber}</Typography>} />
                                </ListItem>
                            </List>
                        </Item>
                    )
                })}
              </Stack>
            }
            <Dialog
                fullScreen={fullScreen}
                className="add-payee"
                open={open}
                onClose={handleClose}
                fullWidth
            >
                <DialogContent sx={{ m: 4 }}>
                    <DialogContentText>
                        <AddBpayBiller handleClose={handleClose}/>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
};

const ManageBPayBillerHistory = () => {
    const [billerToDelete, setBillerToDelete] = useState<BpayBiller | undefined>(undefined);
    const [deleteBPayMutation, { loading: deleteBpayLoading }] = useDeleteBpayPartyBillerMutation();
    const fullScreen = useMediaQuery("(max-width: 768px)");

    const deleteBPay = () => {
        if (billerToDelete != null) {
            deleteBPayMutation({
                variables: { input: { PartyBillerHistoryId: billerToDelete.id } },
                refetchQueries: [
                    { query: BpayBillerHistoryDocument },
                ],
            }).then(() => setBillerToDelete(undefined));
        }
    }

    const { data, loading, error } = useBpayBillerHistoryQuery();

    if (loading) {
        return <Loading />
    }
    if (error != null || data?.userSession?.Party?.BpayBillerHistory == null) {
        return <CError error="Could not retrieve BPAY Biller History" />
    }

    if (billerToDelete == null) {
        const bpayHistory = data.userSession.Party.BpayBillerHistory.map(x => {
            return {
                code: x?.Billercode as string,
                id: x?.Id || "",
                description: x?.BillerDescription || "",
                customerReferenceNumber: x?.CustomerReferenceNumber || "",
                name: x?.BillerName || "",
                HasPendingTransfer: x?.HasPendingTransfer || false
            }
        });
        return <ManageBpayBillerHistoryView billerHistory={bpayHistory} deleteBiller={setBillerToDelete} />
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            className="delete-payment-account"
            open={true}
        >
            <DialogTitle>
                <DeleteIcon />
                <Typography variant="h5" gutterBottom component="h5" sx={{ mx: 2, mb: 2 }}>
                    Delete Biller?
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ m: 4, mt: 0 }}>
                <DialogContentText>
                <Typography variant="body1" gutterBottom component="p" sx={{ textAlign:"center" }}>
                    Are you sure you would like to delete this <BpayText /> biller?
                </Typography >
                <List sx={{ mb: 2 }}>
                    <ListItem disablePadding>
                        <ListItemText primary={<Typography><span className="subtitle">{billerDisplayName(billerToDelete)}</span></Typography>} />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary={<Typography><span className="subtitle">Biller Code: </span> {billerToDelete.code}</Typography>} />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary={<Typography><span className="subtitle">Customer Ref: </span> {billerToDelete.customerReferenceNumber}</Typography>} />
                    </ListItem>
                </List>

                {billerToDelete.HasPendingTransfer === true &&
                    <Alert severity="warning">
                        There are Upcoming Transfers against these details. They will continue to be processed as usual unless cancelled manually.
                    </Alert>
                }

                <div className="button-row">
                    <Button
                        className="btn-cancel" id="cancel"
                        sx={{ p: 1.5, mt: 1 }}
                        variant="contained"
                        onClick={() => setBillerToDelete(undefined)}>
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit" id="submit"
                        sx={{ p: 1.5, mt: 1 }}
                        onClick={() => deleteBPay()}
                        loading={deleteBpayLoading}
                        loadingPosition="end"
                        variant="contained"
                    >
                        Confirm
                    </LoadingButton>
                    </div>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default ManageBPayBillerHistory;
