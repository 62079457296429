import BankingCutOff from "app/components/BankingCutoff";
import { CError } from "app/common/CError";
import Form from "app/components/Form";
import Input from "app/components/Input";
import { Loading } from "app/lib/components/Loading";
import { nameof } from "core/util/nameof";
import { Formik, useFormikContext } from "formik";
import {
    RecurrenceEndEnum,
    TaskFrequencyPeriod,
    useAccountFundsTransfersQuery
} from "generated/generated-models";
import TransferScheduleInput, { TransferScheduleInputValues } from "../utilities/TransferScheduleInput";
import { Validate } from "./CreateRedrawValidate";
import { useTransferWizard } from "../utilities/TransferWizard";
import Title from "app/components/Title";
import FutureDatedWarningText from "app/components/FutureDatedWarningText";
import FundsTransferCardsContainer from "app/components/fundsTransferCards/FundsTransferCardsContainer";
import FromAccountCard from "app/components/fundsTransferCards/FromAccountCard";
import Button from "app/components/Button";
import PaymentCard from "app/components/PaymentCard/PaymentCard";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "app/components/Alert";


export type RedrawFormProps = {
    transferReference?: string;
    transferDescription?: string;
    amount?: number;
} & TransferScheduleInputValues

type Props = {
    accountId: string;
}

const Payment = (props: Props) => {
    const { data, loading, error } = useAccountFundsTransfersQuery({
        variables: { accountIdOrNumber: props.accountId }
    });
    const { redrawAccount, changeAccount, gotoSelect, returnToHome } = useTransferWizard();
    const { values, isSubmitting } = useFormikContext<RedrawFormProps>();

    if (loading) {
        return <Loading />
    }

    if (error || data?.accountByIdOrNumber?.BankAccounts == null) {
        return <CError error="Could not load Redraw details" />
    }

    return (
        <div className="redraw-create-page">
            <Title
                title="New Redraw"
            />

            <FundsTransferCardsContainer>
                <FromAccountCard account={data.accountByIdOrNumber} title="From" onClick={changeAccount} />
                {redrawAccount == null ?
                    <PaymentCard
                        onClick={gotoSelect}
                        title="To"
                        name={<div><FontAwesomeIcon icon={faExclamationTriangle} /> Please select a bank account</div>}
                        details="Click here to select a bank account"
                        className="payment-create-summary-account-warning"
                    /> :

                    <PaymentCard
                        onClick={gotoSelect}
                        title="To"
                        name={redrawAccount?.AccountName}
                        details={
                            <div>
                                <div>{redrawAccount?.BankIdentifierText} {redrawAccount?.AccountNumber}</div>
                                <div>{redrawAccount?.BankName}</div>
                            </div>}
                    />
                }
            </FundsTransferCardsContainer>

            <Form>
                <Input
                    name={nameof<RedrawFormProps>("transferDescription")}
                    type="text"
                    helpText="This will appear in the account you're redrawing money from"
                    label="Description"
                    printableOnly = {true}
                />

                <Input
                    name={nameof<RedrawFormProps>("transferReference")}
                    type="text"
                    helpText="This will appear in the account you're depositing money into"
                    label="Reference"
                    printableOnly = {true}
                />

                <Input
                    name={nameof<RedrawFormProps>("amount")}
                    type="currency"
                    label="Amount"
                />

                <TransferScheduleInput
                    values={values}
                    config={{
                        minStartingDate: new Date(),
                        minUntilDate: new Date(values.startingOn),
                    }}
                />

                <FutureDatedWarningText date={values.startingOn} />

                <BankingCutOff />

                <Alert message="Please select a bank account" visible={redrawAccount == null} />

                <Button
                    role="secondary"
                    disabled={isSubmitting}
                    onClick={() => returnToHome(data.accountByIdOrNumber?.Number!)}
                    id="goBackButton"
                >
                    Go Back
                </Button>

                <Button
                    isSubmit={true}
                    loading={isSubmitting}
                    disabled={redrawAccount == null}
                    id="submit"
                >
                    Create Redraw
                </Button>
            </Form>
        </div>
    )
}

const PaymentForm = () => {
    const wizard = useTransferWizard<RedrawFormProps>();

    const initialValues: RedrawFormProps = {
        amount: undefined,
        transferDescription: "",
        endingOn: undefined,
        startingOn: new Date(),
        frequency: TaskFrequencyPeriod.Once,
        recurrenceEnd: RecurrenceEndEnum.Never,
        numberOfTimesAmount: undefined,
        transferReference: "",

        ...wizard.formState
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => wizard.createTransfer(values)}
            validationSchema={Validate(wizard.accountId)}
        >
            <Payment accountId={wizard.accountId} />
        </Formik>
    )
}

export default PaymentForm;
