import React from "react";
import {
    Route,
    withRouter,
    match as Match,
    Switch,
    Redirect,
} from "react-router-dom";
import AccountTransfers from "../accountTransfer";
import { History, Location } from "history";
import StatementsList from "../statements/StatementsList";
import { makeNonEmpty } from "core/util/array";
import { IndueCardStatus, useAccountByIdOrNumberQuery } from "generated/generated-models";
import { Loading } from "app/lib/components/Loading";
import { CError } from "app/common/CError";
import { AccountView } from "./AccountView";
import IndueCardPage from "app/cards/indue/IndueCardPage";
import IndueCardConfirmStatusChange from "app/cards/indue/IndueCardConfirmStatusChange";
import IndueCardListPage from "app/cards/indue/IndueCardListPage";
import IndueCardConfirmCreateCardPage from "app/cards/IndueCardConfirmCreateCard";
import { clientConfigFn } from "clientConfig";
import DisbursementRequestForm from "app/disbursement/DisbursementRequestForm";

type Props = {
    history: History;
    location: Location;
    match: Match<{ number: string }>;
}

const AccountPage: React.FC<Props> = ({ match, history }) => {
    const { data, loading, error } = useAccountByIdOrNumberQuery({
        variables: { idOrNumber: match.params.number },
        fetchPolicy: "network-only",
    });

    if (loading) {
        return <Loading />;
    }
    if (error) {
        return <CError error="Could not retrieve account details" />;
    }

    const account = data?.accountByIdOrNumber;

    if (account === undefined) {
        return <CError error="Could not retrieve account details" />;
    }

    const config = clientConfigFn();
    const cardsEnabled = account.CardAvailable || (account.Cards?.length ?? 0) > 0;

    return (
        <Switch>
            {config.hideTransferPage === false &&
                <Route
                    path="/account/index/:number/transfer"
                    render={({ match }) =>
                        <AccountTransfers accountIdOrNumber={match && match.params.number} />
                    }
                />
            }
            {config.allowOnLineStatements === true &&
                <Route
                    path="/account/index/:number/statements"
                    render={() =>
                        <StatementsList statements={makeNonEmpty(account.Statements)} />
                    }
                />
            }
            {cardsEnabled &&
                <Route
                    path="/account/index/:number/induecards/:cardid/activatecard"
                    render={({ match }) => {
                        const cardId = `${match.params.cardid}`;
                        return (
                            <IndueCardConfirmStatusChange
                                cardId={cardId}
                                accountNumber={match.params.number}
                                statusType={IndueCardStatus.Active} />
                        )
                    }
                    }
                />
            }
            {cardsEnabled &&
                <Route
                    path="/account/index/:number/induecards/:cardid/holdcard"
                    render={({ match }) => {
                        const cardId = `${match.params.cardid}`;
                        return (
                            <IndueCardConfirmStatusChange
                                cardId={cardId}
                                accountNumber={match.params.number}
                                statusType={IndueCardStatus.Activeoff} />
                        )
                    }
                    }
                />
            }
            {cardsEnabled && !config.hideCardPage &&
                <Route
                    path="/account/index/:number/induecards/:cardid/loststolencard"
                    render={({ match }) => {
                        const cardId = `${match.params.cardid}`;
                        return (
                            <IndueCardConfirmStatusChange
                                cardId={cardId}
                                accountNumber={match.params.number}
                                statusType={IndueCardStatus.Lost} />
                        )
                    }
                    }
                />
            }
            {cardsEnabled &&
                <Route
                    path="/account/index/:number/induecards/:cardid/closecard"
                    render={({ match }) => {
                        const cardId = `${match.params.cardid}`;
                        return (
                            <IndueCardConfirmStatusChange
                                cardId={cardId}
                                accountNumber={match.params.number}
                                statusType={IndueCardStatus.Closed} />
                        )
                    }
                    }
                />
            }
            {cardsEnabled &&
                <Route
                    path="/account/index/:number/induecards/requestcard"
                    render={() => (
                        <IndueCardConfirmCreateCardPage accountId={account.Id} />
                    )} />
            }
            {cardsEnabled &&
                <Route
                    path="/account/index/:number/induecards/:cardid"
                    render={({ match }) => {
                        const cardId = `${match.params.cardid}`;
                        return (
                            <IndueCardPage
                                accountId={account.Id}
                                accountNumber={match.params.number}
                                cardId={cardId} />
                        )
                    }
                    }
                />
            }
            {cardsEnabled &&
                <Route
                    path="/account/index/:number/induecards"
                    children={() => (
                        <IndueCardListPage
                            accountId={account.Id}
                            accountNumber={match.params.number}
                            cardAvailable={account.CardAvailable ?? false}
                        />
                    )}
                />
            }
            {config.allowDisbursements &&
                <Route
                    path="/account/index/:number/disbursement"
                    render={({ match }) => {
                        return (
                            <DisbursementRequestForm
                                AccountId={account.Id}
                                InstrumentId={account.InstrumentId}
                            />
                        );
                    }}
                />
            }
            <Route
                path="/account/index/:number"
                render={() => (
                    <AccountView accountId={account.Id} />
                )}
            />
            <Route
                path="/account/"
                children={<Redirect to={"/"} push={true} />}
            />
        </Switch>
    );
}

export const AccountPageComponent = withRouter(AccountPage);
export default AccountPageComponent;
