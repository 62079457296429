import { IsNullOrWhiteSpace } from "core/util/stringExtensions";
import { AccountFundsTransfersQuery } from "generated/generated-models";
import MoneyFormatted from "../MoneyFormatted";


type Props = {
    account: AccountFundsTransfersQuery["accountByIdOrNumber"];
    title?: string;
    onClick: () => void;
}

const FromAccountCard = ({ account, title, onClick }: Props) => {
    return (
        <div className="card-body payment-create-summary-account" onClick={onClick}>
            <h5 className="card-title">{title}</h5>
            <div className="payment-create-summary-account-details">
                <div>{IsNullOrWhiteSpace(account?.Description) ? account?.ProductType : account?.Description}</div>
                <div className="payment-create-summary-account-details-number">{account?.Number}</div>
            </div>
            <div className="payment-create-summary-account-details-available">
                <div>Available</div>
                <strong>
                    <MoneyFormatted amount={account?.Redraw} />
                </strong>
            </div>
            <div className="payment-create-summary-account-details-balance">
                <div>Balance</div>
                <div>
                    <MoneyFormatted amount={account?.CurrentBalance} />
                </div>
            </div>
        </div>
    );
}

export default FromAccountCard;
