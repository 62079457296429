import React, { useEffect, useState } from "react";
import { Loading } from "../lib/components/Loading";
import { CError } from "../common/CError";

type Props = {
    url: string,
    errorMessage: string
}

const DisplayHtmlContext = ({ url, errorMessage }: Props) => {
    const [content, setContent] = useState("");
    const [loading, setloading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    useEffect(() => {
        const getContent = async () => {
            try {
                setloading(true);
                setError(undefined);

                const result = await fetch(url)

                if (!result.ok) {
                    setloading(false);
                    setError(errorMessage);
                    return;
                }

                const text = await result.text();
                setContent(text);
                setloading(false);
            } catch {
                setloading(false);
                setError(errorMessage);
            }
        }
        getContent();
    }, [errorMessage, url]);

    if (loading) {
        return (
            <div className="htmlcontentview loading">
                <Loading />
            </div>
        )
    }

    if (error !== undefined) {
        return (
            <div className="htmlcontentview error">
                <CError error={error} />
            </div >
        )
    }

    return <div
        className="htmlcontentview content flex-fill"
        dangerouslySetInnerHTML={{ __html: content }}
    />
}

export default DisplayHtmlContext;
