
import SendIcon from '@mui/icons-material/Send';
import { Button, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import AddMessageAttachmentDialog from './AddMessageAttachmentDialog';
interface MessageTypeBarProps {
    handleSubmit: (messageBody: string) => void,
}

const MessageTypeBar = (props: MessageTypeBarProps) => {
    const [messageBody, setMessageBody] = React.useState("")

    function handleInputChange(event: React.FormEvent<HTMLInputElement| HTMLTextAreaElement>) {
        setMessageBody(event.currentTarget.value);
    }

    function handleSubmit() {
        props.handleSubmit(messageBody);
        setMessageBody("");
    }

    function handleKeyPress(event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.key === "Enter") {
            handleSubmit();
        }
    }

    return (
        <TextField
            className='typeMessageField'
            onChange={handleInputChange}
            value={messageBody}
            placeholder='Type something here'
            onKeyPress={handleKeyPress}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        {/* Hidden as per CAP-7727*/}
                        {false &&
                            <AddMessageAttachmentDialog />
                        }
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <Button variant='contained' disableElevation size='small' onClick={handleSubmit} sx={{px:1, py:2}}>
                            <SendIcon sx={{paddingRight:"5px"}} />
                            Send
                        </Button>
                    </InputAdornment>
                ),
            }}
            sx={{
                "& legend": {display: "none"},
                "& fieldset": {top: 0, border: "none"},
                "& hover fieldset": {borderColor:"grey"},
                "& focus fieldset": {borderColor:"grey"},
                "boxShadow": 2
            }}
        />
    );
}

export default MessageTypeBar;