import { clientConfigFn } from "clientConfig";
import Alert from '@mui/material/Alert';

type Props = {
    date: Date
}

const FutureDatedWarningText = ({ date }: Props) => {

    if (date > new Date() && clientConfigFn().futureDatedTransferWarning.trim() !== "") {
        return (
            <Alert severity="warning">
                {clientConfigFn().futureDatedTransferWarning}
            </Alert>
        )
    }
    else{
        return (null);
    }
}

export default FutureDatedWarningText;