import React, { Fragment } from "react";
import {
    LoginAttempt,
    useCloseOtherSessionsMutation,
    IsUserAuthenticatedQuery
} from "generated/generated-models";
import { CDate, CTime } from "app/common/CapitalDate";
import { SupportContactInformation } from "app/SupportContactInformation/SupportContactInformation";
import Alert from '@mui/material/Alert';
import SignoutIcon from '@mui/icons-material/Logout';
import Button from '@mui/material/Button';

type UserSession = IsUserAuthenticatedQuery["userSession"];

interface Props {
    userSession?: UserSession;
}

const LoginRow = ({ attempt, currentSession }: { attempt?: LoginAttempt, currentSession: boolean }) => {
    if(attempt == null) {
        return null;
    }

    const activeText = hasExpired(attempt, new Date()) ? "Closed" : "Active";

    return (
        <tr key={attempt.Id}>
            <td>
                {activeText}
                {currentSession && (
                    <><br />(This session)</>
                )}
            </td>
            <td>
                <CDate date={attempt.SessionStart} /><br /> at <CTime date={attempt.SessionStart} />
            </td>
            <td>
                <CDate date={attempt.LastActivity} /><br /> at <CTime date={attempt.LastActivity} />
            </td>
            <td>{attempt.IpAddress}</td>
            <td>{attempt.UserAgentHeader}</td>
        </tr>
    )
}

const hasExpired = (la: LoginAttempt, now: Date) => (
    la.Expires == null
    || new Date(la.Expires) < now
    || la.IsDeleted === true
);

const ActiveSessions = ({ userSession }: Props) => {
    const hasMultipleActive = hasMultipleActiveSessions(userSession);
    const [closeOtherSessions, data] = useCloseOtherSessionsMutation();
    const { called, loading, error } = data;

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const handleCloseOtherSessions = () => {
        closeOtherSessions({ variables: { input: { CloseCurrentSession: false } } });
        scrollToTop();
    }

    return (
        <section className="container sub-wrap login-history">
            <div className="session-header">
                <h1>Login History</h1>
                <div className="quick-actions">
                    <Button onClick={() => handleCloseOtherSessions()} variant="contained" sx={{ p: 1.5, mt: 1 }} id="closeOtherSessionsButton">
                        Close other Sessions
                    </Button>
                    <Button href={`/Security/Logout`} variant="contained" sx={{ p: 1.5, mt: 1 }} startIcon={<SignoutIcon />} id="logOutButton">
                        Log Out
                    </Button>
                </div>
            </div>
            {called && loading && (
                 <Alert severity="warning">Closing other sessions</Alert>
            )}
            {called && error && (
                <Alert severity="error">
                    Could not close other sessions. Please logout and try again{" "}
                    <a href="/Security/Logout" className="btn btn-success">
                        Log Out
                    </a>
                </Alert>
            )}
            {called && !loading && !error && (
                <Alert severity="success">
                    Other Sessions closed. <a href="/">Return Home</a>
                </Alert>
            )}

            {hasMultipleActive && (
                <Fragment>
                    <Alert severity="warning">
                        There is already another active session for the credentials
                        you supplied.
                    </Alert>
                </Fragment>
            )}
            <div>
                <h3>What does this mean?</h3>
                <p>
                    There are a couple of harmless reasons you may be seeing this
                    message:
                </p>
                <ul>
                    <li>
                        You have logged back into web banking after closing your
                        browser, without correctly logging off; or,
                    </li>
                    <li>
                        Your spouse, partner or another authorised operator may be
                        logged in at the same time with your credentials.
                    </li>
                </ul>
                <p>
                    If you have confirmed that neither of these are the cause, please
                    make a note of the IP address of the 'Last Login Location' (this can be found
                    in user profile by clicking the 'User' icon in the top-right hand corner of the application) then <SupportContactInformation />
                </p>
            </div>
            <div>
                <h3>What can I do?</h3>
                <p>You have several options:</p>
                <ul>
                    <li>
                        Make sure you always log off using the 'Log Out' link in the left hand menu bar.
                    </li>
                    <li>
                        Do not share your credentials with anyone, including your spouse
                        or partner. If you require they have access then you can contact
                        support and have them added as an authority and they will
                        receive their own credentials.
                    </li>
                    <li>
                        You can{" "}
                        <button className="inline-text-btn" onClick={() => handleCloseOtherSessions()}>
                            Close other sessions
                        </button>
                        {" "}then continue with your login, or you can{" "}
                        <a href="/Security/Logout" className="inline-text-btn">
                            Log Out
                        </a>{" "}
                            and try again later.
                        </li>
                </ul>
                <p>
                    All activity on this site is tracked, so if you believe this login
                    to be suspicious, please report it.
                </p>
                <SupportContactInformation />
            </div>
            <div>
                <table className="table session-list">
                    <thead>
                        <tr>
                            <th>Active</th>
                            <th>Started</th>
                            <th>Last Activity</th>
                            <th>IP Address</th>
                            <th>Browser Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userSession?.LoginAttempts?.map(x => 
                            <LoginRow attempt={x} currentSession={x === userSession.CurrentSession}/>
                        )}
                    </tbody>
                </table>
            </div>
        </section>
    );
};

export function hasMultipleActiveSessions(
    userSession: UserSession | undefined
) {
    const currentTime = new Date();
    const currentSessionId = userSession?.CurrentSession?.Id;
    const hasMultipleActive = userSession?.LoginAttempts?.some(
        la =>
            la != null
            && la.Id !== currentSessionId
            && !hasExpired(la, currentTime)
    );
    return hasMultipleActive;
}

export default ActiveSessions;
