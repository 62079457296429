import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CDate } from "app/common/CapitalDate";
import MoneySigned from "app/components/MoneySigned";
import TransactionPageIconMoney from "app/components/TransactionPageIconMoney";
import { IsNullOrWhiteSpace } from "core/util/stringExtensions";
import { AccountTransaction } from "generated/generated-models";
import DetailViewDashboard from "./DetailViewDashboard";

import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PaidIcon from '@mui/icons-material/Paid';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

const TransactionDescription = ({ transaction }: { transaction: AccountTransaction }) => {
    if (!transaction.IsPending) {
        return null;
    }
    return <>
        <FontAwesomeIcon icon={faClock} /> PENDING
    </>
}

// WILL BE USED LATER WHEN FUNCTIONALITY HAS BEEN FURTHER DEVELOPED 
// const ExpandDetailView = ({ currentDetail, setDetailView, transId }: { transId: string, currentDetail?: string, setDetailView: (transId?: string) => void }) => {
//     const { BETA_showDetailView } = clientConfigFn();
//     if(!BETA_showDetailView) {
//         return null;
//     }
//     const handleClick = () => {
//         if (transId === currentDetail) {
//             setDetailView(undefined);
//         } else {
//             setDetailView(transId);
//         }
//     }
//     if (transId === currentDetail) {
//         return <FontAwesomeIcon icon={faChevronUp} onClick={handleClick} style={{ cursor: "pointer" }} />
//     } else {
//         return <FontAwesomeIcon icon={faChevronDown} onClick={handleClick} style={{ cursor: "pointer" }} />
//     }
// }

type Props = {
    trans?: AccountTransaction | null;
    detailView?: string;
    setDetailView: (id?: string) => void;
}

const TransactionRowDashboard = ({ trans, detailView, setDetailView }: Props) => {
    if (!trans) {
        return <></>;
    }

    return (
        <>
        <Accordion disabled disableGutters className="dash-accordion accordion-parent">
             <AccordionSummary className="accordion-summary-parent"
                expandIcon={<ExpandMoreIcon />}
             >
                <Grid container spacing={2}>
                    <Grid item xs={1} className="trans-icon">
                        {trans.TransactionType === "Direct Debit Repayment"
                            ? <CurrencyExchangeIcon className="dd-icon"/>
                            : trans.TransactionType === "Direct Credit Payment"
                                ? <CurrencyExchangeIcon className="dd-icon"/>
                                : <PaidIcon className="fee-icon"/>
                        }
                    </Grid>
                    <Grid item xs={7} className="trans-details">
                        <div className="ra-trans-date"><CDate date={trans.Effective}/></div>
                        <div className="ra-trans-type">{trans.TransactionType}</div>
                        <TransactionDescription transaction={trans} />
                        {!IsNullOrWhiteSpace(trans.Note) &&
                            <div className="ra-trans-note">{trans.Note}</div>
                        }
                    </Grid>
                    <Grid item xs={4} className="trans-balances">
                        <p className="ra-trans-debit"><TransactionPageIconMoney amount={trans.Increase} direction="debit" isShowZero={false} /></p>
                        <p className="ra-trans-credit"><TransactionPageIconMoney amount={trans.Decrease} direction="credit" isShowZero={false} /></p>
                        <p className="ra-trans-balance"><MoneySigned amount={trans.Balance} direction="total" /></p>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <DetailViewDashboard trans={trans} />
            </AccordionDetails>
        </Accordion>
        {detailView === trans.Id &&
            <DetailViewDashboard trans={trans} />
        }
    </>
    );
};

export default TransactionRowDashboard;
