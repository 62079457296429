import { useField } from "formik";
import React from "react";
import RequestMultiFactorToken from "./RequestMultiFactorToken";
import { useIsUserAuthenticatedQuery } from "generated/generated-models"

type Props = {
    /** Formik name */
    name: string;
    /** Whether the request new token button is displayed */
    showRequestNewToken?: boolean;
    /** Whether a token is automatically requested when this component mounts */
    requestMfaOnDisplay?: boolean;
    /** Whether the component should be displayed */
    visible?: boolean;
}

const RequestMultiFactorTokenFormik = (props: Props) => {
    const [, meta, helpers] = useField(props)
    const { data: authenticatedData } = useIsUserAuthenticatedQuery();

    const isUsingGoogleAuth = authenticatedData?.userSession?.IsUsingGoogleAuth || false;

    const getError = () => {
        if(meta.touched && meta.error != null && meta.error !== "") {
            return meta.error;
        } else {
            return undefined;
        }
    }

    const handleTokenUpdate = (token: string) => {
        helpers.setValue(token);
    }

    return (
        <RequestMultiFactorToken
            getToken={handleTokenUpdate}
            errorMessage={getError()}
            showRequestNewToken={props.showRequestNewToken}
            requestMfaOnDisplay={props.requestMfaOnDisplay}
            visible={props.visible}
            isUsingGoogleAuth={isUsingGoogleAuth}
        />
    )
}

export default RequestMultiFactorTokenFormik;
