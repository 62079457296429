import { useField } from "formik";
import React from "react";
import { useButtonGroup } from "./useButtonGroup";
import classnames from "classnames";

export type Props = {
    /** CSS class names */
    className?: string;
    /** toggle the field to disabled */
    disabled?: boolean;
    /** Value of the button */
    value: string;
}

const ButtonGroupItem: React.FC<Props> = (props) => {
    const context = useButtonGroup();
    const [field] = useField(context.name);

    const fieldId = `${context.name}-${props.value}`;

    let className = classnames([
        "btn button-group-btn-item",
        {"active": field.value === props.value},
        props.className
    ]);

    return (
        <label className={className}>
            <input
                {...field}
                type="radio"
                id={fieldId}
                autoComplete="off"
                value={props.value}
                disabled={props.disabled}
            />
            {props.children}
        </label>
    )
}

export default ButtonGroupItem;
