import React from "react";
import { FormattedNumber, IntlProvider } from "react-intl";
import { clientConfigFn } from "clientConfig";
import classNames from "classnames";

type Props = {
    amount?: number,
    addColour?: boolean,
    className?: string
}

const MoneyFormatted = ({ className, amount = 0, addColour = false }: Props) => {
    const classes = classNames([
        className,
        "money",
    ])

    return (
        <IntlProvider locale={clientConfigFn().currencyLocale}>
            <span className={classes}>
                <FormattedNumber
                    value={amount}
                    // eslint-disable-next-line
                    style="currency"
                    currency={clientConfigFn().currencyFormat}
                    minimumFractionDigits={2}
                />
            </span>
        </IntlProvider>
    );
};

export default MoneyFormatted;
