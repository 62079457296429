import React from "react";
import { useIndueCardDetailsQuery, IndueCardStatus, IndueCard } from "generated/generated-models";
import { Loading } from "app/lib/components/Loading";
import { CError } from "app/common/CError";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Dialog, ListItemButton, Typography } from "@mui/material";
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PasswordIcon from '@mui/icons-material/Password';
import LockIcon from '@mui/icons-material/Lock';
import SupportIcon from '@mui/icons-material/Support';
import { clientConfigFn } from "clientConfig";
import { IndueCardResetPinPage } from "./IndueCardResetPinPage";
import IndueCardConfirmStatusChange from "./IndueCardConfirmStatusChange";
import { useParams } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';

type Props = {
    cardId?: string;
    indueCardProps?: IndueCard;
}

const statusToText = (status: IndueCardStatus) => {
    switch (status) {
        case IndueCardStatus.Active:
            return "Active";
        case IndueCardStatus.Activeoff:
            return "On Hold";
        case IndueCardStatus.Cardcreated:
            return "Created";
        case IndueCardStatus.Closed:
            return "Closed";
        case IndueCardStatus.Lost:
            return "Lost";
        case IndueCardStatus.Ordered:
            return "Ordered";
        case IndueCardStatus.Other:
            return "Other";
        case IndueCardStatus.Restricted:
            return "Restricted";
        case IndueCardStatus.Stolen:
            return "Stolen";
        case IndueCardStatus.Damaged:
            return "Damaged";
    }
    return true
}

const IndueCardDetailsPage: React.FC<Props> = ({ cardId }) => {
    const { data, loading, error } = useIndueCardDetailsQuery({
        variables: { input: { CardId: cardId } },
        fetchPolicy: "network-only",
    });
    const card = data?.indueCardDetails;
    const { number }: { number: string } = useParams();
    const [value, setValue] = React.useState("CardDetails");
    const [openHoldCard, setOpenHoldCard] = React.useState(false);
    const [openActivateCard, setOpenActivateCard] = React.useState(false);
    const [openChangePin, setOpenChangePin] = React.useState(false);
    const [openCloseCard, setOpenCloseCard] = React.useState(false);
    const [openBlockCard, setOpenBlockCard] = React.useState(false);
    const fullScreen = useMediaQuery("(max-width: 768px)");

    const handleTabChange = (event_: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
    };  
    const handleClickHoldCard = () => {
        setOpenHoldCard(true);
    };
    const handleCloseHoldCard = () => {
        setOpenHoldCard(false);
    };
    const handleClickActivateCard = () => {
        setOpenActivateCard(true);
    };
    const handleCloseActivateCard = () => {
        setOpenActivateCard(false);
    };
    const handleClickChangePin = () => {
        setOpenChangePin(true);
    };
    const handleCloseChangePin = () => {
        setOpenChangePin(false);
    };
    const handleClickCloseCard = () => {
        setOpenCloseCard(true);
    };
    const handleCloseCard = () => {
        setOpenCloseCard(false);
    };
    const handleClickBlockCard = () => {
        setOpenBlockCard(true);
    };
    const handleCloseBlockCard = () => {
        setOpenBlockCard(false);
    };

    if (loading) {
        return <Loading />;
    }
    if (error || card == null) {
        return <CError error="Could not retrieve card details" />;
    }

    const lastFourDigits = card.CardNumber?.substring(card.CardNumber?.length - 4) ?? "";
    const maskedCard = "•• " + lastFourDigits;

    return (
        <div className="cardDetailsContainer">
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange}>
                    <Tab label="Card Details" value="CardDetails" />
                </TabList>
                </Box>
                <TabPanel value="CardDetails" sx={{ px: 0, py: 3 }}>
                    <h4>Settings &amp; Info</h4>
                    <List className="cardOptions containerListOptions" sx={{ width: '100%'}}>
                        {(card.CanHold) &&
                            <ListItem disablePadding onClick={handleClickHoldCard} id="holdCardButton">
                                <ListItemButton>
                                    <ListItemIcon>
                                        <CreditCardIcon />
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary="Hold card" 
                                        secondary={<Typography className={`cardSetting status-${card.Status}`}>Card is {statusToText(card.Status)} ({maskedCard})</Typography>}
                                    />
                                    <KeyboardArrowRightIcon />
                                </ListItemButton>
                            </ListItem>
                        }
                        {(card?.CanActivate) &&
                            <ListItem disablePadding onClick={handleClickActivateCard} id="activateCardButton">
                                <ListItemButton>
                                    <ListItemIcon>
                                        <CreditCardIcon />
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary="Activate card" 
                                        secondary={<Typography className={`cardSetting status-${card.Status}`}>Card is {statusToText(card.Status)} ({maskedCard})</Typography>}
                                    />
                                    <KeyboardArrowRightIcon />
                                </ListItemButton>
                            </ListItem>
                        }
                        <ListItem disablePadding onClick={handleClickChangePin} id="changePinButton">
                            <ListItemButton>
                                <ListItemIcon>
                                    <PasswordIcon />
                                </ListItemIcon>
                                <ListItemText primary="Change PIN" secondary="Set a new PIN" />
                                <KeyboardArrowRightIcon />
                            </ListItemButton>
                        </ListItem>
                        {card?.CanClose
                            ? <ListItem disablePadding onClick={handleClickCloseCard} id="closeCardButton">
                                <ListItemButton>
                                    <ListItemIcon>
                                        <DeleteIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Close card" secondary="Request to close this card" />
                                    <KeyboardArrowRightIcon />
                                </ListItemButton>
                            </ListItem>
                            : <ListItem sx={{ px: 2, py: 0.4 }} className="cardClosed">
                                    <ListItemIcon>
                                        <DeleteIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Card Closed" secondary="This card has been permanently closed." />
                            </ListItem>
                        }
                    </List>

                    <h4>Card Issues</h4>
                    <List className="cardOptions containerListOptions" sx={{ width: '100%'}}>
                        {(card?.CanReportLostStolen) &&
                            <ListItem disablePadding onClick={handleClickBlockCard} id="blockCardButton">
                                <ListItemButton>
                                    <ListItemIcon>
                                        <LockIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography>Block card</Typography>} secondary="Report card as lost or stolen" />
                                    <KeyboardArrowRightIcon />
                                </ListItemButton>
                            </ListItem>
                        }
                        <ListItem sx={{ px: 2.2, py: 0.2}}>
                            <ListItemIcon>
                                <SupportIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Contact Support" 
                                secondary={<>
                                    {clientConfigFn().supportEmailAddress && !clientConfigFn().supportPhoneNumber && <span><a href={'mailto:' + clientConfigFn().supportEmailAddress}> {clientConfigFn().supportEmailAddress}</a></span>}
                                    {clientConfigFn().supportPhoneNumber && !clientConfigFn().supportEmailAddress && <span>{clientConfigFn().supportPhoneNumber}</span>}
                                    {clientConfigFn().supportEmailAddress && clientConfigFn().supportPhoneNumber && <p className="contactSupport"><span className="emailSupport"><a href={'mailto:' + clientConfigFn().supportEmailAddress}> {clientConfigFn().supportEmailAddress}</a></span><span>{clientConfigFn().supportPhoneNumber}</span></p>}
                                </>} 
                            />
                        </ListItem>
                    </List>
                </TabPanel>
            </TabContext>

            <Dialog open={openHoldCard} onClose={handleCloseHoldCard} fullScreen={fullScreen} fullWidth>
                <DialogContent sx={{ m: 4 }}>
                    <DialogContentText>
                        <IndueCardConfirmStatusChange
                            cardId={card.Id}
                            accountNumber={number}
                            statusType={IndueCardStatus.Activeoff} />
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Dialog open={openActivateCard} onClose={handleCloseActivateCard} fullScreen={fullScreen} fullWidth>
                <DialogContent sx={{ m: 4 }}>
                    <DialogContentText>
                        <IndueCardConfirmStatusChange
                            cardId={card.Id}
                            accountNumber={number}
                            statusType={IndueCardStatus.Active} />
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Dialog open={openChangePin} onClose={handleCloseChangePin} fullScreen={fullScreen} fullWidth>
                <DialogContent sx={{ m: 4 }}>
                    <DialogContentText>
                        <IndueCardResetPinPage cardId={card.Id} handleClose={handleCloseChangePin}/>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Dialog open={openCloseCard} onClose={handleCloseCard} fullScreen={fullScreen} fullWidth>
                <DialogContent sx={{ m: 4 }}>
                    <DialogContentText>
                        <IndueCardConfirmStatusChange
                            cardId={card.Id}
                            accountNumber={number}
                            statusType={IndueCardStatus.Closed} />
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Dialog open={openBlockCard} onClose={handleCloseBlockCard} fullScreen={fullScreen} fullWidth>
                <DialogContent sx={{ m: 4 }}>
                    <DialogContentText>
                        <IndueCardConfirmStatusChange
                            cardId={card.Id}
                            accountNumber={number}
                            statusType={IndueCardStatus.Lost} />
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default IndueCardDetailsPage;
