import { CError } from "app/common/CError";
import { Loading } from "app/lib/components/Loading";
import { useFinanceSummaryQueryQuery } from "generated/generated-models";
import React from "react";
import DueOnListItems from "../utilities/list/DueOnListItems";
import { useTransferWizard } from "../utilities/TransferWizard";
import { InterAccountFormProps } from "./CreateInterAccount";
import MoneyFormatted from "app/components/MoneyFormatted";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import useMediaQuery from '@mui/material/useMediaQuery';

const InterAccountReceipt = () => {
    const { data, loading, error } = useFinanceSummaryQueryQuery();
    const { formState, accountId: fromAccountId, interAccountToAccount, returnToHome } = useTransferWizard<InterAccountFormProps>();
    const fullScreen = useMediaQuery("(max-width: 768px)");
    const [, setOpen] = React.useState(false);

    const handleClose = () => {
      setOpen(false);
    };

    if (loading === true) {
        return <Loading />
    }
    const accounts = data?.financeSummary?.Instruments
        ?.flatMap(instrument => instrument?.Accounts);

    const fromAccount = accounts
        ?.find(account => account?.Id === fromAccountId);

    const toAccount = accounts
        ?.find(account => account?.Id === interAccountToAccount?.Id);

    if (fromAccount == null || toAccount == null || error != null) {
        return <CError error="Could not load payment details. Please try again." />
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={true}
            className="success-transfer-payment"
            onClose={handleClose}
        >
            <DialogTitle>
                <ReceiptLongIcon />
            </DialogTitle>
            <DialogContent sx={{ m: 4, mt: 0 }}>
                <DialogContentText>
                <Typography variant="body1" gutterBottom component="p" sx={{ textAlign:"center" }} className="transfer-amount">
                    <MoneyFormatted amount={formState.amount} />
                    <span className="caption">Paid successfully to {toAccount.ProductType}</span>
                </Typography >
                <List sx={{ mb: 2 }} className="transfer-details">
                    <ListItem disablePadding>
                        <ListItemText primary={<Typography><span className="subtitle">From: </span>{fromAccount.ProductType} - {fromAccount.Number || ""}</Typography>} />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary={<Typography><span className="subtitle">To: </span>{toAccount.ProductType} - {toAccount?.Number}</Typography>} />
                    </ListItem>
                    <DueOnListItems
                        recurrence={formState.recurrenceEnd || ""}
                        paymentDate={formState.startingOn}
                        frequency={formState.frequency || ""}
                        endDate={formState.endingOn}
                        repeats={formState.numberOfTimesAmount}
                    />
                    <ListItem disablePadding>
                        <ListItemText primary={<Typography><span className="subtitle">Description: </span> {formState.transferDescription}</Typography>} />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary={<Typography><span className="subtitle">Reference: </span> {formState.transferReference}</Typography>} />
                    </ListItem>
                </List>

                    <div className="button-row">
                        <Button
                            fullWidth
                            type="submit" id="submit"
                            sx={{ p: 1.5, mt: 1 }}
                            onClick={() => returnToHome(fromAccount.Number!)}
                            variant="contained"
                        >
                            Done
                        </Button>
                    </div>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default InterAccountReceipt;
