import { CError } from "app/common/CError";
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import Title from "app/components/Title";
import { AccountLimit, usePartyPaymentLimitMutation } from "generated/generated-models";
import Datepicker from "app/components/DatePicker";
import { useHistory } from "react-router";
import { Form, Formik } from "formik";
import Input from "app/components/Input";
import { nameof } from "core/util/nameof";
import { ChangeEvent, useState } from "react";
import { Validate } from "./EditPartyLimitsValidate";
import RequestMultiFactorTokenFormik from "app/components/RequestMultiFactorTokenFormik";
import TimePicker from "app/components/TimePicker";
import { ApolloError } from "@apollo/client";
import { Loading } from "app/lib/components/Loading";
import Alert from '@mui/material/Alert';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { clientConfigFn } from "clientConfig";

export type EditPartyLimitsInput = {
    PayAnyoneLimit: number;
    RedrawLimit: number;
    BpayLimit: number;
    EffectiveTo?: Date;
    TwoFactorToken?: string;
}

interface Props {
    partyLimits?: AccountLimit;
    loading?: boolean;
    error?: ApolloError
}

type EditLimitsMFAProps =
    | "WithoutMFA"
    | "WithMFA"

const EditPartyLimitsPage = ({ partyLimits, loading, error }: Props) => {
    const [editLimit, { error: editLimitError, data: editLimitData}] = usePartyPaymentLimitMutation();
    const history = useHistory();
    const date = new Date();
    const [checked, setChecked] = useState(false);
    const [time, setTime] = useState("00:00");
    const [editLimits, setEditLimits] = useState<EditLimitsMFAProps>("WithoutMFA");

    const handleRequiresMFA = () => {
        setEditLimits("WithMFA")
    };

    if(loading) {
        return <Loading />
    }
    if (error != null || partyLimits == null) {
        return <CError error="Could not load party limits" />
    }

    const handleEditLimitsSubmit = async (values: EditPartyLimitsInput) => {
        var effective = undefined;
        if(checked)
        {
            effective = values.EffectiveTo;
            var splitTime = time.split(":")
            const times = splitTime.map(i => parseInt(i, 10));
            effective?.setHours(times[0], times[1]);
        }
        const result = await editLimit({
            variables: {
                input: {
                    PayAnyoneLimit: values.PayAnyoneLimit,
                    RedrawLimit: values.RedrawLimit,
                    BpayLimit: values.BpayLimit,
                    EffectiveTo: effective,
                    TwoFactorToken: values.TwoFactorToken
                }
            },
        });

        if (result.data?.setPartyPaymentLimit?.Code === "499") {
            handleRequiresMFA();
        }

        if (result.data?.setPartyPaymentLimit?.Success) {
            window.location.href = '/Security/Limits'
        }
    }

    const handleCheckChange = () => {
        setChecked(!checked);
    }

    const timeChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTime(e.currentTarget.value);
    }

    const initialValues: EditPartyLimitsInput = {
        PayAnyoneLimit: partyLimits?.DefaultPayAnyoneLimit?.Amount || 0,
        RedrawLimit: partyLimits?.DefaultRedrawLimit?.Amount || 0,
        BpayLimit: partyLimits?.DefaultBPayLimit?.Amount || 0,
        EffectiveTo: undefined
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleEditLimitsSubmit(values)}
            validationSchema={() => Validate(editLimits === "WithMFA", checked)}
        >
        {({ isSubmitting }) =>
        <div className="sub-wrap form-wrap">
            <Title title="Edit Daily Transfer Limits" />
            <Form>
                <Input
                    name={nameof<EditPartyLimitsInput>("PayAnyoneLimit")}
                    type="currency"
                    label="Pay Anyone Limit:"
                    placeHolder={initialValues.PayAnyoneLimit.toString()}
                />

                <Input
                    name={nameof<EditPartyLimitsInput>("RedrawLimit")}
                    type="currency"
                    label="Redraw Limit:"
                    placeHolder={initialValues.RedrawLimit.toString()}
                />
                {clientConfigFn().showManageBPAYBillers &&
                    <Input
                        name={nameof<EditPartyLimitsInput>("BpayLimit")}
                        type="currency"
                        label="BPay Limit:"
                        placeHolder={initialValues.BpayLimit.toString()}
                    />
                }
                <FormGroup>
                    <FormControlLabel sx={{mb:3}} control={<Checkbox onChange={handleCheckChange} />} label="Create Temporary Limits" />
                </FormGroup>
                
                {checked &&
                    <Box className="temp-details" sx={{ mb:12 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Datepicker
                                    name={nameof<EditPartyLimitsInput>("EffectiveTo")}
                                    label="Effective Until:"
                                    minDate={date}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TimePicker
                                    time={time}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => timeChange(e)} />
                            </Grid>
                        </Grid>
                    </Box>
                }
                <RequestMultiFactorTokenFormik
                    name={"TwoFactorToken"}
                    showRequestNewToken={true}
                    requestMfaOnDisplay={true}
                    visible={editLimits === "WithMFA"}
                />
                {(editLimitError || editLimitData?.setPartyPaymentLimit?.Success === false) &&
                    <Alert severity="error">
                            {editLimitData?.setPartyPaymentLimit?.Message}
                    </Alert>
                }

                <div className="form-group button-row">
                    <Button 
                        id="cancel"
                        variant="contained" 
                        className="btn-cancel"
                        onClick={() => history.goBack()}
                    >
                        Cancel
                    </Button>
                    
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        loading={isSubmitting}
                        loadingPosition="end"
                    >
                        Save
                    </LoadingButton>
                </div>
            </Form>
        </div>
        }
        </Formik>
    )
}

export default EditPartyLimitsPage;