import * as React from "react";
import { IsNullOrWhiteSpace } from 'core/util/stringExtensions';
import { Link, NavLink, useRouteMatch } from "react-router-dom";
import { useIsUserAuthenticatedQuery, useUnreadMessagesCountByPartyQuery} from "generated/generated-models";
import { clientConfigFn } from 'clientConfig';
import TermsAndConditions from "../../other/TermsAndConditions";
import PrivacyPolicy from "../../other/PrivacyPolicy";
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import AccountIcon from '@mui/icons-material/AccountBalance';
import CloseIcon from '@mui/icons-material/Close';
import SignoutIcon from '@mui/icons-material/Logout';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import MailIcon from '@mui/icons-material/Mail';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import BadgeIcon from '@mui/icons-material/Badge';
import FeedIcon from '@mui/icons-material/Feed';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import SanitisedLogo from "app/components/SanitisedLogo";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Badge } from "@mui/material";

const drawerWidth = 260;
const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(6)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer)(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const NavbarMini = () => {
    const accountMatch = useRouteMatch<{ accountId: string }>("/Account/Index/:accountId/");
    const { data: userSessionData, error: userSessionError, loading: userSessionLoading } = useIsUserAuthenticatedQuery({
        partialRefetch: true,
        notifyOnNetworkStatusChange: true,
        errorPolicy: "all"
    });

    const { data: unreadMessagesCountByParty} = useUnreadMessagesCountByPartyQuery({
        partialRefetch: true,
        notifyOnNetworkStatusChange: true,
        errorPolicy: "all"
        });

    const { hideTransferPage, allowOnLineStatements } = clientConfigFn();
    const loggedIn = userSessionData && !userSessionError && !userSessionLoading;
    const clientConfig = clientConfigFn();
    const fullScreen = useMediaQuery("(max-width: 768px)");
    const [scroll] = React.useState<DialogProps['scroll']>('paper');

    const [open, setOpenNav] = React.useState(false);
    const handleDrawerOpenNav = () => {
        setOpenNav(true);
    };
    const handleDrawerCloseNav = () => {
        setOpenNav(false);
    };

    const [openHelp, setOpenHelp] = React.useState(false);
    const handleClickHelp = () => {
      setOpenHelp(!openHelp);
    };
    const [openTerms, setOpenTerms] = React.useState(false);
    const handleClickOpenTerms = () => {
        setOpenTerms(true);
    };
    const handleCloseTerms = () => {
        setOpenTerms(false);
    };
    const [openPrivacy, setOpenPrivacy] = React.useState(false);
    const handleClickOpenPrivacy = () => {
        setOpenPrivacy(true);
    };
    const handleClosePrivacy = () => {
        setOpenPrivacy(false);
    };
    const [openSupport, setOpenSupport] = React.useState(false);
    const handleClickOpenSupport = () => {
        setOpenSupport(true);
    };
    const handleCloseSupport = () => {
        setOpenSupport(false);
    };

    const settings = ['Message Title 1', 'New Message', 'System Alert', 'Capital Upgrade'];
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };

    return (
        <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} className="appbar">
        <Toolbar>
            {loggedIn &&
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpenNav}
                    edge="start"
                    sx={{
                        marginLeft: '-7px',
                        ...(open && { display: 'none' }),
                    }}
                    id="openDrawerButton">
                    <MenuIcon />
                </IconButton>
            }
            {!IsNullOrWhiteSpace(clientConfig.logoImageVariantUri) &&
                <div className="flex-fill logo-wrapper">
                    <SanitisedLogo 
                        logoUri={clientConfig.logoImageVariantUri ? clientConfig.logoImageVariantUri : clientConfig.logoImageUri}
                        siteTitle={clientConfig.siteTitle}
                    />
                </div>
            }
            {loggedIn && clientConfigFn().displaySecureMessagePage &&
                <IconButton 
                    id="secure-messaging-button"
                    color="inherit"
                    component={Link}
                    to={`/Security/SecureMessaging`}
                    edge="end"
                    sx={{ mr: 1 }}
                >
                    <Badge badgeContent={unreadMessagesCountByParty?.secureMessagesByParty?.UnreadMessageCount ?? 0} color="error">
                        <MailIcon />
                    </Badge>
                </IconButton>
            }
            {loggedIn &&
                <IconButton
                    color="inherit"
                    component={Link}
                    to={`/Security/ProfileSettings`}
                    edge="end"
                    id="openProfileSettings"
                >
                    <AccountCircle />
                </IconButton>
            }
            </Toolbar>
        </AppBar>
        <Drawer 
            className="nav-drawer"
            variant={"permanent"} open={open} anchor="left"
            >
            <DrawerHeader>
                <IconButton onClick={handleDrawerCloseNav} id="closeDrawerButton">
                    <ChevronLeftIcon />
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List className="nav-drawer-container">
                {!loggedIn && (window.location.pathname !== "/security/login") &&
                    <NavLink to="/" activeClassName="active" id="homeButton">
                        <ListItem button>
                            <ListItemIcon><HomeIcon /></ListItemIcon>
                            <ListItemText primary='Home' />
                        </ListItem>
                    </NavLink>
                }
                {loggedIn &&
                    <NavLink to="/" title="Home" exact activeClassName="active" id="homeButton">
                        <ListItem button>
                            <ListItemIcon><HomeIcon /></ListItemIcon>
                            <ListItemText primary='Home'/>
                        </ListItem>
                    </NavLink>
                }
                {loggedIn &&
                    <NavLink to={`/account/accountlist`} title="Accounts" exact activeClassName="active" id="accountsButton">
                        <ListItem button>
                            <ListItemIcon><AccountIcon /></ListItemIcon>
                            <ListItemText primary='Accounts'/>
                        </ListItem>
                    </NavLink>
                }    
                {accountMatch &&
                <div className="drawer-subnav">
                    <NavLink to={`${accountMatch.url}`} title={`Account - ${accountMatch.params.accountId}`} activeClassName="active" id="accountButton">
                        <ListItem button>
                            <ListItemIcon><BadgeIcon /></ListItemIcon>
                            <ListItemText primary='Account' secondary={`${accountMatch.params.accountId}`}/>
                        </ListItem>
                    </NavLink>
                     {/* <Collapse in={openSub} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <>
                                {accountMatch && !hideTransferPage &&
                                    <NavLink to={`${accountMatch.url}/Transfer`} title="Scheduled Transfers" exact activeClassName="active">
                                        <ListItem button>
                                            <ListItemIcon><ScheduleSendIcon /></ListItemIcon>
                                            <ListItemText primary="Scheduled Transfers" />
                                        </ListItem>
                                    </NavLink>
                                }
                                {allowDisbursements &&
                                    <NavLink to={`${accountMatch.url}/disbursement`} title="Request Payment" exact activeClassName="active">
                                    <ListItem button>
                                        <ListItemIcon><RequestQuoteIcon /></ListItemIcon>
                                        <ListItemText primary="Request Payment" />
                                    </ListItem>
                                    </NavLink>
                                }
                            </>
                            {accountMatch?.params.accountId &&
                                <CardNavLink accountNumber={accountMatch.params.accountId} url={accountMatch.url} />
                            }
                        </List>
                    </Collapse> */}
                </div>
                }
                {loggedIn && !hideTransferPage &&
                    <NavLink to={"/transfersAndPayments"} title="Transfers &amp; Payments" exact activeClassName="active" id="transfersAndPaymentsPageButton">
                        <ListItem button>
                            <ListItemIcon><CurrencyExchangeIcon /></ListItemIcon>
                            <ListItemText primary={`Transfers & Payments`} />
                        </ListItem>
                    </NavLink>
                }
                {loggedIn && allowOnLineStatements &&
                    <NavLink to={`/statements/StatementsListForParty`} title="All Statements" exact activeClassName="active" id="statementsPageButton">
                        <ListItem button>
                            <ListItemIcon><FeedIcon /></ListItemIcon>
                            <ListItemText primary='All Statements' />
                        </ListItem>
                    </NavLink>
                }
                {loggedIn &&
                    <NavLink to={"/transaction/TransactionsListForParty"} title="All Transactions" exact activeClassName="active" id="transactionsPageButton">
                        <ListItem button>
                            <ListItemIcon><CompareArrowsIcon /></ListItemIcon>
                            <ListItemText primary='All Transactions' />
                        </ListItem>
                    </NavLink>
                }
            </List>
            <List className="nav-help">
                <div>
                    <ListItemButton onClick={handleClickHelp} title="Help" id="helpButton">
                        <ListItemIcon><SupportOutlinedIcon /></ListItemIcon>
                        <ListItemText primary='Help'/>
                        {openHelp ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                     <Collapse in={openHelp} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {clientConfig.supportEmailAddress &&
                                 <>
                                <ListItemButton onClick={handleClickOpenSupport} className="contact-support" id="openSupportButton" title={`Contact Support - ${clientConfig.supportEmailAddress ? clientConfig.supportEmailAddress : ''} ${clientConfig.supportPhoneNumber ? ` | ` + clientConfig.supportPhoneNumber : ''}`}>
                                    <ListItemIcon><HelpOutlineIcon /></ListItemIcon>
                                    <ListItemText primary='Contact Us' />
                                  </ListItemButton>
                                <Dialog
                                    className="support-dialog"
                                    open={openSupport}
                                    onClose={handleCloseSupport}
                                    fullWidth={true}
                                    maxWidth={'sm'}
                                    scroll={scroll}
                                    fullScreen={fullScreen}
                                >
                                    <DialogTitle sx={{ m: 0, p: 2 }}>
                                        <IconButton
                                        id="closeSupportButton"
                                        color="inherit"
                                        onClick={handleCloseSupport}
                                        aria-label="close"
                                        sx={{
                                            position: 'absolute',
                                            right: 14,
                                            top: 12,
                                        }}
                                        >
                                        <CloseIcon />
                                        </IconButton>
                                    </DialogTitle>
                                    <DialogContent>
                                        <div className="dialog-details-container">
                                            <h2>Need support?</h2>
                                            <p>If you need assistance, you can contact our support team anytime by
                                            {clientConfig.supportEmailAddress && !clientConfig.supportPhoneNumber && <> emailing <a href={'mailto:' + clientConfig.supportEmailAddress}> {clientConfig.supportEmailAddress}</a>.</>}
                                            {clientConfig.supportPhoneNumber && !clientConfig.supportEmailAddress && <> calling <br /> {clientConfig.supportPhoneNumber}.</>}
                                            {clientConfig.supportEmailAddress && clientConfig.supportPhoneNumber && <> emailing <a href={'mailto:' + clientConfig.supportEmailAddress}> {clientConfig.supportEmailAddress}</a> or calling <br /> {clientConfig.supportPhoneNumber}.</>}</p>

                                        </div>
                                    </DialogContent>
                                </Dialog>
                                </>
                            }
                            {clientConfig.showTermsAndConditions &&
                                <div className="button-container">
                                    <ListItemButton onClick={handleClickOpenTerms} title="Terms and Conditions" id="openTermsAndConditionsButton">
                                        <ListItemIcon><GavelOutlinedIcon /></ListItemIcon>
                                        <ListItemText primary='Terms and Conditions' />
                                    </ListItemButton>
                                    <Dialog
                                        className="terms-conditions-dialog"
                                        fullScreen
                                        open={openTerms}
                                        onClose={handleCloseTerms}
                                        TransitionComponent={Transition}
                                    >
                                        <AppBar className="appbar-legal" sx={{ position: 'fixed' }}>
                                        <Toolbar>
                                            <Typography sx={{ ml: 2, flex: 1 }} component="div">
                                            Terms and Conditions
                                            </Typography>
                                            <IconButton
                                            id="closeTermsAndConditionsButton"
                                            edge="end"
                                            color="inherit"
                                            onClick={handleCloseTerms}
                                            aria-label="close"
                                            >
                                            <CloseIcon />
                                            </IconButton>
                                        </Toolbar>
                                        </AppBar>
                                        <TermsAndConditions />
                                    </Dialog>
                                </div>
                            }
                            {clientConfig.showPrivacyPolicy &&
                                <div className="button-container">
                                    <ListItemButton onClick={handleClickOpenPrivacy} title="Privacy Policy" id="openPrivacyButton">
                                        <ListItemIcon><PolicyOutlinedIcon /></ListItemIcon>
                                        <ListItemText primary='Privacy Policy' />
                                    </ListItemButton>
                                    <Dialog
                                        className="privacy-policy-dialog"
                                        fullScreen
                                        open={openPrivacy}
                                        onClose={handleClosePrivacy}
                                        TransitionComponent={Transition}
                                    >
                                        <AppBar className="appbar-legal" sx={{ position: 'fixed' }}>
                                        <Toolbar>
                                            <Typography sx={{ ml: 2, flex: 1 }} component="div">
                                                Privacy Policy
                                            </Typography>
                                            <IconButton
                                            id="closePrivacyButton"
                                            edge="end"
                                            color="inherit"
                                            onClick={handleClosePrivacy}
                                            aria-label="close"
                                            >
                                            <CloseIcon />
                                            </IconButton>
                                        </Toolbar>
                                        </AppBar>
                                        <PrivacyPolicy />
                                    </Dialog>
                                </div>
                            }
                            {clientConfig.clientVersion === "" || clientConfig.showClientVersion === false ?
                                <></> : 
                                <ListItem title={`Version - ${clientConfig.clientVersion}`}>
                                    <ListItemIcon><InfoOutlinedIcon /></ListItemIcon>
                                    <ListItemText primary='Version' secondary={`${clientConfig.clientVersion}`}/>
                                </ListItem>
                            }
                        </List>
                    </Collapse>
                </div>
                <Divider />
                <Link to={`/Security/Logout`} title="Log Out" id="logOutButton">
                    <ListItem button>
                        <ListItemIcon><SignoutIcon /></ListItemIcon>
                        <ListItemText primary='Log Out' />
                    </ListItem>
                </Link>
            </List>
        </Drawer>
        </Box>
    );
}

export {
    NavbarMini
}
