import React from "react";
import { useTransferWizardContext } from "./useTransferWizardContext";

const Create: React.FC = (props) => {
    const context = useTransferWizardContext();

    if (context.currentPage === "Create") {
        return (
            <>
                {props.children}
            </>
        )
    }

    return null;
}

export default Create;
