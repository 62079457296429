import React from "react"
import { ButtonGroupContext } from "./ButtonGroup";

export const useButtonGroup = () => {
    const context = React.useContext(ButtonGroupContext)
    if (context == null) {
        throw new Error("This component must be used within a <ButtonGroup> component.")
    }
    return context
}
