import { Card, CardActions, CardContent, CardHeader, Chip, Typography } from '@mui/material';
import ForumIcon from '@mui/icons-material/Forum';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { SecureMessage } from 'generated/generated-models';
import { useCallback, useContext } from 'react';
import { SecureMessageContext } from './SecureMessagingView';
import { FormattedDate } from 'react-intl';

const SHORT_DESCRIPTION_LENGTH = 80;

interface MessageOverviewCardProps {
    message: SecureMessage,
    handleSelectMessage: (secureMessage: SecureMessage) => void
}

const MessageOverviewCard = (props: MessageOverviewCardProps) => {
    const selectedMessageContext = useContext(SecureMessageContext);

    function handleSelectMessage() {
        props.handleSelectMessage(props.message);
    }
    
    if (!props.message.ToDoItem.Messages || props.message.ToDoItem.Messages.length === 0) {
        return (<></>)
    }
    const lastDate = props.message.ToDoItem.Messages[props.message.ToDoItem.Messages.length - 1].CreatedOn;

    return (
        <Card   
            className={`messageItem ${!props.message.IsRead ? "unreadMessage" : ""} ${selectedMessageContext?.SelectedMessage?.Id === props.message.Id ? "selectedMessage" : ""}`} 
            elevation={0} 
            onClick={handleSelectMessage}
            sx={{
                "&:hover": {
                    cursor: "pointer"
                }
            }}>
            <CardHeader
                title={
                    <>
                        <Typography variant="h4" className='messageTitle'>
                            {props.message.Subject}
                        </Typography>
                        {/* <Chip label={props.message.Type} size="small" className='messageTag'/> - When system messages are introduced, this chip should appear for system messages only. */} 
                        <Typography variant="caption" className='messageDate'>
                            {(new Date(lastDate)).toDateString() === new Date().toDateString() 
                                ? (new Date(lastDate)).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})
                                : <FormattedDate value={lastDate} day="2-digit" month="2-digit" year="2-digit"/>
                            }
                        </Typography>
                    </>
                }
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {props.message.ToDoItem.Messages[props.message.ToDoItem.Messages.length - 1].Text.substring(0, SHORT_DESCRIPTION_LENGTH).trimEnd()}{props.message.ToDoItem.Messages[props.message.ToDoItem.Messages.length - 1].Text.length > SHORT_DESCRIPTION_LENGTH && "..."}
                </Typography>
            </CardContent>
            <CardActions>
                {props.message.ToDoItem.AccountNumber && (<span><LocalOfferIcon /> {props.message.ToDoItem.AccountNumber} </span>)}
                {/*<span><AttachFileIcon /> 1</span> to be added with attachments*/}
                <span><ForumIcon /> {props.message.ToDoItem.Messages?.length}</span>
            </CardActions>
        </Card>
    );
}

export default MessageOverviewCard;