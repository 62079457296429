import { clientConfigFn } from "clientConfig";

export const ForgottenPassword = () => (
    <div className="dialog-details-container">
        {clientConfigFn().forgotPasswordPageTitle !== ""
            ? <h2>{clientConfigFn().forgotPasswordPageTitle}</h2>
            : <h2>Forgot your Password?</h2>
        }

        {clientConfigFn().forgotPasswordPageCopy !== ""
            ? <><p>{clientConfigFn().forgotPasswordPageCopy}</p></>
            : <>{clientConfigFn().supportEmailAddress || clientConfigFn().supportPhoneNumber
                    ? <p>Don't worry, if you have forgotten your Password you can contact our help desk by
                        {clientConfigFn().supportEmailAddress && !clientConfigFn().supportPhoneNumber && <> emailing <a href={'mailto:' + clientConfigFn().supportEmailAddress}> {clientConfigFn().supportEmailAddress}</a>.</>}
                        {clientConfigFn().supportPhoneNumber && !clientConfigFn().supportEmailAddress && <> calling {clientConfigFn().supportPhoneNumber}.</>}
                        {clientConfigFn().supportEmailAddress && clientConfigFn().supportPhoneNumber && <> emailing <a href={'mailto:' + clientConfigFn().supportEmailAddress}> {clientConfigFn().supportEmailAddress}</a> or calling {clientConfigFn().supportPhoneNumber}.</>}
                    </p>
                    : <p>Don't worry, if you have forgotten your Password you can contact our help desk via email or telephone.</p>
                }
                <p>
                    The help desk operator will require that you answer certain security
                    questions to verify that you are the owner of the account. Once you are
                    verified, your username will be provided to you.
                </p>
              </>
        }
    </div>
);

export default ForgottenPassword;