import React from "react";
import { ErrorMessage, ErrorMessageProps } from "formik";

export const CErrorMessage = ({
    name,
    className = "",
    ...props
}: ErrorMessageProps) => {
    return (
        <span className={`invalid-feedback form-error-message ${className}`}>
            <ErrorMessage
                {...props}
                name={name}
                className={`invalid-feedback ${className}`}
                data-valmsg-for={name}
                data-valmsg-replace="true"
            />
        </span>
    );
};
