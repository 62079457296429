import React from "react";
import { Redirect } from "react-router";
import { InterAccountFormProps } from "./CreateInterAccount";
import DueOnListItems from "../utilities/list/DueOnListItems";
import {
    AccountFundsTransfersDocument,
    useFinanceSummaryQueryQuery,
    useRequestPayAnyoneTransferMutation
} from "generated/generated-models";
import { Loading } from "app/lib/components/Loading";
import { CError } from "app/common/CError";
import { createTransferVariable, transferTypes } from "../utilities/fundsTransfers";
import { useTransferWizard } from "../utilities/TransferWizard";
import MoneyFormatted from "app/components/MoneyFormatted";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Alert from '@mui/material/Alert';
import useMediaQuery from '@mui/material/useMediaQuery';

const ConfirmInterAccount = () => {
    const { data, loading, error } = useFinanceSummaryQueryQuery();
    const [requestPayment, paymentData] = useRequestPayAnyoneTransferMutation();
    const { formState, confirmTransfer, modifyTransfer, interAccountToAccount, accountId: fromAccountId } = useTransferWizard<InterAccountFormProps>();
    const fullScreen = useMediaQuery("(max-width: 768px)");
    const [, setOpen] = React.useState(false);

    const handleClose = () => {
      setOpen(false);
    };

    if (formState == null || formState.amount == null) {
        return <Redirect to="." />
    }
    if (loading) {
        return <Loading />
    }

    const accounts = data?.financeSummary?.Instruments
        ?.flatMap(instrument => instrument?.Accounts);

    const fromAccount = accounts
        ?.find(account => account?.Id === fromAccountId);

    const toAccount = accounts
        ?.find(account => account?.Id === interAccountToAccount?.Id);

    if (fromAccount == null || toAccount == null || error) {
        return <section className="sub-wrap"><CError error="Could not load account details. Please try again." /></section>
    }

    const sendPaymentRequest = () => {
        const input = createTransferVariable(
            fromAccountId,
            formState.amount!,
            formState.transferDescription || "",
            formState.transferReference || "",
            formState
        )
        input.TransferType = transferTypes.InterAccountTransferTypeId;
        input.FromAccountId = fromAccountId;
        input.ToAccountId = interAccountToAccount?.Id;

        requestPayment({
            variables: {
                input: input
            },
            refetchQueries: [
                {
                    query: AccountFundsTransfersDocument,
                    variables: { accountIdOrNumber: fromAccount.Id }
                },
                {
                    query: AccountFundsTransfersDocument,
                    variables: { accountIdOrNumber: toAccount.Id }
                }
            ]
        }).then(x => {
            const fundsTransferId = x.data?.requestPayAnyoneTransfer?.FundsTransfer?.Id;
            if (x.data?.requestPayAnyoneTransfer?.Success === true) {
                confirmTransfer(fundsTransferId);
            }
        })
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={true}
            className="confirm-transfer-payment"
            onClose={handleClose}
        >
            <DialogTitle>
                <CurrencyExchangeIcon />
                <Typography variant="h5" gutterBottom component="h5" sx={{ mx: 2, mb: 2 }}>
                    Confirm Transfer
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ m: 4, mt: 0 }}>
                <DialogContentText>
                <Typography variant="body1" gutterBottom component="p" sx={{ textAlign:"center" }}>
                    Please ensure all details are correct before proceeding. Once confirmed your
                    transfer is final.
                </Typography >
                <Typography variant="body1" gutterBottom component="p" sx={{ textAlign:"center" }} className="transfer-amount">
                    <MoneyFormatted amount={formState.amount} />
                    <span className="caption">Amount to be paid</span>
                </Typography >
                <List sx={{ mb: 2 }} className="transfer-details">
                    <ListItem disablePadding>
                        <ListItemText primary={<Typography><span className="subtitle">From: </span>{fromAccount.ProductType} - {fromAccount.Number || ""}</Typography>} />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary={<Typography><span className="subtitle">To: </span>{toAccount.ProductType} - {toAccount?.Number}</Typography>} />
                    </ListItem>
                    <DueOnListItems
                        recurrence={formState.recurrenceEnd || ""}
                        paymentDate={formState.startingOn}
                        frequency={formState.frequency || ""}
                        endDate={formState.endingOn}
                        repeats={formState.numberOfTimesAmount}
                    />
                    <ListItem disablePadding>
                        <ListItemText primary={<Typography><span className="subtitle">Description: </span> {formState.transferDescription}</Typography>} />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary={<Typography><span className="subtitle">Reference: </span> {formState.transferReference}</Typography>} />
                    </ListItem>
                </List>

                { (paymentData.error !== null || paymentData.data?.requestPayAnyoneTransfer?.Success === false) &&
                    <Alert severity="error">
                        Error submitting transfer request. {paymentData.data?.requestPayAnyoneTransfer?.Message}
                    </Alert>
                }
                <div className="button-row">
                    <Button
                        className="btn-cancel" id="cancel"
                        sx={{ p: 1.5, mt: 1 }}
                        variant="contained"
                        onClick={() => modifyTransfer()}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit" id="submit"
                        sx={{ p: 1.5, mt: 1 }}
                        onClick={() => sendPaymentRequest()}
                        disabled={paymentData.loading}
                        loading={paymentData.loading}
                        loadingPosition="end"
                        variant="contained"
                    >
                        Confirm
                    </LoadingButton>
                </div>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default ConfirmInterAccount;
