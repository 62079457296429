import DOMPurify from "dompurify";

type SanitisedBackgroundImageProps = {
    src: string,
    className?: string,
}

const SanitisedBackgroundImage = (props: SanitisedBackgroundImageProps) => {
    return (
        <div className={props.className} style={{backgroundImage: "url("+ DOMPurify.sanitize(props.src) +")"}} />
    )
}

//<div className="login-hero-img" style={{backgroundImage: "url("+ config.heroImageUri +")"}}></div>
export default SanitisedBackgroundImage;