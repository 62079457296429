import { ApolloError } from "@apollo/client";
import React from "react";
import { CError } from "./CError";

interface Props {
    header?: string;
    error?: ApolloError;
}
const CApolloError: React.FC<Props> = ({ header, error }) => {
    return (
        <CError header={header || "Error"} error={error?.message ?? "An unexpected error has occured"} />
    );
};

export { CApolloError };
