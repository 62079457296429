import React from "react";
import { Redirect } from "react-router";
import DueOnListItems from "app/accountTransfer/utilities/list/DueOnListItems";
import {
    TransferAccount,
    useBpayTransferRequestMutation,
    useRequestPayAnyoneTransferMutation,
    PartyLimitDocument
} from "generated/generated-models";
import { createTransferTimes, transferTypes } from "app/accountTransfer/utilities/fundsTransfers";
import { useTransferPayments } from "./context/useTransferPayments";
import { TransfersAndPaymentsProps } from "./TransfersAndPayments";
import MoneyFormatted from "app/components/MoneyFormatted";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Alert from '@mui/material/Alert';
import useMediaQuery from '@mui/material/useMediaQuery';

const DetermineTransferType = ( fromAccount: TransferAccount, toAccount: TransferAccount) => {
    if(toAccount?.IsBPay)
    {
        return "BPay"
    }
    else if(!fromAccount?.IsBankAccount && !toAccount?.IsBankAccount)
    {
        return "InterAccount"
    }
    else if(!fromAccount?.IsBankAccount && toAccount?.IsBankAccount && toAccount.IsExternal)
    {
        return "PayAnyone"
    }
    else if(!fromAccount?.IsBankAccount && toAccount?.IsBankAccount && !toAccount.IsExternal)
    {
        return "Redraw"
    }
    else if(fromAccount?.IsBankAccount && !toAccount?.IsBankAccount)
    {
        return "Payment"
    }
    else
    {
        return "Unknown"
    }
}

const SetTransferTypeId = (transferType: string) => {
    switch(transferType)
    {
        case "BPay":
            return transferTypes.BpayTransferTypeId
        case "PayAnyone":
            return transferTypes.PayAnyoneTransferTypeId
        case "Redraw":
            return transferTypes.RedrawTransferTypeId
        case "Payment":
            return transferTypes.RepaymentTransferTypeId
        default:
            return transferTypes.InterAccountTransferTypeId
    }
}

const ConfirmTransfer = () => {   
    const [bpayTransfer, bpayData] = useBpayTransferRequestMutation();
    const [requestPayment, paymentData] = useRequestPayAnyoneTransferMutation();
    const { fromAccount, toAccount, formState, modifyTransfer, confirmTransfer, updateTransferType, updateIsPending } = useTransferPayments<TransfersAndPaymentsProps>();
    const transferType = DetermineTransferType(fromAccount!, toAccount!);
    const transferTypeId = SetTransferTypeId(transferType);
    const fullScreen = useMediaQuery("(max-width: 768px)");
    const [, setOpen] = React.useState(false);

    const handleClose = () => {
      setOpen(false);
    };

    if (formState == null || formState.amount == null) {
        return <Redirect to="." />
    }

    const sendPaymentRequest = () => {
        const transferTimes = createTransferTimes(formState);

        if(transferType === "BPay")
        {
            bpayTransfer({
                variables: {
                    input: {
                        AccountId: fromAccount?.AccountId,
                        Amount: formState.amount!,
                        Billercode: toAccount?.BillerCode,
                        CustomerReferenceNumber: toAccount?.AccountNumber!,
                        SaveBillerDetails: false,
                        BillerDescription: toAccount?.AccountName,
                        WithdrawalDescription: formState.transferDescription,
                        StartingOn: transferTimes.StartingOn,
                        EndingOn: transferTimes.EndingOn,
                        Frequency: transferTimes.Frequency,
                        NumberOfTimesAmount: transferTimes.NumberOfTimesAmount,
                        RecurrenceEnd: transferTimes.RecurrenceEnd
                    }
                },
                refetchQueries: [
                    { 
                        query: PartyLimitDocument,
                    }
                ]
            }).then(x => {
                const fundsTransferId = x.data?.bpayTransferRequest?.FundsTransfer?.Id;
                updateTransferType(transferType);
                confirmTransfer(fundsTransferId);
            })
        }
        else{    
            requestPayment({
                variables: {
                    input: {
                        AccountId: transferType === "Payment" ? toAccount?.AccountId : fromAccount?.AccountId,
                        Amount: formState.amount!,
                        BankAccountId: transferType === "Payment" ? fromAccount?.AccountId : toAccount?.AccountId,
                        DepositDescription: formState.transferDescription,
                        WithdrawalDescription: formState.transferReference,
                        StartingOn: transferTimes.StartingOn,
                        EndingOn: transferTimes.EndingOn,
                        Frequency: transferTimes.Frequency,
                        TransferType: transferTypeId,
                        FromAccountId: transferType === "InterAccount" ? fromAccount?.AccountId : null,
                        ToAccountId: transferType === "InterAccount" ? toAccount?.AccountId : null,
                        RecurrenceEnd: transferTimes.RecurrenceEnd,
                        NumberOfTimesAmount: transferTimes.NumberOfTimesAmount,
                        FixedAmountOption: true,
                        UseNPP: formState.useNPP
                    }
                },
                refetchQueries: [
                    { 
                        query: PartyLimitDocument,
                    }
                ]
            }).then(x => {
                const fundsTransferId = x.data?.requestPayAnyoneTransfer?.FundsTransfer?.Id;
                if (x.data?.requestPayAnyoneTransfer?.Success === true) {
                    const pending = formState.useNPP && x.data?.requestPayAnyoneTransfer?.Message !== null;
                    updateTransferType(transferType);
                    updateIsPending(pending ?? false);
                    confirmTransfer(fundsTransferId);
                }
            })
        }
    }
    return (
        <Dialog
            fullScreen={fullScreen}
            open={true}
            className="confirm-transfer-payment"
            onClose={handleClose}
        >
            <DialogTitle>
                <CurrencyExchangeIcon />
                <Typography variant="h5" gutterBottom component="h5" sx={{ mx: 2, mb: 2 }}>
                    Confirm Transfer
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ m: 4, mt: 0 }}>
                <DialogContentText>
                <Typography variant="body1" gutterBottom component="p" sx={{ textAlign:"center" }}>
                    Please ensure all details are correct before proceeding. Once confirmed your
                    transfer is final.
                </Typography >
                <Typography variant="body1" gutterBottom component="p" sx={{ textAlign:"center" }} className="transfer-amount">
                    <MoneyFormatted amount={formState.amount} />
                    <span className="caption">Amount to be paid</span>
                </Typography >
                <List sx={{ mb: 2 }} className="transfer-details">
                    <ListItem disablePadding>
                        <ListItemText primary={<Typography><span className="subtitle">From: </span>{fromAccount?.AccountName} - {fromAccount?.AccountNumber || ""}</Typography>} />
                    </ListItem>
                    <ListItem disablePadding>
                        {toAccount?.BillerCode || toAccount?.BSB 
                            ? <ListItemText primary={<Typography><span className="subtitle">To: </span>{toAccount?.AccountName} <br /> {toAccount?.BillerCode ?? toAccount?.BSB} | {toAccount?.AccountNumber ?? ""}</Typography>} />
                            : <ListItemText primary={<Typography><span className="subtitle">To: </span>{toAccount?.AccountName} - {toAccount?.AccountNumber ?? ""}</Typography>} />
                        }
                    </ListItem>
                    <DueOnListItems
                        recurrence={formState.recurrenceEnd || ""}
                        paymentDate={formState.startingOn}
                        frequency={formState.frequency || ""}
                        endDate={formState.endingOn}
                        repeats={formState.numberOfTimesAmount}
                    />
                    <ListItem disablePadding>
                        <ListItemText primary={<Typography><span className="subtitle">Description: </span> {formState.transferDescription}</Typography>} />
                    </ListItem>
                    {formState.transferReference &&
                        <ListItem disablePadding>
                            <ListItemText primary={<Typography><span className="subtitle">Reference: </span> {formState.transferReference}</Typography>} />
                        </ListItem>
                    }
                </List>

                { ((paymentData.data?.requestPayAnyoneTransfer?.Message && !paymentData.data?.requestPayAnyoneTransfer?.Success) || paymentData.error != null || bpayData.error != null) &&
                    <Alert severity="error">
                        Error submitting transfer request. {paymentData.data?.requestPayAnyoneTransfer?.Message ?? bpayData.data?.bpayTransferRequest?.Message}
                    </Alert>
                }
                <div className="button-row">
                    <Button
                        className="btn-cancel" id="cancel"
                        sx={{ p: 1.5, mt: 1 }}
                        variant="contained"
                        onClick={() => modifyTransfer()}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit" id="submit"
                        sx={{ p: 1.5, mt: 1 }}
                        onClick={() => sendPaymentRequest()}
                        disabled={paymentData.loading}
                        loading={paymentData.loading}
                        loadingPosition="end"
                        variant="contained"
                    >
                        Confirm
                    </LoadingButton>
                </div>
                </DialogContentText>
            </DialogContent>
        </Dialog> 
    )
}

export default ConfirmTransfer;
