import Input from "app/components/Input";
import React, { useState } from "react";
import Form from "app/components/Form";
import {
    BpayBiller,
    BpayBillerHistoryDocument,
    useCreateBpayBillerMutation,
    TransferAccountsByPartyDocument
} from "generated/generated-models";
import { nameof } from "core/util/nameof";
import { Formik } from "formik";
import { Validate } from "./AddBpayBillerValidate";
import RequestMultiFactorTokenFormik from "app/components/RequestMultiFactorTokenFormik";
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import BpayText from "app/components/BpayText";

export type AddBpayFormProps = {
    billerCode?: number;
    customerReferenceNumber: string;
    billerDescription?: string;
    twoFactorToken?: string;
    hasPendingTransfer? : boolean
}

type AddBpayBillerProps = {
    handleClose: () => void;
    goBack?: () => void;
    billerAdded?: (biller: BpayBiller) => void;
}

type AddBpayBillerMFAProps =
    | "WithoutMFA"
    | "WithMFA"

const AddBpayBiller = ({
    handleClose,
    goBack = () => { },
    billerAdded = (_) => { } }: AddBpayBillerProps) => {
    const [createBiller, { data, error }] = useCreateBpayBillerMutation();
    const initialValues: AddBpayFormProps = {
        billerDescription: "",
        billerCode: undefined,
        customerReferenceNumber: ""
    }

    const [addBpayBillerPage, setAddBpayBillerPage] = useState<AddBpayBillerMFAProps>("WithoutMFA");

    const handleRequiresMFA = () => {
        setAddBpayBillerPage("WithMFA")
    };

    const handleCancelClick = () => {
        goBack();
        handleClose();
    }

    const handleSubmit = async (values: AddBpayFormProps) => {
        const result = await createBiller(
            {
                variables: {
                    input: {
                        BillerCode: (values.billerCode!),
                        CustomerReferenceNumber: values.customerReferenceNumber,
                        BillerDescription: values.billerDescription,
                        TwoFactorToken: values.twoFactorToken,
                        HasPendingTransfer: false
                    }
                },
                refetchQueries: [{ query: BpayBillerHistoryDocument }, { query: TransferAccountsByPartyDocument}]
            }
        );

        if (result.data?.createBpayBiller?.Code === "499") {
            handleRequiresMFA();
        }

        if (result.data?.createBpayBiller?.NewBpayBiller != null) {
            billerAdded(result.data.createBpayBiller.NewBpayBiller);
            handleClose();
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={() => Validate(addBpayBillerPage === "WithMFA")}
        >
            {({ isSubmitting }) => {
                return (
                    <div>
                        <h2>Add <BpayText /> Biller</h2>
                        <Form>
                            <Input
                                name={nameof<AddBpayFormProps>("billerCode")}
                                type="wholeNumber"
                                label="Biller Code"
                            />

                            <Input
                                name={nameof<AddBpayFormProps>("customerReferenceNumber")}
                                type="text"
                                label="Customer Reference Number"
                            />

                            <Input
                                name={nameof<AddBpayFormProps>("billerDescription")}
                                type="text"
                                label="Biller Nickname"
                            />

                            {addBpayBillerPage === "WithMFA" &&
                                <RequestMultiFactorTokenFormik
                                    name={nameof<AddBpayFormProps>("twoFactorToken")}
                                    showRequestNewToken={true}
                                    requestMfaOnDisplay={true}
                                />
                            }

                            {data?.createBpayBiller?.Message != null &&
                                <Alert severity="error">
                                    {data?.createBpayBiller.Message}
                                </Alert>
                            }

                            {error != null &&
                                <Alert severity="error">
                                    Error submitting new biller request. Please try again
                                </Alert>
                            }

                            <div className="button-row">
                                <Button
                                    className="btn-cancel" id="cancel"
                                    sx={{ p: 1.5, mt: 1 }}
                                    variant="contained"
                                    onClick={handleCancelClick}
                                    disabled={isSubmitting} >
                                    Cancel
                                </Button>
                                <LoadingButton
                                    type="submit" id="submit"
                                    sx={{ p: 1.5, mt: 1 }}
                                    loading={isSubmitting}
                                    loadingPosition="end"
                                    variant="contained"
                                >
                                    Create Biller
                                </LoadingButton>
                            </div>
                        </Form>
                    </div>
                )
            }}
        </Formik>
    )
}

export default AddBpayBiller;
