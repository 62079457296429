import SelectPayAnyone from "./SelectPayAnyone";
import CreatePayAnyone from "./CreatePayAnyone";
import ConfirmPayAnyone from "./ConfirmPayAnyone";
import PayAnyoneReciept from "./PayAnyoneReceipt";
import TransferWizard from "../utilities/TransferWizard";

const PayAnyonePage = (props: { accountId: string}) => {
    return (
        <TransferWizard accountId={props.accountId} useSelectionPage={true} authorityType={"payAnyone"}>
            <TransferWizard.Select>
                <SelectPayAnyone />
            </TransferWizard.Select>
            <TransferWizard.Create>
                <CreatePayAnyone />
            </TransferWizard.Create>
            <TransferWizard.Confirm>
                <ConfirmPayAnyone />
            </TransferWizard.Confirm>
            <TransferWizard.Receipt>
                <PayAnyoneReciept />
            </TransferWizard.Receipt>
        </TransferWizard>
    )
}

export default PayAnyonePage;
