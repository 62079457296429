import { useState } from "react";
import Title from "app/components/Title";
import { Form, Formik } from "formik";
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import { usePartyChangeMarketingCommsStatusMutation } from "generated/generated-models";
import { useSecurityTokenMutation } from "core/apollo/SecurityTokenApollo";
import Alert from '@mui/material/Alert';
import RequestMultiFactorTokenFormik from "app/components/RequestMultiFactorTokenFormik";
import { Validate } from "./ProfileMarketingCommunicationsInputValidator";

export type MarketingCommsStatusInput = {
    ProfileId?: string;
    MarketingStatus?: boolean;
    TwoFactorToken?: string;
}

type MarketingCommsStatusMFAProps =
    | "WithoutMFA"
    | "WithMFA"

const ProfileMarketingCommunications = ({ ProfileId, MarketingStatus }: MarketingCommsStatusInput) => {
    const [marketingCommsStatus, setMarketingCommsStatus] = useState<boolean | undefined>(undefined);
    const [changeMarketingStatus, { data: changeMarketingStatusData, error: changeMarketingStatusError }] = useSecurityTokenMutation(usePartyChangeMarketingCommsStatusMutation());
    const [marketingCommsMFA, setMarketingCommsMFA] = useState<MarketingCommsStatusMFAProps>("WithoutMFA");

    const handleRequiresMFA = () => {
        setMarketingCommsMFA("WithMFA")
    };

    const handleMarketingCommsSubmit = async (values: MarketingCommsStatusInput) => {
        const result = await changeMarketingStatus({
            variables: {
                input:
                {
                    MarketingStatus: !values.MarketingStatus!,
                    TwoFactorToken: values.TwoFactorToken
                }
            }
        });

        if (result.data?.changeMarketingCommsStatus?.Code === "499") {
            handleRequiresMFA();
        }
        if (result.data?.changeMarketingCommsStatus?.Success) {
            setMarketingCommsStatus(true);

            setTimeout(
                () => {
                    setMarketingCommsStatus(true);
                    window.location.reload();
                },
                3000
            );
        }
    }

    return (
        <Formik
            initialValues={{ ProfileId: ProfileId, MarketingStatus: MarketingStatus }}
            onSubmit={(values) => handleMarketingCommsSubmit(values)}
            validationSchema={Validate(marketingCommsMFA === "WithMFA")}
        >
            {({ isSubmitting }) => {
                return (
                    <>
                        <Title title="Marketing Communications"/>
                        <p className="intro-text">You are about to {!MarketingStatus ? "opt in to receive" : "opt out from receiving"} marketing communications. Please confirm you are happy to proceed.</p>
                        
                        <Form>
                            {!marketingCommsStatus &&
                                <RequestMultiFactorTokenFormik
                                    name={"TwoFactorToken"}
                                    showRequestNewToken={true}
                                    requestMfaOnDisplay={true}
                                    visible={marketingCommsMFA === "WithMFA"}
                                />
                            }
                            {(changeMarketingStatusError || changeMarketingStatusData?.changeMarketingCommsStatus?.Success === false) &&
                                <Alert severity="error">
                                    {changeMarketingStatusData?.changeMarketingCommsStatus?.Message}
                                </Alert>
                            }
                            {marketingCommsStatus
                                ? <Alert severity="success">You have successfully updated your marketing communication settings.</Alert>
                                : <div className="button-row">
                                    <Button
                                        className="btn-cancel" id="cancel"
                                        sx={{ p: 1.5, mt: 1 }}
                                        variant="contained"
                                        onClick={() => window.location.reload()}>
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit" id="submit"
                                        sx={{ p: 1.5, mt: 1 }}
                                        loading={isSubmitting}
                                        loadingPosition="end"
                                        variant="contained"
                                    >
                                        Confirm
                                    </LoadingButton>
                                  </div>
                            }
                        </Form>
                    </>
                )
            }}
        </Formik>
    )
}

export default ProfileMarketingCommunications;
