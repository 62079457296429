import debug from "debug";

const BASE = "capital";

type logLevel = "trace" | "info" | "warn" | "error";

class Log {
    // tslint:disable-next-line:no-any
    public generateMessage(level: logLevel, message: any, source: any) {
        // Set the prefix which will cause debug to enable the message
        const namespace = `${BASE}:${level}`;
        const createDebug = debug(namespace);

        // Set the colour of the message based on the level
        // createDebug.color = COLOURS[level];

        if (source) {
            createDebug(source, message);
        } else {
            createDebug(message);
        }
    }

    // tslint:disable-next-line:no-any
    public trace(message: any, source?: any) {
        return this.generateMessage("trace", message, source);
    }

    // tslint:disable-next-line:no-any
    public info(message: any, source?: any) {
        return this.generateMessage("info", message, source);
    }

    // tslint:disable-next-line:no-any
    public warn(message: any, source?: any) {
        return this.generateMessage("warn", message, source);
    }

    // tslint:disable-next-line:no-any
    public error(message: any, source?: any) {
        return this.generateMessage("error", message, source);
    }
}

export const log = new Log();
export default log;
