import React, { useEffect, useState } from "react";
import { Loading } from "app/lib/components/Loading";
import classNames from "classnames";
import { clientConfigFn } from "clientConfig";
import { groupBy } from "core/util/array";
import { endOfDay } from "date-fns";
import { AccountTransaction } from "generated/generated-models";
import { usePagedTransactions } from "../PaginationUtilities";
import MobileTransaction from "./MobileTransaction";
import { TransactionListProps } from "./TransactionListView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { CError } from "app/common/CError";
import { FormattedDate } from "react-intl";

type CardProps = {
    date: Date;
    transactions: Array<AccountTransaction>;
    showDetailView: (account: AccountTransaction) => void;
}

const TransactionDateCard = (props: CardProps) => {
    const { BETA_showDetailView } = clientConfigFn();

    const handleClick = (transaction: AccountTransaction) => {
        if (BETA_showDetailView) {
            props.showDetailView(transaction);
        }
    }

    const groupItemClasses = classNames([
        "list-group-item",
        { "cursor-pointer": BETA_showDetailView }
    ])
console.log(props.date)
    return (
        <div className="card">
            <div className="card-header">
                <FormattedDate value={props.date} year="numeric" month="long" day="numeric"/>
            </div>
            <ul className="list-group list-group-flush">
                {props.transactions.map(x => (
                    <li className={groupItemClasses} onClick={() => handleClick(x)}>
                        <div className="left-item">
                            <p>
                                {x.TransactionType}
                            </p>
                            <p className="text-muted small">
                                {x.Note}
                            </p>
                        </div>
                        <div className="right-item">
                            <MobileTransaction transaction={x} />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

const MobileTransactionList = ({ account, setDetailView }: TransactionListProps) => {
    let { transactions, loading: loadingTransactions, error: errorTransactions } = usePagedTransactions(account, true);
    const [isVisible, setIsVisible] = useState(false);
    const { loading, items, hasNextPage, error, loadMore, resetItems } = useLoadItems();
    const { transactionPageSize } = clientConfigFn();
    const [ awaitingTransactionReset, setAwaitingTransactionReset ] = useState<string | undefined>();
    
    const groupedTransactions = transactions===undefined?[]:groupBy(transactions,
        (transaction) => endOfDay(new Date(transaction.EffectiveDate)),
        (firstElement, secondElement) => secondElement.valueOf() - firstElement.valueOf());
    const groupedTransactionList = groupedTransactions.map(x => <TransactionDateCard date={x.key} transactions={x.elements} showDetailView={setDetailView} />)
        
    useEffect(() => {
        setAwaitingTransactionReset(transactions?.reduce((state, transaction) => {
            return state+transaction.Id
        }, ""));
    }, [account]);

    useEffect(() => {
        if (!loading) {
            const transactionIds = transactions?.reduce((state, transaction) => {return state+transaction.Id}, "")
            if (transactionIds !== awaitingTransactionReset) {
                setAwaitingTransactionReset(transactionIds)
                resetItems()
            }
        }
    }, [transactions]);

    const ARRAY_SIZE = transactionPageSize || 20;
    const RESPONSE_TIME_IN_MS = 1200;
    
    interface Item {
      key: number;
      value: string;
    }
    interface Response {
      hasNextPage: boolean;
      data: Item[];
    }
   
    function loadItems(startCursor = 0): Promise<Response> {
      return new Promise((resolve) => {
        let newArray: any[] = [];

        setTimeout(() => {
            if(groupedTransactionList.length >= items.length) {
                for (let i = startCursor; i < startCursor + ARRAY_SIZE; i++) {
                    const transactionItem = groupedTransactionList[i];
                    const newItem = {
                        key: i,
                        value: transactionItem,
                    };
                    newArray = [...newArray, newItem];
                }
                resolve({ hasNextPage: true, data: newArray });

            } else {
                resolve({ hasNextPage: false, data: newArray });
            }
        }, RESPONSE_TIME_IN_MS);
      });
    }
    function useLoadItems() {
        const [loading, setLoading] = React.useState(false);
        const [items, setItems] = React.useState<Item[]>([]);
        const [hasNextPage, setHasNextPage] = React.useState<boolean>(true);
        const [error, setError] = React.useState<any | Error>();

        async function loadMore() {
            setLoading(true);
            try {
                const { data, hasNextPage: newHasNextPage } = await loadItems(
                items.length,
                );
                setItems((current) => [...current, ...data]);
                setHasNextPage(newHasNextPage);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        }

        function resetItems() {
            if (true) {
                setHasNextPage(true);
                setItems([]);
                setLoading(false);

            }
        }

        return { loading, items, hasNextPage, error, loadMore, resetItems };
      }
    const [sentryRef] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: loadMore,
        // When there is an error, we stop infinite loading.
        // It can be reactivated by setting "error" state as undefined.
        disabled: !!error,
        // `rootMargin` is passed to `IntersectionObserver`.
        // We can use it to trigger 'onLoadMore' when the sentry comes near to become
        // visible, instead of becoming fully visible on the screen.
        rootMargin: '0px 0px 400px 0px',
    });

    const toggleVisibility = () => {
        if (window.scrollY > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
        top: 0,
        behavior: "smooth"
        });
    };
    
    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
    }, []);

    if (loadingTransactions || transactions == null) {
        return <Loading />;
    }
    if (errorTransactions) {
        return <CError error={"Could not retrieve account transactions."} />
    }

    return (
        <div className="mobile-transaction-list-container">
            {items.map((item) => (
                <>{item.value}</>
            ))}
            {(loading || hasNextPage) && (
                <p className="dataEnd" ref={sentryRef}>
                    <Loading />
                </p>
            )}
            {(!hasNextPage) && (
                <p className="dataEnd" ref={sentryRef}>
                    There are no more items to display.
                </p>
            )}

            <div className="scroll-to-top">
            {isVisible && 
                <div className="btn-scroll" onClick={scrollToTop}>
                    <FontAwesomeIcon icon={faArrowUp} />
                </div>}
            </div>
        </div>
    )
}

export default MobileTransactionList;
