import React from "react";
import AccountSelect from "./AccountSelect";
import { TransferWizardContextProvider } from "./useTransferWizardContext";

export type AuthorityType =
    | "operate"
    | "redraw"
    | "payAnyone"
    | "bpay"

export type Props = {
    accountId: string;
    useSelectionPage?: boolean;
    authorityType: AuthorityType;
}

const TransferWizard: React.FC<Props> = (props) => {
    return (
        // <div className="sub-wrap form-wrap flex-fill d-flex">
        <div className="sub-wrap">
            <TransferWizardContextProvider
                accountId={props.accountId}
                useSelectionPage={props.useSelectionPage}
            >
                {props.children}
                <AccountSelect authorityType={props.authorityType} />
            </TransferWizardContextProvider>
        </div>
    )
}

export default TransferWizard;
