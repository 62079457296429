import * as React from 'react';
import { Account, AccountTransaction, useAccountByIdOrNumberQuery } from "generated/generated-models";
import Box from '@mui/material/Box';
import { CDate } from "../../../common/CapitalDate";
import { CError } from "app/common/CError";
import { Loading } from "app/lib/components/Loading";
import { useScreenSize } from "app/lib/useScreenSize";
import { DataGrid, GridLinkOperator, GridRowsProp, GridColDef, GridOverlay } from '@mui/x-data-grid';
import type { Theme } from '@mui/material/styles';
import { IsNullOrWhiteSpace } from 'core/util/stringExtensions';
import TransactionPageIconMoney from 'app/components/TransactionPageIconMoney';
import MoneySigned from 'app/components/MoneySigned';
import { makeNonEmpty } from 'core/util/array';
import Paper from '@mui/material/Paper';
import { clientConfigFn } from 'clientConfig';
import { addDays } from 'date-fns';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MobileTransaction from '../MobileTransaction';

const DesktopTransactionList = ({ account }: { account?: Account }) => {  
    const [filteredData, setFilteredData] = React.useState<AccountTransaction[]>([]);
    const { transactionPageSize, transactionHistoryLimitDays = 0 } = clientConfigFn();
    const screenSize = useScreenSize();

    const { data: dataAccountById, loading: loadingAccountById, error: errorAccountById } = useAccountByIdOrNumberQuery({
        variables: { idOrNumber: account?.Number! },
        fetchPolicy: "network-only",
        skip: account === undefined
    });

    React.useEffect(()=> {
        if(!loadingAccountById && dataAccountById) {
            const filteredTrans = makeNonEmpty(dataAccountById?.accountByIdOrNumber?.Transactions)
                .filter(tran => (tran.IsPending && !tran.WasReversed) || // Pending transactions will be reversed once no longer pending
                    (tran.AppearsOnStatement && !tran.WasBackedOut));
            const cutOffDate = transactionHistoryLimitDays > 0 ? addDays(new Date(), -transactionHistoryLimitDays) : new Date(1990, 1, 1);

            // Hack because our types are wrong
            const filteredTransactions = filteredTrans.filter(tran => new Date(tran.EffectiveDate) >= cutOffDate);
            setFilteredData(filteredTransactions);
        }
    },[loadingAccountById, dataAccountById])

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
            <Box sx={{ m: 3, textAlign:"center" }}>There are no transactions for this account.</Box>
            </GridOverlay>
        );
    }

    if (loadingAccountById) {
        return <Loading />;
    }
    if (errorAccountById) {
        return <section className="sub-wrap"><CError error={"Could not retrieve account transactions."} /></section>
    }

    const rows: GridRowsProp = filteredData;
    const columns: GridColDef[] = [
        { field: 'Id', headerName: 'Id', width: 0, hide: true, filterable: false },
        { field: 'CreatedOn', headerName: 'Date', minWidth: 100, flex: 2, renderCell: (params) => (<CDate date={params.value} />) }, 
        { field: 'TransactionType', headerName: 'Description', minWidth: 280, renderCell: (params) => (!IsNullOrWhiteSpace(params.row.Note) ? <span>{params.value}<br/><b>Note:</b>  {params.row.Note}</span> : <span>{params.value}</span>)},
        { field: 'Increase', headerName: 'Debit', minWidth: 100, flex: 2, renderCell: (params) => (<TransactionPageIconMoney amount={params.value} direction="debit" isShowZero={false} />)},
        { field: 'Decrease', headerName: 'Credit', minWidth: 100, flex: 2, renderCell: (params) => (<TransactionPageIconMoney amount={params.value} direction="credit" isShowZero={false} />)},
        { field: 'Balance', headerName: 'Balance', minWidth: 100, flex: 2, renderCell: (params) => (<MoneySigned amount={params.value} direction="total" />)},
    ];
        const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    return (
        <>
            {screenSize === "desktop" 
             ? <DataGrid
                autoHeight
                className='custom-grid'
                columns={columns}
                rows={rows}
                getRowId={(r) => r.Id}
                getRowHeight={() => 'auto'}
                rowsPerPageOptions={[20, 50, 100]}
                initialState={{
                pagination: {
                    pageSize: transactionPageSize,
                  },
                }}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay
                }}
                disableColumnSelector
                componentsProps={{
                  filterPanel: {
                    linkOperators: [GridLinkOperator.And],
                    columnsSort: 'asc',
                    filterFormProps: {
                      linkOperatorInputProps: {
                        variant: 'outlined',
                        size: 'small',
                      },
                      columnInputProps: {
                        variant: 'outlined',
                        size: 'small',
                        sx: { mt: 'auto' },
                      },
                      operatorInputProps: {
                        variant: 'outlined',
                        size: 'small',
                        sx: { mt: 'auto' },
                      },
                      deleteIconProps: {
                        sx: {
                          '& .MuiSvgIcon-root': { color: '#d32f2f' },
                        },
                      },
                    },
                    sx: {
                      '& .MuiDataGrid-filterForm': { p: 2 },
                      '& .MuiDataGrid-filterForm:nth-child(even)': {
                        backgroundColor: (theme: Theme) =>
                          theme.palette.mode === 'dark' ? '#444' : '#f5f5f5',
                      },
                      '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 2 },
                      '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                      '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                      '& .MuiDataGrid-filterFormValueInput': { width: 200 },
                    },
                  },
                }}
               />
             : <Stack spacing={2}>
                  {filteredData.map(transaction => {
                    return (
                        <Item sx={{ pb: 1.5 }}>
                            <List className="transaction-list-mobile" sx={{ px: 2 }}>
                                <ListItem disablePadding className="delete-item">
                                    <ListItemText primary={<Typography><CDate date={transaction.CreatedOn} /></Typography>}/>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemText primary={<Typography><span className="subtitle">{transaction.TransactionType}</span></Typography>} secondary={<Typography><span className="subtitle"><MobileTransaction transaction={transaction} /></span></Typography>} />
                                </ListItem>
                                {transaction.Note &&
                                    <ListItem disablePadding>
                                        <ListItemText primary={<Typography><span className="subtitle">{transaction.Note}</span></Typography>} />
                                    </ListItem>
                                }
                            </List>
                        </Item>
                    )
                  })}
               </Stack>
            }
        </>
    );
};

export default DesktopTransactionList;