import React from "react";
import { Form as FormikForm } from "formik";

type Props = {
    className?: string;
}

const Form: React.FC<Props> = (props) => {
    let className = "standardform";

    if (props.className != null) {
        className = `${className} ${props.className}`;
    }

    return (
        <FormikForm noValidate={true} className={className}>
            {props.children}
        </FormikForm>
    )
}

export default Form;
