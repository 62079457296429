import * as React from 'react';
import { useStatementsByPartyIdQuery, useFinanceSummaryQueryQuery} from "generated/generated-models";
import { CDate } from "../common/CapitalDate";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { DataGrid, GridLinkOperator, GridColDef, GridOverlay } from '@mui/x-data-grid';
import type { Theme } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import MuiLink from "@mui/material/Link";
import FormControl from '@mui/material/FormControl';
import { CError } from "app/common/CError";
import { Loading } from "app/lib/components/Loading";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';

const StatementsListForParty = () => {
  const { data: dataStatements, loading: loadingStatements, error: errorStatements } = useStatementsByPartyIdQuery();
  const { data: dataAccounts, loading: loadingAccounts, error: errorAccounts  } = useFinanceSummaryQueryQuery();
  const statementByPartyId = dataStatements?.statementsByPartyId?.filter(dt=>dt?.AccountNumber);
  const accountsByPartyId = dataAccounts?.financeSummary?.Instruments?.flatMap(instrument => instrument?.Accounts);
  const [selectedAccountNumber, setSelectedAccountNumber] = React.useState('');
  const [filteredData, setFilteredData] = React.useState<Array<string>>([]);
  const [listAccounts, setListAccounts] = React.useState<Array<string>>([]);
  const widthMed = useMediaQuery("(max-width: 575px)");
  const widthSml = useMediaQuery("(max-width: 450px)");
  const { number }: { number: string } = useParams();

  React.useEffect(()=> {
    if(!loadingStatements && dataStatements) {

      if(number) {
        const filterStatements = statementByPartyId!.sort((a, b) => (a!.CreatedOn! > b!.CreatedOn!) ? -1 : 1 ).filter(dt => dt?.AccountNumber === number).map((item: any) => item);
        setFilteredData(filterStatements);
      }
      else {
        const filteredStatementData = statementByPartyId!.sort((a, b) => (a!.CreatedOn! > b!.CreatedOn!) ? -1 : 1 ).map((item: any) => item);
        setFilteredData(filteredStatementData)
      }
    }
    if(!loadingAccounts && dataAccounts) {
      const accountNumber = accountsByPartyId?.map(account => account?.Number);
      const accountNumberObject = accountNumber!.map((item: any) => item);
      setListAccounts(accountNumberObject)
    }
  },[loadingStatements, dataStatements, loadingAccounts, dataAccounts])
  
  if (loadingStatements || loadingAccounts) {
    return <Loading />;
  }
  if (errorStatements || errorAccounts) {
      return <section className="sub-wrap"><CError error={"Could not retrieve account statements."} /></section>
  }

  const handleAccountChange = (_event: any, value: any) => {
    if(value !== null || undefined || "") {
      const filterStatements = statementByPartyId!.sort((a, b) => (a!.CreatedOn! > b!.CreatedOn!) ? -1 : 1 ).filter(dt => dt?.AccountNumber === value).map((item: any) => item);
      setFilteredData(filterStatements);
    }
    else {
       setFilteredData(statementByPartyId!.sort((a, b) => (a!.CreatedOn! > b!.CreatedOn!) ? -1 : 1 ).map((item: any) => item))
    }
  }

  function CustomNoRowsOverlay() {
    return (
      <GridOverlay>
        <Box sx={{ m: 3, textAlign:"center" }}>There are currently no statements for this account.</Box>
      </GridOverlay>
    );
  }

  const rows = filteredData;
  const columns: GridColDef[] = [
    { field: 'InstrumentId', headerName: 'Instrument', width: 0, hide: true, filterable: false },
    { field: 'AdHoc', headerName: '', width: 0, hide: true, filterable: false, },
    { field: 'Number', headerName: 'Statement', minWidth: 120, flex: 2, renderCell: (params) => (params.row.AdHoc === true ? <><MuiLink href={`/api/statements/${params.row.InstrumentId}/documents/${params.row.FileId}`} target="_blank" rel="noopener noreferrer">{params.value}</MuiLink><span className='adhoc'>Adhoc</span></> : <MuiLink href={`/api/statements/${params.row.InstrumentId}/documents/${params.row.FileId}`} target="_blank" rel="noopener noreferrer">{params.value}</MuiLink>) },
    { field: 'AccountNumber', headerName: 'Account', minWidth: 110, flex: 2, },
    { field: 'PeriodFrom', headerName: 'From', minWidth: 120, flex: 2, hide: widthMed, renderCell: (params) => (<CDate date={params.value} />) },
    { field: 'PeriodTo', headerName: 'To', minWidth: 120, flex: 2, renderCell: (params) => (<CDate date={params.value} />) },
    { field: 'FileId', headerName: '', align: 'center', headerAlign: 'center', minWidth: 40, flex: 1, hide: widthSml, filterable: false, renderCell: (params) => (<MuiLink href={`/api/statements/${params.row.InstrumentId}/documents/${params.value}`} target="_blank" rel="noopener noreferrer"><DownloadIcon /></MuiLink>) },
  ];

  return (
    <section className="sub-wrap statements-list">
        <div className="list-header">
          <h2>Statements</h2>
        </div>
        <Box>
          <div className="statement-container" style={{ height: 700, width: '100%' }}>
          <Box sx={{ flex: '1 1 100%' }} className="filterContainer">
            <FormControl sx={{ width: 220, mr: 3 }} size="small" className='filterControl'>
              <Autocomplete
                  id="filtered-account-outlined"
                  size="small"
                  options={listAccounts}
                  getOptionLabel={(option) => option ? option : ""}
                  onChange={handleAccountChange}
                  inputValue={selectedAccountNumber}
                  defaultValue={number ? number : ""}
                  onInputChange={(_event, newSelectedAccountNumber) => {
                    setSelectedAccountNumber(newSelectedAccountNumber);
                  }}
                  PaperComponent={({ children }) => (
                    <Paper style={{ fontSize:'0.9rem' }}>{children}</Paper>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Account" />
                  )}
                />
              </FormControl>
            </Box>
            <DataGrid
                className='custom-grid'
                columns={columns}
                rows={rows}
                getRowId={(r) => r.Id}
                rowsPerPageOptions={[10, 25, 50]}
                initialState={{
                pagination: {
                    pageSize: 10,
                  },
                }}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay
                }}
                disableColumnSelector
                componentsProps={{
                  filterPanel: {
                    linkOperators: [GridLinkOperator.And],
                    columnsSort: 'asc',
                    filterFormProps: {
                      linkOperatorInputProps: {
                        variant: 'outlined',
                        size: 'small',
                      },
                      columnInputProps: {
                        variant: 'outlined',
                        size: 'small',
                        sx: { mt: 'auto' },
                      },
                      operatorInputProps: {
                        variant: 'outlined',
                        size: 'small',
                        sx: { mt: 'auto' },
                      },
                      deleteIconProps: {
                        sx: {
                          '& .MuiSvgIcon-root': { color: '#d32f2f' },
                        },
                      },
                    },
                    sx: {
                      '& .MuiDataGrid-filterForm': { p: 2 },
                      '& .MuiDataGrid-filterForm:nth-child(even)': {
                        backgroundColor: (theme: Theme) =>
                          theme.palette.mode === 'dark' ? '#444' : '#f5f5f5',
                      },
                      '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 2 },
                      '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                      '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                      '& .MuiDataGrid-filterFormValueInput': { width: 200 },
                    },
                  },
                }}
              />
          </div>
        </Box>
      </section>
  );
}

export default StatementsListForParty;