import * as yup from "yup";
import { CreateNewMessageInput } from "./CreateNewMessage";
import { ToDoItemType } from 'generated/generated-models';

export const Validate = () => {

    const schema: yup.ObjectSchema<CreateNewMessageInput> = yup.object({
       
            SelectedToDoItem: yup
            .object<ToDoItemType>()
            .required("Please select a subject"),

            SelectedAccount: yup
            .string()
            .when("SelectedToDoItem", (SelectedToDoItem: ToDoItemType) => {
                if (SelectedToDoItem != undefined && SelectedToDoItem.SecureMessageType?.IsAccount) {
                    return yup.string().required("Please select an account");
                }
                return yup.string();
            }),

            Message: yup
            .string()
            .required("Please enter a message")
        
    })
    .defined();

    return schema;
}
