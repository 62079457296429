import { ApolloClient, InMemoryCache, ApolloLink, HttpOptions } from "@apollo/client";
import { clientConfigFn } from "clientConfig";
import { errorHandler } from "./errorHandler";
import { dataIdFromObject } from "./dataIdFromObject";
import { ClientSchema } from "schema/clientSchema";
import { resolvers } from "../localState/resolvers";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import path from "path";
import { Capacitor } from "@capacitor/core";

const Platform = Capacitor.getPlatform();

console.log(clientConfigFn().baseuri);

// graphQl uri - if not web use base URI else use serve location (reverse proxy required for latter)
const BaseApiUri = Platform !== "web"
    ? new URL(path.join(clientConfigFn().baseuri, "/graphql")).toString()
    : new URL(path.join(window.location.origin, "/graphql")).toString();

export const LoginUri = new URL(path.join(window.location.origin, "/security/login")).href.toString();
export const SecurityUri = new URL(path.join(window.location.origin, "/security")).toString();

function httpLinkOptions(): HttpOptions {
    return {
        credentials: "same-origin",
        uri: BaseApiUri,
        headers: {
            "X-Interfi-Authorisation": "YWJjLWRlZi1naGktMzMzLTMzMy0zMzM=",
        },
    };
}

const apolloCache = new InMemoryCache({
    // cacheRedirects: CacheRedirects,
    dataIdFromObject,
    addTypename: true,
});

const client = new ApolloClient({
    cache: apolloCache,
    link: ApolloLink.from([
        onError(errorHandler),
        // Old types so we just cast it around until everything is happy
        (createUploadLink(httpLinkOptions()) as unknown) as ApolloLink,
    ]),
    resolvers,
    typeDefs: ClientSchema,
});

export { client };
