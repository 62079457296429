import { CTime } from "app/common/CapitalDate";
import { CError } from "app/common/CError";
import Button from '@mui/material/Button';
import DisplayTable from "./LimitsDisplayTable";
import Title from "app/components/Title";
import { AccountLimit} from "generated/generated-models";
import { FormattedDate } from "react-intl";
import { useHistory } from "react-router";
import { Loading } from "app/lib/components/Loading";
import { ApolloError } from "@apollo/client";
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { clientConfigFn } from "clientConfig";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

interface Props {
    partyLimits?: AccountLimit;
    loading?: boolean;
    error?: ApolloError
    isMFAEnabled: boolean
}
    
const PartyLimitsPage = ({ partyLimits, loading, error, isMFAEnabled }: Props) => {
    const history = useHistory();

    if(loading)
    {
        return <Loading />
    }
    if (error != null || partyLimits == null) {
        return <CError error="Could not load party limits" />
    }

    return (
        <div className="sub-wrap form-wrap limits-container">
            <Title title="Current Daily Transfer Limits" />
            <Grid container spacing={2} className="list-limits" direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={4}>
                    <Item>
                        <DisplayTable
                        rowHeading="Pay Anyone"
                        dailyLimit="Daily Limit"
                        rowAmount={partyLimits?.DailyPayAnyoneLimit?.Amount || 0}
                        subHeading="Remaining "
                        subAmount={partyLimits?.RemainingDailyPayAnyoneLimit?.Amount || 0}/>
                    </Item>
                </Grid>
                <Grid item xs={4}>
                    <Item>
                        <DisplayTable
                        rowHeading="Redraw"
                        dailyLimit="Daily Limit"
                        rowAmount={partyLimits?.DailyRedrawLimit?.Amount || 0}
                        subHeading="Remaining "
                        subAmount={partyLimits?.RemainingDailyRedrawLimit?.Amount || 0}/>
                    </Item>
                </Grid>
                {clientConfigFn().showManageBPAYBillers &&
                    <Grid item xs={4}>
                        <Item>
                            <DisplayTable
                            rowHeading="BPAY"
                            dailyLimit="Daily Limit"
                            rowAmount={partyLimits?.DailyBPayLimit?.Amount || 0}
                            subHeading="Remaining "
                            subAmount={partyLimits?.RemainingDailyBPayLimit?.Amount || 0}/>
                        </Item>
                    </Grid>
                }
            </Grid>

            {partyLimits?.LimitEffectiveTo &&
                <>
                <Alert severity="error" className="effective-alert">
                    The above limits will revert automatically to the following limits below on the <b>
                    <FormattedDate value={partyLimits?.LimitEffectiveTo} year="numeric" month="short" day="numeric"/> at <CTime date={partyLimits?.LimitEffectiveTo} /></b>
                </Alert>

                <Grid container spacing={2} className="list-limits">
                    <Grid item xs={4}>
                        <Item>
                            <DisplayTable
                                rowHeading="Pay Anyone"
                                dailyLimit="Daily Limit "
                                rowAmount={partyLimits?.DefaultPayAnyoneLimit?.Amount || 0}/>
                        </Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item>
                            <DisplayTable
                                rowHeading="Redraw"
                                dailyLimit="Daily Limit "
                                rowAmount={partyLimits?.DefaultRedrawLimit?.Amount || 0}/>
                        </Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item>
                        <DisplayTable
                            rowHeading="BPAY"
                            dailyLimit="Daily Limit "
                            rowAmount={partyLimits?.DefaultBPayLimit?.Amount || 0}/>
                        </Item>
                    </Grid>
                </Grid>
                </>
            }

            <div className="form-group button-row">
                <Button 
                    variant="contained" id="cancel"
                    className="btn-cancel"
                    onClick={() => history.push(`/Security/ProfileSettings/Account`)}
                >
                    Cancel
                </Button>
                {isMFAEnabled &&
                    <Button
                        variant="contained" id="submit"
                        type="submit"
                        onClick={() => history.push(`/Security/Limits/Edit`)}
                    >
                        Edit Limits
                    </Button>
                }
            </div>
        </div>
    )
}

export default PartyLimitsPage;