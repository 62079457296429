import SelectBpay from "./SelectBpay";
import CreateBpay from "./CreateBpay";
import ConfirmBpay from "./ConfirmBpay";
import BpayReciept from "./BpayReceipt";
import TransferWizard from "../utilities/TransferWizard";

const BpayPage = (props: { accountId: string }) => {
    return (
        <TransferWizard accountId={props.accountId} useSelectionPage={true} authorityType={"bpay"}>
            <TransferWizard.Select>
                <SelectBpay />
            </TransferWizard.Select>
            <TransferWizard.Create>
                <CreateBpay />
            </TransferWizard.Create>
            <TransferWizard.Confirm>
                <ConfirmBpay />
            </TransferWizard.Confirm>
            <TransferWizard.Receipt>
                <BpayReciept />
            </TransferWizard.Receipt>
        </TransferWizard>
    )
}

export default BpayPage;
