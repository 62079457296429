import classNames from "classnames";
import React from "react";

type Props = {
    loading?: boolean;
    type?: "submit" | "reset" | "button";
    role?: "primary" | "secondary";
    isBlock?: boolean;
    onClick?: (event: React.MouseEvent) => void;
    id?: string;
    className?: string;
    isSubmit?: boolean;
    disabled?: boolean;
    style?: React.CSSProperties
}

const Button: React.FC<Props> = ({
    loading = false,
    isSubmit,
    type = isSubmit ? "submit" : "button",
    role = "primary",
    onClick,
    isBlock,
    id,
    className,
    children,
    disabled = false,
    style
}) => {
    const buttonClasses = classNames([
        "btn",
        className,
        { "btn-primary": role === "primary" },
        { "btn-secondary": role === "secondary" },
        { "btn-block": isBlock },
        { "btn-submit": isSubmit},
    ]);

    return (
        <button
            type={type}
            className={buttonClasses}
            disabled={loading || disabled}
            onClick={onClick}
            id={id}
            style={style}
        >
            {loading && <>
                <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                Loading...
            </>}
            {!loading && <>{ children }</>}
        </button>
    )
}

export default Button;
