import { useInstrumentAuthorityAccountsQuery } from "generated/generated-models";
import { Loading } from "app/lib/components/Loading";
import Title from "app/components/Title";
import { useTransferWizard } from "../utilities/TransferWizard";
import { CError } from "app/common/CError";
import { makeNonEmpty } from "core/util/array";
import FundsTransferCardsContainer from "app/components/fundsTransferCards/FundsTransferCardsContainer";
import FromAccountCard from "app/components/fundsTransferCards/FromAccountCard";
import Button from '@mui/material/Button';
import { useHistory } from "react-router";
import Alert from '@mui/material/Alert';

export type Props = {
    accountId: string;
}

const SelectInterAccount = () => {
    const wizard = useTransferWizard();
    const { data, loading, error } = useInstrumentAuthorityAccountsQuery({
        variables: {
            account: wizard.accountId,
        },
        fetchPolicy: "network-only"
    });
    const history = useHistory();

    if (loading) {
        return <Loading />
    }

    if (error || data?.instrumentByAccount?.Accounts === null) {
        return <CError error="Could not load Pay Anyone details" />
    }

    const currentAccount = data?.instrumentByAccount?.Accounts?.filter(x => x?.Id === wizard.accountId)[0];

    const accounts = makeNonEmpty(data?.instrumentByAccount?.Accounts)?.filter(x => x?.CurrentUserAccountAuthority?.Operate && x.Id !== wizard.accountId);

    return (
        <div>
            <div className="select-destination">
                <Title
                    title="Select To Account"
                    subtitle={`From Account ${currentAccount?.Number}`}
                />
                {accounts.length === 0 &&
                    <Alert severity="warning">No Accounts available for selection</Alert>
                }
                <FundsTransferCardsContainer>
                    {accounts.map(x =>
                        <FromAccountCard 
                        account={x} 
                        onClick={() => wizard.confirmInterAccount(x)} />
                        )}
                </FundsTransferCardsContainer>
            </div>
            <div className="form-group button-row">
                <Button
                    onClick={() => history.goBack()}
                    sx={{ width: 150, p: 1.5, mt: 1 }}
                    variant="contained"
                    className="btn-cancel"
                    id="cancelButton">
                    Cancel
                </Button>
            </div>
        </div>
    )
}

export default SelectInterAccount;
