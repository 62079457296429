import DOMPurify from "dompurify";

type SanitisedImageProps = {
    src: string,
    alt?: string,
    className?: string,
    width?: string,
    height?: string,
}

const SanitisedImage = (props: SanitisedImageProps) => {
    return (
            <img src={DOMPurify.sanitize(props.src)} 
                alt={props.alt}
                className={props.className}
                width={props.width}
                height={props.height}
                />
    )
}

export default SanitisedImage;