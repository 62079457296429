import { TransferAccount } from "generated/generated-models";
import React, { ReactNode, useState } from "react";

export type TransferAndPaymentStage =
    | "Create"
    | "Confirm"
    | "Receipt"

export type TransfersPaymentContextProps = {
    formStage: TransferAndPaymentStage;
    formState: any;
    transferType?: string;
    fundsTransferId?: string;
    fromAccount?: TransferAccount;
    toAccount?: TransferAccount;
    isPending?: boolean;
    updateform: (state: any) => void;
    updateStage: (stage: TransferAndPaymentStage) => void;
    updateFundsTransfer: (id: string) => void;
    updateFromAccount: (fromAccount: TransferAccount) => void;
    updateToAccount: (toAccount: TransferAccount) => void;
    updateTransferType: (transferType: string) => void;
    updateIsPending: (isPending: boolean) => void;
  }
  
export const TransferPaymentsContext = React.createContext<TransfersPaymentContextProps | undefined>(undefined);

type TransferPaymentsContextProviderProps = {
    transferType?: string
    children?: ReactNode
}

export const TransferPaymentsContextProvider: React.FC<TransferPaymentsContextProviderProps> = (props) => {
    const [formState, updateFormsState] = useState({});
    const initialStage = "Create";
    const [formStage, updateFormsStage] = useState<TransferAndPaymentStage>(initialStage)
    const [fundsTransferId, updateFundsTransferId] = useState<string | undefined>(undefined);
    const [fromAccount, updateFromAccount] = useState<TransferAccount | undefined>(undefined);
    const [toAccount, updateToAccount] = useState<TransferAccount | undefined>(undefined);
    const [transferType, updateTransferType] = useState(props.transferType);
    const [isPending, updateIsPending] = useState<boolean | undefined>(undefined);

    const context: TransfersPaymentContextProps = {
        formStage: formStage,
        formState: formState,
        transferType: transferType,
        fundsTransferId: fundsTransferId,
        fromAccount: fromAccount,
        toAccount: toAccount,
        isPending: isPending,
        updateform: formState => updateFormsState(() => formState),
        updateStage: formStage => updateFormsStage(() => formStage),
        updateFundsTransfer: updateFundsTransferId,
        updateFromAccount: fromAccount => updateFromAccount(() => fromAccount),
        updateToAccount: toAccount => updateToAccount(() => toAccount),
        updateTransferType: transferType => updateTransferType(() => transferType),
        updateIsPending: updateIsPending
    }

    return (
        <TransferPaymentsContext.Provider value={context}>
            {props.children}
        </TransferPaymentsContext.Provider>
    )
}

export const useTransferPaymentsContext = () => {
    const context = React.useContext(TransferPaymentsContext)
    if (context == null) {
        throw new Error("This component must be used within a <TransferPayments> component.")
    }
    return context
}