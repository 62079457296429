import React from "react";
import ButtonGroup, { Props as ButtonGroupProps } from "./ButtonGroup";
import ButtonGroupItem, { Props as ButtonGroupItemProps } from "./ButtonGroupItem";

type ButtonGroupComposition = {
    Button: React.FC<ButtonGroupItemProps>;
} & React.FC<ButtonGroupProps>;

const composition: ButtonGroupComposition = props =>
    <ButtonGroup {...props} />

composition.Button = ButtonGroupItem;

export default composition;
