import React from "react";
import gql from "graphql-tag";
// import query from "./financeSummaryQuery.graphql";
// type definitions define the "shape" of your data and specify
// which ways the data can be fetched from the GraphQL server.
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client"
import {
    FinanceSummaryQueryInlineQuery,
    Account,
} from "generated/generated-models";
import { Loading } from "../lib/components/Loading";
import { clientConfigFn } from 'clientConfig';
import { FinanceSummaryView } from './FinanceSummaryView';

const query = gql`
    query financeSummaryQueryInline {
        financeSummary {
            Id
            TotalPrincipal {
                CurrencyCode
                Amount
            }
            TotalRedraw {
                CurrencyCode
                Amount
            }
            Instruments {
                Id
                Number
                LoanPurpose
                Description
                TotalPrincipal
                TotalRedraw
                Status
                IsUnderHardshipAgreement
                InstrumentType
                Term
                TotalTermMonths
                RemainingTermMonths
                MaturityDate
                PrimaryBorrower

                Accounts {
                    Id
                    Number
                    Description
                    DisplayOrder
                    InstrumentId
                    LegacyNumber
                    ProductType
                    CurrentBalance
                    Redraw
                    InterestType
                    CardAccount
                }
            }
        }
    }
`;

interface FinanceSummaryComponentProps {
    account: Account | undefined
    handleOnSelectedAccount(account: Account): void
}

const FinanceSummaryComponent = (props: FinanceSummaryComponentProps) => (
    <Query<FinanceSummaryQueryInlineQuery> query={query}>
        {({
            data,
            loading,
            error,
        }: QueryResult<FinanceSummaryQueryInlineQuery>) => {
            if (loading) {
                return <Loading />;
            }
            if (error) {
                return <div>`Error!: ${error.message}`</div>;
            }
            if (!data) {
                return FinanceSummaryView({handleOnSelectedAccount: props.handleOnSelectedAccount});
            }
            const financeSummary = data.financeSummary || undefined;
            const {myFinancesTitle, showUserInWelcomeMessage} = clientConfigFn() 

            if(financeSummary!.Instruments!.length > 0 && !props.account) {
                props.handleOnSelectedAccount(financeSummary?.Instruments![0]!.Accounts![0]!)
            }

            return FinanceSummaryView({
                financeSummary,
                myFinancesTitle,
                showUserInWelcomeMessage,
                handleOnSelectedAccount: props.handleOnSelectedAccount
            });
        }}
    </Query>
);

export {
    FinanceSummaryComponent
};
