import React from "react";
import { Link } from 'react-router-dom';
import MoneyFormatted from "../components/MoneyFormatted";
import MoneySigned from "../components/MoneySigned";
import { Account, Instrument, InstrumentByIdOrNumberQuery, useAccountByIdOrNumberQuery, useAccountFundsTransfersQuery, useIndueCardsandAssociationsByAccountQuery } from "generated/generated-models";
import { sortByProp } from "core/util/sort";
import { makeNonEmpty } from "core/util/array";
import { clientConfigFn } from "clientConfig";
import { CError } from "app/common/CError";
import { CApolloError } from "app/common/CApolloError";
import { Loading } from "app/lib/components/Loading";
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import FeedIcon from '@mui/icons-material/Feed';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingUsd, faChartLine } from "@fortawesome/free-solid-svg-icons";
import Button from '@mui/material/Button';

type AccountTableRowProps = {
    redrawConsideration?: string
    account: Account
    instrument: Instrument;
    handleOnSelectedAccount: (account: Account) => void;
}
const { hideTransferPage, allowOnLineStatements } = clientConfigFn();
const AccountTableRow = ({ account, instrument, handleOnSelectedAccount, redrawConsideration }: AccountTableRowProps) => {
    const { data, loading, error } = useAccountFundsTransfersQuery({
        variables: { accountIdOrNumber: account.Number! }
    });
    const [selectedCard, setSelectedCard] = React.useState();
    const { data: dataAccountDetails } = useAccountByIdOrNumberQuery({ variables: { idOrNumber: account.Number! } });
    const accountDetails = dataAccountDetails?.accountByIdOrNumber;
    
    const { data: dataIndueCards, loading: loadingIndueCards, error: errorIndueCards } = useIndueCardsandAssociationsByAccountQuery({
        variables: { accountId: account.Id, accountIdString: account.Id },
        fetchPolicy: "network-only",
    });

    React.useEffect(()=> {
        if(!loadingIndueCards && dataIndueCards?.indueCardsByAccount?.length !== 0) {
            const indueCards = makeNonEmpty(dataIndueCards?.indueCardsByAccount);
            if(indueCards.length > 0) {
                const firstCard = indueCards[0].Id;
                setSelectedCard(firstCard);
            }
        }
      },[loadingIndueCards, dataIndueCards])

    if (loadingIndueCards || errorIndueCards) {
        return <></>;
    }
    
    if (loading) {
        return <Loading />;
    }
    if (error || account == null) {
        return <CError error={"Could not load funds transfer details"} />;
    }    
    if (account == null) {
        return null;
    }
    const showCards = (accountDetails?.CardAvailable ?? false) || (accountDetails?.Cards?.length ?? 0) > 0
    const cardsEnabled = !clientConfigFn().hideCardPage && showCards;

    const clickHandler = (account: Account) => {
        handleOnSelectedAccount(account);
    };
    return (
        <><Accordion disableGutters className={"dash-accordion accordion-child"}
            id={"ac_" + account.Number}
            key={account.Id || account.Number}
            onClick={() => clickHandler(account)} 
        >
            <AccordionSummary className="accordion-summary-child"
                expandIcon={<ExpandMoreIcon />}
            >
                <Grid container spacing={1}>
                    <Grid item xs={1} className="account-details-icon">
                        {instrument.InstrumentType === "Investment"
                            ? <FontAwesomeIcon icon={faChartLine} className="account-icon"/>
                            : <FontAwesomeIcon icon={faHandHoldingUsd} className="account-icon"/>
                        }
                    </Grid>
                    <Grid item xs={5} className="account-details">
                        {clientConfigFn().showPrimaryBorrowerName
                            ? <b>{instrument.PrimaryBorrower}</b>
                            : <b>{account.Description || account.ProductType}</b>}
                        <br />
                        {account.Number}
                    </Grid>
                    <Grid item xs={6} className="account-balances">
                        <p className="banner-balance main-balance"><span className="sub-title">Curr Bal </span>
                            <MoneySigned amount={account.CurrentBalance} direction="total" /></p>

                        {clientConfigFn().showAvailableRedraw &&
                            <p className="banner-balance"><span className="sub-title">Avail Bal </span>
                                {redrawConsideration === "Account" &&
                                    <MoneyFormatted amount={account.Redraw} />
                                }
                                <MoneyFormatted amount={account.Redraw || 0.0} />
                            </p>
                        }
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{ p:1 }}>
                <div className="button-row-center">
                    {!hideTransferPage && data?.accountByIdOrNumber?.CurrentUserAccountAuthority?.Operate &&
                        <Button
                            component={Link}
                            variant="text"
                            to={`/transfersAndPayments`}
                            startIcon={<CurrencyExchangeIcon />}
                            id="payButton">
                            Pay
                        </Button>
                    }
                    {allowOnLineStatements &&
                        <Button className="btn-view-statement"
                            component={Link}
                            variant="text"
                            to={`/account/index/${account.Number}/statements`}
                            startIcon={<FeedIcon />}
                            id="viewStatementsButton">
                            Statements
                        </Button>
                    }
                    {cardsEnabled &&
                    <>
                        {selectedCard !== undefined
                            ? <Button className="btn-view-cards"
                                component={Link}
                                variant="text"
                                to={`/account/index/${account.Number}/induecards/${selectedCard}`}
                                startIcon={<CreditCardIcon />}
                                id="viewSelectedCardButton">
                                Cards
                              </Button>
                            : <Button className="btn-view-cards"
                                component={Link}
                                variant="text"
                                to={`/account/index/${account.Number}/induecards`}
                                startIcon={<CreditCardIcon />}
                                id="viewCardButton">
                                Cards
                              </Button>
                        }
                        </>
                    }
                    <Button className="btn-view-account"
                        component={Link}
                        variant="text"
                        to={`/account/index/${account.Number}`}
                        startIcon={<InfoIcon />}
                        id="viewAccountDetailsButton">
                        Details
                    </Button>
                </div>
            </AccordionDetails>
        </Accordion></>
    );
};

const AccountTableBody = ( props : { instrument: InstrumentByIdOrNumberQuery["instrumentByIdOrNumber"], handleOnSelectedAccount: (account: Account) => void }) => {
    if (props.instrument?.Accounts == null) {
        return (
            <p>No Active Accounts</p>
        );
    }
    return (
        <> {
            sortByProp(makeNonEmpty(props.instrument.Accounts), "DisplayOrder")
                .map(account => <AccountTableRow account={account} redrawConsideration={props.instrument!.RedrawConsideration} instrument={props.instrument!} handleOnSelectedAccount={props.handleOnSelectedAccount} />)
        } </>
    )
};

const AccountsSummary = ( props : { handleOnSelectedAccount: (account: Account) => void, instrument: InstrumentByIdOrNumberQuery["instrumentByIdOrNumber"] }) => {
    if (props.instrument == null) {
        return <CApolloError />;
    }
    return (
        <div className="account-summary-container">
            <div className="accord-container">
                <AccountTableBody instrument={props.instrument} handleOnSelectedAccount={props.handleOnSelectedAccount} />
            </div>
        </div>
    );
};

export default AccountsSummary;
