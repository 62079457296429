import { CError } from "app/common/CError";
import ButtonGroup from "app/components/ButtonGroup";
import RequestMultiFactorTokenFormik from "app/components/RequestMultiFactorTokenFormik";
import Title from "app/components/Title";
import { useSecurityTokenMutation } from "core/apollo/SecurityTokenApollo";
import { nameof } from "core/util/nameof";
import { Form, Formik } from "formik";
import { useIndueCardChangeStatusMutation, IndueCardStatus, useIndueCardActivateCardMutation } from "generated/generated-models";
import { useHistory } from "react-router-dom";
import * as H from 'history';
import { Validate } from "./IndueCardChangeStatusValidate";
import { ValidateActivation } from "./IndueCardActivateValidate";
import React, { useState } from "react";
import Alert from '@mui/material/Alert';
import Input from "app/components/Input";
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';

type IndueCardStatusProps = {
    cardId: string;
    accountNumber: string;
    statusType: IndueCardStatus;
}

export type IndueCardActivationInput = {
    NewPin: string;
    ConfirmPin: string;
    twoFactorToken?: string;
}

export type IndueCardStatusFormProps = {
    twoFactorToken?: string;
    selectedStatus: IndueCardStatus | null;
}

const handleSubmit = async (values: IndueCardStatusFormProps, cardId: string, changeStatus: ReturnType<typeof useIndueCardChangeStatusMutation>["0"], history: H.History) => {
    const result = await changeStatus({
        variables: {
            input:
            {
                CardId: cardId,
                Status: values.selectedStatus!,
                TwoFactorToken: values.twoFactorToken
            }
        }
    });

    if (result.data?.changeStatus?.Result) {
        window.location.reload();
    }
}

const handleActivateCardSubmit = async (values: IndueCardActivationInput, cardId: string, changeStatus: ReturnType<typeof useIndueCardActivateCardMutation>["0"], history: H.History, callback: (succcess: boolean, cardActivationResult: boolean, ChangePinResult: boolean) => void) => {
    const result = await changeStatus({
        variables: {
            input:
            {
                CardId: cardId,
                TwoFactorToken: values.twoFactorToken,
                Pin: values.ConfirmPin.toString()
            }
        }
    });
    callback(result.data?.indueActivateCard?.Success ?? false, result.data?.indueActivateCard?.ActivateCardResult ?? false, result.data?.indueActivateCard?.ChangePinResult ?? false);
}

const ActivateCardForm = ({ cardId }: { cardId: string }) => {
    const [changeStatus, {error, tokenRequired }] = useSecurityTokenMutation(useIndueCardActivateCardMutation());
    const history = useHistory();
    const [cardActivationError, setCardActivationError] = useState(false);
    const [changePinError, setChangePinError] = useState(false);
    const [successActivation, setSuccess] = useState(false);
    const ResultResponse = (succcess: boolean, cardActivationResult: boolean, ChangePinResult: boolean) => {
        setCardActivationError(!cardActivationResult);
        setChangePinError(!ChangePinResult);

        if (succcess === true) {
            setSuccess(true);

            setTimeout(
                () => {
                    setSuccess(true);
                    window.location.reload();
                },
                1500);
        }
    };

    return (
        <Formik
            initialValues={{ NewPin: "", ConfirmPin: "" }}
            validationSchema={() => ValidateActivation(tokenRequired)}
            onSubmit={(values) => handleActivateCardSubmit(values, cardId, changeStatus, history, ResultResponse)}
        >
            {({ isSubmitting }) => {
                return (
                    <>
                        <Title title="Card Activation" />
                        <p>Choose a new 4 digit PIN to confirm card activation. You should use this PIN the next time you are using this card.</p>
                        <Form>
                            <Input
                                name={nameof<IndueCardActivationInput>("NewPin")}
                                type="wholeNumberAuth"
                                label="New PIN"
                            />
                            <Input
                                name={nameof<IndueCardActivationInput>("ConfirmPin")}
                                type="wholeNumberAuth"
                                label="Confirm PIN"
                            />
                            <RequestMultiFactorTokenFormik
                                name={nameof<IndueCardActivationInput>("twoFactorToken")}
                                showRequestNewToken={true}
                                requestMfaOnDisplay={true}
                                visible={tokenRequired}
                            />

                            {successActivation === true &&
                                <Alert severity="success">Card has been activated and PIN has been changed.</Alert>
                            }
                            {error != null &&
                                <Alert severity="error">Failed to activate the card and change PIN. {error?.message}</Alert>
                            }
                            {cardActivationError &&
                                <Alert severity="error">Failed to activate the card. No new PIN has been set for the card. Please try again and if the problem persists contact support for assistance.</Alert>
                            }
                            {changePinError && !cardActivationError &&
                                <Alert severity="error">Failed to change the PIN, however the card has been activated. Your PIN still needs to be changed. Please change your PIN via the change PIN page or contact support for assistance.</Alert>
                            }

                            <div className="button-row">
                                <Button
                                    id="cancel"
                                    className="btn-cancel"
                                    sx={{ p: 1.5, mt: 1 }}
                                    variant="contained"
                                    onClick={() => window.location.reload()}
                                    disabled={isSubmitting}>
                                    Cancel
                                </Button>
                                <LoadingButton
                                    type="submit" id="submit"
                                    sx={{ p: 1.5, mt: 1 }}
                                    loading={isSubmitting}
                                    loadingPosition="end"
                                    variant="contained"
                                >
                                    Confirm
                                </LoadingButton>
                            </div>
                        </Form>
                    </>
                )
            }}
        </Formik>
    )
}

const CloseCardForm = ({ cardId }: { cardId: string }) => {
    const [changeStatus, { error, tokenRequired }] = useSecurityTokenMutation(useIndueCardChangeStatusMutation());
    const history = useHistory();

    return (
        <Formik
            initialValues={{ selectedStatus: IndueCardStatus.Closed }}
            validationSchema={() => Validate(tokenRequired)}
            onSubmit={(values) => handleSubmit(values, cardId, changeStatus, history)}
        >
            {({ isSubmitting }) => {
                return (
                    <>
                        <Title title="Close Card?" />
                        <p>Please confirm that you wish to close the selected card.</p>
                        <Form>
                            <RequestMultiFactorTokenFormik
                                name={nameof<IndueCardStatusFormProps>("twoFactorToken")}
                                showRequestNewToken={true}
                                requestMfaOnDisplay={true}
                                visible={tokenRequired}
                            />
                            {error != null &&
                                <Alert severity="error">Error closing card. {error?.message}</Alert>
                            }
                            <div className="button-row">
                                <Button
                                    className="btn-cancel" 
                                    id="cancel"
                                    sx={{ p: 1.5, mt: 1 }}
                                    variant="contained"
                                    onClick={() => window.location.reload()}
                                    disabled={isSubmitting}>
                                    Cancel
                                </Button>
                                <LoadingButton
                                    type="submit" id="submit"
                                    sx={{ p: 1.5, mt: 1 }}
                                    loading={isSubmitting}
                                    loadingPosition="end"
                                    variant="contained"
                                >
                                    Confirm
                                </LoadingButton>
                            </div>
                        </Form>
                    </>
                )
            }}
        </Formik>
    )
}

const HoldCardForm = ({ cardId }: { cardId: string }) => {
    const [changeStatus, { error, tokenRequired }] = useSecurityTokenMutation(useIndueCardChangeStatusMutation());
    const history = useHistory();

    return (
        <Formik
            initialValues={{ selectedStatus: IndueCardStatus.Activeoff }}
            validationSchema={() => Validate(tokenRequired)}
            onSubmit={(values) => handleSubmit(values, cardId, changeStatus, history)}
        >
            {({ isSubmitting }) => {
                return (
                    <>
                        <Title title="Hold Card?"/>
                        <p>You are about to place a hold on this card. Please confirm you are happy to proceed.</p>
                        <Form>
                            <RequestMultiFactorTokenFormik
                                name={nameof<IndueCardStatusFormProps>("twoFactorToken")}
                                showRequestNewToken={true}
                                requestMfaOnDisplay={true}
                                visible={tokenRequired}
                            />
                            {error != null &&
                                <Alert severity="error">Error holding card. {error?.message}</Alert>
                            }
                            <div className="button-row">
                                <Button
                                    className="btn-cancel"
                                    id="cancel"
                                    sx={{ p: 1.5, mt: 1 }}
                                    variant="contained"
                                    onClick={() => window.location.reload()}
                                    disabled={isSubmitting}>
                                    Cancel
                                </Button>
                                <LoadingButton
                                    type="submit" id="submit"
                                    sx={{ p: 1.5, mt: 1 }}
                                    loading={isSubmitting}
                                    loadingPosition="end"
                                    variant="contained"
                                >
                                    Confirm
                                </LoadingButton>
                            </div>
                        </Form>
                    </>
                )
            }}
        </Formik>
    )
}

const LostOrStolenCardForm = ({ cardId }: { cardId: string }) => {
    const [changeStatus, { error, tokenRequired }] = useSecurityTokenMutation(useIndueCardChangeStatusMutation());
    const history = useHistory();

    return (
        <Formik
            initialValues={{
                selectedStatus: null
            }}
            validationSchema={() => Validate(tokenRequired)}
            onSubmit={(values) => handleSubmit(values, cardId, changeStatus, history)}
        >
            {({ isSubmitting, errors }) => {
                return (
                    <>
                        <Title title="Card lost or stolen?" />
                        <p>Please advise if your card is lost or stolen, this action cannot be undone.</p>
                        <Form>
                            <ButtonGroup
                                name={nameof<IndueCardStatusFormProps>("selectedStatus")}
                            >
                                <ButtonGroup.Button
                                    value={IndueCardStatus.Lost}
                                    className="indue-status-buttongroup"
                                >
                                    Lost
                                </ButtonGroup.Button>
                                <ButtonGroup.Button
                                    value={IndueCardStatus.Stolen}
                                    className="indue-status-buttongroup"
                                >
                                    Stolen
                                </ButtonGroup.Button>
                            </ButtonGroup>
                            <RequestMultiFactorTokenFormik
                                name={nameof<IndueCardStatusFormProps>("twoFactorToken")}
                                showRequestNewToken={true}
                                requestMfaOnDisplay={true}
                                visible={tokenRequired}
                            />
                            {error != null &&
                                <Alert severity="error">Error setting card as lost/stolen. {error?.message}</Alert>
                            }
                            <div className="button-row">
                                <Button
                                    className="btn-cancel"
                                    id="cancel"
                                    sx={{ p: 1.5, mt: 1 }}
                                    variant="contained"
                                    onClick={() => window.location.reload()}
                                    disabled={isSubmitting}>
                                    Cancel
                                </Button>
                                <LoadingButton
                                    type="submit" id="submit"
                                    sx={{ p: 1.5, mt: 1 }}
                                    loading={isSubmitting}
                                    loadingPosition="end"
                                    variant="contained"
                                >
                                    Confirm
                                </LoadingButton>
                            </div>
                        </Form>
                    </>
                )
            }}
        </Formik>
    )
}

const IndueCardConfirmStatusChange = ({ cardId, accountNumber, statusType }: IndueCardStatusProps) => {
    switch (statusType) {
        case IndueCardStatus.Active:
            return <ActivateCardForm cardId={cardId} />
        case IndueCardStatus.Activeoff:
            return <HoldCardForm cardId={cardId} />
        case IndueCardStatus.Lost:
        case IndueCardStatus.Stolen:
            return <LostOrStolenCardForm cardId={cardId} />
        case IndueCardStatus.Closed:
            return <CloseCardForm cardId={cardId} />
        default:
            return <CError error="No valid status was found" />
    }
}

export default IndueCardConfirmStatusChange;
