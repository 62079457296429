import { FormattedNumber } from "react-intl";

type Props = {
    number: number
}

const NumberPercentage = (props: Props) => {
    return (
        <FormattedNumber
            value={props.number / 100}
            // eslint-disable-next-line
            style="percent"
            minimumFractionDigits={2}
            maximumFractionDigits={2}
        />
    );
}

export default NumberPercentage;