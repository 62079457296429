import * as yup from "yup";
import { GetSecurityTokenValidator } from "app/validation/securityTokenValidator";
import { EditPartyLimitsInput } from "./EditPartyLimitsPage";
import { DateRequiredValidator } from "./dateValidator";
import { validateLimit } from "./maxLimitValidation";

export const Validate = (requiresMfa: boolean, isTemporary: boolean) => {
    const schema: yup.ObjectSchema<EditPartyLimitsInput> = yup.object({
        PayAnyoneLimit: validateLimit("payAnyone"),
        
        RedrawLimit: validateLimit("redraw"),
        
        BpayLimit: validateLimit("bpay"),

        EffectiveTo: DateRequiredValidator(isTemporary),
        
        TwoFactorToken: GetSecurityTokenValidator(requiresMfa)
    })
    .defined();

    return schema;
}