import CreateInterAccount from "./CreateInterAccount";
import ConfirmInterAccount from "./ConfirmInterAccount";
import InterAccountReceipt from "./InterAccountReceipt";
import TransferWizard from "../utilities/TransferWizard";
import SelectInterAccount from "./SelectInterAccount";

const InterAccountPage = (props: { accountId: string }) => {
    return (
        <TransferWizard accountId={props.accountId} useSelectionPage={true} authorityType={"operate"}>
            <TransferWizard.Create>
                <CreateInterAccount />
            </TransferWizard.Create>
            <TransferWizard.Confirm>
                <ConfirmInterAccount />
            </TransferWizard.Confirm>
            <TransferWizard.Receipt>
                <InterAccountReceipt />
            </TransferWizard.Receipt>
            <TransferWizard.Select>
                <SelectInterAccount />
            </TransferWizard.Select>
        </TransferWizard>
    )
}

export default InterAccountPage;
