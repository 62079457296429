import React from "react";
import DisplayHtmlContext from "./DisplayHtmlContent";

const TermsAndConditions = () => {
    return <DisplayHtmlContext
        url="/client/misc/TermsAndConditions.html"
        errorMessage="Could not load terms and conditions"
    />
}

export default TermsAndConditions;
