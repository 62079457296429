import MoneyFormatted from "./MoneyFormatted";
import { clientConfigFn } from "../../clientConfig";
import classNames from "classnames";

type Props = {
    amount?: number,
    direction: "credit" | "debit" | "total"
    isShowZero?: boolean,
    addColour?: boolean,
    className?: string
}

const signMoney = (amount: number, direction: "credit" | "debit" | "total") => {
    const config = clientConfigFn();
    var isLenderPerspective = config.showTransactionsAsLender

    if (amount === 0) {
        return 0;
    }

    if (isLenderPerspective) {
        if (direction === "credit") {
            return -1 * amount;
        }
        else if (direction === "debit" || direction === "total") {
            return amount;
        }
    }
    else {
        if (direction === "credit") {
            return amount;
        }
        else if (direction === "debit" || direction === "total") {
            return -1 * amount;
        }
    }

    return amount;
}

const MoneySigned = ({ amount = 0, direction, isShowZero = true, addColour = false, className }: Props) => {
    if (amount === 0 && !isShowZero) {
        return null;
    }

    const classes = classNames([
        {"money-credit": direction === "credit" },
        {"money-debit": direction === "debit" },
        {"money-total": direction === "total" },
        {"money-colorise": addColour },
        className
    ]);

    return <MoneyFormatted
        amount={signMoney(amount, direction)}
        addColour={addColour}
        className={classes}
    />;
}

export default MoneySigned;
