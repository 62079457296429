import React from "react";
import { TransferPaymentsContextProvider } from "./useTransferPaymentsContext";

export type Props = {
    transferType?: string;
}

const TransferPayments: React.FC<Props> = (props) => {
    return (
        <div className="sub-wrap">
            <TransferPaymentsContextProvider
                transferType={props.transferType}
            >
                {props.children}
            </TransferPaymentsContextProvider>
        </div>
    )
}

export default TransferPayments;
