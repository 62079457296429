import { useMediaQuery } from "react-responsive"

type ScreenSize =
    | "mobile"
    | "desktop"

export const useScreenSize: () => ScreenSize = () => {
    const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })

    if (isDesktop) {
        return "desktop";
    } else {
        return "mobile";
    }
}
