import * as yup from "yup";
import { PaymentFormProps } from "./CreatePayment";
import { validateSchedule, validateTransferDescription } from "../utilities/validation";

export const Validate = () => {
    const schema: yup.ObjectSchema<PaymentFormProps> = yup.object({
        
        transferDescription: validateTransferDescription("Description"),
        transferReference: validateTransferDescription("Reference"),

        startingOn: yup
            .date()
            .required(),

        amount: yup
            .number()
            .required()
            .moreThan(0)
            .label("Amount"),

        ...validateSchedule()
    }).defined();

    return schema;
}
