import Button from "app/components/Button";
import Title from "app/components/Title";
import { AccountTransaction } from "generated/generated-models";
import MobileTransaction from "./MobileTransaction";

type Props = {
    goBack: () => void;
    transaction: AccountTransaction
}

const ListItem = ({ title, value }: { title: string; value?: string; }) => (
    <li className="list-group-item">
        <h6 className="term">
            {title}
        </h6>
        <div className="definition">
            {value}
        </div>
    </li>
)

const TransactionContainer: React.FC<Props> = ({ goBack, transaction, children }) => {

    return (
        <div className="mobile-transaction-detail-container">
            <Title title="Transaction" />
            <div className="card">
                <div className="card-header">
                    <h6 className="term">
                        {transaction.StatementText}
                    </h6>
                    <div className="definition">
                        <MobileTransaction transaction={transaction} />
                    </div>
                </div>

                <>{children}</>
            </div>

            <Button onClick={goBack} role="secondary" isBlock={true}  id="goBackButton">Go Back</Button>
        </div>
    )
}

const TransactionView = ({ transaction }: { transaction: AccountTransaction }) => {

    return (
        <ul className="list-group list-group-flush">
            <ListItem title="Note" value={transaction.Note} />
            <ListItem title="Date" value={(new Date(transaction.CreatedOn)).toDateString()} />
            <ListItem title="Transaction Source" value="Cheque Transaction" />
            <ListItem title="Description" value="" />
            <ListItem title="Additional Text" value="INDUE MESSAGE DECODER CHATSWOOD NSW" />
            <ListItem title="Card Number" value="366000xxxxxx0412" />
            <ListItem title="Terminal ID" value="654" />
            <ListItem title="Location" value="INDUE MESSAGE DECODER NSW" />
            <ListItem title="Merchant Code" value="5541" />
            <ListItem title="Processing Code" value="Debit" />
            <ListItem title="Billing Flag" value="POS" />
            <ListItem title="Cash Component" value="$0.00" />
            <ListItem title="Device Type" value="POS" />
            <ListItem title="Transaction Subtype" value="POS with goods sold" />
        </ul>
    )
}

const PendingTransactionView = ({ transaction }: { transaction: AccountTransaction }) => {

    return (
        <ul className="list-group list-group-flush">
            <ListItem title="Note" value={transaction.Note} />
            <ListItem title="Message ID" value="fc31e3d01949490894b05ef59d1095dc" />
            <ListItem title="Pending ID" value="1092" />
            <ListItem title="Date Created" value={(new Date(transaction.CreatedOn)).toDateString()} />
            <ListItem title="Trace Number" value="82938" />
            <ListItem title="Card Number" value="366000XXXXXX0412" />
            <ListItem title="Description" value="AUSACC" />
            <ListItem title="Merchant ID" value="321" />
            <ListItem title="Merchant" value="TEST MERCHANT" />
            <ListItem title="Location" value="SYDNEY NSW AU" />
        </ul>
    )
}

const MobileTransactionDetail = ({ goBack, transaction }: Props) => {
    if (transaction.IsPending) {
        return (
            <TransactionContainer goBack={goBack} transaction={transaction}>
                <PendingTransactionView transaction={transaction} />
            </TransactionContainer>
        )
    } else {
        return (
            <TransactionContainer goBack={goBack} transaction={transaction}>
                <TransactionView transaction={transaction} />
            </TransactionContainer>
        )
    }
}

export default MobileTransactionDetail;
