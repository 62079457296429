import { ApolloError } from "@apollo/client";
import { BankingUserLoginV2Mutation, LoginV3AuthError } from "generated/generated-models";
import Alert from '@mui/material/Alert';

const generateErrorMessage = (error?: ApolloError, data?: BankingUserLoginV2Mutation | null, isUsingGoogleAuth?:boolean | null) => {
    if (error != null) {
        return "An error has occured during login";
    }

    switch (data?.loginV2?.AuthError) {
        case LoginV3AuthError.Invalidcredentials:
            return "Invalid Username or Password";
        case LoginV3AuthError.Mfatokeninvalid:
            return isUsingGoogleAuth ? "Invalid Auth Code" : "Invalid Security Token";
        case LoginV3AuthError.Newpasswordinvalid:
            return `New Password is invalid: ${data.loginV2.AuthMessage}`;
        case LoginV3AuthError.Passwordexpired:
            return "Your temporary password has expired";
        case LoginV3AuthError.Mfarequired:
        case LoginV3AuthError.Requirepasswordchange:
        default:
            return null;
    }
}

const LoginErrorMessage = ({ loginError, loginResponse, isUsingGoogleAuth }: { loginError?: ApolloError, loginResponse?: BankingUserLoginV2Mutation | null, isUsingGoogleAuth?:boolean | null }) => {
    const errorMessage = generateErrorMessage(loginError, loginResponse, isUsingGoogleAuth);

    if (errorMessage == null) {
        return null;
    }

    return (
        <Alert severity="error">{errorMessage}</Alert>
    )
}


export default LoginErrorMessage;