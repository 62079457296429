import React from "react";
import { useTransferPaymentsContext } from "./useTransferPaymentsContext";

const Confirm: React.FC = (props) => {
    const context = useTransferPaymentsContext();

    if (context.formStage === "Confirm") {
        return (
            <>
                {props.children}
            </>
        )
    }

    return null;
}

export default Confirm;
